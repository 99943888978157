// local environment
/*export const environment = {
    production: false,
    dateFormatCreate: 'dd/mm/yy',
    dateFormatEdit: 'dd/mm/yy',
    dateFormatRdv: 'dd/MM/yyyy',
    dateFormatView: 'dd/mm/yy',
    dateFormatList: 'dd/MM/yyyy',
    trueValue: 'Vrai',
    falseValue: 'Faux',
    emptyForExport: '-----',
    baseUrl: 'http://localhost:8036/api/',
    apiUrl: 'http://localhost:8036/api/',
    loginUrl: 'http://localhost:8036/',
    rootAppUrl: 'app',
    emailValidation: undefined
};*/
/*export const environment = {
    production: false,
    dateFormatCreate: 'dd/mm/yy',
    dateFormatEdit: 'dd/mm/yy',
    dateFormatRdv: 'dd/MM/yyyy',
    dateFormatView: 'dd/mm/yy',
    dateFormatList: 'dd/MM/yyyy',
    trueValue: 'Vrai',
    falseValue: 'Faux',
    emptyForExport: '-----',
    ipAdress: '82.66.33.186',
    baseUrl: 'http://82.66.33.186/back/api/',
    apiUrl: 'http://82.66.33.186/back/api/',
    loginUrl: 'http://82.66.33.186/back/',
    rootAppUrl: 'app',
    emailValidation: undefined
};
*/
// prod environment
/*export const environment = {
    production: false,
    dateFormatCreate: 'dd/mm/yy',
    dateFormatEdit: 'dd/mm/yy',
    dateFormatRdv: 'dd/MM/yyyy',
    dateFormatView: 'dd/mm/yy',
    dateFormatList: 'dd/MM/yyyy',
    trueValue: 'Vrai',
    falseValue: 'Faux',
    emptyForExport: '-----',
    ipAdress: '90.8.232.129',
    baseUrl: 'http://90.8.232.129/back/api/',
    apiUrl: 'http://90.8.232.129/back/api/',
    loginUrl: 'http://90.8.232.129/back/',
    rootAppUrl: 'app',
    emailValidation: undefined
};*/
// pre-prod environment
export const environment = {
    production: false,
    dateFormatCreate: 'dd/mm/yy',
    dateFormatEdit: 'dd/mm/yy',
    dateFormatRdv: 'dd/MM/yyyy',
    dateFormatView: 'dd/mm/yy',
    dateFormatList: 'dd/MM/yyyy',
    trueValue: 'Vrai',
    falseValue: 'Faux',
    emptyForExport: '-----',
    ipAdress: '82.66.33.186',
    baseUrl: 'http://82.66.33.186/back/api/',
    apiUrl: 'http://82.66.33.186/back/api/',
    loginUrl: 'http://82.66.33.186/back/',
    rootAppUrl: 'app',
    emailValidation: undefined
}
