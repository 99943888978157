import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {TemplateEmailPropositionVo} from '../../model/template/TemplateEmailProposition.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEmailPropositionService {
    public editTemplateEmailProposition$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/templateEmailProposition/';
        });
    }

    private _templateEmailPropositions: Array<TemplateEmailPropositionVo>;
    get templateEmailPropositions(): Array<TemplateEmailPropositionVo> {
        if (this._templateEmailPropositions == null) {
            this._templateEmailPropositions = new Array<TemplateEmailPropositionVo>();
        }
        return this._templateEmailPropositions;
    }

    set templateEmailPropositions(value: Array<TemplateEmailPropositionVo>) {
        this._templateEmailPropositions = value;
    }

    private _selectedTemplateEmailProposition: TemplateEmailPropositionVo;
    get selectedTemplateEmailProposition(): TemplateEmailPropositionVo {
        if (this._selectedTemplateEmailProposition == null) {
            this._selectedTemplateEmailProposition = new TemplateEmailPropositionVo();
        }
        return this._selectedTemplateEmailProposition;
    }

    set selectedTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this._selectedTemplateEmailProposition = value;
    }

    private _templateEmailPropositionSelections: Array<TemplateEmailPropositionVo>;
    get templateEmailPropositionSelections(): Array<TemplateEmailPropositionVo> {
        if (this._templateEmailPropositionSelections == null) {
            this._templateEmailPropositionSelections = new Array<TemplateEmailPropositionVo>();
        }
        return this._templateEmailPropositionSelections;
    }

    set templateEmailPropositionSelections(value: Array<TemplateEmailPropositionVo>) {
        this._templateEmailPropositionSelections = value;
    }

    private _createTemplateEmailPropositionDialog: boolean;
    get createTemplateEmailPropositionDialog(): boolean {
        return this._createTemplateEmailPropositionDialog;
    }

    set createTemplateEmailPropositionDialog(value: boolean) {
        this._createTemplateEmailPropositionDialog = value;
    }

    private _editTemplateEmailPropositionDialog: boolean;
    get editTemplateEmailPropositionDialog(): boolean {
        return this._editTemplateEmailPropositionDialog;
    }

    set editTemplateEmailPropositionDialog(value: boolean) {
        this._editTemplateEmailPropositionDialog = value;
    }

    private _viewTemplateEmailPropositionDialog: boolean;
    get viewTemplateEmailPropositionDialog(): boolean {
        return this._viewTemplateEmailPropositionDialog;
    }

    set viewTemplateEmailPropositionDialog(value: boolean) {
        this._viewTemplateEmailPropositionDialog = value;
    }

    private _searchTemplateEmailProposition: TemplateEmailPropositionVo;
    get searchTemplateEmailProposition(): TemplateEmailPropositionVo {
        if (this._searchTemplateEmailProposition == null) {
            this._searchTemplateEmailProposition = new TemplateEmailPropositionVo();
        }
        return this._searchTemplateEmailProposition;
    }

    set searchTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this._searchTemplateEmailProposition = value;
    }

    public findAll() {
        return this.http.get<Array<TemplateEmailPropositionVo>>(this.API);
    }

    public save(): Observable<TemplateEmailPropositionVo> {
        return this.http.post<TemplateEmailPropositionVo>(this.API, this.selectedTemplateEmailProposition);
    }

    delete(templateEmailProposition: TemplateEmailPropositionVo) {
        return this.http.delete<number>(this.API + 'id/' + templateEmailProposition.id);
    }

    public edit(): Observable<TemplateEmailPropositionVo> {
        return this.http.put<TemplateEmailPropositionVo>(this.API, this.selectedTemplateEmailProposition);
    }

    public findByCriteria(templateEmailProposition: TemplateEmailPropositionVo): Observable<Array<TemplateEmailPropositionVo>> {
        return this.http.post<Array<TemplateEmailPropositionVo>>(this.API + 'search', templateEmailProposition);
    }

    public findByIdWithAssociatedList(templateEmailProposition: TemplateEmailPropositionVo): Observable<TemplateEmailPropositionVo> {
        return this.http.get<TemplateEmailPropositionVo>(this.API + 'detail/id/' + templateEmailProposition.id);
    }
}
