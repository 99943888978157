import {Component, OnInit} from '@angular/core';
import {TemplateEmailPropositionService} from 'src/app/controller/service/template/TemplateEmailProposition.service';
import {TemplateEmailPropositionVo} from 'src/app/controller/model/template/TemplateEmailProposition.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-template-email-proposition-view-admin',
    templateUrl: './template-email-proposition-view-admin.component.html',
    styleUrls: ['./template-email-proposition-view-admin.component.css']
})
export class TemplateEmailPropositionViewAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private templateEmailPropositionService: TemplateEmailPropositionService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    get templateEmailPropositions(): Array<TemplateEmailPropositionVo> {
        return this.templateEmailPropositionService.templateEmailPropositions;
    }

    set templateEmailPropositions(value: Array<TemplateEmailPropositionVo>) {
        this.templateEmailPropositionService.templateEmailPropositions = value;
    }

    get selectedTemplateEmailProposition(): TemplateEmailPropositionVo {
        return this.templateEmailPropositionService.selectedTemplateEmailProposition;
    }

    set selectedTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this.templateEmailPropositionService.selectedTemplateEmailProposition = value;
    }

    get viewTemplateEmailPropositionDialog(): boolean {
        return this.templateEmailPropositionService.viewTemplateEmailPropositionDialog;
    }

    set viewTemplateEmailPropositionDialog(value: boolean) {
        this.templateEmailPropositionService.viewTemplateEmailPropositionDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatView;
    }

    get dateFormatColumn() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
    }

    hideViewDialog() {
        this.viewTemplateEmailPropositionDialog = false;
    }
}
