import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ordre-kosc-rappel-48-heures-cdd',
    templateUrl: './ordre-kosc-rappel-48-heures-cdd.component.html',
    styleUrls: ['./ordre-kosc-rappel-48-heures-cdd.component.css']
})
export class OrdreKoscRappel48HeuresCddComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
