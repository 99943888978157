import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {StatutEmailVo} from '../../model/template/StatutEmail.model';
import {OrdreKoscVo} from "../../model/kosc/OrdreKosc.model";

@Injectable({
    providedIn: 'root'
})
export class StatutEmailService {
    public editStatutEmail$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/statutEmail/';
        });
    }

    private _statutEmails: Array<StatutEmailVo>;
    get statutEmails(): Array<StatutEmailVo> {
        if (this._statutEmails == null) {
            this._statutEmails = new Array<StatutEmailVo>();
        }
        return this._statutEmails;
    }

    set statutEmails(value: Array<StatutEmailVo>) {
        this._statutEmails = value;
    }

    private _selectedStatutEmail: StatutEmailVo;
    get selectedStatutEmail(): StatutEmailVo {
        if (this._selectedStatutEmail == null) {
            this._selectedStatutEmail = new StatutEmailVo();
        }
        return this._selectedStatutEmail;
    }

    set selectedStatutEmail(value: StatutEmailVo) {
        this._selectedStatutEmail = value;
    }

    private _statutEmailSelections: Array<StatutEmailVo>;
    get statutEmailSelections(): Array<StatutEmailVo> {
        if (this._statutEmailSelections == null) {
            this._statutEmailSelections = new Array<StatutEmailVo>();
        }
        return this._statutEmailSelections;
    }

    set statutEmailSelections(value: Array<StatutEmailVo>) {
        this._statutEmailSelections = value;
    }

    private _createStatutEmailDialog: boolean;
    get createStatutEmailDialog(): boolean {
        return this._createStatutEmailDialog;
    }

    set createStatutEmailDialog(value: boolean) {
        this._createStatutEmailDialog = value;
    }

    private _editStatutEmailDialog: boolean;

    get editStatutEmailDialog(): boolean {
        return this._editStatutEmailDialog;
    }

    set editStatutEmailDialog(value: boolean) {
        this._editStatutEmailDialog = value;
    }

    private _viewStatutEmailDialog: boolean;

    get viewStatutEmailDialog(): boolean {
        return this._viewStatutEmailDialog;
    }

    set viewStatutEmailDialog(value: boolean) {
        this._viewStatutEmailDialog = value;
    }

    private _searchStatutEmail: StatutEmailVo;
    get searchStatutEmail(): StatutEmailVo {
        if (this._searchStatutEmail == null) {
            this._searchStatutEmail = new StatutEmailVo();
        }
        return this._searchStatutEmail;
    }

    set searchStatutEmail(value: StatutEmailVo) {
        this._searchStatutEmail = value;
    }

    public async loadStatutEmailExcept(etatNonDesire: Array<String>, searchOrdreKosc: OrdreKoscVo) {
        this.findAll().subscribe(statutEmails => {
            this.statutEmails = statutEmails;
            searchOrdreKosc.statutEmailVos = this.statutEmails.filter(e => etatNonDesire.indexOf(e.code) == -1);
        });
    }

    public findByCode(code: string, etats: Array<StatutEmailVo>) {
        return etats.find(e => e.code === code);

    }

    public findAll() {
        return this.http.get<Array<StatutEmailVo>>(this.API);
    }

    public save(): Observable<StatutEmailVo> {
        return this.http.post<StatutEmailVo>(this.API, this.selectedStatutEmail);
    }

    delete(statutEmail: StatutEmailVo) {
        return this.http.delete<number>(this.API + 'id/' + statutEmail.id);
    }

    public edit(): Observable<StatutEmailVo> {
        return this.http.put<StatutEmailVo>(this.API, this.selectedStatutEmail);
    }

    public findByCriteria(statutEmail: StatutEmailVo): Observable<Array<StatutEmailVo>> {
        return this.http.post<Array<StatutEmailVo>>(this.API + 'search', statutEmail);
    }

    public findByIdWithAssociatedList(statutEmail: StatutEmailVo): Observable<StatutEmailVo> {
        return this.http.get<StatutEmailVo>(this.API + 'detail/id/' + statutEmail.id);
    }
}
