import {Component, OnInit} from '@angular/core';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {
    TemplateEmailRappel48HeuresService
} from "../../../../../../controller/service/template/TemplateEmailRappel48Heures.service";
import {
    TemplateEmailRappel48HeuresVo
} from "../../../../../../controller/model/template/TemplateEmailRappel48Heures.model";

@Component({
    selector: 'app-template-email-rappel-48-heures-view-admin',
    templateUrl: './template-email-rappel-48-heures-view-admin.component.html',
    styleUrls: ['./template-email-rappel-48-heures-view-admin.component.css']
})
export class TemplateEmailRappel48HeuresViewAdminComponent implements OnInit {

    constructor(private datePipe: DatePipe, private templateEmailRappel48HeuresService: TemplateEmailRappel48HeuresService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    get templateEmailRappel48Heuress(): Array<TemplateEmailRappel48HeuresVo> {
        return this.templateEmailRappel48HeuresService.templateEmailRappel48Heuress;
    }

    set templateEmailRappel48Heuress(value: Array<TemplateEmailRappel48HeuresVo>) {
        this.templateEmailRappel48HeuresService.templateEmailRappel48Heuress = value;
    }

    get selectedTemplateEmailRappel48Heures(): TemplateEmailRappel48HeuresVo {
        return this.templateEmailRappel48HeuresService.selectedTemplateEmailRappel48Heures;
    }

    set selectedTemplateEmailRappel48Heures(value: TemplateEmailRappel48HeuresVo) {
        this.templateEmailRappel48HeuresService.selectedTemplateEmailRappel48Heures = value;
    }

    get viewTemplateEmailRappel48HeuresDialog(): boolean {
        return this.templateEmailRappel48HeuresService.viewTemplateEmailRappel48HeuresDialog;
    }

    set viewTemplateEmailRappel48HeuresDialog(value: boolean) {
        this.templateEmailRappel48HeuresService.viewTemplateEmailRappel48HeuresDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatView;
    }

    get dateFormatColumn() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
    }

    hideViewDialog() {
        this.viewTemplateEmailRappel48HeuresDialog = false;
    }
}
