import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {OrdreKoscService} from "../../../../../../controller/service/kosc/OrdreKosc.service";

@Component({
    selector: 'app-kpis-koscky-admin',
    templateUrl: './kpis-koscky-admin.component.html',
    styleUrls: ['./kpis-koscky-admin.component.css']
})
export class KpisKosckyAdminComponent implements OnInit {
    blocked = false;
    options: any;
    private basicOptions: any;

    constructor(private ordreKoscService: OrdreKoscService, private messageService: MessageService) {
        this.data = {
            labels: [],
            datasets: []
        };
        this.basicData = {
            labels: [],
            datasets: []
        };
    }

    private _pieChart = false;
    get pieChart(): any {
        return this._pieChart;
    }

    set pieChart(value: any) {
        this._pieChart = value;
    }

    private _barChart = false;
    get barChart(): any {
        return this._barChart;
    }

    set barChart(value: any) {
        this._barChart = value;
    }

    private _basicData: any;
    get basicData(): any {
        return this._basicData;
    }

    set basicData(value: any) {
        this._basicData = value;
    }

    private _submissionDateMin: Date;
    get submissionDateMin(): Date {
        return this._submissionDateMin;
    }

    set submissionDateMin(value: Date) {
        this._submissionDateMin = value;
    }

    private _submissionDateMax: Date;
    get submissionDateMax(): Date {
        return this._submissionDateMax;
    }

    set submissionDateMax(value: Date) {
        this._submissionDateMax = value;
    }

    private _selectedKPIType: string;

    /*   public searchRequest() {
           if (this.submissionDateMin == undefined || this.submissionDateMax == undefined) {
               this.messageService.add({
                   severity: 'error',
                   summary: 'Erreur',
                   detail: 'Merci de remplir tous les champs.'
               });
               console.error('Validation Error: Please fill in all fields.');
               return;
           } else {
               if (this.selectedKPIType == 'I001-Délai de Prise de RDV') {
                   this.ordreKoscService.calculatePercentageDelaiPriseRdv(this.submissionDateMin, this.submissionDateMax).subscribe(
                       (result) => {
                           // Update the chart data after both service calls are complete
                           this.data = {
                               labels: [result.percentageLessThanOrEqual2 + '% des orders ont un délai de prise de RDV <= 2j (' + result.countLessThanOrEqual2 + ' order(s))',
                                   result.percentageCreaterThan2 + '% des orders ont un délai de prise de RDV > 7j (' + result.countCreaterThan2 + ' order(s))'],
                               datasets: [
                                   {
                                       data: [result.percentageLessThanOrEqual2, result.percentageCreaterThan2],
                                       backgroundColor: ["#36A2EB", "#FFCE56"],
                                       hoverBackgroundColor: ["#36A2EB", "#FFCE56"]
                                   }
                               ]
                           };
                       },
                       (error) => {
                           console.error('Error fetching KPI data:', error);
                           // Handle error, display error message, etc.
                       }
                   );
               } else if (this.selectedKPIType == 'I002-Délai de Planification') {
                   this.ordreKoscService.calculatePercentageDelaiPlanification(this.submissionDateMin, this.submissionDateMax).subscribe(
                       (result) => {
                           // Update the chart data after both service calls are ciomplete
                           this.data = {
                               labels: [result.percentageLessThanOrEqual7 + '% des orders ont un délai de planification <= 7j (' + result.countLessThanOrEqual7 + ' order(s))',
                                   result.percentageGreaterThan7AndLessThan15 + '% des orders ont un délai de planification compris entre 8j et 14j (' + result.countGreaterThan7AndLessThan15 + ' order(s))',
                                   result.percentageCreaterThanOrEqual15 + '% des orders ont un délai de planification >= 15j (' + result.countCreaterThanOrEqual15 + ' order(s))'],
                               datasets: [
                                   {
                                       data: [result.percentageLessThanOrEqual7, result.percentageGreaterThan7AndLessThan15, result.percentageCreaterThanOrEqual15],
                                       backgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"],
                                       hoverBackgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"]
                                   }
                               ]
                           };
                       },
                       (error) => {
                           console.error('Error fetching KPI data:', error);
                           // Handle error, display error message, etc.
                       }
                   );
               } else if (this.selectedKPIType == 'I003-Respect des dates sélectionnées en eRDV') {
                   this.ordreKoscService.calculatePercentageRespectDatesErdv(this.submissionDateMin, this.submissionDateMax).subscribe(
                       (result) => {
                           // Update the chart data after both service calls are ciomplete
                           this.data = {
                               labels: [result.percentageRespectedErdv + '% des eRDVs ont eu lieu conformément aux dates/heures sélectionnées par le client lors de la commande (' + result.countRespectedErdv + ' order(s) eRDV)',
                                   result.percentageUnfulfilledErdv + '% des eRDVs n\'ont pas respecté les dates/heures sélectionnées par le client lors de la commande (' + result.countUnfulfilledErdv + ' order(s)) eRDV'],
                               datasets: [
                                   {
                                       data: [result.percentageRespectedErdv, result.percentageUnfulfilledErdv],
                                       backgroundColor: ["#36A2EB", "#FFCE56"],
                                       hoverBackgroundColor: ["#36A2EB", "#FFCE56"]
                                   }
                               ]
                           };
                       },
                       (error) => {
                           console.error('Error fetching KPI data:', error);
                           // Handle error, display error message, etc.
                       }
                   );
               } else if (this.selectedKPIType == 'I004-Respect des RDV') {
                   this.ordreKoscService.calculatePercentageRespectRdv(this.submissionDateMin, this.submissionDateMax).subscribe(
                       (result) => {
                           // Update the chart data after both service calls are ciomplete
                           this.data = {
                               labels: [
                                   result.percentageRdvNonHonore + '% des RDVs planifiés n\'ont pas été honorés sans notification préalable (' + result.countRdvNonHonore + ' rendez-vous)',
                                   result.percentageRdvHonore + '% des RDVs planifiés ont été honorés conformément aux dates/heures convenues (' + result.countRdvHonore + ' rendez-vous)'
                               ],
                               datasets: [
                                   {
                                       data: [result.percentageRdvNonHonore, result.percentageRdvHonore],
                                       backgroundColor: ["#36A2EB", "#FFCE56"],
                                       hoverBackgroundColor: ["#36A2EB", "#FFCE56"]
                                   }
                               ]
                           };
                       },
                       (error) => {
                           console.error('Error fetching KPI data:', error);
                           // Handle error, display error message, etc.
                       }
                   );
               } else if (this.selectedKPIType == 'I005-Délai d’émission des PV/CRI') {
                   this.ordreKoscService.calculatePercentageDelaiEmissionCri(this.submissionDateMin, this.submissionDateMax).subscribe(
                       (result) => {
                           // Update the chart data after both service calls are ciomplete
                           this.data = {
                               labels: [
                                   result.percentageRespectedDelaiEmissionCri + '% des RDVs planifiés ont respecté le délai d\'émission des CRI (' + result.countRespectedDelaiEmissionCri + ' rendez-vous)',
                                   result.percentageUnfulfilledDelaiEmissionCri + '% des RDVs planifiés n\'ont pas respecté le délai d\'émission des CRI conformes et exploitables le jour même (' + result.countUnfulfilledDelaiEmissionCri + ' rendez-vous)'
                               ],
                               datasets: [
                                   {
                                       data: [result.percentageRespectedDelaiEmissionCri, result.percentageUnfulfilledDelaiEmissionCri],
                                       backgroundColor: ["#36A2EB", "#FFCE56"],
                                       hoverBackgroundColor: ["#36A2EB", "#FFCE56"]
                                   }
                               ]
                           };
                       },
                       (error) => {
                           console.error('Error fetching KPI data:', error);
                           // Handle error, display error message, etc.
                       }
                   );
               } else {
                   console.error('Invalid KPI Type');
               }
           }
       }*/
    get selectedKPIType(): string {
        return this._selectedKPIType;
    }

    set selectedKPIType(value: string) {
        this._selectedKPIType = value;
    }

    private _data: any;
    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }

    private _findByCriteriaShow = false;
    get findByCriteriaShow(): boolean {
        return this._findByCriteriaShow;
    }

    set findByCriteriaShow(value: boolean) {
        this._findByCriteriaShow = value;
    }

    private _kpiTypes: MenuItem[];
    get kpiTypes(): MenuItem[] {
        return this._kpiTypes;
    }

    set kpiTypes(value: MenuItem[]) {
        this._kpiTypes = value;
    }

    get showSpinner(): boolean {
        return this.ordreKoscService.showSpinner;
    }

    set showSpinner(value: boolean) {
        this.ordreKoscService.showSpinner = value;
    }

    public handleSearchRequest() {
        if (!this.submissionDateMin || !this.submissionDateMax) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Les dates de soumission minimales et maximales doivent être sélectionnées avant de cliquer sur Valider.'
            });
            return;
        }
        if (this.pieChart) {
            this.searchRequestPieChart();
        } else if (this.barChart) {
            this.searchRequestBarChart();
        } else {
            console.error('Invalid chart type');
        }
        // Set loading back to false after the API call is complete
    }

    getMinSelectableDate(): Date {
        const today = new Date();
        const minSelectableDate = new Date();
        minSelectableDate.setDate(today.getDate() - 3);
        return minSelectableDate;
    }

    ngOnInit(): void {
        this.initKpiTypes();
    }

    public searchRequestPieChart() {
        this.showSpinner = true;
        this.blocked = true;
        const kpiConfig = {
            'I001-Délai de Prise de RDV': {
                method: 'calculatePercentageDelaiPriseRdv',
                // labels: ['% des orders ont un délai de prise de RDV <= 2j', '% des orders ont un délai de prise de RDV > 7j'],
                labels: ['% RDV <= 2j', '% RDV > 2j'],
                percentages: ['percentageLessThanOrEqual2', 'percentageCreaterThan2'],
                counts: ['countLessThanOrEqual2', 'countCreaterThan2'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            }, 'I002-Délai de Planification': {
                method: 'calculatePercentageDelaiPlanification',
                // labels: ['% des orders ont un délai de planification <= 7j', '% des orders ont un délai de planification compris entre 8j et 14j', '% des orders ont un délai de planification >= 15j'],
                labels: ['% Planif. <= 7j', '% 7 < Planif. < 15 ', '% Planif. >= 15j'],
                percentages: ['percentageLessThanOrEqual7', 'percentageGreaterThan7AndLessThan15', 'percentageCreaterThanOrEqual15'],
                counts: ['countLessThanOrEqual7', 'countGreaterThan7AndLessThan15', 'countCreaterThanOrEqual15'],
                backgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"]
            }, 'I003-Respect des dates sélectionnées en eRDV': {
                method: 'calculatePercentageRespectDatesErdv',
                // labels: ['% des eRDVs ont eu lieu conformément aux dates/heures sélectionnées par le client lors de la commande', '% des eRDVs n\'ont pas respecté les dates/heures sélectionnées par le client lors de la commande'],
                labels: ['% eRDVs conformes', '% eRDVs non conformes'],
                percentages: ['percentageRespectedErdv', 'percentageUnfulfilledErdv'],
                counts: ['countRespectedErdv', 'countUnfulfilledErdv'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            }, 'I004-Respect des RDV': {
                method: 'calculatePercentageRespectRdv',
                // labels: ['% des RDVs planifiés n\'ont pas été honorés sans notification préalable', '% des RDVs planifiés ont été honorés conformément aux dates/heures convenues'],
                labels: ['% RDV non honorés', '% RDV honorés'],
                percentages: ['percentageRdvNonHonore', 'percentageRdvHonore'],
                counts: ['countRdvNonHonore', 'countRdvHonore'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            }, 'I005-Délai d’émission des PV/CRI': {
                method: 'calculatePercentageDelaiEmissionCri',
                // labels: ['% des RDVs planifiés ont respecté le délai d\'émission des CRI', '% des RDVs planifiés n\'ont pas respecté le délai d\'émission des CRI conformes et exploitables le jour même'],
                labels: ['% CRI respectés', '% CRI non respectés'],
                percentages: ['percentageRespectedDelaiEmissionCri', 'percentageUnfulfilledDelaiEmissionCri'],
                counts: ['countRespectedDelaiEmissionCri', 'countUnfulfilledDelaiEmissionCri'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            }, 'I007-Taux de réussite à la première intervention': {
                method: 'calculatePercentageTauxReussite',
                // labels: ['% des références uniques ont réussi à la première intervention', '% des références non uniques n\'ont pas réussi à la première intervention'],
                labels: ['% Réf. uniques réussies', '% Réf. non uniques non réussies'],
                percentages: ['percentageUniqueReferences', 'percentageNonUniqueReferences'],
                counts: ['countUniqueReferences', 'countNonUniqueReferences'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            },
        };
        const selectedKPIConfig = kpiConfig[this.selectedKPIType];
        if (!selectedKPIConfig) {
            console.error('Invalid KPI Type');
            return;
        }
        this.ordreKoscService[selectedKPIConfig.method](this.submissionDateMin, this.submissionDateMax).subscribe(
            (result) => {
                const labels = selectedKPIConfig.labels.map((label, index) => `${result[selectedKPIConfig.percentages[index]]}${label} (${result[selectedKPIConfig.counts[index]]} RDV)`);
                this.data = {
                    labels: labels,
                    datasets: [
                        {
                            data: selectedKPIConfig.percentages.map(percentage => result[percentage]),
                            backgroundColor: selectedKPIConfig.backgroundColor,
                            hoverBackgroundColor: selectedKPIConfig.backgroundColor
                        }
                    ]
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public searchRequestBarChart() {
        this.ordreKoscService.calculatePercentageDelaiMoyenGem(this.submissionDateMin, this.submissionDateMax).subscribe(
            (result) => {
                const labels = [' j'];
                const percentages = ['averageGEMDuration'];
                const counts = ['orderCount'];
                this.basicData = {
                    labels: labels.map((label, index) => `${result[percentages[index]]}${label} (${result[counts[index]]} RDV)`),
                    datasets: [
                        {
                            label: 'Délai moyen de GEM',
                            data: percentages.map(percentage => result[percentage]),
                            backgroundColor: ["#36A2EB", "#FFCE56"],
                            hoverBackgroundColor: ["#36A2EB", "#FFCE56"]
                        }
                    ]
                };
            },
            (error) => {
                console.error('Error fetching I008 KPI data:', error);
            }
        );
    }

    public exportKPIData() {
        // Prepare data for export
        const exportData = this.data.datasets[0].data.map((value, index) => {
            return {
                label: this.data.labels[index],
                value: value
            };
        });
        // Convert the data to CSV format
        const csvContent = 'data:text/csv;charset=utf-8,' + exportData.map(e => `${e.label},${e.value}`).join('\n');
        // Encode the CSV content and create a download link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${this.selectedKPIType}.csv`);
        // Append the link to the document, trigger a click to download, and then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private initKpiTypes() {
        this._kpiTypes = [
            {
                label: 'I001-Délai de Prise de RDV',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I001-Délai de Prise de RDV',
                        this.findByCriteriaShow = true;
                    this.pieChart = true;
                    // this.barChart = false;
                }
            }, {
                label: 'I002-Délai de Planification',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I002-Délai de Planification'
                    this.findByCriteriaShow = true;
                    this.pieChart = true;
                    // this.barChart = false;
                }
            }, {
                label: 'I003-Respect des dates sélectionnées en eRDV',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I003-Respect des dates sélectionnées en eRDV'
                    this.findByCriteriaShow = true;
                    this.pieChart = true;
                    // this.barChart = false;
                }
            }, {
                label: 'I004-Respect des RDV',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I004-Respect des RDV'
                    this.findByCriteriaShow = true
                    this.pieChart = true;
                    // this.barChart = false;
                }
            }, {
                label: 'I005-Délai d’émission des PV/CRI',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I005-Délai d’émission des PV/CRI'
                    this.findByCriteriaShow = true
                    this.pieChart = true;
                    // this.barChart = false;
                }
            }, {
                label: 'I007-Taux de réussite à la première intervention',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I007-Taux de réussite à la première intervention'
                    this.findByCriteriaShow = true
                    this.pieChart = true;
                    // this.barChart = false;
                }
            },/* {
                label: 'I008-Délai moyen de GEM',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'I008-Délai moyen de GEM'
                    this.findByCriteriaShow = true
                    this.barChart = true;
                    this.pieChart = false;
                }
            },*/
        ];
    }
}
