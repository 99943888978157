import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailReportDemandeManeoClientInjoignableService
} from 'src/app/controller/service/template/TemplateEmailReportDemandeManeoClientInjoignable.service';
import {
    TemplateEmailReportDemandeManeoClientInjoignableVo
} from 'src/app/controller/model/template/TemplateEmailReportDemandeManeoClientInjoignable.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-report-demande-maneo-client-injoignable-edit-admin',
    templateUrl: './template-email-report-demande-maneo-client-injoignable-edit-admin.component.html',
    styleUrls: ['./template-email-report-demande-maneo-client-injoignable-edit-admin.component.css']
})
export class TemplateEmailReportDemandeManeoClientInjoignableEditAdminComponent implements OnInit {

    constructor(private datePipe: DatePipe, private templateEmailReportDemandeManeoClientInjoignableService: TemplateEmailReportDemandeManeoClientInjoignableService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;

    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailReportDemandeManeoClientInjoignableLibelle = true;

    get validTemplateEmailReportDemandeManeoClientInjoignableLibelle(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientInjoignableLibelle;
    }

    set validTemplateEmailReportDemandeManeoClientInjoignableLibelle(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientInjoignableLibelle = value;
    }

    _validTemplateEmailReportDemandeManeoClientInjoignableObjet = true;

    get validTemplateEmailReportDemandeManeoClientInjoignableObjet(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientInjoignableObjet;
    }

    set validTemplateEmailReportDemandeManeoClientInjoignableObjet(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientInjoignableObjet = value;
    }

    _validTemplateEmailReportDemandeManeoClientInjoignableCorps = true;

    get validTemplateEmailReportDemandeManeoClientInjoignableCorps(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientInjoignableCorps;
    }

    set validTemplateEmailReportDemandeManeoClientInjoignableCorps(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientInjoignableCorps = value;
    }

    get templateEmailReportDemandeManeoClientInjoignables(): Array<TemplateEmailReportDemandeManeoClientInjoignableVo> {
        return this.templateEmailReportDemandeManeoClientInjoignableService.templateEmailReportDemandeManeoClientInjoignables;
    }

    set templateEmailReportDemandeManeoClientInjoignables(value: Array<TemplateEmailReportDemandeManeoClientInjoignableVo>) {
        this.templateEmailReportDemandeManeoClientInjoignableService.templateEmailReportDemandeManeoClientInjoignables = value;
    }

    get selectedTemplateEmailReportDemandeManeoClientInjoignable(): TemplateEmailReportDemandeManeoClientInjoignableVo {
        return this.templateEmailReportDemandeManeoClientInjoignableService.selectedTemplateEmailReportDemandeManeoClientInjoignable;
    }

    set selectedTemplateEmailReportDemandeManeoClientInjoignable(value: TemplateEmailReportDemandeManeoClientInjoignableVo) {
        this.templateEmailReportDemandeManeoClientInjoignableService.selectedTemplateEmailReportDemandeManeoClientInjoignable = value;
    }

    get editTemplateEmailReportDemandeManeoClientInjoignableDialog(): boolean {
        return this.templateEmailReportDemandeManeoClientInjoignableService.editTemplateEmailReportDemandeManeoClientInjoignableDialog;

    }

    set editTemplateEmailReportDemandeManeoClientInjoignableDialog(value: boolean) {
        this.templateEmailReportDemandeManeoClientInjoignableService.editTemplateEmailReportDemandeManeoClientInjoignableDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    ngOnInit(): void {
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editWithShowOption(showList: boolean) {
        this.templateEmailReportDemandeManeoClientInjoignableService.edit().subscribe(templateEmailReportDemandeManeoClientInjoignable => {
            const myIndex = this.templateEmailReportDemandeManeoClientInjoignables.findIndex(e => e.id === this.selectedTemplateEmailReportDemandeManeoClientInjoignable.id);
            this.templateEmailReportDemandeManeoClientInjoignables[myIndex] = templateEmailReportDemandeManeoClientInjoignable;
            this.editTemplateEmailReportDemandeManeoClientInjoignableDialog = false;
            this.submitted = false;
            this.selectedTemplateEmailReportDemandeManeoClientInjoignable = new TemplateEmailReportDemandeManeoClientInjoignableVo();
        }, error => {
            console.log(error);
        });
    }

    hideEditDialog() {
        this.editTemplateEmailReportDemandeManeoClientInjoignableDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailReportDemandeManeoClientInjoignableLibelle = value;
        this.validTemplateEmailReportDemandeManeoClientInjoignableObjet = value;
        this.validTemplateEmailReportDemandeManeoClientInjoignableCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailReportDemandeManeoClientInjoignableLibelle();
        this.validateTemplateEmailReportDemandeManeoClientInjoignableObjet();
        this.validateTemplateEmailReportDemandeManeoClientInjoignableCorps();
    }

    private validateTemplateEmailReportDemandeManeoClientInjoignableLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientInjoignable.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailReportDemandeManeoClientInjoignableLibelle = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientInjoignableLibelle = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientInjoignableObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientInjoignable.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailReportDemandeManeoClientInjoignableObjet = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientInjoignableObjet = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientInjoignableCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientInjoignable.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailReportDemandeManeoClientInjoignableCorps = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientInjoignableCorps = true;
        }
    }
}
