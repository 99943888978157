import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {
    TemplateEmailReportDemandeManeoClientJoignableAccepteVo
} from '../../model/template/TemplateEmailReportDemandeManeoClientJoignableAccepte.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEmailReportDemandeManeoClientJoignableAccepteService {
    public editTemplateEmailReportDemandeManeoClientJoignableAccepte$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/templateEmailReportDemandeManeoClientJoignableAccepte/';
        });
    }

    private _templateEmailReportDemandeManeoClientJoignableAcceptes: Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>;
    get templateEmailReportDemandeManeoClientJoignableAcceptes(): Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        if (this._templateEmailReportDemandeManeoClientJoignableAcceptes == null) {
            this._templateEmailReportDemandeManeoClientJoignableAcceptes = new Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>();
        }
        return this._templateEmailReportDemandeManeoClientJoignableAcceptes;
    }

    set templateEmailReportDemandeManeoClientJoignableAcceptes(value: Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>) {
        this._templateEmailReportDemandeManeoClientJoignableAcceptes = value;
    }

    private _selectedTemplateEmailReportDemandeManeoClientJoignableAccepte: TemplateEmailReportDemandeManeoClientJoignableAccepteVo;

    get selectedTemplateEmailReportDemandeManeoClientJoignableAccepte(): TemplateEmailReportDemandeManeoClientJoignableAccepteVo {
        if (this._selectedTemplateEmailReportDemandeManeoClientJoignableAccepte == null) {
            this._selectedTemplateEmailReportDemandeManeoClientJoignableAccepte = new TemplateEmailReportDemandeManeoClientJoignableAccepteVo();
        }
        return this._selectedTemplateEmailReportDemandeManeoClientJoignableAccepte;
    }

    set selectedTemplateEmailReportDemandeManeoClientJoignableAccepte(value: TemplateEmailReportDemandeManeoClientJoignableAccepteVo) {
        this._selectedTemplateEmailReportDemandeManeoClientJoignableAccepte = value;
    }

    private _templateEmailReportDemandeManeoClientJoignableAccepteSelections: Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>;
    get templateEmailReportDemandeManeoClientJoignableAccepteSelections(): Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        if (this._templateEmailReportDemandeManeoClientJoignableAccepteSelections == null) {
            this._templateEmailReportDemandeManeoClientJoignableAccepteSelections = new Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>();
        }
        return this._templateEmailReportDemandeManeoClientJoignableAccepteSelections;
    }

    set templateEmailReportDemandeManeoClientJoignableAccepteSelections(value: Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>) {
        this._templateEmailReportDemandeManeoClientJoignableAccepteSelections = value;
    }

    private _createTemplateEmailReportDemandeManeoClientJoignableAccepteDialog: boolean;
    get createTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(): boolean {
        return this._createTemplateEmailReportDemandeManeoClientJoignableAccepteDialog;
    }

    set createTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(value: boolean) {
        this._createTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = value;
    }

    private _editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog: boolean;
    get editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(): boolean {
        return this._editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog;
    }

    set editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(value: boolean) {
        this._editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = value;
    }

    private _viewTemplateEmailReportDemandeManeoClientJoignableAccepteDialog: boolean;
    get viewTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(): boolean {
        return this._viewTemplateEmailReportDemandeManeoClientJoignableAccepteDialog;
    }

    set viewTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(value: boolean) {
        this._viewTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = value;
    }

    private _searchTemplateEmailReportDemandeManeoClientJoignableAccepte: TemplateEmailReportDemandeManeoClientJoignableAccepteVo;
    get searchTemplateEmailReportDemandeManeoClientJoignableAccepte(): TemplateEmailReportDemandeManeoClientJoignableAccepteVo {
        if (this._searchTemplateEmailReportDemandeManeoClientJoignableAccepte == null) {
            this._searchTemplateEmailReportDemandeManeoClientJoignableAccepte = new TemplateEmailReportDemandeManeoClientJoignableAccepteVo();
        }
        return this._searchTemplateEmailReportDemandeManeoClientJoignableAccepte;
    }

    set searchTemplateEmailReportDemandeManeoClientJoignableAccepte(value: TemplateEmailReportDemandeManeoClientJoignableAccepteVo) {
        this._searchTemplateEmailReportDemandeManeoClientJoignableAccepte = value;
    }

    public findAll() {
        return this.http.get<Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>>(this.API);
    }

    public save(): Observable<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        return this.http.post<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>(this.API, this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte);
    }

    delete(templateEmailReportDemandeManeoClientJoignableAccepte: TemplateEmailReportDemandeManeoClientJoignableAccepteVo) {
        return this.http.delete<number>(this.API + 'id/' + templateEmailReportDemandeManeoClientJoignableAccepte.id);
    }

    public edit(): Observable<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        return this.http.put<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>(this.API, this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte);
    }

    public findByCriteria(templateEmailReportDemandeManeoClientJoignableAccepte: TemplateEmailReportDemandeManeoClientJoignableAccepteVo): Observable<Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>> {
        return this.http.post<Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>>(this.API + 'search', templateEmailReportDemandeManeoClientJoignableAccepte);
    }

    public findByIdWithAssociatedList(templateEmailReportDemandeManeoClientJoignableAccepte: TemplateEmailReportDemandeManeoClientJoignableAccepteVo): Observable<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        return this.http.get<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>(this.API + 'detail/id/' + templateEmailReportDemandeManeoClientJoignableAccepte.id);
    }
}
