import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailRappel48HeuresService
} from 'src/app/controller/service/template/TemplateEmailRappel48Heures.service';
import {TemplateEmailRappel48HeuresVo} from 'src/app/controller/model/template/TemplateEmailRappel48Heures.model';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';

@Component({
    selector: 'app-template-email-rappel-48-heures-list-admin',
    templateUrl: './template-email-rappel-48-heures-list-admin.component.html',
    styleUrls: ['./template-email-rappel-48-heures-list-admin.component.css']
})
export class TemplateEmailRappel48HeuresListAdminComponent implements OnInit {
    // declarations
    findByCriteriaShow = false;
    cols: any[] = [];
    excelPdfButons: MenuItem[];
    exportData: any[] = [];
    criteriaData: any[] = [];
    fileName = 'TemplateEmailRappel48Heures';
    items: MenuItem[];
    home: MenuItem;

    constructor(private datePipe: DatePipe, private templateEmailRappel48HeuresService: TemplateEmailRappel48HeuresService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
    ) {
    }

    get templateEmailRappel48Heuress(): Array<TemplateEmailRappel48HeuresVo> {
        return this.templateEmailRappel48HeuresService.templateEmailRappel48Heuress;
    }

    set templateEmailRappel48Heuress(value: Array<TemplateEmailRappel48HeuresVo>) {
        this.templateEmailRappel48HeuresService.templateEmailRappel48Heuress = value;
    }

    get templateEmailRappel48HeuresSelections(): Array<TemplateEmailRappel48HeuresVo> {
        return this.templateEmailRappel48HeuresService.templateEmailRappel48HeuresSelections;
    }

    set templateEmailRappel48HeuresSelections(value: Array<TemplateEmailRappel48HeuresVo>) {
        this.templateEmailRappel48HeuresService.templateEmailRappel48HeuresSelections = value;
    }

    get selectedTemplateEmailRappel48Heures(): TemplateEmailRappel48HeuresVo {
        return this.templateEmailRappel48HeuresService.selectedTemplateEmailRappel48Heures;
    }

    set selectedTemplateEmailRappel48Heures(value: TemplateEmailRappel48HeuresVo) {
        this.templateEmailRappel48HeuresService.selectedTemplateEmailRappel48Heures = value;
    }

    get createTemplateEmailRappel48HeuresDialog(): boolean {
        return this.templateEmailRappel48HeuresService.createTemplateEmailRappel48HeuresDialog;
    }

    set createTemplateEmailRappel48HeuresDialog(value: boolean) {
        this.templateEmailRappel48HeuresService.createTemplateEmailRappel48HeuresDialog = value;
    }

    get editTemplateEmailRappel48HeuresDialog(): boolean {
        return this.templateEmailRappel48HeuresService.editTemplateEmailRappel48HeuresDialog;
    }

    set editTemplateEmailRappel48HeuresDialog(value: boolean) {
        this.templateEmailRappel48HeuresService.editTemplateEmailRappel48HeuresDialog = value;
    }

    get viewTemplateEmailRappel48HeuresDialog(): boolean {
        return this.templateEmailRappel48HeuresService.viewTemplateEmailRappel48HeuresDialog;
    }

    set viewTemplateEmailRappel48HeuresDialog(value: boolean) {
        this.templateEmailRappel48HeuresService.viewTemplateEmailRappel48HeuresDialog = value;
    }

    get searchTemplateEmailRappel48Heures(): TemplateEmailRappel48HeuresVo {
        return this.templateEmailRappel48HeuresService.searchTemplateEmailRappel48Heures;
    }

    set searchTemplateEmailRappel48Heures(value: TemplateEmailRappel48HeuresVo) {
        this.templateEmailRappel48HeuresService.searchTemplateEmailRappel48Heures = value;
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
        this.loadTemplateEmailRappel48Heuress();
        this.initExport();
        this.initCol();
        this.items = [
            {label: 'Email Rappel 48 heures', routerLink: '/app/admin/kosc/template-email-rappel-48-heures/list'},
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    public async loadTemplateEmailRappel48Heuress() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('TemplateEmailRappel48Heures', 'list');
        isPermistted ? this.templateEmailRappel48HeuresService.findAll().subscribe(templateEmailRappel48Heuress => this.templateEmailRappel48Heuress = templateEmailRappel48Heuress, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public searchRequest() {
        this.templateEmailRappel48HeuresService.findByCriteria(this.searchTemplateEmailRappel48Heures).subscribe(templateEmailRappel48Heuress => {
            this.templateEmailRappel48Heuress = templateEmailRappel48Heuress;
            // this.searchTemplateEmailRappel48Heures = new TemplateEmailRappel48HeuresVo();
        }, error => console.log(error));
    }

    public async editTemplateEmailRappel48Heures(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailRappel48Heures', 'edit');
        if (isPermistted) {
            this.templateEmailRappel48HeuresService.findByIdWithAssociatedList(templateEmailRappel48Heures).subscribe(res => {
                this.selectedTemplateEmailRappel48Heures = res;
                this.editTemplateEmailRappel48HeuresDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewTemplateEmailRappel48Heures(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailRappel48Heures', 'view');
        if (isPermistted) {
            this.templateEmailRappel48HeuresService.findByIdWithAssociatedList(templateEmailRappel48Heures).subscribe(res => {
                this.selectedTemplateEmailRappel48Heures = res;
                this.viewTemplateEmailRappel48HeuresDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateTemplateEmailRappel48Heures(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedTemplateEmailRappel48Heures = new TemplateEmailRappel48HeuresVo();
            this.createTemplateEmailRappel48HeuresDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteTemplateEmailRappel48Heures(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailRappel48Heures', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Template email rappel 48 heures) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.templateEmailRappel48HeuresService.delete(templateEmailRappel48Heures).subscribe(status => {
                        if (status > 0) {
                            const position = this.templateEmailRappel48Heuress.indexOf(templateEmailRappel48Heures);
                            position > -1 ? this.templateEmailRappel48Heuress.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'Template email rappel 48 heures Supprimé',
                                life: 3000
                            });
                        }

                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async duplicateTemplateEmailRappel48Heures(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo) {
        this.templateEmailRappel48HeuresService.findByIdWithAssociatedList(templateEmailRappel48Heures).subscribe(
            res => {
                this.initDuplicateTemplateEmailRappel48Heures(res);
                this.selectedTemplateEmailRappel48Heures = res;
                this.selectedTemplateEmailRappel48Heures.id = null;
                this.createTemplateEmailRappel48HeuresDialog = true;
            });
    }

    initDuplicateTemplateEmailRappel48Heures(res: TemplateEmailRappel48HeuresVo) {
    }

    initExport(): void {
        this.excelPdfButons = [
            {
                label: 'CSV', icon: 'pi pi-file', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterCSV(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'XLS', icon: 'pi pi-file-excel', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterPdf(this.criteriaData, this.exportData, this.fileName);
                }
            }
        ];
    }

    prepareColumnExport(): void {
        this.exportData = this.templateEmailRappel48Heuress.map(e => {
            return {
                'Libelle': e.libelle,
                'Objet': e.objet,
                'Corps': e.corps,
            }
        });
        this.criteriaData = [{
            'Libelle': this.searchTemplateEmailRappel48Heures.libelle ? this.searchTemplateEmailRappel48Heures.libelle : environment.emptyForExport,
            'Objet': this.searchTemplateEmailRappel48Heures.objet ? this.searchTemplateEmailRappel48Heures.objet : environment.emptyForExport,
            'Corps': this.searchTemplateEmailRappel48Heures.corps ? this.searchTemplateEmailRappel48Heures.corps : environment.emptyForExport,
        }];
    }

    private initCol() {
        this.cols = [
            {field: 'libelle', header: 'Libelle'},
            {field: 'objet', header: 'Objet'},
        ];
    }
}
