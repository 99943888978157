import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ordre-kosc-suivi-admin',
    templateUrl: './ordre-kosc-suivi-admin.component.html',
    styleUrls: ['./ordre-kosc-suivi-admin.component.css']
})
export class OrdreKoscSuiviAdminComponent implements OnInit {
    constructor() {
    }
    ngOnInit(): void {
    }
}
