export class JourFerieVo {
    public id: number;
    public dateDebut: Date;
    public dateFin: Date;
    public libelle: string;
    public dateDebutMax: string;
    public dateDebutMin: string;
    public dateFinMax: string;
    public dateFinMin: string;
}
