import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ArretTravailService} from 'src/app/controller/service/technicien/ArretTravail.service';
import {ArretTravailVo} from 'src/app/controller/model/technicien/ArretTravail.model';
import {Router} from '@angular/router';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {TechnicienService} from 'src/app/controller/service/technicien/Technicien.service';
import {TechnicienVo} from 'src/app/controller/model/technicien/Technicien.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';
import {CalendarOptions, EventClickArg} from '@fullcalendar/core';
import * as moment from 'moment/moment';
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {DepartementVo} from "../../../../controller/model/technicien/Departement.model";
import {ScheduleDto} from "../../../../controller/model/referentiel/ScheduleDto";
import {ScheduleTrancheRequestVo} from "../../../../controller/model/referentiel/ScheduleTrancheRequest.model";
import {DepartementTechnicienService} from "../../../../controller/service/technicien/DepartementTechnicien.service";
import {DepartementService} from "../../../../controller/service/technicien/Departement.service";
import {DepartementTechnicienVo} from "../../../../controller/model/technicien/DepartementTechnicien.model";
import {DepartementTechnicienDisponibiliteVo} from "../../../../controller/model/technicien/DepartementTechnicienDisponibiliteVo.model";
import {DepartementTechnicienJourDisponibiliteVo} from "../../../../controller/model/technicien/DepartementTechnicienJourDisponibiliteVo.model";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-grille-technicien-list-cdd',
    templateUrl: './grille-technicien-list-cdd.component.html',
    styleUrls: ['./grille-technicien-list-cdd.component.css']
})
export class GrilleTechnicienListCddComponent implements OnInit {
    // declarations
    // findByCriteriaShow = false;
    findByCriteriaShow = true;
    createDialog = false;
    editDialog = false;
    cols: any[] = [];
    criteriaData: any[] = [];
    fileName = 'DepartementTechnicien';
    techniciens: Array<TechnicienVo>;
    departements: Array<DepartementVo>;
    res: Array<ArretTravailVo>;
    items: MenuItem[];
    home: MenuItem;
    dateFormat = 'dd/mm/yy'
    schedules: ScheduleDto[];
    scheduleTrancheRequestVo: ScheduleTrancheRequestVo;
    schedulesTranche: ScheduleDto[];
    public events: Array<any> = new Array<any>();
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    showEditDialogContent = false;
    createDialogVisible = false;
    calendarOptions: CalendarOptions = {
        locale: 'fr',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        headerToolbar: {
            left: 'prev,today,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        allDaySlot: false,
        buttonText: {
            today: 'Aujourd\'hui',
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            list: 'Liste',
        },
        slotMinTime: "08:00:00",
        slotMaxTime: "17:00:00",
        views: {
            month: {
                contentHeight: 650
            },
            week: {
                contentHeight: 415
            },
            day: {
                contentHeight: 415
            }
        },
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false,
        },
        nextDayThreshold: '17:00:00',
        eventDisplay: 'block',
        initialView: 'timeGridWeek',
        weekends: false,
        editable: false,
        selectable: false,
        eventStartEditable: false,
        eventDurationEditable: false,
        eventResizableFromStart: false,
        selectMirror: true,
        dayMaxEvents: true,
        datesSet: (info) => {
            const start = info.start;
            const end = info.end;
            // this.handleDateSet(start, end);
        },
        eventClick: this.handleEventClick.bind(this),
        dateClick: this.handleDateClick.bind(this),
    };
    item: ScheduleDto = new ScheduleDto();

    constructor(private datePipe: DatePipe, private departementTechnicienService: DepartementTechnicienService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
        , private technicienService: TechnicienService, private departementService: DepartementService, private arretTravailService: ArretTravailService
        , private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private _showSpinner = false;
    get showSpinner(): boolean {
        return this._showSpinner;
    }

    set showSpinner(value: boolean) {
        this._showSpinner = value;
    }

    get departementTechniciens(): Array<DepartementTechnicienVo> {
        return this.departementTechnicienService.departementTechniciens;
    }

    set departementTechniciens(value: Array<DepartementTechnicienVo>) {
        this.departementTechnicienService.departementTechniciens = value;
    }

    get departementTechnicienDisponibilites(): Array<DepartementTechnicienDisponibiliteVo> {
        return this.departementTechnicienService.departementTechnicienDisponibilites;
    }

    set departementTechnicienDisponibilites(value: Array<DepartementTechnicienDisponibiliteVo>) {
        this.departementTechnicienService.departementTechnicienDisponibilites = value;
    }

    get departementTechnicienJourDisponibilites(): Array<DepartementTechnicienJourDisponibiliteVo> {
        return this.departementTechnicienService.departementTechnicienJourDisponibilites;
    }

    set departementTechnicienJourDisponibilites(value: Array<DepartementTechnicienJourDisponibiliteVo>) {
        this.departementTechnicienService.departementTechnicienJourDisponibilites = value;
    }

    get selectedDepartementTechnicienDisponibilite(): DepartementTechnicienDisponibiliteVo {
        return this.departementTechnicienService.selectedDepartementTechnicienDisponibilite;
    }

    set selectedDepartementTechnicienDisponibilite(value: DepartementTechnicienDisponibiliteVo) {
        this.departementTechnicienService.selectedDepartementTechnicienDisponibilite = value;
    }

    get searchDepartementTechnicienDisponibilite(): DepartementTechnicienDisponibiliteVo {
        return this.departementTechnicienService.searchDepartementTechnicienDisponibilite;
    }

    set searchDepartementTechnicienDisponibilite(value: DepartementTechnicienDisponibiliteVo) {
        this.departementTechnicienService.searchDepartementTechnicienDisponibilite = value;
    }

    get selectedDepartementTechnicienJourDisponibilite(): DepartementTechnicienJourDisponibiliteVo {
        return this.departementTechnicienService.selectedDepartementTechnicienJourDisponibilite;
    }

    set selectedDepartementTechnicienJourDisponibilite(value: DepartementTechnicienJourDisponibiliteVo) {
        this.departementTechnicienService.selectedDepartementTechnicienJourDisponibilite = value;
    }

    ngOnInit(): void {
        this.loadTechnicien();
        this.loadDepartement();
        this.items = [{label: 'Grille Technicien', routerLink: '/app/cdd/grille-technicien-component'},];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    public searchRequest() {
        this.showSpinner = true;
        this.departementTechnicienService.findByTechnicienParJour(this.searchDepartementTechnicienDisponibilite).subscribe(departementTechnicienDisponibilite => {
                this.selectedDepartementTechnicienDisponibilite = departementTechnicienDisponibilite;
                this.departementTechnicienJourDisponibilites = departementTechnicienDisponibilite.departementTechnicienJourDisponibiliteVos;
                this.showSpinner = false;
            }, error => {
                console.log(error);
                this.showSpinner = false;
            }
        );
    }
    public async loadTechnicien() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('DepartementTechnicien', 'list');
        isPermistted ? this.technicienService.findAll().subscribe(techniciens => this.techniciens = techniciens, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }
    public async loadDepartement() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('DepartementTechnicien', 'list');
        isPermistted ? this.departementService.findAll().subscribe(departements => this.departements = departements, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }
    updateFullCalendarEvents(): void {
        // Create events for schedules
        this.events = this.schedules.map(item => ({
            id: item.id,
            title: item.subject,
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            color: '#3b82ed' // Color for regular schedules
        }));
        // Set to track added tranche events
        const addedTrancheEventIds = new Set<string>();
        // Map tranche events without filtering
        const trancheEvents = this.schedulesTranche.map(item => ({
            id: item.id,
            title: item.subject,
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            color: '#edcd1a' // Color for tranche schedules
        }));
        // Combine all events
        this.events = this.events.concat(trancheEvents);
        // Update the calendar
        if (this.calendarComponent) {
            this.calendarComponent.getApi().removeAllEvents(); // Clear existing events
            this.calendarComponent.getApi().addEventSource(this.events); // Add updated events
            this.calendarComponent.getApi().refetchEvents(); // Refresh the events
        }
        this.changeDetectorRef.detectChanges(); // Detect changes
    }

    handleDateClick(info: any) {
        const clickedDate = info.date;
        this.item.startTime = new Date(clickedDate);
        this.item.endTime = new Date(this.item.startTime.getTime() + 60 * 60 * 1000);
        this.createDialog = true;
        this.createDialogVisible = true;
        this.calendarComponent.getApi().refetchEvents();
        this.changeDetectorRef.markForCheck();
    }

    handleEventClick(clickInfo: EventClickArg) {
        const eventTitle = clickInfo.event.title;
        const clickedDate = clickInfo.event.start;
        const itemIndex = this.schedules.findIndex(item => item.subject === eventTitle);
        if (itemIndex !== -1) {
            this.item.id = Number(clickInfo.event.id);
            this.item.subject = clickInfo.event.title;
            this.item.startTime = new Date(clickedDate);
            this.item.endTime = new Date(this.item.startTime.getTime() + 60 * 60 * 1000);
            this.showEditDialogContent = true;
            this.editDialog = true;
        }
        this.calendarComponent.getApi().refetchEvents();
        this.changeDetectorRef.markForCheck();
    }

    public format(myDate: Date): Date {
        if (myDate != null) {
            const newdate = new Date(myDate);
            const formattedDate = moment(newdate).format(environment.dateFormatEdit);
            console.log(formattedDate);
            myDate = new Date(formattedDate);
        }
        return myDate;
    }

    public prepareEdit() {
        this.item.startTime = this.format(this.item.startTime);
        this.item.endTime = this.format(this.item.endTime);
    }

    protected handleDateSet() {
        this.showSpinner = true;
        this.scheduleTrancheRequestVo = new ScheduleTrancheRequestVo();
        this.departementTechnicienService.findSchedule(this.searchDepartementTechnicienDisponibilite)
            .subscribe(response => {
                this.schedules = response;
                this.scheduleTrancheRequestVo.technicienId = Number(this.searchDepartementTechnicienDisponibilite.technicien?.id);
                this.scheduleTrancheRequestVo.schedules = this.schedules;
                this.departementTechnicienService.findByTechnicienParTrancheSchedule(this.scheduleTrancheRequestVo)
                    .subscribe(trancheResponse => {
                        this.schedulesTranche = trancheResponse;
                        this.updateFullCalendarEvents();
                        this.showSpinner = false;
                    }, error => {
                        console.error('Error fetching tranche schedules:', error);
                        this.showSpinner = false;
                    });
            }, error => {
                console.error('Error fetching schedules:', error);
                this.showSpinner = false;
            });
    }
}
