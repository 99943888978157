import {Component, OnInit} from '@angular/core';
import {OrdreKoscService} from 'src/app/controller/service/kosc/OrdreKosc.service';
import {OrdreKoscVo} from 'src/app/controller/model/kosc/OrdreKosc.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';
import {TemplateEmailClotureVo} from 'src/app/controller/model/template/TemplateEmailCloture.model';
import {TemplateEmailClotureService} from 'src/app/controller/service/template/TemplateEmailCloture.service';
import {EtatDemandeKoscVo} from 'src/app/controller/model/referentiel/EtatDemandeKosc.model';
import {EtatDemandeKoscService} from 'src/app/controller/service/referentiel/EtatDemandeKosc.service';
import {TechnicienVo} from 'src/app/controller/model/technicien/Technicien.model';
import {TechnicienService} from 'src/app/controller/service/technicien/Technicien.service';
import {TemplateEmailReplanificationVo} from 'src/app/controller/model/template/TemplateEmailReplanification.model';
import {
    TemplateEmailReplanificationService
} from 'src/app/controller/service/template/TemplateEmailReplanification.service';
import {TemplateSuiviVo} from 'src/app/controller/model/template/TemplateSuivi.model';
import {TemplateSuiviService} from 'src/app/controller/service/template/TemplateSuivi.service';
import {OperatorVo} from 'src/app/controller/model/referentiel/Operator.model';
import {OperatorService} from 'src/app/controller/service/referentiel/Operator.service';
import {DepartementVo} from 'src/app/controller/model/technicien/Departement.model';
import {DepartementService} from 'src/app/controller/service/technicien/Departement.service';
import {
    TemplateEmailClientInjoinableKoscVo
} from 'src/app/controller/model/template/TemplateEmailClientInjoinableKosc.model';
import {
    TemplateEmailClientInjoinableKoscService
} from 'src/app/controller/service/template/TemplateEmailClientInjoinableKosc.service';
import {TemplateEmailPlanificationVo} from 'src/app/controller/model/template/TemplateEmailPlanification.model';
import {
    TemplateEmailPlanificationService
} from 'src/app/controller/service/template/TemplateEmailPlanification.service';
import {RegionVo} from "../../../../../../controller/model/technicien/Region.model";
import {RegionService} from "../../../../../../controller/service/technicien/Region.service";
import {
    DefaultTemplateConfigurationService
} from "../../../../../../controller/service/template/DefaultTemplateConfiguration.service";
import {
    DefaultTemplateConfigurationVo
} from "../../../../../../controller/model/template/DefaultTemplateConfiguration.model";
import {AuthService} from "../../../../../../controller/service/security/Auth.service";
import {WeekDayVo} from "../../../../../../controller/model/referentiel/WeekDay.model";
import {JourFerieService} from "../../../../../../controller/service/referentiel/JourFerie.service";
import {StatutEmailService} from "../../../../../../controller/service/template/StatutEmail.service";
import {StatutEmailVo} from "../../../../../../controller/model/template/StatutEmail.model";

@Component({
    selector: 'app-ordre-kosc-rappel-48-heures-edit-admin',
    templateUrl: './ordre-kosc-rappel-48-heures-edit-admin.component.html',
    styleUrls: ['./ordre-kosc-rappel-48-heures-edit-admin.component.css']
})
export class OrdreKoscRappel48HeuresEditAdminComponent implements OnInit {
    display = false;
    displayPopup = false;
    showSpinner = false;
    blocked = false;
    indexEdit = 0;
    public appropriateTechniciens: Array<TechnicienVo>;
    weekdays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi',];
    today = new Date();
    nextTwoWeeks: WeekDayVo[] = [];
    nextWeekDays = this.jourFerieService.weekDaysSubject.subscribe(data => {
        this.nextTwoWeeks = data
        console.log(data)
    })
    newDateRdv: Date = new Date();
    trancheRdv: number;
    disabledDates: Date[] = [];

    constructor(private datePipe: DatePipe, private ordreKoscService: OrdreKoscService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
        , private regionService: RegionService
        , private templateEmailClotureService: TemplateEmailClotureService
        , private etatDemandeKoscService: EtatDemandeKoscService
        , private statutEmailService: StatutEmailService
        , private technicienService: TechnicienService
        , private templateEmailReplanificationService: TemplateEmailReplanificationService
        , private templateSuiviService: TemplateSuiviService
        , private operatorService: OperatorService
        , private departementService: DepartementService
        , private templateEmailClientInjoinableKoscService: TemplateEmailClientInjoinableKoscService
        , private templateEmailPlanificationService: TemplateEmailPlanificationService
        , private defaultTemplateConfigurationService: DefaultTemplateConfigurationService
        , private authService: AuthService
        , private jourFerieService: JourFerieService
    ) {
        this.generateDisabledDates();
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validOrdreKoscReferenceWorkOrder = true;
    get validOrdreKoscReferenceWorkOrder(): boolean {
        return this._validOrdreKoscReferenceWorkOrder;
    }

    set validOrdreKoscReferenceWorkOrder(value: boolean) {
        this._validOrdreKoscReferenceWorkOrder = value;
    }

    get searchOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.searchOrdreKoscSuiviRdv;
    }

    set searchordreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.searchOrdreKosc = value;
    }

    get ordreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviRdv;
    }

    set ordreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviRdv = value;
    }

    get selectedOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.selectedOrdreKosc;
    }

    set selectedOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.selectedOrdreKosc = value;
    }

    get editOrdreKoscDialog(): boolean {
        return this.ordreKoscService.editOrdreKoscDialog;
    }

    set editOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.editOrdreKoscDialog = value;
    }

    get selectedTemplateEmailPlanification(): TemplateEmailPlanificationVo {
        return this.templateEmailPlanificationService.selectedTemplateEmailPlanification;
    }

    set selectedTemplateEmailPlanification(value: TemplateEmailPlanificationVo) {
        this.templateEmailPlanificationService.selectedTemplateEmailPlanification = value;
    }

    get createTemplateEmailPlanificationDialog(): boolean {
        return this.templateEmailPlanificationService.createTemplateEmailPlanificationDialog;
    }

    set createTemplateEmailPlanificationDialog(value: boolean) {
        this.templateEmailPlanificationService.createTemplateEmailPlanificationDialog = value;
    }

    get selectedTemplateEmailCloture(): TemplateEmailClotureVo {
        return this.templateEmailClotureService.selectedTemplateEmailCloture;
    }

    set selectedTemplateEmailCloture(value: TemplateEmailClotureVo) {
        this.templateEmailClotureService.selectedTemplateEmailCloture = value;
    }

    get templateEmailClotures(): Array<TemplateEmailClotureVo> {
        return this.templateEmailClotureService.templateEmailClotures;
    }

    set templateEmailClotures(value: Array<TemplateEmailClotureVo>) {
        this.templateEmailClotureService.templateEmailClotures = value;
    }

    get createTemplateEmailClotureDialog(): boolean {
        return this.templateEmailClotureService.createTemplateEmailClotureDialog;
    }

    get selectedDepartement(): DepartementVo {
        return this.departementService.selectedDepartement;
    }

    set selectedDepartement(value: DepartementVo) {
        this.departementService.selectedDepartement = value;
    }

    get departements(): Array<DepartementVo> {
        return this.departementService.departements;
    }

    set departements(value: Array<DepartementVo>) {
        this.departementService.departements = value;
    }

    get createDepartementDialog(): boolean {
        return this.departementService.createDepartementDialog;
    }

    set createDepartementDialog(value: boolean) {
        this.departementService.createDepartementDialog = value;
    }

    get selectedTemplateEmailReplanification(): TemplateEmailReplanificationVo {
        return this.templateEmailReplanificationService.selectedTemplateEmailReplanification;
    }

    set selectedTemplateEmailReplanification(value: TemplateEmailReplanificationVo) {
        this.templateEmailReplanificationService.selectedTemplateEmailReplanification = value;
    }

    get templateEmailReplanifications(): Array<TemplateEmailReplanificationVo> {
        return this.templateEmailReplanificationService.templateEmailReplanifications;
    }

    set templateEmailReplanifications(value: Array<TemplateEmailReplanificationVo>) {
        this.templateEmailReplanificationService.templateEmailReplanifications = value;
    }

    get createTemplateEmailReplanificationDialog(): boolean {
        return this.templateEmailReplanificationService.createTemplateEmailReplanificationDialog;
    }

    set createTemplateEmailReplanificationDialog(value: boolean) {
        this.templateEmailReplanificationService.createTemplateEmailReplanificationDialog = value;
    }

    get selectedTemplateEmailClientInjoinableKosc(): TemplateEmailClientInjoinableKoscVo {
        return this.templateEmailClientInjoinableKoscService.selectedTemplateEmailClientInjoinableKosc;
    }

    set selectedTemplateEmailClientInjoinableKosc(value: TemplateEmailClientInjoinableKoscVo) {
        this.templateEmailClientInjoinableKoscService.selectedTemplateEmailClientInjoinableKosc = value;
    }

    get templateEmailClientInjoinableKoscs(): Array<TemplateEmailClientInjoinableKoscVo> {
        return this.templateEmailClientInjoinableKoscService.templateEmailClientInjoinableKoscs;
    }

    set templateEmailClientInjoinableKoscs(value: Array<TemplateEmailClientInjoinableKoscVo>) {
        this.templateEmailClientInjoinableKoscService.templateEmailClientInjoinableKoscs = value;
    }

    get createTemplateEmailClientInjoinableKoscDialog(): boolean {
        return this.templateEmailClientInjoinableKoscService.createTemplateEmailClientInjoinableKoscDialog;
    }

    set createTemplateEmailClientInjoinableKoscDialog(value: boolean) {
        this.templateEmailClientInjoinableKoscService.createTemplateEmailClientInjoinableKoscDialog = value;
    }

    get selectedEtatDemandeKosc(): EtatDemandeKoscVo {
        return this.etatDemandeKoscService.selectedEtatDemandeKosc;
    }

    set selectedEtatDemandeKosc(value: EtatDemandeKoscVo) {
        this.etatDemandeKoscService.selectedEtatDemandeKosc = value;
    }

    get selectedStatutEmail(): StatutEmailVo {
        return this.statutEmailService.selectedStatutEmail;
    }

    set selectedStatutEmail(value: EtatDemandeKoscVo) {
        this.statutEmailService.selectedStatutEmail = value;
    }

    get etatDemandeKoscs(): Array<EtatDemandeKoscVo> {
        return this.etatDemandeKoscService.etatDemandeKoscs;
    }

    set etatDemandeKoscs(value: Array<EtatDemandeKoscVo>) {
        this.etatDemandeKoscService.etatDemandeKoscs = value;
    }

    get statutEmails(): Array<StatutEmailVo> {
        return this.statutEmailService.statutEmails;
    }

    set statutEmails(value: Array<StatutEmailVo>) {
        this.statutEmailService.statutEmails = value;
    }

    get createEtatDemandeKoscDialog(): boolean {
        return this.etatDemandeKoscService.createEtatDemandeKoscDialog;
    }

    set createEtatDemandeKoscDialog(value: boolean) {
        this.etatDemandeKoscService.createEtatDemandeKoscDialog = value;
    }

    get selectedTemplateSuivi(): TemplateSuiviVo {
        return this.templateSuiviService.selectedTemplateSuivi;
    }

    set selectedTemplateSuivi(value: TemplateSuiviVo) {
        this.templateSuiviService.selectedTemplateSuivi = value;
    }

    get templateSuivis(): Array<TemplateSuiviVo> {
        return this.templateSuiviService.templateSuivis;
    }

    set templateSuivis(value: Array<TemplateSuiviVo>) {
        this.templateSuiviService.templateSuivis = value;
    }

    get createTemplateSuiviDialog(): boolean {
        return this.templateSuiviService.createTemplateSuiviDialog;
    }

    set createTemplateSuiviDialog(value: boolean) {
        this.templateSuiviService.createTemplateSuiviDialog = value;
    }

    get selectedTechnicien(): TechnicienVo {
        return this.technicienService.selectedTechnicien;
    }

    set selectedTechnicien(value: TechnicienVo) {
        this.technicienService.selectedTechnicien = value;
    }

    get techniciens(): Array<TechnicienVo> {
        return this.technicienService.techniciens;
    }

    set techniciens(value: Array<TechnicienVo>) {
        this.technicienService.techniciens = value;
    }

    get createTechnicienDialog(): boolean {
        return this.technicienService.createTechnicienDialog;
    }

    set createTechnicienDialog(value: boolean) {
        this.technicienService.createTechnicienDialog = value;
    }

    get selectedOperator(): OperatorVo {
        return this.operatorService.selectedOperator;
    }

    set selectedOperator(value: OperatorVo) {
        this.operatorService.selectedOperator = value;
    }

    get operators(): Array<OperatorVo> {
        return this.operatorService.operators;
    }

    set operators(value: Array<OperatorVo>) {
        this.operatorService.operators = value;
    }

    get createOperatorDialog(): boolean {
        return this.operatorService.createOperatorDialog;
    }

    set createOperatorDialog(value: boolean) {
        this.operatorService.createOperatorDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    get selectedRegion(): RegionVo {
        return this.regionService.selectedRegion;
    }

    set selectedRegion(value: RegionVo) {
        this.regionService.selectedRegion = value;
    }

    get regions(): Array<RegionVo> {
        return this.regionService.regions;
    }

    set regions(value: Array<RegionVo>) {
        this.regionService.regions = value;
    }

    get createRegionDialog(): boolean {
        return this.regionService.createRegionDialog;
    }

    set createRegionDialog(value: boolean) {
        this.regionService.createRegionDialog = value;
    }

    get selectedDefaultTemplateConfiguration(): DefaultTemplateConfigurationVo {
        return this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration;
    }

    set selectedDefaultTemplateConfiguration(value: DefaultTemplateConfigurationVo) {
        this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration = value;
    }

    private _validOrdreKoscFromReplanification = true;

    get validOrdreKoscFromReplanification(): boolean {
        return this._validOrdreKoscFromReplanification;
    }

    set validOrdreKoscFromReplanification(value: boolean) {
        this._validOrdreKoscFromReplanification = value;
    }

    private _validOrdreKoscDateReplanificationReplanification = true;

    get validOrdreKoscDateReplanificationReplanification(): boolean {
        return this._validOrdreKoscDateReplanificationReplanification;
    }

    set validOrdreKoscDateReplanificationReplanification(value: boolean) {
        this._validOrdreKoscDateReplanificationReplanification = value;
    }

    private _validOrdreKoscToReplanification = true;
    get validOrdreKoscToReplanification(): boolean {
        return this._validOrdreKoscToReplanification;
    }

    set validOrdreKoscToReplanification(value: boolean) {
        this._validOrdreKoscToReplanification = value;
    }

    private _validOrdreKoscObjetReplanification = true;
    get validOrdreKoscObjetReplanification(): boolean {
        return this._validOrdreKoscObjetReplanification;
    }

    set validOrdreKoscObjetReplanification(value: boolean) {
        this._validOrdreKoscObjetReplanification = value;
    }

    private _validOrdreKoscCorpsReplanification = true;
    get validOrdreKoscCorpsReplanification(): boolean {
        return this._validOrdreKoscCorpsReplanification;
    }

    set validOrdreKoscCorpsReplanification(value: boolean) {
        this._validOrdreKoscCorpsReplanification = value;
    }

    private _validOrdreKoscFromRappel48Heures = true;
    get validOrdreKoscFromRappel48Heures(): boolean {
        return this._validOrdreKoscFromRappel48Heures;
    }

    set validOrdreKoscFromRappel48Heures(value: boolean) {
        this._validOrdreKoscFromRappel48Heures = value;
    }

    private _validOrdreKoscToRappel48Heures = true;
    get validOrdreKoscToRappel48Heures(): boolean {
        return this._validOrdreKoscToRappel48Heures;
    }

    set validOrdreKoscToRappel48Heures(value: boolean) {
        this._validOrdreKoscToRappel48Heures = value;
    }

    private _validOrdreKoscObjetRappel48Heures = true;
    get validOrdreKoscObjetRappel48Heures(): boolean {
        return this._validOrdreKoscObjetRappel48Heures;
    }

    set validOrdreKoscObjetRappel48Heures(value: boolean) {
        this._validOrdreKoscObjetRappel48Heures = value;
    }

    private _validOrdreKoscCorpsRappel48Heures = true;
    get validOrdreKoscCorpsRappel48Heures(): boolean {
        return this._validOrdreKoscCorpsRappel48Heures;
    }

    set validOrdreKoscCorpsRappel48Heures(value: boolean) {
        this._validOrdreKoscCorpsRappel48Heures = value;
    }

    generateDisabledDates(): void {
        const today = new Date();
        const endDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
        for (let currentDate = new Date(today); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
            const day = currentDate.getDay();
            if (day === 0 || day === 6) {
                this.disabledDates.push(new Date(currentDate));
            }
        }
    }

    getMinSelectableDate(): Date {
        const today = new Date();
        const minSelectableDate = new Date();
        minSelectableDate.setDate(today.getDate());
        return minSelectableDate;
    }

    ngOnInit(): void {
        this.defaultTemplateConfigurationService.findDefaultTemplateConfiguration().subscribe((data) => this.selectedDefaultTemplateConfiguration = data);
        this.selectedOperator = new OperatorVo();
        this.operatorService.findAll().subscribe((data) => this.operators = data);
        this.selectedDepartement = new DepartementVo();
        this.departementService.findAll().subscribe((data) => this.departements = data);
        this.selectedTechnicien = new TechnicienVo();
        this.technicienService.findAll().subscribe((data) => this.techniciens = data);
        this.selectedEtatDemandeKosc = new EtatDemandeKoscVo();
        this.etatDemandeKoscService.findAll().subscribe((data) => this.etatDemandeKoscs = data);
        this.selectedStatutEmail = new StatutEmailVo();
        this.statutEmailService.findAll().subscribe((data) => this.statutEmails = data);
        this.selectedTemplateEmailCloture = new TemplateEmailClotureVo();
        this.templateEmailClotureService.findAll().subscribe((data) => this.templateEmailClotures = data);
        this.selectedTemplateSuivi = new TemplateSuiviVo();
        this.templateSuiviService.findAll().subscribe((data) => this.templateSuivis = data);
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editRappel() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editRappelWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editRappelWithShowOption(showList: boolean) {
        this.ordreKoscService.editRappel().subscribe(ordreKosc => {
            const myIndex = this.ordreKoscs.findIndex(e => e.id === this.selectedOrdreKosc.id);
            this.ordreKoscs[myIndex] = ordreKosc;
            this.ordreKoscService.deleteIfEtatNotIn(this.searchOrdreKosc.etatDemandeKoscVos, this.ordreKoscs, ordreKosc);
            this.editOrdreKoscDialog = false;
            this.submitted = false;
            this.selectedOrdreKosc = new OrdreKoscVo();
        }, error => {
            console.log(error);
        });
    }

    public editWithShowOption(showList: boolean) {
        this.ordreKoscService.edit().subscribe(ordreKosc => {
            const myIndex = this.ordreKoscs.findIndex(e => e.id === this.selectedOrdreKosc.id);
            this.ordreKoscs[myIndex] = ordreKosc;
            this.ordreKoscService.deleteIfEtatNotIn(this.searchOrdreKosc.etatDemandeKoscVos, this.ordreKoscs, ordreKosc);
            this.editOrdreKoscDialog = false;
            this.submitted = false;
            this.selectedOrdreKosc = new OrdreKoscVo();
        }, error => {
            console.log(error);
        });
    }

    public async openCreateRegion(region: string) {
        const isPermistted = await this.roleService.isPermitted('Region', 'add');
        if (isPermistted) {
            this.selectedRegion = new RegionVo();
            this.createRegionDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTemplateEmailPlanification(templateEmailPlanification: string) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailPlanification', 'edit');
        if (isPermistted) {
            this.selectedTemplateEmailPlanification = new TemplateEmailPlanificationVo();
            this.createTemplateEmailPlanificationDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateDepartement(departement: string) {
        const isPermistted = await this.roleService.isPermitted('Departement', 'edit');
        if (isPermistted) {
            this.selectedDepartement = new DepartementVo();
            this.createDepartementDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTemplateEmailReplanification(templateEmailReplanification: string) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailReplanification', 'edit');
        if (isPermistted) {
            this.selectedTemplateEmailReplanification = new TemplateEmailReplanificationVo();
            this.createTemplateEmailReplanificationDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTemplateEmailClientInjoinableKosc(templateEmailClientInjoinableKosc: string) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailClientInjoinableKosc', 'edit');
        if (isPermistted) {
            this.selectedTemplateEmailClientInjoinableKosc = new TemplateEmailClientInjoinableKoscVo();
            this.createTemplateEmailClientInjoinableKoscDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateEtatDemandeKosc(etatDemandeKosc: string) {
        const isPermistted = await this.roleService.isPermitted('EtatDemandeKosc', 'edit');
        if (isPermistted) {
            this.selectedEtatDemandeKosc = new EtatDemandeKoscVo();
            this.createEtatDemandeKoscDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTemplateSuivi(templateSuivi: string) {
        const isPermistted = await this.roleService.isPermitted('TemplateSuivi', 'edit');
        if (isPermistted) {
            this.selectedTemplateSuivi = new TemplateSuiviVo();
            this.createTemplateSuiviDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTechnicien(technicien: string) {
        const isPermistted = await this.roleService.isPermitted('Technicien', 'edit');
        if (isPermistted) {
            this.selectedTechnicien = new TechnicienVo();
            this.createTechnicienDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateOperator(operator: string) {
        const isPermistted = await this.roleService.isPermitted('Operator', 'edit');
        if (isPermistted) {
            this.selectedOperator = new OperatorVo();
            this.createOperatorDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    hideEditDialog() {
        this.editOrdreKoscDialog = false;
        this.setValidation(true);
    }

    goToMailReplanification() {
        this.indexEdit = 2;
        this.selectedOrdreKosc.fromReplanification = eval(this.selectedDefaultTemplateConfiguration.emailManeo);
        this.selectedOrdreKosc.toReplanification = eval(this.selectedDefaultTemplateConfiguration.toPlanification);
        this.selectedOrdreKosc.ccReplanification = eval(this.selectedDefaultTemplateConfiguration.ccReplanification);
        this.selectedOrdreKosc.objetReplanification = eval(this.selectedDefaultTemplateConfiguration.templateEmailReplanificationVo.objet);
        this.selectedOrdreKosc.corpsReplanification = eval(this.selectedDefaultTemplateConfiguration.templateEmailReplanificationVo.corps);
    }

    public findEtatDemandeByCode(code: string) {
        let res = this.etatDemandeKoscService.findByCode(code, this.etatDemandeKoscs);
        return res;
    }

    sendMailReplanification() {
        this.validateFormReplanification();
        if (this.errorMessages.length === 0) {
            this.showSpinner = true;
            this.blocked = true;
            this.ordreKoscService.sendMailReplanification().subscribe(data => {
                    this.deleteFromList(this.selectedOrdreKosc);
                    if (data.envoyeReplanification == true) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Email envoyé avec succès'
                        });
                    } else {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Warning', detail: 'échec d\'envoi'
                        });
                    }
                    this.showSpinner = false;
                    this.blocked = false;
                    this.editOrdreKoscDialog = false;
                }
            );
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public formatDdMmYy(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'dd/MM/yyyy') : '';
    }

    public formatHhMm(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'HH:mm') : '';
    }

    showTechnicians(date: Date, code: string | undefined, tranche: number) {
        const dateRdv = new Date(date);
        this.newDateRdv = new Date(dateRdv.getTime());
        this.newDateRdv.setHours(14);
        if (tranche != null) {
            if (tranche == 1) {
                this.newDateRdv.setHours(8);
                this.selectedOrdreKosc.trancheJour = 1
            } else if (tranche == 2) {
                this.newDateRdv.setHours(10)
                this.selectedOrdreKosc.trancheJour = 2
            } else if (tranche == 3) {
                this.newDateRdv.setHours(13)
                this.selectedOrdreKosc.trancheJour = 3
            } else if (tranche == 4) {
                this.newDateRdv.setHours(15)
                this.selectedOrdreKosc.trancheJour = 4
            }
            this.newDateRdv.setMinutes(0)
        }
        this.technicienService.findAppropriateTechnicien(this.newDateRdv, code, tranche).subscribe(data => {
            this.appropriateTechniciens = data;
            this.display = true;
            this.deleteTech(date, code)
        })
        this.trancheRdv = tranche
    }

    public deleteTech(date: Date, departmentCode: string) {
        const dateRdv = new Date(date);
        const newDateRdvCopy = new Date(dateRdv.getTime());
        for (const technician of this.appropriateTechniciens) {
            this.technicienService.findByTechAndDate(newDateRdvCopy, technician.id).subscribe(
                data => data.forEach(order => {
                        if (order.departementVo.code !== departmentCode)
                            // Perform actions for orders with the specified department code
                        {
                            const indexToDelete = this.appropriateTechniciens.findIndex(item => item.id === technician.id); // Replace 'id' with the property you want to match
                            if (indexToDelete !== -1) {
                                this.appropriateTechniciens.splice(indexToDelete, 1);
                            }
                        }
                    }
                ));
        }
    }

    setRdvTech(technician: TechnicienVo) {
        this.selectedOrdreKosc.technicienVo = technician;
        this.selectedOrdreKosc.dateAppelReplanification = this.newDateRdv;
        this.selectedOrdreKosc.dateRdvFinale = this.newDateRdv;
        this.display = false;
        this.displayPopup = false;
    }

    public checkAvailability(date: Date, departmentCode: string, tranche: number): boolean {
        const days = this.nextTwoWeeks.find((day: any) => new Date(day.date).getTime() === new Date(date).getTime());
        if (!days) {
            return true;
        }
        const technicianAvailability = days.availableTechnicians.get(tranche.toString());
        if (technicianAvailability === 0) {
            return true; // if technician is not available, disable the button
        } else {
            return false; // if technician is available, enable the button
        }
    }

    showPopup() {
        if (this.selectedOrdreKosc.departementVo != null) {
            this.displayPopup = true;
        } else {
            this.messageService.add({
                severity: 'warn',
                summary: 'Warning', detail: 'Le département n\'est pas disponible, Veuillez le préciser',
            });
        }
    }

    isAvailable(technicienVo: TechnicienVo) {
        if (technicienVo.available == false) {
            return true;
        } else
            return false;
    }

    goToRappel48Heures() {
        this.indexEdit = 1;
        this.selectedOrdreKosc.fromRappel48Heures = eval(this.selectedDefaultTemplateConfiguration.emailManeo);
        this.selectedOrdreKosc.toRappel48Heures = eval(this.selectedDefaultTemplateConfiguration.toRappel48Heures);
        this.selectedOrdreKosc.ccRappel48Heures = eval(this.selectedDefaultTemplateConfiguration.ccRappel48Heures);
        this.selectedOrdreKosc.objetRappel48Heures = eval(this.selectedDefaultTemplateConfiguration.templateEmailRappel48HeuresVo?.objet);
        this.selectedOrdreKosc.corpsRappel48Heures = eval(this.selectedDefaultTemplateConfiguration.templateEmailRappel48HeuresVo?.corps);
        let userCourant = this.authService.authenticatedUser;
        this.selectedOrdreKosc.userRappel48Heures = userCourant;
        this.isEmailRappelElementsEmpty(this.selectedOrdreKosc);
    }

    isEmailRappelElementsEmpty(selectedOrdreKosc: OrdreKoscVo) {
        if (selectedOrdreKosc.toRappel48Heures == null && selectedOrdreKosc.fromRappel48Heures == null && selectedOrdreKosc.ccRappel48Heures == null
            && selectedOrdreKosc.objetRappel48Heures == null && selectedOrdreKosc.corpsRappel48Heures == null) {
            return true;
        } else {
            return false;
        }
    }

    private setValidation(value: boolean) {
        this.validOrdreKoscReferenceWorkOrder = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        //this.validateOrdreKoscReferenceWorkOrder();
    }

    private validateOrdreKoscObjetReplanification() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.objetReplanification)) {
            this.errorMessages.push('Objet replanification non valide');
            this.validOrdreKoscObjetReplanification = false;
        } else {
            this.validOrdreKoscObjetReplanification = true;
        }
    }

    private validateOrdreKoscCorpsReplanification() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.corpsReplanification)) {
            this.errorMessages.push('Corps replanification non valide');
            this.validOrdreKoscCorpsReplanification = false;
        } else {
            this.validOrdreKoscCorpsReplanification = true;
        }
    }

    private validateOrdreKoscFromReplanification() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.fromReplanification)) {
            this.errorMessages.push('De replanification non valide');
            this.validOrdreKoscFromReplanification = false;
        } else {
            this.validOrdreKoscFromReplanification = true;
        }
    }

    private validateOrdreKoscToReplanification() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.toReplanification)) {
            this.errorMessages.push('A replanification non valide');
            this.validOrdreKoscToReplanification = false;
        } else {
            this.validOrdreKoscToReplanification = true;
        }
    }

    private validateOrdreKoscDateReplanificationReplanification() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.dateAppelReplanification)) {
            this.errorMessages.push('Date replanification replanification non valide');
            this.validOrdreKoscDateReplanificationReplanification = false;
        } else {
            this.validOrdreKoscDateReplanificationReplanification = true;
        }
    }

    private validateOrdreKoscObjetRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.objetRappel48Heures)) {
            this.errorMessages.push('Objet rappel 48 heures non valide');
            this.validOrdreKoscObjetRappel48Heures = false;
        } else {
            this.validOrdreKoscObjetRappel48Heures = true;
        }
    }

    private validateOrdreKoscCorpsRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.corpsRappel48Heures)) {
            this.errorMessages.push('Corps rappel 48 heures non valide');
            this.validOrdreKoscCorpsRappel48Heures = false;
        } else {
            this.validOrdreKoscCorpsRappel48Heures = true;
        }
    }

    private validateOrdreKoscFromRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.fromRappel48Heures)) {
            this.errorMessages.push('De rappel 48 heures non valide');
            this.validOrdreKoscFromRappel48Heures = false;
        } else {
            this.validOrdreKoscFromRappel48Heures = true;
        }
    }

    private validateOrdreKoscToRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.toRappel48Heures)) {
            this.errorMessages.push('A rappel 48 heures non valide');
            this.validOrdreKoscToRappel48Heures = false;
        } else {
            this.validOrdreKoscToRappel48Heures = true;
        }
    }

    private validateFormRappel48Heures(): void {
        this.errorMessages = new Array<string>();
        this.validateOrdreKoscObjetRappel48Heures();
        this.validateOrdreKoscCorpsRappel48Heures();
        this.validateOrdreKoscFromRappel48Heures();
        this.validateOrdreKoscToRappel48Heures();
    }

    private validateFormReplanification(): void {
        this.errorMessages = new Array<string>();
        this.validateOrdreKoscDateReplanificationReplanification();
        this.validateOrdreKoscObjetReplanification();
        this.validateOrdreKoscCorpsReplanification();
        this.validateOrdreKoscFromReplanification();
        this.validateOrdreKoscToReplanification();
    }

    private deleteFromList(selectedOrdreKosc: OrdreKoscVo) {
        const position = this.ordreKoscs.findIndex(e => e.id == selectedOrdreKosc.id);
        position > -1 ? this.ordreKoscs.splice(position, 1) : false;
    }
}
