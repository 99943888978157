import {Component, OnInit} from '@angular/core';
import {OrdreKoscService} from 'src/app/controller/service/kosc/OrdreKosc.service';
import {OrdreKoscVo} from 'src/app/controller/model/kosc/OrdreKosc.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';
import {TechnicienVo} from 'src/app/controller/model/technicien/Technicien.model';
import {TechnicienService} from 'src/app/controller/service/technicien/Technicien.service';
import {OperatorVo} from 'src/app/controller/model/referentiel/Operator.model';
import {OperatorService} from 'src/app/controller/service/referentiel/Operator.service';
import {DepartementVo} from 'src/app/controller/model/technicien/Departement.model';
import {DepartementService} from 'src/app/controller/service/technicien/Departement.service';
import {EtatDemandeKoscService} from "../../../../controller/service/referentiel/EtatDemandeKosc.service";
import {DefaultTemplateConfigurationService} from "../../../../controller/service/template/DefaultTemplateConfiguration.service";
import {CauseKoOkService} from "../../../../controller/service/referentiel/CauseKoOk.service";
import {EtatDemandeKoscVo} from "../../../../controller/model/referentiel/EtatDemandeKosc.model";
import {CauseKoOkVo} from "../../../../controller/model/referentiel/CauseKoOk.model";
import * as moment from "moment/moment";
import {AuthService} from "../../../../controller/service/security/Auth.service";
import {DefaultTemplateConfigurationVo} from "../../../../controller/model/template/DefaultTemplateConfiguration.model";
import {PboReelVo} from "../../../../controller/model/referentiel/PboReel";
import {TypeForfaitDevisVo} from "../../../../controller/model/referentiel/TypeForfaitDevis";
import {TypeForfaitDevisService} from "../../../../controller/service/referentiel/TypeForfaitDevis.service";
import {PboReelService} from "../../../../controller/service/referentiel/PboReel.service";
@Component({
    selector: 'app-ordre-kosc-suivi-historique-edit-cdd',
    templateUrl: './ordre-kosc-suivi-historique-edit-cdd.component.html',
    styleUrls: ['./ordre-kosc-suivi-historique-edit-cdd.component.css']
})
export class OrdreKoscSuiviHistoriqueEditCddComponent implements OnInit {
    submitted = false;
    indexCdd = 0;
    showSpinner = false;
    blocked = false;
    showCauseKo = false;
    showCodeDecharge = false;
    _validTemplateEmailPlanificationLibelle = true;
    showDateGardeEnMain: boolean = false;
    selectedFiles: File[];
    uploadedFiles: File[] = [];
    causeKoOkVos: Array<CauseKoOkVo>;

    constructor(private datePipe: DatePipe, private ordreKoscService: OrdreKoscService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
        , private etatDemandeKoscService: EtatDemandeKoscService
        , private technicienService: TechnicienService
        , private operatorService: OperatorService
        , private typeForfaitDevisService: TypeForfaitDevisService
        , private pboReelService: PboReelService
        , private departementService: DepartementService
        , private defaultTemplateConfigurationService: DefaultTemplateConfigurationService
        , private causeKoOkService: CauseKoOkService
        , private authService: AuthService) {
    }

    _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validOrdreKoscCodeDecharge = true;
    get validOrdreKoscCodeDecharge(): boolean {
        return this._validOrdreKoscCodeDecharge;
    }

    set validOrdreKoscCodeDecharge(value: boolean) {
        this._validOrdreKoscCodeDecharge = value;
    }

    _validOrdreKoscMontantDevis = true;
    get validOrdreKoscMontantDevis(): boolean {
        return this._validOrdreKoscMontantDevis;
    }

    set validOrdreKoscMontantDevis(value: boolean) {
        this._validOrdreKoscMontantDevis = value;
    }

    _validOrdreKoscEtatDemandeKosc = true;
    get validOrdreKoscEtatDemandeKosc(): boolean {
        return this._validOrdreKoscEtatDemandeKosc;
    }

    set validOrdreKoscEtatDemandeKosc(value: boolean) {
        this._validOrdreKoscEtatDemandeKosc = value;
    }

    _validOrdreKoscDateCri = true;
    get validOrdreKoscDateCri(): boolean {
        return this._validOrdreKoscDateCri;
    }

    set validOrdreKoscDateCri(value: boolean) {
        this._validOrdreKoscDateCri = value;
    }

    _validOrdreKoscObjetCri = true;

    get validOrdreKoscObjetCri(): boolean {
        return this._validOrdreKoscObjetCri;
    }

    set validOrdreKoscObjetCri(value: boolean) {
        this._validOrdreKoscObjetCri = value;
    }

    _validOrdreKoscCorpsCri = true;

    get validOrdreKoscCorpsCri(): boolean {
        return this._validOrdreKoscCorpsCri;
    }

    set validOrdreKoscCorpsCri(value: boolean) {
        this._validOrdreKoscCorpsCri = value;
    }

    _validOrdreKoscFromCri = true;

    get validOrdreKoscFromCri(): boolean {
        return this._validOrdreKoscFromCri;
    }

    set validOrdreKoscFromCri(value: boolean) {
        this._validOrdreKoscFromCri = value;
    }

    _validOrdreKoscReferenceWorkOrder = true;

    get validOrdreKoscReferenceWorkOrder(): boolean {
        return this._validOrdreKoscReferenceWorkOrder;
    }

    set validOrdreKoscReferenceWorkOrder(value: boolean) {
        this._validOrdreKoscReferenceWorkOrder = value;
    }

    _validOperatorReference = true;
    get validOperatorReference(): boolean {
        return this._validOperatorReference;
    }

    set validOperatorReference(value: boolean) {
        this._validOperatorReference = value;
    }

    _validOperatorLibelle = true;
    get validOperatorLibelle(): boolean {
        return this._validOperatorLibelle;
    }

    set validOperatorLibelle(value: boolean) {
        this._validOperatorLibelle = value;
    }

    _validDepartementLibelle = true;
    get validDepartementLibelle(): boolean {
        return this._validDepartementLibelle;
    }

    set validDepartementLibelle(value: boolean) {
        this._validDepartementLibelle = value;
    }

    _validDepartementCode = true;
    get validDepartementCode(): boolean {
        return this._validDepartementCode;
    }

    set validDepartementCode(value: boolean) {
        this._validDepartementCode = value;
    }

    _validDepartementRegion = true;

    get validDepartementRegion(): boolean {
        return this._validDepartementRegion;
    }

    set validDepartementRegion(value: boolean) {
        this._validDepartementRegion = value;
    }

    _validOrdreKoscToCri = true;
    get validOrdreKoscToCri(): boolean {
        return this._validOrdreKoscToCri;
    }

    set validOrdreKoscToCri(value: boolean) {
        this._validOrdreKoscToCri = value;
    }

    _validTechnicienIdentifiant = true;
    get validTechnicienIdentifiant(): boolean {
        return this._validTechnicienIdentifiant;
    }

    set validTechnicienIdentifiant(value: boolean) {
        this._validTechnicienIdentifiant = value;
    }

    private _validOrdreKoscDateGardeEnMain = true;

    get validOrdreKoscDateGardeEnMain(): boolean {
        return this._validOrdreKoscDateGardeEnMain;
    }

    set validOrdreKoscDateGardeEnMain(value: boolean) {
        this._validOrdreKoscDateGardeEnMain = value;
    }

    private _validOrdreKoscObjetGardeEnMain = true;
    get validOrdreKoscObjetGardeEnMain(): boolean {
        return this._validOrdreKoscObjetGardeEnMain;
    }

    set validOrdreKoscObjetGardeEnMain(value: boolean) {
        this._validOrdreKoscObjetGardeEnMain = value;
    }

    private _validOrdreKoscCorpsGardeEnMain = true;
    get validOrdreKoscCorpsGardeEnMain(): boolean {
        return this._validOrdreKoscCorpsGardeEnMain;
    }

    set validOrdreKoscCorpsGardeEnMain(value: boolean) {
        this._validOrdreKoscCorpsGardeEnMain = value;
    }

    private _validOrdreKoscFromGardeEnMain = true;
    get validOrdreKoscFromGardeEnMain(): boolean {
        return this._validOrdreKoscFromGardeEnMain;
    }

    set validOrdreKoscFromGardeEnMain(value: boolean) {
        this._validOrdreKoscFromGardeEnMain = value;
    }

    _validOrdreKoscToGardeEnMain = true;
    get validOrdreKoscToGardeEnMain(): boolean {
        return this._validOrdreKoscToGardeEnMain;
    }

    set validOrdreKoscToGardeEnMain(value: boolean) {
        this._validOrdreKoscToGardeEnMain = value;
    }

    get searchOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.searchOrdreKoscCdd;
    }

    set searchordreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.searchOrdreKoscCdd = value;
    }

    get ordreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviHistoriqueCdd;
    }

    set ordreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviHistoriqueCdd = value;
    }

    get selectedOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.selectedOrdreKosc;
    }

    set selectedOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.selectedOrdreKosc = value;
    }

    get editOrdreKoscDialog(): boolean {
        return this.ordreKoscService.editOrdreKoscDialog;
    }

    set editOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.editOrdreKoscDialog = value;
    }

    get selectedDepartement(): DepartementVo {
        return this.departementService.selectedDepartement;
    }

    set selectedDepartement(value: DepartementVo) {
        this.departementService.selectedDepartement = value;
    }

    get departements(): Array<DepartementVo> {
        return this.departementService.departements;
    }

    set departements(value: Array<DepartementVo>) {
        this.departementService.departements = value;
    }

    get createDepartementDialog(): boolean {
        return this.departementService.createDepartementDialog;
    }

    set createDepartementDialog(value: boolean) {
        this.departementService.createDepartementDialog = value;
    }

    get selectedEtatDemandeKosc(): EtatDemandeKoscVo {
        return this.etatDemandeKoscService.selectedEtatDemandeKosc;
    }

    get etatDemandeKoscs(): Array<EtatDemandeKoscVo> {
        let etatDemandeKoscs = this.etatDemandeKoscService.etatDemandeKoscs;
        etatDemandeKoscs = etatDemandeKoscs.filter(e => e.code == 'ok' || e.code == 'ko' || e.code == 'planification');
        return etatDemandeKoscs;
    }

    set etatDemandeKoscs(value: Array<EtatDemandeKoscVo>) {
        this.etatDemandeKoscService.etatDemandeKoscs = value;
    }

    get selectedTechnicien(): TechnicienVo {
        return this.technicienService.selectedTechnicien;
    }

    set selectedTechnicien(value: TechnicienVo) {
        this.technicienService.selectedTechnicien = value;
    }

    get techniciens(): Array<TechnicienVo> {
        return this.technicienService.techniciens;
    }

    set techniciens(value: Array<TechnicienVo>) {
        this.technicienService.techniciens = value;
    }

    get createTechnicienDialog(): boolean {
        return this.technicienService.createTechnicienDialog;
    }

    set createTechnicienDialog(value: boolean) {
        this.technicienService.createTechnicienDialog = value;
    }

    get selectedOperator(): OperatorVo {
        return this.operatorService.selectedOperator;
    }

    set selectedOperator(value: OperatorVo) {
        this.operatorService.selectedOperator = value;
    }

    get operators(): Array<OperatorVo> {
        return this.operatorService.operators;
    }

    set operators(value: Array<OperatorVo>) {
        this.operatorService.operators = value;
    }

    get createOperatorDialog(): boolean {
        return this.operatorService.createOperatorDialog;
    }

    set createOperatorDialog(value: boolean) {
        this.operatorService.createOperatorDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    get causeKoOks(): Array<CauseKoOkVo> {
        return this.causeKoOkService.causeKoOks;
    }

    set causeKoOks(value: Array<CauseKoOkVo>) {
        this.causeKoOkService.causeKoOks = value;
    }

    get selectedDefaultTemplateConfiguration(): DefaultTemplateConfigurationVo {
        return this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration;
    }

    set selectedDefaultTemplateConfiguration(value: DefaultTemplateConfigurationVo) {
        this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration = value;
    }

    get selectedPboReel(): PboReelVo {
        return this.pboReelService.selectedPboReel;
    }

    set selectedPboReel(value: PboReelVo) {
        this.pboReelService.selectedPboReel = value;
    }

    get pboReels(): Array<PboReelVo> {
        return this.pboReelService.pboReels;
    }

    set pboReels(value: Array<PboReelVo>) {
        this.pboReelService.pboReels = value;
    }

    get createPboReelDialog(): boolean {
        return this.pboReelService.createPboReelDialog;
    }

    set createPboReelDialog(value: boolean) {
        this.pboReelService.createPboReelDialog = value;
    }

    get selectedTypeForfaitDevis(): PboReelVo {
        return this.pboReelService.selectedPboReel;
    }

    set selectedTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.selectedTypeForfaitDevis = value;
    }

    get typeForfaitDeviss(): Array<TypeForfaitDevisVo> {
        return this.typeForfaitDevisService.typeForfaitDeviss;
    }

    set typeForfaitDeviss(value: Array<TypeForfaitDevisVo>) {
        this.typeForfaitDevisService.typeForfaitDeviss = value;
    }

    get createTypeForfaitDevisDialog(): boolean {
        return this.pboReelService.createPboReelDialog;
    }

    set createTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.createTypeForfaitDevisDialog = value;
    }

    handelShowDateGardeEnMain() {
        this.showDateGardeEnMain = this.selectedOrdreKosc.rdvGem;
        return this.showDateGardeEnMain;
    }

    openGoogleDrive() {
        window.open('https://drive.google.com', '_blank');
    }

    public changeEtatKoscOrdre() {
        let etat = this.selectedOrdreKosc.etatDemandeKoscVo;
        if (etat != null && etat.code == 'ok') {
            this.genereCodeDechargeOk();
            // this.selectedOrdreKosc.causeKoOkVo = null;
            this.causeKoOkVos = this.causeKoOks.filter((cause: CauseKoOkVo) => cause.libelle.startsWith('OK'));
            // this.showCodeDecharge = true;
            this.showCauseKo = false;
        } else if (etat != null && etat.code == 'ko') {
            this.genereCodeDechargeKo();
            this.showCauseKo = true;
            this.causeKoOkVos = this.causeKoOks.filter((cause: CauseKoOkVo) => cause.libelle.startsWith('KO'));
            // this.showCodeDecharge = false;
        }
    }

    ngOnInit(): void {
        this.loadCauseKoOks();
        this.defaultTemplateConfigurationService.findDefaultTemplateConfiguration().subscribe((data) =>
            this.selectedDefaultTemplateConfiguration = data,
        );
        this.selectedOperator = new OperatorVo();
        this.operatorService.findAll().subscribe((data) => this.operators = data);
        this.typeForfaitDevisService.findAll().subscribe((data) => this.typeForfaitDeviss = data);
        this.pboReelService.findAll().subscribe((data) => this.pboReels = data);
        this.selectedDepartement = new DepartementVo();
        this.departementService.findAll().subscribe((data) => this.departements = data);
        this.selectedTechnicien = new TechnicienVo();
        this.technicienService.findAll().subscribe((data) => this.techniciens = data);
        this.etatDemandeKoscService.findAll().subscribe((data) => this.etatDemandeKoscs = data);
    }

    public async loadCauseKoOks() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('CauseKoOk', 'list');
        isPermistted ? this.causeKoOkService.findAll().subscribe(causeKoOks => {
                    this.causeKoOks = causeKoOks;
                },
                error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    goToMailCri() {
        let userCourant = this.authService.authenticatedUser;
        this.indexCdd = 2;
        this.selectedOrdreKosc.dateCri = new Date();
        this.selectedOrdreKosc.fromCri = eval(this.selectedDefaultTemplateConfiguration.fromCri);
        this.selectedOrdreKosc.toCri = eval(this.selectedDefaultTemplateConfiguration.toCri);
        this.selectedOrdreKosc.ccCri = eval(this.selectedDefaultTemplateConfiguration.ccCri);
        this.selectedOrdreKosc.objetCri = eval(this.selectedDefaultTemplateConfiguration.templateEmailCriVo?.objet);
        this.selectedOrdreKosc.corpsCri = eval(this.selectedDefaultTemplateConfiguration.templateEmailCriVo?.corps);
        this.selectedOrdreKosc.userCri = userCourant;
    }

    public formatDdMmYy(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'dd/MM/yyyy') : '';
    }

    public formatHhMm(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'HH:mm') : '';
    }

    cloturer() {
        this.indexCdd = 1;
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.selectedOrdreKosc.etatDemandeKoscOldVo = this.selectedOrdreKosc.etatDemandeKoscVo;
            this.editWithShowOption(false);
            this.editOrdreKoscDialog = false;
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editWithShowOption(showList: boolean) {
        this.ordreKoscService.edit().subscribe(ordreKosc => {
            const myIndex = this.ordreKoscs.findIndex(e => e.id === this.selectedOrdreKosc.id);
            this.ordreKoscs[myIndex] = ordreKosc;
            this.ordreKoscService.deleteIfEtatNotIn(this.searchOrdreKosc.etatDemandeKoscVos, this.ordreKoscs, this.selectedOrdreKosc);
            this.editOrdreKoscDialog = false;
            this.submitted = false;
            this.selectedOrdreKosc = new OrdreKoscVo();
        }, error => {
            console.log(error);
        });
    }

    public async openCreateDepartement(departement: string) {
        const isPermistted = await this.roleService.isPermitted('Departement', 'edit');
        if (isPermistted) {
            this.selectedDepartement = new DepartementVo();
            this.createDepartementDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateTechnicien(technicien: string) {
        const isPermistted = await this.roleService.isPermitted('Technicien', 'edit');
        if (isPermistted) {
            this.selectedTechnicien = new TechnicienVo();
            this.createTechnicienDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    public async openCreateOperator(operator: string) {
        const isPermistted = await this.roleService.isPermitted('Operator', 'edit');
        if (isPermistted) {
            this.selectedOperator = new OperatorVo();
            this.createOperatorDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème de permission'
            });
        }
    }

    hideEditDialog() {
        this.editOrdreKoscDialog = false;
        this.setValidation(true);
    }

    goToMailGardeEnMain() {
        let userCourant = this.authService.authenticatedUser;
        this.indexCdd = 3;
        this.selectedOrdreKosc.fromGardeEnMain = eval(this.selectedDefaultTemplateConfiguration.emailManeo);
        this.selectedOrdreKosc.toGardeEnMain = eval(this.selectedDefaultTemplateConfiguration.emailKosc);
        this.selectedOrdreKosc.ccGardeEnMain = eval(this.selectedDefaultTemplateConfiguration.ccGardeEnMain);
        this.selectedOrdreKosc.objetGardeEnMain = eval(this.selectedDefaultTemplateConfiguration.templateEmailGardeEnMainVo?.objet);
        this.selectedOrdreKosc.corpsGardeEnMain = eval(this.selectedDefaultTemplateConfiguration.templateEmailGardeEnMainVo?.corps);
        this.selectedOrdreKosc.userGardeEnMain = userCourant;
    }

    sendMailGardeEnMain() {
        this.validateFormGardeEnMain();
        if (this.errorMessages.length === 0) {
            this.showSpinner = true;
            this.blocked = true;
            this.ordreKoscService.sendMailGardeEnMain().subscribe(data => {
                    this.deleteFromList(this.selectedOrdreKosc);
                    if (data.envoyeGardeEnMain == true) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Email envoyé avec succès'
                        });
                    } else {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Warning',
                            detail: 'échec d\'envoi'
                        });
                    }
                    this.editOrdreKoscDialog = false;
                    this.showSpinner = false;
                    this.blocked = false;
                }
            );
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    sendMailCri() {
        this.validateFormCri();
        if (this.errorMessages.length === 0) {
            this.showSpinner = true;
            this.blocked = true;
            this.ordreKoscService.sendMailCri().subscribe(data => {
                    this.deleteFromList(this.selectedOrdreKosc);
                    if (data.envoyeCri == true) {
                        this.messageService.add({
                            severity: 'success', summary: 'Success', detail: 'Email envoyé avec succès'
                        });
                    } else {
                        this.messageService.add({
                            severity: 'warn', summary: 'Warning', detail: 'échec d\'envoi'
                        });
                    }
                    this.editOrdreKoscDialog = false;
                    this.showSpinner = false;
                    this.blocked = false;
                }
            );
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreurs', detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    onUploadMultiple(event: any) {
        this.uploadedFiles = event.files;
        this.selectedFiles = [...this.uploadedFiles];
    }

    uploadAndSendMail(): void {
        this.validateFormCri();
        if (this.errorMessages.length === 0) {
            this.selectedOrdreKosc.etatDemandeKoscOldVo = this.selectedOrdreKosc.etatDemandeKoscVo;
            this.showSpinner = true;
            this.blocked = true;
            this.ordreKoscService.uploadFilesToMinio(this.selectedFiles).subscribe(minioObjects => {
                for (let i = 0; i < minioObjects.length; i++) {
                    const minioObject = minioObjects[i][0];
                    if (!this.selectedOrdreKosc.filesNames) {
                        this.selectedOrdreKosc.filesNames = [];
                    }
                    if (!this.selectedOrdreKosc.attachments) {
                        this.selectedOrdreKosc.attachments = [];
                    }
                    // Check if minioObject has the properties 'originalFileName' and 'bytes'
                    const hasOriginalFileName = minioObject.hasOwnProperty('originalFileName');
                    const hasBytes = minioObject.hasOwnProperty('bytes');
                    // Use default values if originalFileName or bytes are undefined
                    const fileName = hasOriginalFileName ? minioObject.originalFileName : 'defaultFileName';
                    // Ensure the default value is a Uint8Array
                    const bytes = hasBytes ? minioObject.bytes : new Uint8Array();
                    this.selectedOrdreKosc.filesNames.push(fileName);
                    this.selectedOrdreKosc.attachments.push(bytes);
                }
                this.ordreKoscService.uploadFileAndSendMail().subscribe(data => {
                    this.deleteFromList(this.selectedOrdreKosc);
                    if (data.envoyeCri == true) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Email envoyé avec succès'
                        });
                    } else {
                        this.messageService.add({severity: 'warn', summary: 'Warning', detail: 'échec d\'envoi'});
                    }
                    this.editOrdreKoscDialog = false;
                    this.showSpinner = false;
                    this.blocked = false;
                });
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    private validateFormGardeEnMain(): void {
        this.errorMessages = new Array<string>();
        // this.validateOrdreKoscDateGardeEnMain();
        this.validateOrdreKoscObjetGardeEnMain();
        this.validateOrdreKoscCorpsGardeEnMain();
        this.validateOrdreKoscFromGardeEnMain();
        this.validateOrdreKoscToGardeEnMain();
    }

    private genereCodeDechargeOk() {
        let now = new Date();
        let codeDecharge = 'D' + moment(now).format('yyMMDD').slice(-6) + '-MN' + this.selectedOrdreKosc.id.toString().slice(-3);
        this.selectedOrdreKosc.codeDecharge = codeDecharge;
    }

    private genereCodeDechargeKo() {
        let now = new Date();
        let codeDecharge = 'D' + moment(now).format('yyMMDD').slice(-6) + '-';
        this.selectedOrdreKosc.codeDecharge = codeDecharge;
    }

    private deleteFromList(selectedOrdreKosc: OrdreKoscVo) {
        const position = this.ordreKoscs.indexOf(selectedOrdreKosc);
        position > -1 ? this.ordreKoscs.splice(position, 1) : false;
    }

    private updateListe() {
        this.ordreKoscs = this.ordreKoscs.filter(e => e.codeDecharge != null);
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        //this.validateOrdreKoscReferenceWorkOrder();
    }

    private setValidation(value: boolean) {
        this.validOrdreKoscReferenceWorkOrder = value;
    }

    private validateOrdreKoscObjetCri() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.objetCri)) {
            this.errorMessages.push('Objet cri non valide');
            this.validOrdreKoscObjetCri = false;
        } else {
            this.validOrdreKoscObjetCri = true;
        }
    }

    private validateOrdreKoscCorpsCri() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.corpsCri)) {
            this.errorMessages.push('Corps cri non valide');
            this.validOrdreKoscCorpsCri = false;
        } else {
            this.validOrdreKoscCorpsCri = true;
        }
    }

    private validateOrdreKoscFromCri() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.fromCri)) {
            this.errorMessages.push('De cri non valide');
            this.validOrdreKoscFromCri = false;
        } else {
            this.validOrdreKoscFromCri = true;
        }
    }

    private validateOrdreKoscToCri() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.toCri)) {
            this.errorMessages.push('A cri non valide');
            this.validOrdreKoscToCri = false;
        } else {
            this.validOrdreKoscToCri = true;
        }
    }

    private validateOrdreKoscDateCri() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.dateCri)) {
            this.errorMessages.push('date cri non valide');
            this.validOrdreKoscDateCri = false;
        } else {
            this.validOrdreKoscDateCri = true;
        }
    }

    private validateOrdreKoscCodeDecharge() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.codeDecharge)) {
            this.errorMessages.push('code decharge non valide');
            this.validOrdreKoscCodeDecharge = false;
        } else {
            this.validOrdreKoscCodeDecharge = true;
        }
    }

    private validateOrdreKoscEtatDemandeKosc() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.etatDemandeKoscVo.libelle)) {
            this.errorMessages.push('etat demande non valide');
            this.validOrdreKoscEtatDemandeKosc = false;
        } else {
            this.validOrdreKoscEtatDemandeKosc = true;
        }
    }

    private validateOrdreKoscMontantDevis() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.montantDevis)) {
            this.errorMessages.push('montant devis non valide');
            this.validOrdreKoscMontantDevis = false;
        } else {
            this.validOrdreKoscMontantDevis = true;
        }
    }

    private validateFormCri(): void {
        this.errorMessages = new Array<string>();
        // this.validateOrdreKoscEtatDemandeKosc();
        // this.validateOrdreKoscMontantDevis();
        // this.validateOrdreKoscCodeDecharge();
        this.validateOrdreKoscDateCri();
        this.validateOrdreKoscObjetCri();
        this.validateOrdreKoscCorpsCri();
        this.validateOrdreKoscFromCri();
        this.validateOrdreKoscToCri();
    }

    private validateOrdreKoscReferenceWorkOrder() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.referenceWorkOrder)) {
            this.errorMessages.push('Reference work order non valide');
            this.validOrdreKoscReferenceWorkOrder = false;
        } else {
            this.validOrdreKoscReferenceWorkOrder = true;
        }
    }

    private validateOrdreKoscObjetGardeEnMain() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.objetGardeEnMain)) {
            this.errorMessages.push('Objet non valide');
            this.validOrdreKoscObjetGardeEnMain = false;
        } else {
            this.validOrdreKoscObjetGardeEnMain = true;
        }
    }

    private validateOrdreKoscCorpsGardeEnMain() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.corpsGardeEnMain)) {
            this.errorMessages.push('Corps non valide');
            this.validOrdreKoscCorpsGardeEnMain = false;
        } else {
            this.validOrdreKoscCorpsGardeEnMain = true;
        }
    }

    private validateOrdreKoscFromGardeEnMain() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.fromGardeEnMain)) {
            this.errorMessages.push('De non valide');
            this.validOrdreKoscFromGardeEnMain = false;
        } else {
            this.validOrdreKoscFromGardeEnMain = true;
        }
    }

    private validateOrdreKoscToGardeEnMain() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.toGardeEnMain)) {
            this.errorMessages.push('A non valide');
            this.validOrdreKoscToGardeEnMain = false;
        } else {
            this.validOrdreKoscToGardeEnMain = true;
        }
    }

    private validateOrdreKoscDateGardeEnMain() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.dateGardeEnMain)) {
            this.errorMessages.push('date gardé en main non valide');
            this.validOrdreKoscDateGardeEnMain = false;
        } else {
            this.validOrdreKoscDateGardeEnMain = true;
        }
    }
}
