import {Component, OnInit} from '@angular/core';
import {StatutEmailService} from 'src/app/controller/service/template/StatutEmail.service';
import {StatutEmailVo} from 'src/app/controller/model/template/StatutEmail.model';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';

@Component({
    selector: 'app-statut-email-list-admin',
    templateUrl: './statut-email-list-admin.component.html',
    styleUrls: ['./statut-email-list-admin.component.css']
})
export class StatutEmailListAdminComponent implements OnInit {
    // declarations
    findByCriteriaShow = false;
    cols: any[] = [];
    excelPdfButons: MenuItem[];
    exportData: any[] = [];
    criteriaData: any[] = [];
    fileName = 'StatutEmail';
    items: MenuItem[];
    home: MenuItem;

    constructor(private datePipe: DatePipe, private statutEmailService: StatutEmailService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
    ) {
    }

    get statutEmails(): Array<StatutEmailVo> {
        return this.statutEmailService.statutEmails;
    }

    set statutEmails(value: Array<StatutEmailVo>) {
        this.statutEmailService.statutEmails = value;
    }

    get statutEmailSelections(): Array<StatutEmailVo> {
        return this.statutEmailService.statutEmailSelections;
    }

    set statutEmailSelections(value: Array<StatutEmailVo>) {
        this.statutEmailService.statutEmailSelections = value;
    }

    get selectedStatutEmail(): StatutEmailVo {
        return this.statutEmailService.selectedStatutEmail;
    }

    set selectedStatutEmail(value: StatutEmailVo) {
        this.statutEmailService.selectedStatutEmail = value;
    }

    get createStatutEmailDialog(): boolean {
        return this.statutEmailService.createStatutEmailDialog;
    }

    set createStatutEmailDialog(value: boolean) {
        this.statutEmailService.createStatutEmailDialog = value;
    }

    get editStatutEmailDialog(): boolean {
        return this.statutEmailService.editStatutEmailDialog;
    }

    set editStatutEmailDialog(value: boolean) {
        this.statutEmailService.editStatutEmailDialog = value;
    }

    get viewStatutEmailDialog(): boolean {
        return this.statutEmailService.viewStatutEmailDialog;
    }

    set viewStatutEmailDialog(value: boolean) {
        this.statutEmailService.viewStatutEmailDialog = value;
    }

    get searchStatutEmail(): StatutEmailVo {
        return this.statutEmailService.searchStatutEmail;
    }

    set searchStatutEmail(value: StatutEmailVo) {
        this.statutEmailService.searchStatutEmail = value;
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
        this.loadStatutEmails();
        this.initExport();
        this.initCol();
        this.items = [
            {label: 'Statuts email 48h', routerLink: '/app/admin/kosc/statut-email/list'},
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    // methods
    public async loadStatutEmails() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('StatutEmail', 'list');
        isPermistted ? this.statutEmailService.findAll().subscribe(statutEmails => this.statutEmails = statutEmails, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public searchRequest() {
        this.statutEmailService.findByCriteria(this.searchStatutEmail).subscribe(statutEmails => {
            this.statutEmails = statutEmails;
            // this.searchStatutEmail = new StatutEmailVo();
        }, error => console.log(error));
    }

    public async editStatutEmail(statutEmail: StatutEmailVo) {
        const isPermistted = await this.roleService.isPermitted('StatutEmail', 'edit');
        if (isPermistted) {
            this.statutEmailService.findByIdWithAssociatedList(statutEmail).subscribe(res => {
                this.selectedStatutEmail = res;
                this.editStatutEmailDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewStatutEmail(statutEmail: StatutEmailVo) {
        const isPermistted = await this.roleService.isPermitted('StatutEmail', 'view');
        if (isPermistted) {
            this.statutEmailService.findByIdWithAssociatedList(statutEmail).subscribe(res => {
                this.selectedStatutEmail = res;
                this.viewStatutEmailDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateStatutEmail(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedStatutEmail = new StatutEmailVo();
            this.createStatutEmailDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteStatutEmail(statutEmail: StatutEmailVo) {
        const isPermistted = await this.roleService.isPermitted('StatutEmail', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Statut Email) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.statutEmailService.delete(statutEmail).subscribe(status => {
                        if (status > 0) {
                            const position = this.statutEmails.indexOf(statutEmail);
                            position > -1 ? this.statutEmails.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success', summary: 'Succès', detail: 'Statut Email Supprimé', life: 3000
                            });
                        }
                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async duplicateStatutEmail(statutEmail: StatutEmailVo) {
        this.statutEmailService.findByIdWithAssociatedList(statutEmail).subscribe(
            res => {
                this.initDuplicateStatutEmail(res);
                this.selectedStatutEmail = res;
                this.selectedStatutEmail.id = null;
                this.createStatutEmailDialog = true;
            });
    }

    initDuplicateStatutEmail(res: StatutEmailVo) {
    }

    initExport(): void {
        this.excelPdfButons = [
            {
                label: 'CSV', icon: 'pi pi-file', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterCSV(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'XLS', icon: 'pi pi-file-excel', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterPdf(this.criteriaData, this.exportData, this.fileName);
                }
            }
        ];
    }

    prepareColumnExport(): void {
        this.exportData = this.statutEmails.map(e => {
            return {
                'Code': e.code,
                'Libelle': e.libelle,
                'Style': e.style,
            }
        });
        this.criteriaData = [{
            'Code': this.searchStatutEmail.code ? this.searchStatutEmail.code : environment.emptyForExport,
            'Libelle': this.searchStatutEmail.libelle ? this.searchStatutEmail.libelle : environment.emptyForExport,
            'Style': this.searchStatutEmail.style ? this.searchStatutEmail.style : environment.emptyForExport,
        }];
    }

    private initCol() {
        this.cols = [
            {field: 'code', header: 'Code'},
            {field: 'libelle', header: 'Libelle'},
            {field: 'style', header: 'Style'},
        ];
    }
}
