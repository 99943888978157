import {Component, OnInit} from '@angular/core';
import {TemplateEmailPropositionService} from 'src/app/controller/service/template/TemplateEmailProposition.service';
import {TemplateEmailPropositionVo} from 'src/app/controller/model/template/TemplateEmailProposition.model';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';

@Component({
    selector: 'app-template-email-proposition-list-admin',
    templateUrl: './template-email-proposition-list-admin.component.html',
    styleUrls: ['./template-email-proposition-list-admin.component.css']
})
export class TemplateEmailPropositionListAdminComponent implements OnInit {
    // declarations
    findByCriteriaShow = false;
    cols: any[] = [];
    excelPdfButons: MenuItem[];
    exportData: any[] = [];
    criteriaData: any[] = [];
    fileName = 'TemplateEmailProposition';
    items: MenuItem[];
    home: MenuItem;

    constructor(private datePipe: DatePipe, private templateEmailPropositionService: TemplateEmailPropositionService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
    ) {
    }

    get templateEmailPropositions(): Array<TemplateEmailPropositionVo> {
        return this.templateEmailPropositionService.templateEmailPropositions;
    }

    set templateEmailPropositions(value: Array<TemplateEmailPropositionVo>) {
        this.templateEmailPropositionService.templateEmailPropositions = value;
    }

    get templateEmailPropositionSelections(): Array<TemplateEmailPropositionVo> {
        return this.templateEmailPropositionService.templateEmailPropositionSelections;
    }

    set templateEmailPropositionSelections(value: Array<TemplateEmailPropositionVo>) {
        this.templateEmailPropositionService.templateEmailPropositionSelections = value;
    }

    get selectedTemplateEmailProposition(): TemplateEmailPropositionVo {
        return this.templateEmailPropositionService.selectedTemplateEmailProposition;
    }

    set selectedTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this.templateEmailPropositionService.selectedTemplateEmailProposition = value;
    }

    get createTemplateEmailPropositionDialog(): boolean {
        return this.templateEmailPropositionService.createTemplateEmailPropositionDialog;
    }

    set createTemplateEmailPropositionDialog(value: boolean) {
        this.templateEmailPropositionService.createTemplateEmailPropositionDialog = value;
    }

    get editTemplateEmailPropositionDialog(): boolean {
        return this.templateEmailPropositionService.editTemplateEmailPropositionDialog;
    }

    set editTemplateEmailPropositionDialog(value: boolean) {
        this.templateEmailPropositionService.editTemplateEmailPropositionDialog = value;
    }

    get viewTemplateEmailPropositionDialog(): boolean {
        return this.templateEmailPropositionService.viewTemplateEmailPropositionDialog;
    }

    set viewTemplateEmailPropositionDialog(value: boolean) {
        this.templateEmailPropositionService.viewTemplateEmailPropositionDialog = value;
    }

    get searchTemplateEmailProposition(): TemplateEmailPropositionVo {
        return this.templateEmailPropositionService.searchTemplateEmailProposition;
    }

    set searchTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this.templateEmailPropositionService.searchTemplateEmailProposition = value;
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
        this.loadTemplateEmailPropositions();
        this.initExport();
        this.initCol();
        this.items = [
            {label: 'Email Proposition', routerLink: '/app/admin/kosc/template-email-proposition/list'},
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    public async loadTemplateEmailPropositions() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('TemplateEmailProposition', 'list');
        isPermistted ? this.templateEmailPropositionService.findAll().subscribe(templateEmailPropositions => this.templateEmailPropositions = templateEmailPropositions, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public searchRequest() {
        this.templateEmailPropositionService.findByCriteria(this.searchTemplateEmailProposition).subscribe(templateEmailPropositions => {
            this.templateEmailPropositions = templateEmailPropositions;
            // this.searchTemplateEmailProposition = new TemplateEmailPropositionVo();
        }, error => console.log(error));
    }

    public async editTemplateEmailProposition(templateEmailProposition: TemplateEmailPropositionVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailProposition', 'edit');
        if (isPermistted) {
            this.templateEmailPropositionService.findByIdWithAssociatedList(templateEmailProposition).subscribe(res => {
                this.selectedTemplateEmailProposition = res;
                this.editTemplateEmailPropositionDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewTemplateEmailProposition(templateEmailProposition: TemplateEmailPropositionVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailProposition', 'view');
        if (isPermistted) {
            this.templateEmailPropositionService.findByIdWithAssociatedList(templateEmailProposition).subscribe(res => {
                this.selectedTemplateEmailProposition = res;
                this.viewTemplateEmailPropositionDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateTemplateEmailProposition(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedTemplateEmailProposition = new TemplateEmailPropositionVo();
            this.createTemplateEmailPropositionDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteTemplateEmailProposition(templateEmailProposition: TemplateEmailPropositionVo) {
        const isPermistted = await this.roleService.isPermitted('TemplateEmailProposition', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Template email proposition) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.templateEmailPropositionService.delete(templateEmailProposition).subscribe(status => {
                        if (status > 0) {
                            const position = this.templateEmailPropositions.indexOf(templateEmailProposition);
                            position > -1 ? this.templateEmailPropositions.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'Template email proposition Supprimé',
                                life: 3000
                            });
                        }

                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async duplicateTemplateEmailProposition(templateEmailProposition: TemplateEmailPropositionVo) {
        this.templateEmailPropositionService.findByIdWithAssociatedList(templateEmailProposition).subscribe(
            res => {
                this.initDuplicateTemplateEmailProposition(res);
                this.selectedTemplateEmailProposition = res;
                this.selectedTemplateEmailProposition.id = null;
                this.createTemplateEmailPropositionDialog = true;
            });
    }

    initDuplicateTemplateEmailProposition(res: TemplateEmailPropositionVo) {
    }

    initExport(): void {
        this.excelPdfButons = [
            {
                label: 'CSV', icon: 'pi pi-file', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterCSV(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'XLS', icon: 'pi pi-file-excel', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterPdf(this.criteriaData, this.exportData, this.fileName);
                }
            }
        ];
    }

    prepareColumnExport(): void {
        this.exportData = this.templateEmailPropositions.map(e => {
            return {
                'Libelle': e.libelle,
                'Objet': e.objet,
                'Corps': e.corps,
            }
        });
        this.criteriaData = [{
            'Libelle': this.searchTemplateEmailProposition.libelle ? this.searchTemplateEmailProposition.libelle : environment.emptyForExport,
            'Objet': this.searchTemplateEmailProposition.objet ? this.searchTemplateEmailProposition.objet : environment.emptyForExport,
            'Corps': this.searchTemplateEmailProposition.corps ? this.searchTemplateEmailProposition.corps : environment.emptyForExport,
        }];
    }

    private initCol() {
        this.cols = [
            {field: 'libelle', header: 'Libelle'},
            {field: 'objet', header: 'Objet'},
        ];
    }
}
