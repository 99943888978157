import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';
import {TypeForfaitDevisService} from "../../../../../../controller/service/referentiel/TypeForfaitDevis.service";
import {TypeForfaitDevisVo} from "../../../../../../controller/model/referentiel/TypeForfaitDevis";

@Component({
    selector: 'app-type-forfait-devis-list-admin',
    templateUrl: './type-forfait-devis-list-admin.component.html',
    styleUrls: ['./type-forfait-devis-list-admin.component.css']
})
export class TypeForfaitDevisListAdminComponent implements OnInit {
    // declarations
    findByCriteriaShow = false;
    cols: any[] = [];
    excelPdfButons: MenuItem[];
    exportData: any[] = [];
    criteriaData: any[] = [];
    fileName = 'TypeForfaitDevis';
    items: MenuItem[];
    home: MenuItem;

    constructor(private datePipe: DatePipe, private typeForfaitDevisService: TypeForfaitDevisService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
    ) {
    }

    get typeForfaitDeviss(): Array<TypeForfaitDevisVo> {
        return this.typeForfaitDevisService.typeForfaitDeviss;
    }

    set typeForfaitDeviss(value: Array<TypeForfaitDevisVo>) {
        this.typeForfaitDevisService.typeForfaitDeviss = value;
    }

    get typeForfaitDevisSelections(): Array<TypeForfaitDevisVo> {
        return this.typeForfaitDevisService.typeForfaitDevisSelections;
    }

    set typeForfaitDevisSelections(value: Array<TypeForfaitDevisVo>) {
        this.typeForfaitDevisService.typeForfaitDevisSelections = value;
    }

    get selectedTypeForfaitDevis(): TypeForfaitDevisVo {
        return this.typeForfaitDevisService.selectedTypeForfaitDevis;
    }

    set selectedTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.selectedTypeForfaitDevis = value;
    }

    get createTypeForfaitDevisDialog(): boolean {
        return this.typeForfaitDevisService.createTypeForfaitDevisDialog;
    }

    set createTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.createTypeForfaitDevisDialog = value;
    }

    get editTypeForfaitDevisDialog(): boolean {
        return this.typeForfaitDevisService.editTypeForfaitDevisDialog;
    }

    set editTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.editTypeForfaitDevisDialog = value;
    }

    get viewTypeForfaitDevisDialog(): boolean {
        return this.typeForfaitDevisService.viewTypeForfaitDevisDialog;
    }

    set viewTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.viewTypeForfaitDevisDialog = value;
    }

    get searchTypeForfaitDevis(): TypeForfaitDevisVo {
        return this.typeForfaitDevisService.searchTypeForfaitDevis;
    }

    set searchTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.searchTypeForfaitDevis = value;
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
        this.loadTypeForfaitDeviss();
        this.initExport();
        this.initCol();
        this.items = [
            {label: 'Type Forfait Devis', routerLink: '/app/admin/kosc/type-forfait-devis/list'},
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    // methods
    public async loadTypeForfaitDeviss() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('TypeForfaitDevis', 'list');
        isPermistted ? this.typeForfaitDevisService.findAll().subscribe(typeForfaitDeviss => this.typeForfaitDeviss = typeForfaitDeviss, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public searchRequest() {
        this.typeForfaitDevisService.findByCriteria(this.searchTypeForfaitDevis).subscribe(typeForfaitDeviss => {
            this.typeForfaitDeviss = typeForfaitDeviss;
            // this.searchTypeForfaitDevis = new TypeForfaitDevisVo();
        }, error => console.log(error));
    }

    public async editTypeForfaitDevis(typeForfaitDevis: TypeForfaitDevisVo) {
        const isPermistted = await this.roleService.isPermitted('TypeForfaitDevis', 'edit');
        if (isPermistted) {
            this.typeForfaitDevisService.findByIdWithAssociatedList(typeForfaitDevis).subscribe(res => {
                this.selectedTypeForfaitDevis = res;
                this.editTypeForfaitDevisDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewTypeForfaitDevis(typeForfaitDevis: TypeForfaitDevisVo) {
        const isPermistted = await this.roleService.isPermitted('TypeForfaitDevis', 'view');
        if (isPermistted) {
            this.typeForfaitDevisService.findByIdWithAssociatedList(typeForfaitDevis).subscribe(res => {
                this.selectedTypeForfaitDevis = res;
                this.viewTypeForfaitDevisDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateTypeForfaitDevis(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedTypeForfaitDevis = new TypeForfaitDevisVo();
            this.createTypeForfaitDevisDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteTypeForfaitDevis(typeForfaitDevis: TypeForfaitDevisVo) {
        const isPermistted = await this.roleService.isPermitted('TypeForfaitDevis', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Type Forfait Devis) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.typeForfaitDevisService.delete(typeForfaitDevis).subscribe(status => {
                        if (status > 0) {
                            const position = this.typeForfaitDeviss.indexOf(typeForfaitDevis);
                            position > -1 ? this.typeForfaitDeviss.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'Type Forfait Devis Supprimé',
                                life: 3000
                            });
                        }
                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async duplicateTypeForfaitDevis(typeForfaitDevis: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.findByIdWithAssociatedList(typeForfaitDevis).subscribe(
            res => {
                this.initDuplicateTypeForfaitDevis(res);
                this.selectedTypeForfaitDevis = res;
                this.selectedTypeForfaitDevis.id = null;
                this.createTypeForfaitDevisDialog = true;

            });
    }

    initDuplicateTypeForfaitDevis(res: TypeForfaitDevisVo) {
    }

    initExport(): void {
        this.excelPdfButons = [
            {
                label: 'CSV', icon: 'pi pi-file', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterCSV(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'XLS', icon: 'pi pi-file-excel', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                }
            }, {
                label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
                    this.prepareColumnExport();
                    this.exportService.exporterPdf(this.criteriaData, this.exportData, this.fileName);
                }
            }
        ];
    }

    prepareColumnExport(): void {
        this.exportData = this.typeForfaitDeviss.map(e => {
            return {
                'Code': e.code,
                'Libelle': e.libelle,
            }
        });
        this.criteriaData = [{
            'Code': this.searchTypeForfaitDevis.code ? this.searchTypeForfaitDevis.code : environment.emptyForExport,
            'Libelle': this.searchTypeForfaitDevis.libelle ? this.searchTypeForfaitDevis.libelle : environment.emptyForExport,
        }];
    }

    private initCol() {
        this.cols = [
            {field: 'code', header: 'Code'},
            {field: 'libelle', header: 'Libelle'},
        ];
    }
}
