import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-type-forfait-devis-admin',
    templateUrl: './type-forfait-devis-admin.component.html',
    styleUrls: ['./type-forfait-devis-admin.component.css']
})
export class TypeForfaitDevisAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
