import {Component, OnInit} from '@angular/core';
import {SourceReplanificationService} from 'src/app/controller/service/referentiel/SourceReplanification.service';
import {SourceReplanificationVo} from 'src/app/controller/model/referentiel/SourceReplanification.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
@Component({
    selector: 'app-source-replanification-view-admin',
    templateUrl: './source-replanification-view-admin.component.html',
    styleUrls: ['./source-replanification-view-admin.component.css']
})
export class SourceReplanificationViewAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private sourceReplanificationService: SourceReplanificationService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }
    get sourceReplanifications(): Array<SourceReplanificationVo> {
        return this.sourceReplanificationService.sourceReplanifications;
    }
    set sourceReplanifications(value: Array<SourceReplanificationVo>) {
        this.sourceReplanificationService.sourceReplanifications = value;
    }
    get selectedSourceReplanification(): SourceReplanificationVo {
        return this.sourceReplanificationService.selectedSourceReplanification;
    }
    set selectedSourceReplanification(value: SourceReplanificationVo) {
        this.sourceReplanificationService.selectedSourceReplanification = value;
    }
    get viewSourceReplanificationDialog(): boolean {
        return this.sourceReplanificationService.viewSourceReplanificationDialog;
    }
    set viewSourceReplanificationDialog(value: boolean) {
        this.sourceReplanificationService.viewSourceReplanificationDialog = value;
    }
    get dateFormat() {
        return environment.dateFormatView;
    }
    get dateFormatColumn() {
        return environment.dateFormatList;
    }
    ngOnInit(): void {
    }
    hideViewDialog() {
        this.viewSourceReplanificationDialog = false;
    }
}
