// const root = environment.rootAppUrl;
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthGuard} from 'src/app/controller/guards/auth.guard';

import {LoginAdminComponent} from './login-admin/login-admin.component';
import {RegisterAdminComponent} from './register-admin/register-admin.component';

@NgModule({
    imports: [
        RouterModule.forChild(
            [
                {
                    path: '',
                    children: [
                        {
                            path: 'login',
                            children: [
                                {
                                    path: '',
                                    component: LoginAdminComponent,
                                    canActivate: [AuthGuard]
                                }
                            ]
                        }, {
                            path: 'register',
                            children: [
                                {
                                    path: '',
                                    component: RegisterAdminComponent,
                                    canActivate: [AuthGuard]
                                }
                            ]
                        }, {
                            path: 'kosc',
                            loadChildren: () => import('./view/kosc/kosc-admin-routing.module').then(m => m.KoscAdminRoutingModule),
                            canActivate: [AuthGuard],
                        }
                    ]
                },
            ]
        ),
    ],
    exports: [RouterModule],
})
export class AdminRoutingModule {
}
