import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailReportDemandeManeoClientJoignableRefusService
} from 'src/app/controller/service/template/TemplateEmailReportDemandeManeoClientJoignableRefus.service';
import {
    TemplateEmailReportDemandeManeoClientJoignableRefusVo
} from 'src/app/controller/model/template/TemplateEmailReportDemandeManeoClientJoignableRefus.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-report-demande-maneo-client-joignable-refus-create-admin',
    templateUrl: './template-email-report-demande-maneo-client-joignable-refus-create-admin.component.html',
    styleUrls: ['./template-email-report-demande-maneo-client-joignable-refus-create-admin.component.css']
})
export class TemplateEmailReportDemandeManeoClientJoignableRefusCreateAdminComponent implements OnInit {

    constructor(private datePipe: DatePipe, private templateEmailReportDemandeManeoClientJoignableRefusService: TemplateEmailReportDemandeManeoClientJoignableRefusService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;

    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle = true;

    get validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableRefusObjet = true;

    get validTemplateEmailReportDemandeManeoClientJoignableRefusObjet(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableRefusObjet;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableRefusObjet(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableRefusObjet = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableRefusCorps = true;

    get validTemplateEmailReportDemandeManeoClientJoignableRefusCorps(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableRefusCorps;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableRefusCorps(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableRefusCorps = value;
    }

    get templateEmailReportDemandeManeoClientJoignableRefuss(): Array<TemplateEmailReportDemandeManeoClientJoignableRefusVo> {
        return this.templateEmailReportDemandeManeoClientJoignableRefusService.templateEmailReportDemandeManeoClientJoignableRefuss;
    }

    set templateEmailReportDemandeManeoClientJoignableRefuss(value: Array<TemplateEmailReportDemandeManeoClientJoignableRefusVo>) {
        this.templateEmailReportDemandeManeoClientJoignableRefusService.templateEmailReportDemandeManeoClientJoignableRefuss = value;
    }

    get selectedTemplateEmailReportDemandeManeoClientJoignableRefus(): TemplateEmailReportDemandeManeoClientJoignableRefusVo {
        return this.templateEmailReportDemandeManeoClientJoignableRefusService.selectedTemplateEmailReportDemandeManeoClientJoignableRefus;
    }

    set selectedTemplateEmailReportDemandeManeoClientJoignableRefus(value: TemplateEmailReportDemandeManeoClientJoignableRefusVo) {
        this.templateEmailReportDemandeManeoClientJoignableRefusService.selectedTemplateEmailReportDemandeManeoClientJoignableRefus = value;
    }

    get createTemplateEmailReportDemandeManeoClientJoignableRefusDialog(): boolean {
        return this.templateEmailReportDemandeManeoClientJoignableRefusService.createTemplateEmailReportDemandeManeoClientJoignableRefusDialog;

    }

    set createTemplateEmailReportDemandeManeoClientJoignableRefusDialog(value: boolean) {
        this.templateEmailReportDemandeManeoClientJoignableRefusService.createTemplateEmailReportDemandeManeoClientJoignableRefusDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.templateEmailReportDemandeManeoClientJoignableRefusService.save().subscribe(templateEmailReportDemandeManeoClientJoignableRefus => {
            if (templateEmailReportDemandeManeoClientJoignableRefus != null) {
                this.templateEmailReportDemandeManeoClientJoignableRefuss.push({...templateEmailReportDemandeManeoClientJoignableRefus});
                this.createTemplateEmailReportDemandeManeoClientJoignableRefusDialog = false;
                this.submitted = false;
                this.selectedTemplateEmailReportDemandeManeoClientJoignableRefus = new TemplateEmailReportDemandeManeoClientJoignableRefusVo();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreurs',
                    detail: 'Template email report demande maneo client joignable refus existe déjà'
                });
            }

        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createTemplateEmailReportDemandeManeoClientJoignableRefusDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle = value;
        this.validTemplateEmailReportDemandeManeoClientJoignableRefusObjet = value;
        this.validTemplateEmailReportDemandeManeoClientJoignableRefusCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailReportDemandeManeoClientJoignableRefusLibelle();
        this.validateTemplateEmailReportDemandeManeoClientJoignableRefusObjet();
        this.validateTemplateEmailReportDemandeManeoClientJoignableRefusCorps();
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableRefusLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableRefus.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusLibelle = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableRefusObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableRefus.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusObjet = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusObjet = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableRefusCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableRefus.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusCorps = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableRefusCorps = true;
        }
    }
}
