import {Component, OnInit} from '@angular/core';
import {TypeForfaitDevisService} from 'src/app/controller/service/referentiel/TypeForfaitDevis.service';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';
import {TypeForfaitDevisVo} from "../../../../../../controller/model/referentiel/TypeForfaitDevis";

@Component({
    selector: 'app-type-forfait-devis-edit-admin',
    templateUrl: './type-forfait-devis-edit-admin.component.html',
    styleUrls: ['./type-forfait-devis-edit-admin.component.css']
})
export class TypeForfaitDevisEditAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private typeForfaitDevisService: TypeForfaitDevisService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;

    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTypeForfaitDevisCode = true;

    get validTypeForfaitDevisCode(): boolean {
        return this._validTypeForfaitDevisCode;
    }

    set validTypeForfaitDevisCode(value: boolean) {
        this._validTypeForfaitDevisCode = value;
    }

    _validTypeForfaitDevisLibelle = true;

    get validTypeForfaitDevisLibelle(): boolean {
        return this._validTypeForfaitDevisLibelle;
    }

    set validTypeForfaitDevisLibelle(value: boolean) {
        this._validTypeForfaitDevisLibelle = value;
    }

    get typeForfaitDeviss(): Array<TypeForfaitDevisVo> {
        return this.typeForfaitDevisService.typeForfaitDeviss;
    }

    set typeForfaitDeviss(value: Array<TypeForfaitDevisVo>) {
        this.typeForfaitDevisService.typeForfaitDeviss = value;
    }

    get selectedTypeForfaitDevis(): TypeForfaitDevisVo {
        return this.typeForfaitDevisService.selectedTypeForfaitDevis;
    }

    set selectedTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.selectedTypeForfaitDevis = value;
    }

    get editTypeForfaitDevisDialog(): boolean {
        return this.typeForfaitDevisService.editTypeForfaitDevisDialog;
    }

    set editTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.editTypeForfaitDevisDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    ngOnInit(): void {
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editWithShowOption(showList: boolean) {
        this.typeForfaitDevisService.edit().subscribe(typeForfaitDevis => {
            const myIndex = this.typeForfaitDeviss.findIndex(e => e.id === this.selectedTypeForfaitDevis.id);
            this.typeForfaitDeviss[myIndex] = typeForfaitDevis;
            this.editTypeForfaitDevisDialog = false;
            this.submitted = false;
            this.selectedTypeForfaitDevis = new TypeForfaitDevisVo();
        }, error => {
            console.log(error);
        });
    }

    hideEditDialog() {
        this.editTypeForfaitDevisDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTypeForfaitDevisCode = value;
        this.validTypeForfaitDevisLibelle = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTypeForfaitDevisCode();
        this.validateTypeForfaitDevisLibelle();
    }

    private validateTypeForfaitDevisCode() {
        if (this.stringUtilService.isEmpty(this.selectedTypeForfaitDevis.code)) {
            this.errorMessages.push('Code non valide');
            this.validTypeForfaitDevisCode = false;
        } else {
            this.validTypeForfaitDevisCode = true;
        }
    }

    private validateTypeForfaitDevisLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTypeForfaitDevis.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTypeForfaitDevisLibelle = false;
        } else {
            this.validTypeForfaitDevisLibelle = true;
        }
    }
}
