import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-departement-technicien-admin',
    templateUrl: './departement-technicien-admin.component.html',
    styleUrls: ['./departement-technicien-admin.component.css']
})
export class DepartementTechnicienAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
