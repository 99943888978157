export class DateUtils {
    public static pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    public static toDateWithHour(dateAsString: string) {
        if (dateAsString == null) {
            return '';
        } else {
            let daysAndHours = dateAsString.split(" ");
            let daysMonthandYear = daysAndHours[0].split("-");
            let hourMinuteSecond = daysAndHours[1].split(":");
            return new Date(parseInt(daysMonthandYear[2]), parseInt(daysMonthandYear[1]) - 1, parseInt(daysMonthandYear[0]), parseInt(hourMinuteSecond[0]), parseInt(hourMinuteSecond[1]), parseInt(hourMinuteSecond[2]));
        }
    }

    public static toDateWithoutHour(dateAsString: string) {
        if (dateAsString == null) {
            return '';
        } else {
            let daysAndHours = dateAsString.split(" ");
            let daysMonthandYear = daysAndHours[0].split("-");
            return new Date(parseInt(daysMonthandYear[2]), parseInt(daysMonthandYear[1]), parseInt(daysMonthandYear[0]));
        }
    }

    public static toString(date) {
        const dt = new Date(date);
        if (dt == null) {
            return '';
        } else {
            return dt.getFullYear() +
                '-' + this.pad(dt.getMonth() + 1) +
                '-' + this.pad(dt.getDate()) +
                'T' + this.pad(dt.getHours()) +
                ':' + this.pad(dt.getMinutes()) +
                ':' + this.pad(dt.getSeconds()) +
                '.' + (dt.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                'Z';
        }
    }

    public static toDate(date: any): Date {
        if (date == null || Number.isNaN(date)) {
            return null;
        } else {
            return new Date(date.split('T')[0]);
        }
    }

    public static toDateForExcel(date: any): Date {
        if (date == null || Number.isNaN(date)) {
            return null;
        } else {
            const [day, month, year] = date.split('/');
            return new Date(+year, +month - 1, +day);
        }
    }

    public static ExcelDateToTsDate(serial) {
        if (serial == null) {
            return null;
        } else {
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;
            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;
            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        }
    }

    public static formatDate(date: any): string {
        if (!(date instanceof Date)) {
            console.error("formatDate: Argument is not a Date instance", date);
            return '';
        }
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }
}
