import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ToastModule} from "primeng/toast";
import {ToolbarModule} from "primeng/toolbar";
import {TableModule} from "primeng/table";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from "primeng/dialog";
import {PasswordModule} from "primeng/password";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {SplitButtonModule} from "primeng/splitbutton";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DropdownModule} from "primeng/dropdown";
import {TabViewModule} from "primeng/tabview";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CalendarModule} from "primeng/calendar";
import {PanelModule} from "primeng/panel";
import {MessageModule} from "primeng/message";
import {MessagesModule} from "primeng/messages";
import {InputNumberModule} from "primeng/inputnumber";
import {BadgeModule} from "primeng/badge";
import {MultiSelectModule} from "primeng/multiselect";
import {KoscAdminModule} from "../admin/view/kosc/kosc-admin.module";
import {KoscAdminRoutingModule} from "../admin/view/kosc/kosc-admin-routing.module";
import {FileUploadModule} from "primeng/fileupload";
import {RegisterCddComponent} from "./register-cdd/register-cdd.component";
import {LoginCddComponent} from "./login-cdd/login-cdd.component";
import {OrdreKoscSuiviCddCddComponent} from "./ordre-kosc-suivi-cdd-cdd/ordre-kosc-suivi-cdd-cdd.component";
import {
    OrdreKoscSuiviCddListCddComponent
} from "./ordre-kosc-suivi-cdd-cdd/list-cdd/ordre-kosc-suivi-cdd-list-cdd.component";
import {OrdreKoscSuiviCddComponent} from "./ordre-kosc-suivi-commande-cdd/ordre-kosc-suivi-cdd.component";
import {
    OrdreKoscSuiviCddViewCddComponent
} from "./ordre-kosc-suivi-cdd-cdd/view-cdd/ordre-kosc-suivi-cdd-view-cdd.component";
import {
    OrdreKoscPriseRdvEditCddComponent
} from "./ordre-kosc-prise-rdv-cdd/edit-cdd/ordre-kosc-prise-rdv-edit-cdd.component";
import {
    OrdreKoscPriseRdvListCddComponent
} from "./ordre-kosc-prise-rdv-cdd/list-cdd/ordre-kosc-prise-rdv-list-cdd.component";
import {
    OrdreKoscPriseRdvViewCddComponent
} from "./ordre-kosc-prise-rdv-cdd/view-cdd/ordre-kosc-prise-rdv-view-cdd.component";
import {OrdreKoscPriseRdvCddComponent} from "./ordre-kosc-prise-rdv-cdd/ordre-kosc-prise-rdv-cdd.component";
import {
    OrdreKoscSuiviCddEditCddComponent
} from "./ordre-kosc-suivi-cdd-cdd/edit-cdd/ordre-kosc-suivi-cdd-edit-cdd.component";
import {
    OrdreKoscSuiviEditCddComponent
} from "./ordre-kosc-suivi-commande-cdd/edit-cdd/ordre-kosc-suivi-edit-cdd.component";
import {
    OrdreKoscSuiviListCddComponent
} from "./ordre-kosc-suivi-commande-cdd/list-cdd/ordre-kosc-suivi-list-cdd.component";
import {
    OrdreKoscSuiviViewCddComponent
} from "./ordre-kosc-suivi-commande-cdd/view-cdd/ordre-kosc-suivi-view-cdd.component";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {
    OrdreKoscSuiviHistoriqueEditCddComponent
} from "./ordre-kosc-cdd-cdd/edit-cdd/ordre-kosc-suivi-historique-edit-cdd.component";
import {
    OrdreKoscSuiviHistoriqueListCddComponent
} from "./ordre-kosc-cdd-cdd/list-cdd/ordre-kosc-suivi-historique-list-cdd.component";
import {
    OrdreKoscSuiviHistoriqueViewCddComponent
} from "./ordre-kosc-cdd-cdd/view-cdd/ordre-kosc-suivi-historique-view-cdd.component";
import {OrdreKoscSuiviHistoriqueCddComponent} from "./ordre-kosc-cdd-cdd/ordre-kosc-suivi-historique-cdd.component";
import {RippleModule} from "primeng/ripple";
import {CardModule} from "primeng/card";
import {FieldsetModule} from "primeng/fieldset";
import {TooltipModule} from "primeng/tooltip";
import {
    OrdreKoscReplanificationEnAttenteCddComponent
} from "./ordre-kosc-replanification-en-attente-cdd/ordre-kosc-replanification-en-attente-cdd.component";
import {PaginatorModule} from "primeng/paginator";
import {GrilleTechnicienCddComponent} from "./grille-technicien-cdd/grille-technicien-cdd.component";
import {GrilleTechnicienListCddComponent} from "./grille-technicien-cdd/list-cdd/grille-technicien-list-cdd.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {
    OrdreKoscRappel48HeuresListCddComponent
} from "./ordre-kosc-rappel-48-heures-cdd/list-cdd/ordre-kosc-rappel-48-heures-list-cdd.component";
import {
    OrdreKoscRappel48HeuresCddComponent
} from "./ordre-kosc-rappel-48-heures-cdd/ordre-kosc-rappel-48-heures-cdd.component";
import {
    OrdreKoscRappel48HeuresEditCddComponent
} from "./ordre-kosc-rappel-48-heures-cdd/edit-cdd/ordre-kosc-rappel-48-heures-edit-cdd.component";
import {
    OrdreKoscRappel48HeuresViewCddComponent
} from "./ordre-kosc-rappel-48-heures-cdd/view-cdd/ordre-kosc-rappel-48-heures-view-cdd.component";
import {
    OrdreKoscReplanificationEnAttenteListCddComponent
} from "./ordre-kosc-replanification-en-attente-cdd/list-cdd/ordre-kosc-replanification-en-attente-list-cdd.component";
import {
    OrdreKoscReplanificationEnAttenteViewCddComponent
} from "./ordre-kosc-replanification-en-attente-cdd/view-cdd/ordre-kosc-replanification-en-attente-view-cdd.component";
import {
    OrdreKoscReplanificationEnAttenteEditCddComponent
} from "./ordre-kosc-replanification-en-attente-cdd/edit-cdd/ordre-kosc-replanification-en-attente-edit-cdd.component";

@NgModule({
    declarations: [
        OrdreKoscPriseRdvEditCddComponent,
        OrdreKoscPriseRdvListCddComponent,
        OrdreKoscPriseRdvViewCddComponent,
        OrdreKoscPriseRdvCddComponent,
        OrdreKoscSuiviCddEditCddComponent,
        OrdreKoscSuiviCddListCddComponent,
        OrdreKoscSuiviCddViewCddComponent,
        OrdreKoscSuiviCddCddComponent,
        OrdreKoscSuiviEditCddComponent,
        OrdreKoscSuiviListCddComponent,
        OrdreKoscSuiviViewCddComponent,
        OrdreKoscSuiviCddComponent,
        OrdreKoscSuiviHistoriqueEditCddComponent,
        OrdreKoscSuiviHistoriqueListCddComponent,
        OrdreKoscSuiviHistoriqueViewCddComponent,
        OrdreKoscSuiviHistoriqueCddComponent,
        OrdreKoscReplanificationEnAttenteEditCddComponent,
        OrdreKoscReplanificationEnAttenteListCddComponent,
        OrdreKoscReplanificationEnAttenteViewCddComponent,
        OrdreKoscReplanificationEnAttenteCddComponent,
        RegisterCddComponent,
        LoginCddComponent,
        GrilleTechnicienListCddComponent,
        GrilleTechnicienCddComponent,
        OrdreKoscRappel48HeuresEditCddComponent,
        OrdreKoscRappel48HeuresListCddComponent,
        OrdreKoscRappel48HeuresViewCddComponent,
        OrdreKoscRappel48HeuresCddComponent,
    ],
    imports: [
        CommonModule,
        ToastModule,
        ToolbarModule,
        TableModule,
        ConfirmDialogModule,
        DialogModule,
        PasswordModule,
        InputTextModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SplitButtonModule,
        BrowserAnimationsModule,
        DropdownModule,
        TabViewModule,
        InputSwitchModule,
        InputTextareaModule,
        CalendarModule,
        PanelModule,
        MessageModule,
        MessagesModule,
        InputNumberModule,
        BadgeModule,
        MultiSelectModule,
        KoscAdminModule,
        KoscAdminRoutingModule,
        FileUploadModule,
        RippleModule,
        BreadcrumbModule,
        BlockUIModule,
        ProgressSpinnerModule,
        CardModule,
        FieldsetModule,
        TooltipModule,
        PaginatorModule,
        FullCalendarModule,
    ],
    exports: [
        OrdreKoscPriseRdvEditCddComponent,
        OrdreKoscPriseRdvListCddComponent,
        OrdreKoscPriseRdvViewCddComponent,
        OrdreKoscPriseRdvCddComponent,
        OrdreKoscSuiviCddEditCddComponent,
        OrdreKoscSuiviCddListCddComponent,
        OrdreKoscSuiviCddViewCddComponent,
        OrdreKoscSuiviCddCddComponent,
        OrdreKoscSuiviEditCddComponent,
        OrdreKoscSuiviListCddComponent,
        OrdreKoscSuiviViewCddComponent,
        OrdreKoscSuiviCddComponent,
        OrdreKoscSuiviHistoriqueEditCddComponent,
        OrdreKoscSuiviHistoriqueListCddComponent,
        OrdreKoscSuiviHistoriqueViewCddComponent,
        OrdreKoscSuiviHistoriqueCddComponent,
        OrdreKoscReplanificationEnAttenteEditCddComponent,
        OrdreKoscReplanificationEnAttenteListCddComponent,
        OrdreKoscReplanificationEnAttenteViewCddComponent,
        OrdreKoscReplanificationEnAttenteCddComponent,
        RegisterCddComponent,
        LoginCddComponent,
        GrilleTechnicienListCddComponent,
        GrilleTechnicienCddComponent,
        OrdreKoscRappel48HeuresEditCddComponent,
        OrdreKoscRappel48HeuresListCddComponent,
        OrdreKoscRappel48HeuresViewCddComponent,
        OrdreKoscRappel48HeuresCddComponent,
    ]
})
export class CddManagerModule {
}
