import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, throwError} from 'rxjs';
import {RoleService} from '../security/role.service';
import * as moment from 'moment';
import {environment} from 'src/environments/environment';
import {format} from 'date-fns';
import {OrdreKoscVo} from '../../model/kosc/OrdreKosc.model';
import {StatisticVo} from "../../model/statistic/Statistic.model";
import {EtatDemandeKoscVo} from "../../model/referentiel/EtatDemandeKosc.model";
import {JourFerieVo} from "../../model/referentiel/JourFerie.model";
import {PaginatedList} from "../../model/referentiel/PaginatedList.model";
import {StatisticPriseRdvVo} from "../../model/statistic/StatisticDelaiPriseRdv.model";
import {StatisticDelaiPlanificationVo} from "../../model/statistic/StatisticDelaiPlanification.model";
import {StatisticRespectDatesErdvVo} from "../../model/statistic/StatisticRespectDatesErdv.model";
import {StatisticRespectRdvVo} from "../../model/statistic/StatisticRespectRdv.model";
import {StatisticDelaiEmissionCriVo} from "../../model/statistic/StatisticDelaiEmissionCri.model";
import {StatisticTauxReussiteVo} from "../../model/statistic/StatisticTauxReussite.model";
import {StatisticDelaiMoyenGemVo} from "../../model/statistic/StatisticDelaiMoyenGem.model";
import {map} from "rxjs/operators";
import {MinIOInfos} from "../../model/referentiel/MinIOInfos";
import {OrdreKoscOldVo} from "../../model/kosc/OrdreKoscOld.model";
import {StatisticEtatStockVo} from "../../model/statistic/StatisticEtatStock.model";
import {ImportAllResultVo} from "../../model/referentiel/ImportAllResult.model";
import {StatisticDelaiPriseRdvCovageVo} from "../../model/statistic/StatisticDelaiPriseRdvCovage";
import {StatisticDelaisInterventionsCovageVo} from "../../model/statistic/StatisticDelaisInterventionsCovage";
import {StatisticDelaiEnvoiCriCovageVo} from "../../model/statistic/StatisticDelaiEnvoiCriCovage";
import {StatisticOnceAndDoneCovageVo} from "../../model/statistic/StatisticOnceAndDoneCovage";

@Injectable({
    providedIn: 'root'
})
export class OrdreKoscService {
    public editOrdreKosc$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/ordreKosc/';
        });
    }

    protected _selections: Array<OrdreKoscVo>;
    public get selections(): Array<OrdreKoscVo> {
        if (this._selections == null) {
            this._selections = new Array<OrdreKoscVo>();
        }
        return this._selections;
    }

    public set selections(value: Array<OrdreKoscVo>) {
        this._selections = value;
    }

    private _showCauseKo = false;
    get showCauseKo(): boolean {
        return this._showCauseKo;
    }

    set showCauseKo(value: boolean) {
        this._showCauseKo = value;
    }

    private _showSpinner = false;
    get showSpinner(): boolean {
        return this._showSpinner;
    }

    set showSpinner(value: boolean) {
        this._showSpinner = value;
    }

    private _selectedIndexView = 0;
    get selectedIndexView(): number {
        return this._selectedIndexView;
    }

    set selectedIndexView(value: number) {
        this._selectedIndexView = value;
    }

    private _selectedOrdreKosc: OrdreKoscVo;
    get selectedOrdreKosc(): OrdreKoscVo {
        if (this._selectedOrdreKosc == null) {
            this._selectedOrdreKosc = new OrdreKoscVo();
        }
        return this._selectedOrdreKosc;
    }

    set selectedOrdreKosc(value: OrdreKoscVo) {
        this._selectedOrdreKosc = value;
    }

    private _selectedOrdreKoscOld: OrdreKoscOldVo;
    get selectedOrdreKoscOld(): OrdreKoscOldVo {
        if (this._selectedOrdreKoscOld == null) {
            this._selectedOrdreKoscOld = new OrdreKoscOldVo();
        }
        return this._selectedOrdreKoscOld;
    }

    set selectedOrdreKoscOld(value: OrdreKoscOldVo) {
        this._selectedOrdreKoscOld = value;
    }

    private _ordreKoscSelections: Array<OrdreKoscVo>;
    get ordreKoscSelections(): Array<OrdreKoscVo> {
        if (this._ordreKoscSelections == null) {
            this._ordreKoscSelections = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscSelections;
    }

    set ordreKoscSelections(value: Array<OrdreKoscVo>) {
        this._ordreKoscSelections = value;
    }

    private _editOrdreKoscDialog: boolean;
    get editOrdreKoscDialog(): boolean {
        return this._editOrdreKoscDialog;
    }

    set editOrdreKoscDialog(value: boolean) {
        this._editOrdreKoscDialog = value;
    }

    private _viewOrdreKoscDialog: boolean;
    get viewOrdreKoscDialog(): boolean {
        return this._viewOrdreKoscDialog;
    }

    set viewOrdreKoscDialog(value: boolean) {
        this._viewOrdreKoscDialog = value;
    }

    private _createOrdreKoscDialog: boolean;
    get createOrdreKoscDialog(): boolean {
        return this._createOrdreKoscDialog;
    }

    set createOrdreKoscDialog(value: boolean) {
        this._createOrdreKoscDialog = value;
    }

    private _indexEdit = 0;
    get indexEdit(): number {
        return this._indexEdit;
    }

    set indexEdit(value: number) {
        this._indexEdit = value;
    }

    private _searchOrdreKosc: OrdreKoscVo;
    get searchOrdreKosc(): OrdreKoscVo {
        if (this._searchOrdreKosc == null) {
            this._searchOrdreKosc = new OrdreKoscVo();
        }
        return this._searchOrdreKosc;
    }

    set searchOrdreKosc(value: OrdreKoscVo) {
        this._searchOrdreKosc = value;
    }

    private _searchOrdreKoscSuiviCdd: OrdreKoscVo;
    get searchOrdreKoscSuiviCdd(): OrdreKoscVo {
        if (this._searchOrdreKoscSuiviCdd == null) {
            this._searchOrdreKoscSuiviCdd = new OrdreKoscVo();
        }
        return this._searchOrdreKoscSuiviCdd;
    }

    set searchOrdreKoscSuiviCdd(value: OrdreKoscVo) {
        this._searchOrdreKoscCdd = value;
    }

    private _searchOrdreKoscCdd: OrdreKoscVo;
    get searchOrdreKoscCdd(): OrdreKoscVo {
        if (this._searchOrdreKoscCdd == null) {
            this._searchOrdreKoscCdd = new OrdreKoscVo();
        }
        return this._searchOrdreKoscCdd;
    }

    set searchOrdreKoscCdd(value: OrdreKoscVo) {
        this._searchOrdreKoscCdd = value;
    }

    private _ordreKoscs: Array<OrdreKoscVo>;
    get ordreKoscs(): Array<OrdreKoscVo> {
        if (this._ordreKoscs == null) {
            this._ordreKoscs = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscs;
    }

    set ordreKoscs(value: Array<OrdreKoscVo>) {
        this._ordreKoscs = value;
    }

    private _ordreKoscsPriseRdv: Array<OrdreKoscVo>;
    get ordreKoscsPriseRdv(): Array<OrdreKoscVo> {
        if (this._ordreKoscsPriseRdv == null) {
            this._ordreKoscsPriseRdv = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscsPriseRdv;
    }

    set ordreKoscsPriseRdv(value: Array<OrdreKoscVo>) {
        this._ordreKoscsPriseRdv = value;
    }

    private _ordreKoscsSuiviRdv: Array<OrdreKoscVo>;
    get ordreKoscsSuiviRdv(): Array<OrdreKoscVo> {
        if (this._ordreKoscsSuiviRdv == null) {
            this._ordreKoscsSuiviRdv = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscsSuiviRdv;
    }

    set ordreKoscsSuiviRdv(value: Array<OrdreKoscVo>) {
        this._ordreKoscsSuiviRdv = value;
    }

    private _ordreKoscsSuiviCdd: Array<OrdreKoscVo>;
    get ordreKoscsSuiviCdd(): Array<OrdreKoscVo> {
        if (this._ordreKoscsSuiviCdd == null) {
            this._ordreKoscsSuiviCdd = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscsSuiviCdd;
    }

    set ordreKoscsSuiviCdd(value: Array<OrdreKoscVo>) {
        this._ordreKoscsSuiviCdd = value;
    }

    private _ordreKoscsSuiviHistoriqueCdd: Array<OrdreKoscVo>;
    get ordreKoscsSuiviHistoriqueCdd(): Array<OrdreKoscVo> {
        if (this._ordreKoscsSuiviHistoriqueCdd == null) {
            this._ordreKoscsSuiviHistoriqueCdd = new Array<OrdreKoscVo>();
        }
        return this._ordreKoscsSuiviHistoriqueCdd;
    }

    set ordreKoscsSuiviHistoriqueCdd(value: Array<OrdreKoscVo>) {
        this._ordreKoscsSuiviHistoriqueCdd = value;
    }

    private _searchOrdreKoscSuiviRdv: OrdreKoscVo;
    get searchOrdreKoscSuiviRdv(): OrdreKoscVo {
        if (this._searchOrdreKoscSuiviRdv == null) {
            this._searchOrdreKoscSuiviRdv = new OrdreKoscVo();
        }
        return this._searchOrdreKoscSuiviRdv;
    }

    set searchOrdreKoscSuiviRdv(value: OrdreKoscVo) {
        this._searchOrdreKoscSuiviRdv = value;
    }

    private _totalRecords = 0;
    get totalRecords(): number {
        return this._totalRecords;
    }

    set totalRecords(value: number) {
        this._totalRecords = value;
    }

    private _preparedOrdreKoscs: Array<OrdreKoscVo>;
    get preparedOrdreKoscs(): Array<OrdreKoscVo> {
        if (this._preparedOrdreKoscs == null) {
            this._preparedOrdreKoscs = new Array<OrdreKoscVo>();
        }
        return this._preparedOrdreKoscs;
    }

    set preparedOrdreKoscs(value: Array<OrdreKoscVo>) {
        this._preparedOrdreKoscs = value;
    }

    public getEtat(ordreKosc: OrdreKoscVo) {
        if (ordreKosc != null && ordreKosc.erdv == true) {
            return 'skyblue';
        } else if (ordreKosc != null && ordreKosc.erdv == false) {
            return 'warning';
        }
    }

    public deleteIfEtatNotIn(etats: Array<EtatDemandeKoscVo>, ordreKoscs: Array<OrdreKoscVo>, ordreKosc: OrdreKoscVo) {
        if (ordreKoscs != null) {
            var indice = etats.findIndex(e => e.code === ordreKosc.etatDemandeKoscVo.code);
            if (indice == -1) {
                var indiceOrdreKosc = ordreKoscs.findIndex(e => e.id == ordreKosc.id);
                ordreKoscs.splice(indiceOrdreKosc, 1);
            }
        }
    }

    public calculerStatistic(ordreKosc: OrdreKoscVo): Observable<Array<StatisticVo>> {
        return this.http.get<Array<StatisticVo>>(this.API + 'calculerStatistic/submissionDateMin/' + this.searchOrdreKosc.submissionDateMin + '/submissionDateMax/' + this.searchOrdreKosc.submissionDateMax);
    }

    public findSuivi() {
        return this.http.get<Array<OrdreKoscVo>>(this.API + 'suivi/');
    }

    public sendConfirmationEmailToClient(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/confirmation/client', {...this.selectedOrdreKosc});
    }

    public sendMailPlanificationEmail(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mail/planification', {...this.selectedOrdreKosc});
    }

    public sendClientInjoignableEmailToClient(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/client/injoignable/client', {...this.selectedOrdreKosc});
    }

    public importerFtel(ordreKoscs: Array<OrdreKoscVo>): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'ftel', ordreKoscs);
    }

    uploadFile(file): Observable<any> {
        //Create from data
        const formData = new FormData();
        //Store form name as "file" with file data
        formData.append("file", file, file.name);
        return this.http.post<File>(this.API + 'upload', formData);
    }

    download(filename: string): Observable<HttpEvent<Blob>> {
        return this.http.get(this.API + 'download/' + filename, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        });
    }

    public importerAll(ordreKoscs: Array<OrdreKoscVo>): Observable<ImportAllResultVo> {
        return this.http.post<ImportAllResultVo>(this.API + 'import-all', ordreKoscs);
    }

    /*
    public importerAll(ordreKoscs: Array<OrdreKoscVo>): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'import-all', ordreKoscs);
    }*/

    public importerERdv(ordreKoscs: Array<OrdreKoscVo>): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'eRdv', ordreKoscs);
    }

    public sendClientInjoignableEmailToKosc(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/client/injoignable/kosc', {...this.selectedOrdreKosc});
    }

    public sendMauvaisContactEmail(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mauvais/contact', {...this.selectedOrdreKosc});
    }

    public sendRefusClientEmail(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/refus/client', {...this.selectedOrdreKosc});
    }

    public sendPropositionEmail(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/proposition', {...this.selectedOrdreKosc});
    }

    public sendAutreEmail(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/autre', {...this.selectedOrdreKosc});
    }

    public sendMailReplanificationReport(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mail/replanification/report', {...this.selectedOrdreKosc});
    }

    public sendMailReplanification(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mail/replanification', {...this.selectedOrdreKosc});
    }

    public sendMailReinitialisation(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mail/reinitialisation', {...this.selectedOrdreKosc});
    }

    public sendMailReinitialisationSuiviCdd(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API + 'send/mail/reinitialisation-suivi-cdd', {...this.selectedOrdreKosc});
    }

    public findAll() {
        return this.http.get<Array<OrdreKoscVo>>(this.API);
    }

    public save(): Observable<OrdreKoscVo> {
        return this.http.post<OrdreKoscVo>(this.API, {
            ...this.selectedOrdreKosc,
            dateEnvoiSuivi: moment(this.selectedOrdreKosc.dateEnvoiSuivi).format('YYYY-MM-DD')
        });
    }

    public updateNonJoignable(): Observable<OrdreKoscVo> {
        return this.http.put<OrdreKoscVo>(this.API + 'non-joignable', this.selectedOrdreKosc);
    }

    delete(ordreKosc: OrdreKoscVo) {
        return this.http.delete<number>(this.API + 'id/' + ordreKosc.id);
    }

    public edit(): Observable<OrdreKoscVo> {
        //this.selectedOrdreKosc.datePriseRdv= new Date(moment(this.selectedOrdreKosc.datePriseRdv).format('yyyy-MM-dd hh:mm:ss.SSS'));
        return this.http.put<OrdreKoscVo>(this.API, this.selectedOrdreKosc);
    }

    public editRappel(): Observable<OrdreKoscVo> {
        return this.http.put<OrdreKoscVo>(this.API + 'edit-rappel/', this.selectedOrdreKosc);
    }

    public editPasEncore(): Observable<OrdreKoscVo> {
        return this.http.put<OrdreKoscVo>(this.API + 'edit-pas-encore/', this.selectedOrdreKosc);
    }

    public updateEtat(): Observable<OrdreKoscVo> {
        return this.http.put<OrdreKoscVo>(this.API + "update-etat/", this.selectedOrdreKosc);
    }

    public findByCriteria(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search', ordreKosc);
    }

    public findByCriteriaOrderKoscImport(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-order-kosc-import', ordreKosc);
    }

    public findByCriteriaReplanificationEnAttente(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-order-kosc-replanification-en-attente', ordreKosc);
    }

    public findByCriteriaPriseRdv(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-prise-rdv', ordreKosc);
    }

    public findByCriteriaSuiviCdd(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-suivi-cdd', ordreKosc);
    }

    public findByCriteriaCdd(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-suivi-historique-cdd', ordreKosc);
    }

    public findSuiviByCriteria(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search/suivi', ordreKosc);
    }

    public findByIdWithAssociatedList(ordreKosc: OrdreKoscVo): Observable<OrdreKoscVo> {
        return this.http.get<OrdreKoscVo>(this.API + 'detail/id/' + ordreKosc.id);
    }

    public async findByIdWithAssociatedList2(ordreKosc: OrdreKoscVo): Promise<Observable<OrdreKoscVo>> {
        return this.http.get<OrdreKoscVo>(this.API + 'detail/id/' + ordreKosc.id);
    }

    public findByEtatDemandeKoscCode(ordreKosc: OrdreKoscVo): Observable<OrdreKoscVo> {
        return this.http.get<OrdreKoscVo>(this.API + 'etatDemandeKosc/code/' + ordreKosc.etatDemandeKoscVo.code);
    }

    public importerWordOrder(ordreKoscs: Array<OrdreKoscVo>): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'import-work-order', ordreKoscs);
    }

    public importerDataBase(ordreKoscs: Array<OrdreKoscVo>): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'import-data-base', ordreKoscs);
    }

    public findByCriteriaSuiviRdv(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'search-suivi-rdv', ordreKosc);
    }

    public findByAnneAndMoins(annee, mois): Observable<Array<OrdreKoscVo>> {
        return this.http.get<Array<OrdreKoscVo>>(this.API + 'find-by-year-month/year/' + annee + '/month/' + mois);
    }

    public findEmail(ordreKosc: OrdreKoscVo): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'find-email', ordreKosc);
    }

    sendMailReportDemandeManeoClientInjoignable() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-report-demande-maneo-client-injoignable', {...this.selectedOrdreKosc});
    }

    sendMailRappel48Heures(ordreKoscSelections: OrdreKoscVo[]): Observable<Array<OrdreKoscVo>> {
        return this.http.post<Array<OrdreKoscVo>>(this.API + 'send-mail-rappel-48-heures', ordreKoscSelections);
    }

    sendMailReportDemandeManeoClientJoignableAccepte() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-report-demande-maneo-client-joignable-accepte', {...this.selectedOrdreKosc});
    }

    sendMailReportDemandeManeoClientJoignableRefus() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-report-demande-maneo-client-joignable-refus', {...this.selectedOrdreKosc});
    }

    sendMailReportDemandeClientClientInjoignable() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-report-demande-client-client-injoignable', {...this.selectedOrdreKosc});
    }

    sendMailReportDemandeClientClientJoignable() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-report-demande-client-client-joignable', {...this.selectedOrdreKosc});
    }

    sendMailCri() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-cri', {...this.selectedOrdreKosc});
    }

    uploadFileAndSendMail() {
        return this.http.post<OrdreKoscVo>(this.API + 'upload-and-send-mail-cri', {...this.selectedOrdreKosc});
    }

    sendMailGardeEnMain() {
        return this.http.post<OrdreKoscVo>(this.API + 'send-mail-garde-en-main', {...this.selectedOrdreKosc});
    }

    public findAllJourFer(): Observable<Array<JourFerieVo>> {
        return this.http.get<Array<JourFerieVo>>(environment.apiUrl + 'admin/jourFerie/');
    }

    public findByCriteriaPriseRdvPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-prise-rdv-paginated', ordreKosc);
    }

    public findByCriteriaSuiviRdvPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-suivi-rdv-paginated', ordreKosc);
    }

    public findByCriteriaRappel48HeuresPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-rappel-48-heures-paginated', ordreKosc);
    }

    public findByCriteriaSuiviCddPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-suivi-cdd-paginated', ordreKosc);
    }

    public findByCriteriaReplanificationEnAttentePaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-replanification-en-attente-paginated', ordreKosc);
    }

    public findByCriteriaCddPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-cdd-paginated', ordreKosc);
    }

    public updateDateRdvFinale(): any {
        return this.http.post<any>(this.API + 'adapt', null);
    }

    public findByCriteriaOrderKoscImportPaginated(ordreKosc: OrdreKoscVo): Observable<PaginatedList<OrdreKoscVo>> {
        return this.http.post<PaginatedList<OrdreKoscVo>>(this.API + 'find-by-criteria-paginated', ordreKosc);
    }

    calculatePercentageDelaiPriseRdv(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticPriseRdvVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticPriseRdvVo>(this.API + 'delaiPriseRdv/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageDelaiPlanification(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaiPlanificationVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaiPlanificationVo>(this.API + 'delaiPlanification/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageRespectDatesErdv(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticRespectDatesErdvVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticRespectDatesErdvVo>(this.API + 'RespectDatesErdv/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageRespectRdv(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticRespectRdvVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticRespectRdvVo>(this.API + 'RespectRdv/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageDelaiEmissionCri(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaiEmissionCriVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaiEmissionCriVo>(this.API + 'DelaiEmissionCri/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageTauxReussite(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticTauxReussiteVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticTauxReussiteVo>(this.API + 'TauxReussite/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageDelaiMoyenGem(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaiMoyenGemVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaiMoyenGemVo>(this.API + 'DelaiMoyenGem/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageEtatStock(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticEtatStockVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticEtatStockVo>(this.API + 'etat-stock/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    uploadFileToMinio(file: File): Observable<MinIOInfos> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<MinIOInfos>(this.API + 'minio/upload/file/test', formData);
    }

    uploadFilesToMinio(files: File[]): Observable<MinIOInfos[]> {
        if (!files || files.length === 0) {
            return throwError(() => new Error("No files to upload"));
        }
        const formDataArray: FormData[] = files.map(file => {
            const formData = new FormData();
            formData.append('files', file);
            return formData;
        });
        const requests = formDataArray.map(formData =>
            this.http.post<MinIOInfos>(this.API + `minio/upload/files/koscky-preprod`, formData)
        );
        return forkJoin(requests);
    }

// s7ii7a
    /*   uploadFilesToMinio(files: File[]): Observable<MinIOInfos[]> {
           const formData = new FormData();
           files.forEach(file => formData.append('files', file));
           return this.http.post<MinIOInfos[]>(this.API + `minio/upload/files/test`, formData);
       }*/

    /*    downloadFileFromMinio(bucket: string, objectName: string): Observable<Blob> {
            return this.http.get(this.API + 'minio/download-v2/' + bucket + '/' + objectName, {
                responseType: 'blob'
            });
        }*/
    downloadFileFromMinio(bucket: string, objectName: string): Observable<{ filename: string, data: Blob }> {
        return this.http.get(this.API + 'minio/download-v2/' + bucket + '/' + objectName, {
            responseType: 'blob'
        }).pipe(
            map((data: Blob) => {
                // Extract filename from the objectName
                const filename = objectName.substring(objectName.lastIndexOf('/') + 1);
                return {filename, data};
            })
        );
    }

    calculatePercentageDelaiPriseRdvCovage(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaiPriseRdvCovageVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaiPriseRdvCovageVo>(this.API + 'delaiPriseRdvCovage/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageDelaisInterventionsCovage(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaisInterventionsCovageVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaisInterventionsCovageVo>(this.API + 'delaisInterventionsCovage/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageDelaiEnvoiCriCovage(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticDelaiEnvoiCriCovageVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticDelaiEnvoiCriCovageVo>(this.API + 'delaiEnvoiCriCovage/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    calculatePercentageOnceAndDoneCovage(submissionDateMin: Date, submissionDateMax: Date): Observable<StatisticOnceAndDoneCovageVo> {
        const formattedDateSubmissionMin = format(submissionDateMin, 'yyyy-MM-dd');
        const formattedDateSubmissionMax = format(submissionDateMax, 'yyyy-MM-dd');
        return this.http.get<StatisticOnceAndDoneCovageVo>(this.API + 'onceAndDoneCovage/submissionDateMin/' + formattedDateSubmissionMin + '/submissionDateMax/' + formattedDateSubmissionMax);
    }

    updateAllOccurrences(): Observable<string> {
        return this.http.get<string>(this.API + `updateAllOccurrences`);
    }
}
