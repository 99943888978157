import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {LoginCddComponent} from "./login-cdd/login-cdd.component";
import {RegisterCddComponent} from "./register-cdd/register-cdd.component";
import {OrdreKoscSuiviCddCddComponent} from "./ordre-kosc-suivi-cdd-cdd/ordre-kosc-suivi-cdd-cdd.component";
import {OrdreKoscPriseRdvCddComponent} from "./ordre-kosc-prise-rdv-cdd/ordre-kosc-prise-rdv-cdd.component";
import {OrdreKoscSuiviCddComponent} from "./ordre-kosc-suivi-commande-cdd/ordre-kosc-suivi-cdd.component";
import {OrdreKoscSuiviHistoriqueCddComponent} from "./ordre-kosc-cdd-cdd/ordre-kosc-suivi-historique-cdd.component";
import {
    OrdreKoscReplanificationEnAttenteCddComponent
} from "./ordre-kosc-replanification-en-attente-cdd/ordre-kosc-replanification-en-attente-cdd.component";
import {GrilleTechnicienCddComponent} from "./grille-technicien-cdd/grille-technicien-cdd.component";
import {
    OrdreKoscRappel48HeuresCddComponent
} from "./ordre-kosc-rappel-48-heures-cdd/ordre-kosc-rappel-48-heures-cdd.component";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'prise-rdv-component',
                component: OrdreKoscPriseRdvCddComponent
            }, {
                path: 'replanification-en-attente-component',
                component: OrdreKoscReplanificationEnAttenteCddComponent
            }, {
                path: 'rappel-48-heures-component',
                component: OrdreKoscRappel48HeuresCddComponent
            }, {
                path: 'suivi-commande-component',
                component: OrdreKoscSuiviCddComponent
            }, {
                path: 'cdd-component',
                component: OrdreKoscSuiviHistoriqueCddComponent
            }, {
                path: 'suivi-cdd-component',
                component: OrdreKoscSuiviCddCddComponent
            }, {
                path: 'login',
                component: LoginCddComponent
            }, {
                path: 'register',
                component: RegisterCddComponent
            }, {
                path: 'grille-technicien-component',
                component: GrilleTechnicienCddComponent,
            },
        ])
    ],
    exports: [RouterModule]
})
export class CddManagerRoutingModule {
}
