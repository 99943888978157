import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {PboReelVo} from "../../model/referentiel/PboReel";

@Injectable({
    providedIn: 'root'
})
export class PboReelService {
    public editPboReel$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/pboReel/';
        });
    }

    private _pboReels: Array<PboReelVo>;
    get pboReels(): Array<PboReelVo> {
        if (this._pboReels == null) {
            this._pboReels = new Array<PboReelVo>();
        }
        return this._pboReels;
    }

    set pboReels(value: Array<PboReelVo>) {
        this._pboReels = value;
    }

    private _selectedPboReel: PboReelVo;
    get selectedPboReel(): PboReelVo {
        if (this._selectedPboReel == null) {
            this._selectedPboReel = new PboReelVo();
        }
        return this._selectedPboReel;
    }

    set selectedPboReel(value: PboReelVo) {
        this._selectedPboReel = value;
    }

    private _pboReelSelections: Array<PboReelVo>;
    get pboReelSelections(): Array<PboReelVo> {
        if (this._pboReelSelections == null) {
            this._pboReelSelections = new Array<PboReelVo>();
        }
        return this._pboReelSelections;
    }

    set pboReelSelections(value: Array<PboReelVo>) {
        this._pboReelSelections = value;
    }

    private _createPboReelDialog: boolean;
    get createPboReelDialog(): boolean {
        return this._createPboReelDialog;
    }

    set createPboReelDialog(value: boolean) {
        this._createPboReelDialog = value;
    }

    private _editPboReelDialog: boolean;
    get editPboReelDialog(): boolean {
        return this._editPboReelDialog;
    }

    set editPboReelDialog(value: boolean) {
        this._editPboReelDialog = value;
    }

    private _viewPboReelDialog: boolean;
    get viewPboReelDialog(): boolean {
        return this._viewPboReelDialog;
    }

    set viewPboReelDialog(value: boolean) {
        this._viewPboReelDialog = value;
    }

    private _searchPboReel: PboReelVo;
    get searchPboReel(): PboReelVo {
        if (this._searchPboReel == null) {
            this._searchPboReel = new PboReelVo();
        }
        return this._searchPboReel;
    }

    set searchPboReel(value: PboReelVo) {
        this._searchPboReel = value;
    }

    public findByPboReel(pboReel: string, pboReels: Array<PboReelVo>) {
        return pboReels.find(c => c.code === pboReel);
    }

    public findAll() {
        return this.http.get<Array<PboReelVo>>(this.API);
    }

    public save(): Observable<PboReelVo> {
        return this.http.post<PboReelVo>(this.API, this.selectedPboReel);
    }

    delete(pboReel: PboReelVo) {
        return this.http.delete<number>(this.API + 'id/' + pboReel.id);
    }

    public edit(): Observable<PboReelVo> {
        return this.http.put<PboReelVo>(this.API, this.selectedPboReel);
    }

    public findByCriteria(pboReel: PboReelVo): Observable<Array<PboReelVo>> {
        return this.http.post<Array<PboReelVo>>(this.API + 'search', pboReel);
    }

    public findByIdWithAssociatedList(pboReel: PboReelVo): Observable<PboReelVo> {
        return this.http.get<PboReelVo>(this.API + 'detail/id/' + pboReel.id);
    }
}
