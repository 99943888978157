import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {TemplateEmailGardeEnMainVo} from '../../model/template/TemplateEmailGardeEnMain.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEmailGardeEnMainService {
    public editTemplateEmailGardeEnMain$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/templateEmailGardeEnMain/';
        });
    }

    private _templateEmailGardeEnMains: Array<TemplateEmailGardeEnMainVo>;
    get templateEmailGardeEnMains(): Array<TemplateEmailGardeEnMainVo> {
        if (this._templateEmailGardeEnMains == null) {
            this._templateEmailGardeEnMains = new Array<TemplateEmailGardeEnMainVo>();
        }
        return this._templateEmailGardeEnMains;
    }

    set templateEmailGardeEnMains(value: Array<TemplateEmailGardeEnMainVo>) {
        this._templateEmailGardeEnMains = value;
    }

    private _selectedTemplateEmailGardeEnMain: TemplateEmailGardeEnMainVo;
    get selectedTemplateEmailGardeEnMain(): TemplateEmailGardeEnMainVo {
        if (this._selectedTemplateEmailGardeEnMain == null) {
            this._selectedTemplateEmailGardeEnMain = new TemplateEmailGardeEnMainVo();
        }
        return this._selectedTemplateEmailGardeEnMain;
    }

    set selectedTemplateEmailGardeEnMain(value: TemplateEmailGardeEnMainVo) {
        this._selectedTemplateEmailGardeEnMain = value;
    }

    private _templateEmailGardeEnMainSelections: Array<TemplateEmailGardeEnMainVo>;
    get templateEmailGardeEnMainSelections(): Array<TemplateEmailGardeEnMainVo> {
        if (this._templateEmailGardeEnMainSelections == null) {
            this._templateEmailGardeEnMainSelections = new Array<TemplateEmailGardeEnMainVo>();
        }
        return this._templateEmailGardeEnMainSelections;
    }

    set templateEmailGardeEnMainSelections(value: Array<TemplateEmailGardeEnMainVo>) {
        this._templateEmailGardeEnMainSelections = value;
    }

    private _createTemplateEmailGardeEnMainDialog: boolean;
    get createTemplateEmailGardeEnMainDialog(): boolean {
        return this._createTemplateEmailGardeEnMainDialog;
    }

    set createTemplateEmailGardeEnMainDialog(value: boolean) {
        this._createTemplateEmailGardeEnMainDialog = value;
    }

    private _editTemplateEmailGardeEnMainDialog: boolean;
    get editTemplateEmailGardeEnMainDialog(): boolean {
        return this._editTemplateEmailGardeEnMainDialog;
    }

    set editTemplateEmailGardeEnMainDialog(value: boolean) {
        this._editTemplateEmailGardeEnMainDialog = value;
    }

    private _viewTemplateEmailGardeEnMainDialog: boolean;
    get viewTemplateEmailGardeEnMainDialog(): boolean {
        return this._viewTemplateEmailGardeEnMainDialog;
    }

    set viewTemplateEmailGardeEnMainDialog(value: boolean) {
        this._viewTemplateEmailGardeEnMainDialog = value;
    }

    private _searchTemplateEmailGardeEnMain: TemplateEmailGardeEnMainVo;
    get searchTemplateEmailGardeEnMain(): TemplateEmailGardeEnMainVo {
        if (this._searchTemplateEmailGardeEnMain == null) {
            this._searchTemplateEmailGardeEnMain = new TemplateEmailGardeEnMainVo();
        }
        return this._searchTemplateEmailGardeEnMain;
    }

    set searchTemplateEmailGardeEnMain(value: TemplateEmailGardeEnMainVo) {
        this._searchTemplateEmailGardeEnMain = value;
    }

    public findAll() {
        return this.http.get<Array<TemplateEmailGardeEnMainVo>>(this.API);
    }

    public save(): Observable<TemplateEmailGardeEnMainVo> {
        return this.http.post<TemplateEmailGardeEnMainVo>(this.API, this.selectedTemplateEmailGardeEnMain);
    }

    delete(templateEmailGardeEnMain: TemplateEmailGardeEnMainVo) {
        return this.http.delete<number>(this.API + 'id/' + templateEmailGardeEnMain.id);
    }

    public edit(): Observable<TemplateEmailGardeEnMainVo> {
        return this.http.put<TemplateEmailGardeEnMainVo>(this.API, this.selectedTemplateEmailGardeEnMain);
    }

    public findByCriteria(templateEmailGardeEnMain: TemplateEmailGardeEnMainVo): Observable<Array<TemplateEmailGardeEnMainVo>> {
        return this.http.post<Array<TemplateEmailGardeEnMainVo>>(this.API + 'search', templateEmailGardeEnMain);
    }

    public findByIdWithAssociatedList(templateEmailGardeEnMain: TemplateEmailGardeEnMainVo): Observable<TemplateEmailGardeEnMainVo> {
        return this.http.get<TemplateEmailGardeEnMainVo>(this.API + 'detail/id/' + templateEmailGardeEnMain.id);
    }
}
