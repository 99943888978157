import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {TypeForfaitDevisVo} from "../../model/referentiel/TypeForfaitDevis";

@Injectable({
    providedIn: 'root'
})
export class TypeForfaitDevisService {
    public editTypeForfaitDevis$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/typeForfaitDevis/';
        });
    }

    private _typeForfaitDeviss: Array<TypeForfaitDevisVo>;
    get typeForfaitDeviss(): Array<TypeForfaitDevisVo> {
        if (this._typeForfaitDeviss == null) {
            this._typeForfaitDeviss = new Array<TypeForfaitDevisVo>();
        }
        return this._typeForfaitDeviss;
    }

    set typeForfaitDeviss(value: Array<TypeForfaitDevisVo>) {
        this._typeForfaitDeviss = value;
    }

    private _selectedTypeForfaitDevis: TypeForfaitDevisVo;
    get selectedTypeForfaitDevis(): TypeForfaitDevisVo {
        if (this._selectedTypeForfaitDevis == null) {
            this._selectedTypeForfaitDevis = new TypeForfaitDevisVo();
        }
        return this._selectedTypeForfaitDevis;
    }

    set selectedTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this._selectedTypeForfaitDevis = value;
    }

    private _typeForfaitDevisSelections: Array<TypeForfaitDevisVo>;
    get typeForfaitDevisSelections(): Array<TypeForfaitDevisVo> {
        if (this._typeForfaitDevisSelections == null) {
            this._typeForfaitDevisSelections = new Array<TypeForfaitDevisVo>();
        }
        return this._typeForfaitDevisSelections;
    }

    set typeForfaitDevisSelections(value: Array<TypeForfaitDevisVo>) {
        this._typeForfaitDevisSelections = value;
    }

    private _createTypeForfaitDevisDialog: boolean;
    get createTypeForfaitDevisDialog(): boolean {
        return this._createTypeForfaitDevisDialog;
    }

    set createTypeForfaitDevisDialog(value: boolean) {
        this._createTypeForfaitDevisDialog = value;
    }

    private _editTypeForfaitDevisDialog: boolean;
    get editTypeForfaitDevisDialog(): boolean {
        return this._editTypeForfaitDevisDialog;
    }

    set editTypeForfaitDevisDialog(value: boolean) {
        this._editTypeForfaitDevisDialog = value;
    }

    private _viewTypeForfaitDevisDialog: boolean;
    get viewTypeForfaitDevisDialog(): boolean {
        return this._viewTypeForfaitDevisDialog;
    }

    set viewTypeForfaitDevisDialog(value: boolean) {
        this._viewTypeForfaitDevisDialog = value;
    }

    private _searchTypeForfaitDevis: TypeForfaitDevisVo;
    get searchTypeForfaitDevis(): TypeForfaitDevisVo {
        if (this._searchTypeForfaitDevis == null) {
            this._searchTypeForfaitDevis = new TypeForfaitDevisVo();
        }
        return this._searchTypeForfaitDevis;
    }

    set searchTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this._searchTypeForfaitDevis = value;
    }

    public findByTypeForfaitDevis(typeForfaitDevis: string, typeForfaitDeviss: Array<TypeForfaitDevisVo>) {
        return typeForfaitDeviss.find(c => c.code === typeForfaitDevis);
    }

    public findAll() {
        return this.http.get<Array<TypeForfaitDevisVo>>(this.API);
    }

    public save(): Observable<TypeForfaitDevisVo> {
        return this.http.post<TypeForfaitDevisVo>(this.API, this.selectedTypeForfaitDevis);
    }

    delete(typeForfaitDevis: TypeForfaitDevisVo) {
        return this.http.delete<number>(this.API + 'id/' + typeForfaitDevis.id);
    }

    public edit(): Observable<TypeForfaitDevisVo> {
        return this.http.put<TypeForfaitDevisVo>(this.API, this.selectedTypeForfaitDevis);
    }

    public findByCriteria(typeForfaitDevis: TypeForfaitDevisVo): Observable<Array<TypeForfaitDevisVo>> {
        return this.http.post<Array<TypeForfaitDevisVo>>(this.API + 'search', typeForfaitDevis);
    }

    public findByIdWithAssociatedList(typeForfaitDevis: TypeForfaitDevisVo): Observable<TypeForfaitDevisVo> {
        return this.http.get<TypeForfaitDevisVo>(this.API + 'detail/id/' + typeForfaitDevis.id);
    }
}
