import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './controller/service/security/Auth.service';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {RoleService} from './controller/service/security/role.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    animations: [
        trigger('inline', [
            state(
                'hidden',
                style({
                    height: '0px',
                    overflow: 'hidden',
                })
            ),
            state(
                'visible',
                style({
                    height: '*',
                })
            ),
            state(
                'hiddenAnimated',
                style({
                    height: '0px',
                    overflow: 'hidden',
                })
            ),
            state(
                'visibleAnimated',
                style({
                    height: '*',
                })
            ),
            transition(
                'visibleAnimated => hiddenAnimated',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
            transition(
                'hiddenAnimated => visibleAnimated',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
        ]),
    ],
    styles: ['active{background-color: #90A4AE;}']
})
export class AppMenuComponent implements OnInit {
    model: any[];
    modelsuperadmin: any[];
    modelanonymous: any[];
    modeladmin: any[];
    modelcdd: any[];
    modelrdv: any[];
    modelchercheur: any[];

    constructor(public app: AppComponent, public appMain: AppMainComponent, private roleService: RoleService, private authService: AuthService, private router: Router) {
    }

    ngOnInit() {
        this.modeladmin =
            [
                {
                    label: 'Tableau de bord',
                    icon: 'pi pi-home',
                    items: [
                        {
                            label: 'For koscky',
                            icon: 'pi pi-fw pi-chart-line',
                            routerLink: ['/app/admin/kosc/kpis-koscky/list'],
                            routerLinkActive: " style:'background-color: #90A4AE '"
                        }, {
                            label: 'For covage',
                            icon: 'pi pi-fw pi-chart-bar',
                            routerLink: ['/app/admin/kosc/kpis-covage/list'],
                            routerLinkActive: " style:'background-color: #90A4AE '"
                        },
                    ]
                }, {
                label: 'Kosc Ordre',
                icon: 'pi pi-wallet',
                items: [
                    {
                        label: 'Importation ordre kosc',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc/list'],
                        routerLinkActive: " style:'background-color: #90A4AE '"
                    }, {
                        label: 'Prise Rendez Vous',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-prise-rdv/list']
                    },
                    /*{
                        label: 'Affectation Technicien kosc ordre',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-affectation-technicien/list']
                    },*/
                    {
                        label: 'Replanification en attente',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-replanification-en-attente/list']
                    }, {
                        label: 'Rappel J+2',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-rappel-48-heures/list']
                    }, {
                        label: 'Suivi Planning',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-suivi/list']
                    }, {
                        label: 'Cdd',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-suivi-historique/list']
                    }, {
                        label: 'Suivi Cdd',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/ordre-kosc-suivi-cdd/list']
                    }, {
                        label: 'Grilles techs',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/grille-technicien/list']
                    },
                    /*  {
                          label: 'Emailing',
                          icon: 'pi pi-fw pi-plus-circle',
                          routerLink: ['/app/admin/kosc/emailing/list']
                      }*/
                ]
            },
                {
                    label: 'Gestion Technicien',
                    icon: 'pi pi-wallet',
                    items: [
                        /*  {
                              label: 'Inbox',
                              icon: 'pi pi-fw pi-inbox',
                              routerLink: ['/app/admin/kosc/inbox/list']
                          },*/
                        {
                            label: 'Technicien',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/technicien/list']
                        }, {
                            label: 'Arret travail',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/arret-travail/list']
                        }, {
                            label: 'Departement',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/departement/list']
                        }, {
                            label: 'Raison arret travail',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/raison-arret-travail/list']
                        }, {
                            label: 'Departement technicien',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/departement-technicien/list']
                        }, {
                            label: 'Calendrier technicien',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/calendrier-technicien/list']
                        }, {
                            label: 'Region',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/region/list']
                        }, {
                            label: 'Entreprise',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/entreprise/list']
                        },
                    ]
                }, {
                label: 'Templating',
                icon: 'pi pi-wallet',
                items: [
                    {
                        label: 'Template ftl',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-ftl/list']
                    }, {
                        label: 'Template mauvais contact',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-mauvais-contact/list']
                    }, {
                        label: 'Template proposition',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-proposition/list']
                    }, {
                        label: 'Template refus',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-refus/list']
                    }, {
                        label: 'Template gardé en main',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-garde-en-main/list']
                    }, {
                        label: 'Template confirmation client',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-confirmation-client/list']
                    }, {
                        label: 'Template replanification',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-replanification/list']
                    }, {
                        label: 'Template cloture',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-cloture/list']
                    }, {
                        label: 'Template client injoinable kosc',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-client-injoinable-kosc/list']
                    }, {
                        label: 'Template planification',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-planification/list']
                    }, {
                        label: 'Template rappel 48 heures',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-rappel-48-heures/list']
                    }, {
                        label: 'Template report demande maneo client joignable accepte',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-report-demande-maneo-client-joignable-accepte/list']
                    }, {
                        label: 'Template report demande maneo client injoignable',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-report-demande-maneo-client-injoignable/list']
                    }, {
                        label: 'Template report demande client client injoignable',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-report-demande-client-client-injoignable/list']
                    }, {
                        label: 'Template report demande maneo client joignable refus',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-report-demande-maneo-client-joignable-refus/list']
                    }, {
                        label: 'Template report demande client client joignable',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-report-demande-client-client-joignable/list']
                    }, {
                        label: 'Template suivi',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-suivi/list']
                    }, {
                        label: 'Template client injoinable',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-client-injoinable/list']
                    }, {
                        label: 'Template cri',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/template-email-cri/list']
                    }, {
                        label: 'Default template configuration',
                        icon: 'pi pi-fw pi-plus-circle',
                        routerLink: ['/app/admin/kosc/default-template-configuration/list']
                    },
                ]
            },
                {
                    label: 'Referentiel',
                    icon: 'pi pi-wallet',
                    items: [
                        {
                            label: 'Cause ko ok',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/cause-ko-ok/list']
                        }, {
                            label: 'Source replanification',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/source-replanification/list']
                        }, {
                            label: 'Etat demande kosc',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/etat-demande-kosc/list']
                        }, {
                            label: 'Operator',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/operator/list']
                        }, {
                            label: 'Jours Fériés',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/jour-ferie/list']
                        }, {
                            label: 'Pbo reel',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/pbo-reel/list']
                        }, {
                            label: 'Type Forfait / Devis',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/type-forfait-devis/list']
                        }, {
                            label: 'Statut Email 48H',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/statut-email/list']
                        },
                    ]
                },
                {
                    label: 'Gestion Users',
                    icon: 'pi pi-users',
                    items: [
                        {
                            label: 'Users',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/ordre-kosc-gestion-utilisateur/gestion_users']
                        }, {
                            label: 'Roles',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/admin/kosc/ordre-kosc-gestion-utilisateur/gestion_roles']
                        }
                    ]
                }, {
                label: 'Profile',
                icon: 'pi pi-fw pi-user',
                routerLink: ['/app/admin/kosc/profile/profile']
            }
            ]
        this.modelcdd =
            [
                {
                    label: 'Planification | CDD',
                    icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: 'Prise Rendez Vous',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/prise-rdv-component']
                        }, {
                            label: 'Replanification en attente',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/replanification-en-attente-component']
                        }, {
                            label: 'Rappel J+2',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/rappel-48-heures-component']
                        }, {
                            label: 'Suivi Planning',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/suivi-commande-component']
                        }, {
                            label: 'Cdd',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/cdd-component']
                        }, {
                            label: 'Suivi Cdd',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/suivi-cdd-component']
                        }, {
                            label: 'Grilles techs',
                            icon: 'pi pi-fw pi-plus-circle',
                            routerLink: ['/app/cdd/grille-technicien-component']
                        },
                    ]
                }
            ]
        if (this.authService.authenticated) {
            if (this.authService.authenticatedUser.roles) {
                this.authService.authenticatedUser.roles.forEach(role => {
                    const roleName: string = this.getRole(role);
                    this.roleService._role.next(roleName.toUpperCase());
                    eval('this.model = this.model' + this.getRole(role));
                })
            }
        }
    }

    getRole(text) {
        const [role, ...rest] = text.split('_');
        return rest.join('').toLowerCase();
    }

    onMenuClick(event) {
        this.appMain.onMenuClick(event);
    }
}
