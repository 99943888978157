import {Component, OnInit} from '@angular/core';
import {StatutEmailService} from 'src/app/controller/service/template/StatutEmail.service';
import {StatutEmailVo} from 'src/app/controller/model/template/StatutEmail.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-statut-email-edit-admin',
    templateUrl: './statut-email-edit-admin.component.html',
    styleUrls: ['./statut-email-edit-admin.component.css']
})
export class StatutEmailEditAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private statutEmailService: StatutEmailService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validStatutEmailCode = true;
    get validStatutEmailCode(): boolean {
        return this._validStatutEmailCode;
    }

    set validStatutEmailCode(value: boolean) {
        this._validStatutEmailCode = value;
    }

    _validStatutEmailLibelle = true;
    get validStatutEmailLibelle(): boolean {
        return this._validStatutEmailLibelle;
    }

    set validStatutEmailLibelle(value: boolean) {
        this._validStatutEmailLibelle = value;
    }

    private _validStatutEmailStyle = true;
    get validStatutEmailStyle(): boolean {
        return this._validStatutEmailStyle;
    }

    set validStatutEmailStyle(value: boolean) {
        this._validStatutEmailStyle = value;
    }

    get statutEmails(): Array<StatutEmailVo> {
        return this.statutEmailService.statutEmails;
    }

    set statutEmails(value: Array<StatutEmailVo>) {
        this.statutEmailService.statutEmails = value;
    }

    get selectedStatutEmail(): StatutEmailVo {
        return this.statutEmailService.selectedStatutEmail;
    }

    set selectedStatutEmail(value: StatutEmailVo) {
        this.statutEmailService.selectedStatutEmail = value;
    }

    get editStatutEmailDialog(): boolean {
        return this.statutEmailService.editStatutEmailDialog;
    }

    set editStatutEmailDialog(value: boolean) {
        this.statutEmailService.editStatutEmailDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    ngOnInit(): void {
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editWithShowOption(showList: boolean) {
        this.statutEmailService.edit().subscribe(statutEmail => {
            const myIndex = this.statutEmails.findIndex(e => e.id === this.selectedStatutEmail.id);
            this.statutEmails[myIndex] = statutEmail;
            this.editStatutEmailDialog = false;
            this.submitted = false;
            this.selectedStatutEmail = new StatutEmailVo();
        }, error => {
            console.log(error);
        });
    }

    hideEditDialog() {
        this.editStatutEmailDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validStatutEmailCode = value;
        this.validStatutEmailLibelle = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateStatutEmailCode();
        this.validateStatutEmailLibelle();
    }

    private validateStatutEmailCode() {
        if (this.stringUtilService.isEmpty(this.selectedStatutEmail.code)) {
            this.errorMessages.push('Code non valide');
            this.validStatutEmailCode = false;
        } else {
            this.validStatutEmailCode = true;
        }
    }

    private validateStatutEmailLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedStatutEmail.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validStatutEmailLibelle = false;
        } else {
            this.validStatutEmailLibelle = true;
        }
    }
}
