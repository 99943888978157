import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-ordre-kosc-suivi-cdd',
    templateUrl: './ordre-kosc-suivi-historique-cdd.component.html',
    styleUrls: ['./ordre-kosc-suivi-historique-cdd.component.css']
})
export class OrdreKoscSuiviHistoriqueCddComponent implements OnInit {
    constructor() {
    }
    ngOnInit(): void {
    }
}
