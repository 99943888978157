import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-cause-ko-ok-admin',
    templateUrl: './cause-ko-ok-admin.component.html',
    styleUrls: ['./cause-ko-ok-admin.component.css']
})
export class CauseKoOkAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
