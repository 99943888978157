import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailMauvaisContactService
} from 'src/app/controller/service/template/TemplateEmailMauvaisContact.service';
import {TemplateEmailMauvaisContactVo} from 'src/app/controller/model/template/TemplateEmailMauvaisContact.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-mauvais-contact-create-admin',
    templateUrl: './template-email-mauvais-contact-create-admin.component.html',
    styleUrls: ['./template-email-mauvais-contact-create-admin.component.css']
})
export class TemplateEmailMauvaisContactCreateAdminComponent implements OnInit {

    constructor(private datePipe: DatePipe, private templateEmailMauvaisContactService: TemplateEmailMauvaisContactService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;

    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailMauvaisContactLibelle = true;

    get validTemplateEmailMauvaisContactLibelle(): boolean {
        return this._validTemplateEmailMauvaisContactLibelle;
    }

    set validTemplateEmailMauvaisContactLibelle(value: boolean) {
        this._validTemplateEmailMauvaisContactLibelle = value;
    }

    _validTemplateEmailMauvaisContactObjet = true;

    get validTemplateEmailMauvaisContactObjet(): boolean {
        return this._validTemplateEmailMauvaisContactObjet;
    }

    set validTemplateEmailMauvaisContactObjet(value: boolean) {
        this._validTemplateEmailMauvaisContactObjet = value;
    }

    _validTemplateEmailMauvaisContactCorps = true;

    get validTemplateEmailMauvaisContactCorps(): boolean {
        return this._validTemplateEmailMauvaisContactCorps;
    }

    set validTemplateEmailMauvaisContactCorps(value: boolean) {
        this._validTemplateEmailMauvaisContactCorps = value;
    }

    get templateEmailMauvaisContacts(): Array<TemplateEmailMauvaisContactVo> {
        return this.templateEmailMauvaisContactService.templateEmailMauvaisContacts;
    }

    set templateEmailMauvaisContacts(value: Array<TemplateEmailMauvaisContactVo>) {
        this.templateEmailMauvaisContactService.templateEmailMauvaisContacts = value;
    }

    get selectedTemplateEmailMauvaisContact(): TemplateEmailMauvaisContactVo {
        return this.templateEmailMauvaisContactService.selectedTemplateEmailMauvaisContact;
    }

    set selectedTemplateEmailMauvaisContact(value: TemplateEmailMauvaisContactVo) {
        this.templateEmailMauvaisContactService.selectedTemplateEmailMauvaisContact = value;
    }

    get createTemplateEmailMauvaisContactDialog(): boolean {
        return this.templateEmailMauvaisContactService.createTemplateEmailMauvaisContactDialog;

    }

    set createTemplateEmailMauvaisContactDialog(value: boolean) {
        this.templateEmailMauvaisContactService.createTemplateEmailMauvaisContactDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.templateEmailMauvaisContactService.save().subscribe(templateEmailMauvaisContact => {
            if (templateEmailMauvaisContact != null) {
                this.templateEmailMauvaisContacts.push({...templateEmailMauvaisContact});
                this.createTemplateEmailMauvaisContactDialog = false;
                this.submitted = false;
                this.selectedTemplateEmailMauvaisContact = new TemplateEmailMauvaisContactVo();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreurs',
                    detail: 'Template email mauvais contact existe déjà'
                });
            }
        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createTemplateEmailMauvaisContactDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailMauvaisContactLibelle = value;
        this.validTemplateEmailMauvaisContactObjet = value;
        this.validTemplateEmailMauvaisContactCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailMauvaisContactLibelle();
        this.validateTemplateEmailMauvaisContactObjet();
        this.validateTemplateEmailMauvaisContactCorps();
    }

    private validateTemplateEmailMauvaisContactLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailMauvaisContact.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailMauvaisContactLibelle = false;
        } else {
            this.validTemplateEmailMauvaisContactLibelle = true;
        }
    }

    private validateTemplateEmailMauvaisContactObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailMauvaisContact.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailMauvaisContactObjet = false;
        } else {
            this.validTemplateEmailMauvaisContactObjet = true;
        }
    }

    private validateTemplateEmailMauvaisContactCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailMauvaisContact.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailMauvaisContactCorps = false;
        } else {
            this.validTemplateEmailMauvaisContactCorps = true;
        }
    }
}
