import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {User} from './controller/model/security/User.model';
import {AuthService} from './controller/service/security/Auth.service';
import {Router} from "@angular/router";
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
    roleAdmin = false;

    constructor(public app: AppComponent, public appMain: AppMainComponent, private authService: AuthService, private router: Router) {
    }

    get authenticatedUserByAdmin(): User {
        return this.authService.authenticatedUserByAdmin();
    }

    get authenticatedUser(): User {
        return this.authService.authenticatedUser;
    }

    ngOnInit(): void {
        this.authService.loadInfos();
        if (this.authService.authenticatedUser.roles[0].authority === 'ROLE_ADMIN') {
            this.roleAdmin = true;
        }
    }

    switchAdmin(): void {
        this.authService.unregisterConnectedChercheur();
        window.location.reload();
    }

    logout() {
        this.authService.logout();
    }

    ManageUsers() {
        this.router.navigate(['admin/user']);
    }
    handleLogoClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
