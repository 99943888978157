import {Component, OnInit} from '@angular/core';
import {TemplateEmailGardeEnMainService} from 'src/app/controller/service/template/TemplateEmailGardeEnMain.service';
import {TemplateEmailGardeEnMainVo} from 'src/app/controller/model/template/TemplateEmailGardeEnMain.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-garde-en-main-create-admin',
    templateUrl: './template-email-garde-en-main-create-admin.component.html',
    styleUrls: ['./template-email-garde-en-main-create-admin.component.css']
})
export class TemplateEmailGardeEnMainCreateAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private templateEmailGardeEnMainService: TemplateEmailGardeEnMainService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailGardeEnMainLibelle = true;
    get validTemplateEmailGardeEnMainLibelle(): boolean {
        return this._validTemplateEmailGardeEnMainLibelle;
    }

    set validTemplateEmailGardeEnMainLibelle(value: boolean) {
        this._validTemplateEmailGardeEnMainLibelle = value;
    }

    _validTemplateEmailGardeEnMainObjet = true;
    get validTemplateEmailGardeEnMainObjet(): boolean {
        return this._validTemplateEmailGardeEnMainObjet;
    }

    set validTemplateEmailGardeEnMainObjet(value: boolean) {
        this._validTemplateEmailGardeEnMainObjet = value;
    }

    _validTemplateEmailGardeEnMainCorps = true;
    get validTemplateEmailGardeEnMainCorps(): boolean {
        return this._validTemplateEmailGardeEnMainCorps;
    }

    set validTemplateEmailGardeEnMainCorps(value: boolean) {
        this._validTemplateEmailGardeEnMainCorps = value;
    }

    get templateEmailGardeEnMains(): Array<TemplateEmailGardeEnMainVo> {
        return this.templateEmailGardeEnMainService.templateEmailGardeEnMains;
    }

    set templateEmailGardeEnMains(value: Array<TemplateEmailGardeEnMainVo>) {
        this.templateEmailGardeEnMainService.templateEmailGardeEnMains = value;
    }

    get selectedTemplateEmailGardeEnMain(): TemplateEmailGardeEnMainVo {
        return this.templateEmailGardeEnMainService.selectedTemplateEmailGardeEnMain;
    }

    set selectedTemplateEmailGardeEnMain(value: TemplateEmailGardeEnMainVo) {
        this.templateEmailGardeEnMainService.selectedTemplateEmailGardeEnMain = value;
    }

    get createTemplateEmailGardeEnMainDialog(): boolean {
        return this.templateEmailGardeEnMainService.createTemplateEmailGardeEnMainDialog;
    }

    set createTemplateEmailGardeEnMainDialog(value: boolean) {
        this.templateEmailGardeEnMainService.createTemplateEmailGardeEnMainDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.templateEmailGardeEnMainService.save().subscribe(templateEmailGardeEnMain => {
            if (templateEmailGardeEnMain != null) {
                this.templateEmailGardeEnMains.push({...templateEmailGardeEnMain});
                this.createTemplateEmailGardeEnMainDialog = false;
                this.submitted = false;
                this.selectedTemplateEmailGardeEnMain = new TemplateEmailGardeEnMainVo();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreurs',
                    detail: 'Template email gardé en main existe déjà'
                });
            }

        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createTemplateEmailGardeEnMainDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailGardeEnMainLibelle = value;
        this.validTemplateEmailGardeEnMainObjet = value;
        this.validTemplateEmailGardeEnMainCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailGardeEnMainLibelle();
        this.validateTemplateEmailGardeEnMainObjet();
        this.validateTemplateEmailGardeEnMainCorps();
    }

    private validateTemplateEmailGardeEnMainLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailGardeEnMain.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailGardeEnMainLibelle = false;
        } else {
            this.validTemplateEmailGardeEnMainLibelle = true;
        }
    }

    private validateTemplateEmailGardeEnMainObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailGardeEnMain.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailGardeEnMainObjet = false;
        } else {
            this.validTemplateEmailGardeEnMainObjet = true;
        }
    }

    private validateTemplateEmailGardeEnMainCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailGardeEnMain.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailGardeEnMainCorps = false;
        } else {
            this.validTemplateEmailGardeEnMainCorps = true;
        }
    }
}
