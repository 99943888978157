import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {TemplateEmailRappel48HeuresVo} from '../../model/template/TemplateEmailRappel48Heures.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEmailRappel48HeuresService {
    public editTemplateEmailRappel48Heures$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/templateEmailRappel48Heures/';
        });
    }

    private _templateEmailRappel48Heuress: Array<TemplateEmailRappel48HeuresVo>;
    get templateEmailRappel48Heuress(): Array<TemplateEmailRappel48HeuresVo> {
        if (this._templateEmailRappel48Heuress == null) {
            this._templateEmailRappel48Heuress = new Array<TemplateEmailRappel48HeuresVo>();
        }
        return this._templateEmailRappel48Heuress;
    }

    set templateEmailRappel48Heuress(value: Array<TemplateEmailRappel48HeuresVo>) {
        this._templateEmailRappel48Heuress = value;
    }

    private _selectedTemplateEmailRappel48Heures: TemplateEmailRappel48HeuresVo;
    get selectedTemplateEmailRappel48Heures(): TemplateEmailRappel48HeuresVo {
        if (this._selectedTemplateEmailRappel48Heures == null) {
            this._selectedTemplateEmailRappel48Heures = new TemplateEmailRappel48HeuresVo();
        }
        return this._selectedTemplateEmailRappel48Heures;
    }

    set selectedTemplateEmailRappel48Heures(value: TemplateEmailRappel48HeuresVo) {
        this._selectedTemplateEmailRappel48Heures = value;
    }

    private _templateEmailRappel48HeuresSelections: Array<TemplateEmailRappel48HeuresVo>;
    get templateEmailRappel48HeuresSelections(): Array<TemplateEmailRappel48HeuresVo> {
        if (this._templateEmailRappel48HeuresSelections == null) {
            this._templateEmailRappel48HeuresSelections = new Array<TemplateEmailRappel48HeuresVo>();
        }
        return this._templateEmailRappel48HeuresSelections;
    }

    set templateEmailRappel48HeuresSelections(value: Array<TemplateEmailRappel48HeuresVo>) {
        this._templateEmailRappel48HeuresSelections = value;
    }

    private _createTemplateEmailRappel48HeuresDialog: boolean;
    get createTemplateEmailRappel48HeuresDialog(): boolean {
        return this._createTemplateEmailRappel48HeuresDialog;
    }

    set createTemplateEmailRappel48HeuresDialog(value: boolean) {
        this._createTemplateEmailRappel48HeuresDialog = value;
    }

    private _editTemplateEmailRappel48HeuresDialog: boolean;
    get editTemplateEmailRappel48HeuresDialog(): boolean {
        return this._editTemplateEmailRappel48HeuresDialog;
    }

    set editTemplateEmailRappel48HeuresDialog(value: boolean) {
        this._editTemplateEmailRappel48HeuresDialog = value;
    }

    private _viewTemplateEmailRappel48HeuresDialog: boolean;
    get viewTemplateEmailRappel48HeuresDialog(): boolean {
        return this._viewTemplateEmailRappel48HeuresDialog;
    }

    set viewTemplateEmailRappel48HeuresDialog(value: boolean) {
        this._viewTemplateEmailRappel48HeuresDialog = value;
    }

    private _searchTemplateEmailRappel48Heures: TemplateEmailRappel48HeuresVo;
    get searchTemplateEmailRappel48Heures(): TemplateEmailRappel48HeuresVo {
        if (this._searchTemplateEmailRappel48Heures == null) {
            this._searchTemplateEmailRappel48Heures = new TemplateEmailRappel48HeuresVo();
        }
        return this._searchTemplateEmailRappel48Heures;
    }

    set searchTemplateEmailRappel48Heures(value: TemplateEmailRappel48HeuresVo) {
        this._searchTemplateEmailRappel48Heures = value;
    }

    public findAll() {
        return this.http.get<Array<TemplateEmailRappel48HeuresVo>>(this.API);
    }

    public save(): Observable<TemplateEmailRappel48HeuresVo> {
        return this.http.post<TemplateEmailRappel48HeuresVo>(this.API, this.selectedTemplateEmailRappel48Heures);
    }

    delete(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo) {
        return this.http.delete<number>(this.API + 'id/' + templateEmailRappel48Heures.id);
    }

    public edit(): Observable<TemplateEmailRappel48HeuresVo> {
        return this.http.put<TemplateEmailRappel48HeuresVo>(this.API, this.selectedTemplateEmailRappel48Heures);
    }

    public findByCriteria(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo): Observable<Array<TemplateEmailRappel48HeuresVo>> {
        return this.http.post<Array<TemplateEmailRappel48HeuresVo>>(this.API + 'search', templateEmailRappel48Heures);
    }

    public findByIdWithAssociatedList(templateEmailRappel48Heures: TemplateEmailRappel48HeuresVo): Observable<TemplateEmailRappel48HeuresVo> {
        return this.http.get<TemplateEmailRappel48HeuresVo>(this.API + 'detail/id/' + templateEmailRappel48Heures.id);
    }
}
