import {Component, OnInit} from '@angular/core';
import {StatutEmailService} from 'src/app/controller/service/template/StatutEmail.service';
import {StatutEmailVo} from 'src/app/controller/model/template/StatutEmail.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-statut-email-view-admin',
    templateUrl: './statut-email-view-admin.component.html',
    styleUrls: ['./statut-email-view-admin.component.css']
})
export class StatutEmailViewAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private statutEmailService: StatutEmailService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    get statutEmails(): Array<StatutEmailVo> {
        return this.statutEmailService.statutEmails;
    }

    set statutEmails(value: Array<StatutEmailVo>) {
        this.statutEmailService.statutEmails = value;
    }

    get selectedStatutEmail(): StatutEmailVo {
        return this.statutEmailService.selectedStatutEmail;
    }

    set selectedStatutEmail(value: StatutEmailVo) {
        this.statutEmailService.selectedStatutEmail = value;
    }

    get viewStatutEmailDialog(): boolean {
        return this.statutEmailService.viewStatutEmailDialog;
    }

    set viewStatutEmailDialog(value: boolean) {
        this.statutEmailService.viewStatutEmailDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatView;
    }

    get dateFormatColumn() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
    }

    hideViewDialog() {
        this.viewStatutEmailDialog = false;
    }
}
