import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-arret-travail-admin',
    templateUrl: './arret-travail-admin.component.html',
    styleUrls: ['./arret-travail-admin.component.css']
})
export class ArretTravailAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
