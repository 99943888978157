import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {DepartementTechnicienVo} from '../../model/technicien/DepartementTechnicien.model';
import {DepartementTechnicienDisponibiliteVo} from "../../model/technicien/DepartementTechnicienDisponibiliteVo.model";
import {DepartementTechnicienJourDisponibiliteVo} from "../../model/technicien/DepartementTechnicienJourDisponibiliteVo.model";
import {ScheduleDto} from "../../model/referentiel/ScheduleDto";
import {ScheduleTrancheRequestVo} from "../../model/referentiel/ScheduleTrancheRequest.model";
@Injectable({
    providedIn: 'root'
})
export class DepartementTechnicienService {
    public editDepartementTechnicien$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;
    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/departementTechnicien/';
        });
    }
    private _departementTechnicienDisponibilites: Array<DepartementTechnicienDisponibiliteVo>;
    get departementTechnicienDisponibilites(): Array<DepartementTechnicienDisponibiliteVo> {
        if (this._departementTechnicienDisponibilites == null) {
            this._departementTechnicienDisponibilites = new Array<DepartementTechnicienDisponibiliteVo>();
        }
        return this._departementTechnicienDisponibilites;
    }
    set departementTechnicienDisponibilites(value: Array<DepartementTechnicienDisponibiliteVo>) {
        this._departementTechnicienDisponibilites = value;
    }
    private _departementTechnicienJourDisponibilites: Array<DepartementTechnicienJourDisponibiliteVo>;
    get departementTechnicienJourDisponibilites(): Array<DepartementTechnicienJourDisponibiliteVo> {
        if (this._departementTechnicienJourDisponibilites == null) {
            this._departementTechnicienJourDisponibilites = new Array<DepartementTechnicienJourDisponibiliteVo>();
        }
        return this._departementTechnicienJourDisponibilites;
    }
    set departementTechnicienJourDisponibilites(value: Array<DepartementTechnicienJourDisponibiliteVo>) {
        this._departementTechnicienJourDisponibilites = value;
    }
    private _departementTechniciens: Array<DepartementTechnicienVo>;
    get departementTechniciens(): Array<DepartementTechnicienVo> {
        if (this._departementTechniciens == null) {
            this._departementTechniciens = new Array<DepartementTechnicienVo>();
        }
        return this._departementTechniciens;
    }
    set departementTechniciens(value: Array<DepartementTechnicienVo>) {
        this._departementTechniciens = value;
    }
    private _selectedDepartementTechnicien: DepartementTechnicienVo;
    get selectedDepartementTechnicien(): DepartementTechnicienVo {
        if (this._selectedDepartementTechnicien == null) {
            this._selectedDepartementTechnicien = new DepartementTechnicienVo();
        }
        return this._selectedDepartementTechnicien;
    }
    set selectedDepartementTechnicien(value: DepartementTechnicienVo) {
        this._selectedDepartementTechnicien = value;
    }
    private _selectedDepartementTechnicienDisponibilite: DepartementTechnicienDisponibiliteVo;
    get selectedDepartementTechnicienDisponibilite(): DepartementTechnicienDisponibiliteVo {
        if (this._selectedDepartementTechnicienDisponibilite == null) {
            this._selectedDepartementTechnicienDisponibilite = new DepartementTechnicienDisponibiliteVo();
        }
        return this._selectedDepartementTechnicienDisponibilite;
    }
    set selectedDepartementTechnicienDisponibilite(value: DepartementTechnicienDisponibiliteVo) {
        this._selectedDepartementTechnicienDisponibilite = value;
    }
    private _selectedDepartementTechnicienJourDisponibilite: DepartementTechnicienJourDisponibiliteVo;
    get selectedDepartementTechnicienJourDisponibilite(): DepartementTechnicienJourDisponibiliteVo {
        if (this._selectedDepartementTechnicienJourDisponibilite == null) {
            this._selectedDepartementTechnicienJourDisponibilite = new DepartementTechnicienJourDisponibiliteVo();
        }
        return this._selectedDepartementTechnicienJourDisponibilite;
    }
    set selectedDepartementTechnicienJourDisponibilite(value: DepartementTechnicienJourDisponibiliteVo) {
        this._selectedDepartementTechnicienJourDisponibilite = value;
    }
    private _departementTechnicienSelections: Array<DepartementTechnicienVo>;
    get departementTechnicienSelections(): Array<DepartementTechnicienVo> {
        if (this._departementTechnicienSelections == null) {
            this._departementTechnicienSelections = new Array<DepartementTechnicienVo>();
        }
        return this._departementTechnicienSelections;
    }

    set departementTechnicienSelections(value: Array<DepartementTechnicienVo>) {
        this._departementTechnicienSelections = value;
    }

    private _createDepartementTechnicienDialog: boolean;
    get createDepartementTechnicienDialog(): boolean {
        return this._createDepartementTechnicienDialog;
    }

    set createDepartementTechnicienDialog(value: boolean) {
        this._createDepartementTechnicienDialog = value;
    }
    private _editDepartementTechnicienDialog: boolean;
    get editDepartementTechnicienDialog(): boolean {
        return this._editDepartementTechnicienDialog;
    }
    set editDepartementTechnicienDialog(value: boolean) {
        this._editDepartementTechnicienDialog = value;
    }
    private _viewDepartementTechnicienDialog: boolean;
    get viewDepartementTechnicienDialog(): boolean {
        return this._viewDepartementTechnicienDialog;
    }
    set viewDepartementTechnicienDialog(value: boolean) {
        this._viewDepartementTechnicienDialog = value;
    }
    private _searchDepartementTechnicien: DepartementTechnicienVo;
    get searchDepartementTechnicien(): DepartementTechnicienVo {
        if (this._searchDepartementTechnicien == null) {
            this._searchDepartementTechnicien = new DepartementTechnicienVo();
        }
        return this._searchDepartementTechnicien;
    }
    set searchDepartementTechnicien(value: DepartementTechnicienVo) {
        this._searchDepartementTechnicien = value;
    }
    private _searchDepartementTechnicienDisponibilite: DepartementTechnicienDisponibiliteVo;
    get searchDepartementTechnicienDisponibilite(): DepartementTechnicienDisponibiliteVo {
        if (this._searchDepartementTechnicienDisponibilite == null) {
            this._searchDepartementTechnicienDisponibilite = new DepartementTechnicienDisponibiliteVo();
        }
        return this._searchDepartementTechnicienDisponibilite;
    }
    set searchDepartementTechnicienDisponibilite(value: DepartementTechnicienDisponibiliteVo) {
        this._searchDepartementTechnicienDisponibilite = value;
    }
    public findAll() {
        return this.http.get<Array<DepartementTechnicienVo>>(this.API);
    }
    public save(): Observable<DepartementTechnicienVo> {
        return this.http.post<DepartementTechnicienVo>(this.API, {
            ...this.selectedDepartementTechnicien,
            // dateFin: moment(this.selectedDepartementTechnicien.dateFin).format('YYYY-MM-DD')
        });
    }
    delete(departementTechnicien: DepartementTechnicienVo) {
        return this.http.delete<number>(this.API + 'id/' + departementTechnicien.id);
    }
    public edit(): Observable<DepartementTechnicienVo> {
        return this.http.put<DepartementTechnicienVo>(this.API, this.selectedDepartementTechnicien);
    }
    public findByCriteria(departementTechnicien: DepartementTechnicienVo): Observable<Array<DepartementTechnicienVo>> {
        return this.http.post<Array<DepartementTechnicienVo>>(this.API + 'search', departementTechnicien);
    }
    public findByIdWithAssociatedList(departementTechnicien: DepartementTechnicienVo): Observable<DepartementTechnicienVo> {
        return this.http.get<DepartementTechnicienVo>(this.API + 'detail/id/' + departementTechnicien.id);
    }
    /*  public findByTechnicienParJour(technicienVo: TechnicienVo,departementVo: DepartementVo): Observable<DepartementTechnicienDisponibiliteVo> {
          return this.http.get<DepartementTechnicienDisponibiliteVo>(this.API + 'technicien-jour/idTechnicien/' + technicienVo.id + '/idDepartement/' + departementVo.id);
      }*/
    public findByTechnicienParJour(departementTechnicienDisponibiliteVo: DepartementTechnicienDisponibiliteVo): Observable<DepartementTechnicienDisponibiliteVo> {
        return this.http.post<DepartementTechnicienDisponibiliteVo>(this.API + 'technicien-jour', departementTechnicienDisponibiliteVo);
    }
    findSchedule(departementTechnicienDisponibiliteVo: DepartementTechnicienDisponibiliteVo): Observable<Array<ScheduleDto>> {
        return this.http.post<Array<ScheduleDto>>(this.API + 'technicien-jour-dispo', departementTechnicienDisponibiliteVo);
    }
    public findByTechnicienParTrancheSchedule(scheduleTrancheRequestVo: ScheduleTrancheRequestVo): Observable<Array<ScheduleDto>> {
        return this.http.post<Array<ScheduleDto>>(this.API + 'technicien-tranche', scheduleTrancheRequestVo);
    }
}
