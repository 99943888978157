import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleService} from '../security/role.service';
import {environment} from 'src/environments/environment';
import {
    TemplateEmailReportDemandeClientClientJoignableVo
} from '../../model/template/TemplateEmailReportDemandeClientClientJoignable.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEmailReportDemandeClientClientJoignableService {
    public editTemplateEmailReportDemandeClientClientJoignable$ = new BehaviorSubject<boolean>(false);
    private API = '';
    private role$: Observable<string>;

    constructor(private http: HttpClient, private roleService: RoleService) {
        this.role$ = this.roleService.role$;
        this.role$.subscribe(role => {
            this.API = environment.apiUrl + role.toLowerCase() + '/templateEmailReportDemandeClientClientJoignable/';
        });
    }

    private _templateEmailReportDemandeClientClientJoignables: Array<TemplateEmailReportDemandeClientClientJoignableVo>;
    get templateEmailReportDemandeClientClientJoignables(): Array<TemplateEmailReportDemandeClientClientJoignableVo> {
        if (this._templateEmailReportDemandeClientClientJoignables == null) {
            this._templateEmailReportDemandeClientClientJoignables = new Array<TemplateEmailReportDemandeClientClientJoignableVo>();
        }
        return this._templateEmailReportDemandeClientClientJoignables;
    }

    set templateEmailReportDemandeClientClientJoignables(value: Array<TemplateEmailReportDemandeClientClientJoignableVo>) {
        this._templateEmailReportDemandeClientClientJoignables = value;
    }

    private _selectedTemplateEmailReportDemandeClientClientJoignable: TemplateEmailReportDemandeClientClientJoignableVo;
    get selectedTemplateEmailReportDemandeClientClientJoignable(): TemplateEmailReportDemandeClientClientJoignableVo {
        if (this._selectedTemplateEmailReportDemandeClientClientJoignable == null) {
            this._selectedTemplateEmailReportDemandeClientClientJoignable = new TemplateEmailReportDemandeClientClientJoignableVo();
        }
        return this._selectedTemplateEmailReportDemandeClientClientJoignable;
    }

    set selectedTemplateEmailReportDemandeClientClientJoignable(value: TemplateEmailReportDemandeClientClientJoignableVo) {
        this._selectedTemplateEmailReportDemandeClientClientJoignable = value;
    }

    private _templateEmailReportDemandeClientClientJoignableSelections: Array<TemplateEmailReportDemandeClientClientJoignableVo>;
    get templateEmailReportDemandeClientClientJoignableSelections(): Array<TemplateEmailReportDemandeClientClientJoignableVo> {
        if (this._templateEmailReportDemandeClientClientJoignableSelections == null) {
            this._templateEmailReportDemandeClientClientJoignableSelections = new Array<TemplateEmailReportDemandeClientClientJoignableVo>();
        }
        return this._templateEmailReportDemandeClientClientJoignableSelections;
    }

    set templateEmailReportDemandeClientClientJoignableSelections(value: Array<TemplateEmailReportDemandeClientClientJoignableVo>) {
        this._templateEmailReportDemandeClientClientJoignableSelections = value;
    }

    private _createTemplateEmailReportDemandeClientClientJoignableDialog: boolean;
    get createTemplateEmailReportDemandeClientClientJoignableDialog(): boolean {
        return this._createTemplateEmailReportDemandeClientClientJoignableDialog;
    }

    set createTemplateEmailReportDemandeClientClientJoignableDialog(value: boolean) {
        this._createTemplateEmailReportDemandeClientClientJoignableDialog = value;
    }

    private _editTemplateEmailReportDemandeClientClientJoignableDialog: boolean;

    // getters and setters

    get editTemplateEmailReportDemandeClientClientJoignableDialog(): boolean {
        return this._editTemplateEmailReportDemandeClientClientJoignableDialog;
    }

    set editTemplateEmailReportDemandeClientClientJoignableDialog(value: boolean) {
        this._editTemplateEmailReportDemandeClientClientJoignableDialog = value;
    }

    private _viewTemplateEmailReportDemandeClientClientJoignableDialog: boolean;
    get viewTemplateEmailReportDemandeClientClientJoignableDialog(): boolean {
        return this._viewTemplateEmailReportDemandeClientClientJoignableDialog;
    }

    set viewTemplateEmailReportDemandeClientClientJoignableDialog(value: boolean) {
        this._viewTemplateEmailReportDemandeClientClientJoignableDialog = value;
    }

    private _searchTemplateEmailReportDemandeClientClientJoignable: TemplateEmailReportDemandeClientClientJoignableVo;
    get searchTemplateEmailReportDemandeClientClientJoignable(): TemplateEmailReportDemandeClientClientJoignableVo {
        if (this._searchTemplateEmailReportDemandeClientClientJoignable == null) {
            this._searchTemplateEmailReportDemandeClientClientJoignable = new TemplateEmailReportDemandeClientClientJoignableVo();
        }
        return this._searchTemplateEmailReportDemandeClientClientJoignable;
    }

    set searchTemplateEmailReportDemandeClientClientJoignable(value: TemplateEmailReportDemandeClientClientJoignableVo) {
        this._searchTemplateEmailReportDemandeClientClientJoignable = value;
    }

    public findAll() {
        return this.http.get<Array<TemplateEmailReportDemandeClientClientJoignableVo>>(this.API);
    }

    public save(): Observable<TemplateEmailReportDemandeClientClientJoignableVo> {
        return this.http.post<TemplateEmailReportDemandeClientClientJoignableVo>(this.API, this.selectedTemplateEmailReportDemandeClientClientJoignable);
    }

    delete(templateEmailReportDemandeClientClientJoignable: TemplateEmailReportDemandeClientClientJoignableVo) {
        return this.http.delete<number>(this.API + 'id/' + templateEmailReportDemandeClientClientJoignable.id);
    }

    public edit(): Observable<TemplateEmailReportDemandeClientClientJoignableVo> {
        return this.http.put<TemplateEmailReportDemandeClientClientJoignableVo>(this.API, this.selectedTemplateEmailReportDemandeClientClientJoignable);
    }

    public findByCriteria(templateEmailReportDemandeClientClientJoignable: TemplateEmailReportDemandeClientClientJoignableVo): Observable<Array<TemplateEmailReportDemandeClientClientJoignableVo>> {
        return this.http.post<Array<TemplateEmailReportDemandeClientClientJoignableVo>>(this.API + 'search', templateEmailReportDemandeClientClientJoignable);
    }

    public findByIdWithAssociatedList(templateEmailReportDemandeClientClientJoignable: TemplateEmailReportDemandeClientClientJoignableVo): Observable<TemplateEmailReportDemandeClientClientJoignableVo> {
        return this.http.get<TemplateEmailReportDemandeClientClientJoignableVo>(this.API + 'detail/id/' + templateEmailReportDemandeClientClientJoignable.id);
    }
}
