import {Component, OnInit} from '@angular/core';
import {OrdreKoscService} from 'src/app/controller/service/kosc/OrdreKosc.service';
import {OrdreKoscVo} from 'src/app/controller/model/kosc/OrdreKosc.model';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {OperatorService} from 'src/app/controller/service/referentiel/Operator.service';
import {DepartementService} from 'src/app/controller/service/technicien/Departement.service';
import {TechnicienService} from 'src/app/controller/service/technicien/Technicien.service';
import {EtatDemandeKoscService} from 'src/app/controller/service/referentiel/EtatDemandeKosc.service';
import {EtatDemandeKoscVo} from 'src/app/controller/model/referentiel/EtatDemandeKosc.model';
import {OperatorVo} from 'src/app/controller/model/referentiel/Operator.model';
import {DepartementVo} from 'src/app/controller/model/technicien/Departement.model';
import {TechnicienVo} from 'src/app/controller/model/technicien/Technicien.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';
import {Calendar} from "primeng/calendar";
import {DateUtils} from "../../../../../../utils/DateUtils";
import {StringUtilService} from "../../../../../../controller/service/referentiel/StringUtil.service";
import {
    DefaultTemplateConfigurationVo
} from "../../../../../../controller/model/template/DefaultTemplateConfiguration.model";
import {
    DefaultTemplateConfigurationService
} from "../../../../../../controller/service/template/DefaultTemplateConfiguration.service";
import {TypeForfaitDevisVo} from "../../../../../../controller/model/referentiel/TypeForfaitDevis";
import {TypeForfaitDevisService} from "../../../../../../controller/service/referentiel/TypeForfaitDevis.service";

@Component({
    selector: 'app-ordre-kosc-suivi-cdd-list-admin',
    templateUrl: './ordre-kosc-suivi-cdd-list-admin.component.html',
    styleUrls: ['./ordre-kosc-suivi-cdd-list-admin.component.css']
})
export class OrdreKoscSuiviCddListAdminComponent implements OnInit {
    findByCriteriaShow = false;
    displayView = false;
    showYearDateRdvAndMonthDateRdv = false;
    cols: any[] = [];
    excelPdfButons: MenuItem[];
    excelCddButons: MenuItem[];
    exportData: any[] = [];
    criteriaData: any[] = [];
    colsDevis: any[] = [];
    exportDataDevis: any[] = [];
    criteriaDataDevis: any[] = [];
    fileName = 'OrdreKosc';
    yesOrNoEnvoiMailClient: any[] = [];
    yesOrNoEnvoiMailKosc: any[] = [];
    yesOrNoRacordementLong: any[] = [];
    yesOrNoExistingOtp: any[] = [];
    yesOrNoEnvoyeClient: any[] = [];
    yesOrNoEnvoyeKosc: any[] = [];
    yesOrNoEnvoyePlanification: any[] = [];
    yesOrNoEnvoyeReplanification: any[] = [];
    yesOrNoEnvoyeReport: any[] = [];
    yesOrNoEnvoyeCloture: any[] = [];
    yesOrNoEnvoyeSuivi: any[] = [];
    operators: Array<OperatorVo>;
    departements: Array<DepartementVo>;
    techniciens: Array<TechnicienVo>;
    etatDemandeKoscs: Array<EtatDemandeKoscVo>;
    typeForfaitDeviss: Array<TypeForfaitDevisVo>;
    entryDate: Calendar;
    items: MenuItem[];
    home: MenuItem;
    isLoading: boolean = false;
    yesOrNoErdv: any[] = [];

    constructor(private datePipe: DatePipe, private ordreKoscService: OrdreKoscService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
        , private operatorService: OperatorService
        , private departementService: DepartementService
        , private typeForfaitDevisService: TypeForfaitDevisService
        , private technicienService: TechnicienService
        , private etatDemandeKoscService: EtatDemandeKoscService
        , private stringUtilService: StringUtilService
        , private defaultTemplateConfigurationService: DefaultTemplateConfigurationService
    ) {
    }

    get preparedOrdreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.preparedOrdreKoscs;
    }

    set preparedOrdreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.preparedOrdreKoscs = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validOrdreKoscYearDateRdv = true;
    get validOrdreKoscYearDateRdv(): boolean {
        return this._validOrdreKoscYearDateRdv;
    }

    set validOrdreKoscYearDateRdv(value: boolean) {
        this._validOrdreKoscYearDateRdv = value;
    }

    _validOrdreKoscMonthDateRdv = true;
    get validOrdreKoscMonthDateRdv(): boolean {
        return this._validOrdreKoscMonthDateRdv;
    }

    set validOrdreKoscMonthDateRdv(value: boolean) {
        this._validOrdreKoscMonthDateRdv = value;
    }

    get showCauseKo(): boolean {
        return this.ordreKoscService.showCauseKo;
    }

    set showCauseKo(value: boolean) {
        this.ordreKoscService.showCauseKo = value;
    }

    get ordreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviCdd;
    }

    set ordreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviCdd = value;
    }

    get ordreKoscsExport(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviCdd;
    }

    set ordreKoscsExport(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviCdd = value;
    }

    get ordreKoscSelections(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscSelections;
    }

    set ordreKoscSelections(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscSelections = value;
    }

    get selectedOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.selectedOrdreKosc;
    }

    set selectedOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.selectedOrdreKosc = value;
    }

    get createOrdreKoscDialog(): boolean {
        return this.ordreKoscService.createOrdreKoscDialog;
    }

    set createOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.createOrdreKoscDialog = value;
    }

    get editOrdreKoscDialog(): boolean {
        return this.ordreKoscService.editOrdreKoscDialog;
    }

    set editOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.editOrdreKoscDialog = value;
    }

    get viewOrdreKoscDialog(): boolean {
        return this.ordreKoscService.viewOrdreKoscDialog;
    }

    set viewOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.viewOrdreKoscDialog = value;
    }

    get searchOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.searchOrdreKoscSuiviCdd;
    }

    set searchOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.searchOrdreKoscSuiviCdd = value;
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    get showSpinner(): boolean {
        return this.ordreKoscService.showSpinner;
    }

    set showSpinner(value: boolean) {
        this.ordreKoscService.showSpinner = value;
    }

    get totalRecords(): number {
        return this.ordreKoscService.totalRecords;
    }

    set totalRecords(value: number) {
        this.ordreKoscService.totalRecords = value;
    }

    get selectedDefaultTemplateConfiguration(): DefaultTemplateConfigurationVo {
        return this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration;
    }

    set selectedDefaultTemplateConfiguration(value: DefaultTemplateConfigurationVo) {
        this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration = value;
    }

    public searchRequestSuiviCdd() {
        this.ordreKoscService.findByCriteriaSuiviCdd(this.searchOrdreKosc).subscribe(ordreKoscs => {
            this.ordreKoscs = ordreKoscs;
            this.searchOrdreKosc = new OrdreKoscVo();
        }, error => console.log(error));
    }

    public async loadEtatDemandeKoscExcept(etatNonDesire: Array<String>) {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.etatDemandeKoscService.findAll().subscribe(etatDemandeKoscs => {
                this.etatDemandeKoscs = etatDemandeKoscs;
                this.searchOrdreKosc.etatDemandeKoscVos = this.etatDemandeKoscs.filter(e => etatNonDesire.indexOf(e.code) == -1);
            }, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadEtatDemandeKoscIncluding(etatNonDesire: Array<String>) {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.etatDemandeKoscService.findAll().subscribe(etatDemandeKoscs => {
                this.etatDemandeKoscs = etatDemandeKoscs;
                this.searchOrdreKosc.etatDemandeKoscVos = this.etatDemandeKoscs.filter(e => etatNonDesire.indexOf(e.code) != -1);
            }, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    erdvAndConfort(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.confort)
            return true
        else
            return false
    }

    public async loadOrdreKoscs() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.ordreKoscService.findAll().subscribe(ordreKoscs => this.ordreKoscs = ordreKoscs, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public onPage(event: any) {
        this.searchOrdreKosc.page = event.page;
        this.searchOrdreKosc.maxResults = event.rows;
        this.searchRequest();
    }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
    }

    /*   public findByCriteriaSuiviCddToExport() {
           this.ordreKoscService.findByCriteriaSuiviCdd(this.searchOrdreKosc).subscribe(preparedOrdreKoscs => {
               this.preparedOrdreKoscs = preparedOrdreKoscs;
           }, error => console.log(error));
       }*/
    public async findByCriteriaSuiviCddToExport(): Promise<void> {
        try {
            const preparedOrdreKoscs = await this.ordreKoscService.findByCriteriaSuiviCdd(this.searchOrdreKosc).toPromise();
            this.preparedOrdreKoscs = preparedOrdreKoscs;
            this.ordreKoscs = preparedOrdreKoscs;
            this.totalRecords = preparedOrdreKoscs.length;
        } catch (error) {
            console.log(error);
        }
    }

    public searchRequest() {
        this.showSpinner = true;
        this.searchOrdreKosc.submissionDateMin = this.formatDate(this.searchOrdreKosc.submissionDateMinAsDate);
        this.searchOrdreKosc.submissionDateMax = this.formatDate(this.searchOrdreKosc.submissionDateMaxAsDate);
        this.searchOrdreKosc.dateCriMin = this.formatDate(this.searchOrdreKosc.dateCriMinAsDate);
        this.searchOrdreKosc.dateCriMax = this.formatDate(this.searchOrdreKosc.dateCriMaxAsDate);
        this.searchOrdreKosc.dateRdvFinaleMin = this.formatDate(this.searchOrdreKosc.dateRdvFinaleMinAsDate)
        this.searchOrdreKosc.dateRdvFinaleMax = this.formatDate(this.searchOrdreKosc.dateRdvFinaleMaxAsDate)
        this.ordreKoscService.findByCriteriaSuiviCddPaginated(this.searchOrdreKosc).subscribe(ordreKoscs => {
            this.ordreKoscs = ordreKoscs.list;
            this.totalRecords = ordreKoscs.dataSize;
            this.showSpinner = false;
        }, error => {
            console.log(error);
            this.showSpinner = false;
        });
    }

    ngOnInit(): void {
        this.defaultTemplateConfigurationService.findDefaultTemplateConfiguration().subscribe((data) =>
            this.selectedDefaultTemplateConfiguration = data,
        );
        this.items = [
            {label: 'Suivi CDD', routerLink: '/app/admin/kosc/ordre-kosc-suivi-cdd/list'},
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
        this.loadEtatDemandeKoscIncluding(['ko', 'ok']);
        this.initExport();
        this.initExportCdd();
        this.initCol();
        this.loadOperator();
        this.loadDepartement();
        this.loadTypeForfaitDevis();
        this.loadTechnicien();
        this.yesOrNoEnvoiMailClient = [{label: 'EnvoiMailClient', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoiMailKosc = [{label: 'EnvoiMailKosc', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoRacordementLong = [{label: 'RacordementLong', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoExistingOtp = [{label: 'ExistingOtp', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoyeClient = [{label: 'EnvoyeClient', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoyeKosc = [{label: 'EnvoyeKosc', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoyePlanification = [{label: 'EnvoyePlanification', value: null}, {
            label: 'Oui',
            value: 1
        }, {label: 'Non', value: 0}];
        this.yesOrNoEnvoyeReplanification = [{label: 'EnvoyeReplanification', value: null}, {
            label: 'Oui',
            value: 1
        }, {label: 'Non', value: 0}];
        this.yesOrNoEnvoyeReport = [{label: 'EnvoyeReport', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoyeCloture = [{label: 'EnvoyeCloture', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoEnvoyeSuivi = [{label: 'EnvoyeSuivi', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.yesOrNoErdv = [{label: 'Erdv', value: null}, {label: 'Oui', value: 1}, {
            label: 'Non',
            value: 0
        }];
        this.ordreKoscs = new Array<OrdreKoscVo>();
        this.preparedOrdreKoscs = new Array<OrdreKoscVo>();
    }

    public showExportByYearDateRdvAndMonthDateRdv() {
        this.displayView = true;
        this.showYearDateRdvAndMonthDateRdv = true;
        this.selectedOrdreKosc.yearDateRdv = new Date().getFullYear();
        this.selectedOrdreKosc.monthDateRdv = new Date().getMonth() + 1;
    }

    public async editOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'edit');
        if (isPermistted) {
            this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(res => {
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.dateDebutTraitement = DateUtils.toDate(ordreKosc.dateDebutTraitement);
                this.selectedOrdreKosc.submissionDate = DateUtils.toDate(ordreKosc.submissionDate);
                this.selectedOrdreKosc.datePremierAppel = DateUtils.toDate(ordreKosc.datePremierAppel);
                this.selectedOrdreKosc.dateDeuxiemeAppel = DateUtils.toDate(ordreKosc.dateDeuxiemeAppel);
                this.selectedOrdreKosc.dateTroisiemeAppel = DateUtils.toDate(ordreKosc.dateTroisiemeAppel);
                this.selectedOrdreKosc.datePriseRdv = DateUtils.toDate(ordreKosc.datePriseRdv);
                this.selectedOrdreKosc.dateRdv = DateUtils.toDate(ordreKosc.dateRdv);
                this.selectedOrdreKosc.dateAppelReplanification = DateUtils.toDate(ordreKosc.dateAppelReplanification);
                this.selectedOrdreKosc.dateOuverture = DateUtils.toDate(ordreKosc.dateOuverture);
                this.selectedOrdreKosc.dateCri = DateUtils.toDate(ordreKosc.dateCri);
                this.selectedOrdreKosc.dateInterventionTechniqueFin = DateUtils.toDate(ordreKosc.dateInterventionTechniqueFin);
                this.selectedOrdreKosc.dateEnvoiPlanification = DateUtils.toDate(ordreKosc.dateEnvoiPlanification);
                this.selectedOrdreKosc.dateEnvoiReplanification = DateUtils.toDate(ordreKosc.dateEnvoiReplanification);
                this.selectedOrdreKosc.dateEnvoiCloture = DateUtils.toDate(ordreKosc.dateEnvoiCloture);
                this.selectedOrdreKosc.dateEnvoiSuivi = DateUtils.toDate(ordreKosc.dateEnvoiSuivi);
                this.editOrdreKoscDialog = true;
                this.selectedOrdreKosc.fromSuiviCdd = eval(this.selectedDefaultTemplateConfiguration.emailManeo);
                this.selectedOrdreKosc.toSuiviCdd = eval(this.selectedDefaultTemplateConfiguration.emailKosc);
                this.selectedOrdreKosc.ccSuiviCdd = eval(this.selectedDefaultTemplateConfiguration.ccCloture);
                this.selectedOrdreKosc.objetSuiviCdd = eval(this.selectedDefaultTemplateConfiguration.templateEmailClotureVo.objet);
                this.selectedOrdreKosc.corpsSuiviCdd = eval(this.selectedDefaultTemplateConfiguration.templateEmailClotureVo.corps);
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'view');
        if (isPermistted) {
            this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(res => {
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.dateDebutTraitement = DateUtils.toDate(ordreKosc.dateDebutTraitement);
                this.selectedOrdreKosc.submissionDate = DateUtils.toDate(ordreKosc.submissionDate);
                this.selectedOrdreKosc.datePremierAppel = DateUtils.toDate(ordreKosc.datePremierAppel);
                this.selectedOrdreKosc.dateDeuxiemeAppel = DateUtils.toDate(ordreKosc.dateDeuxiemeAppel);
                this.selectedOrdreKosc.dateTroisiemeAppel = DateUtils.toDate(ordreKosc.dateTroisiemeAppel);
                this.selectedOrdreKosc.datePriseRdv = DateUtils.toDate(ordreKosc.datePriseRdv);
                this.selectedOrdreKosc.dateRdv = DateUtils.toDate(ordreKosc.dateRdv);
                this.selectedOrdreKosc.dateCri = DateUtils.toDate(ordreKosc.dateCri);
                this.selectedOrdreKosc.dateAppelReplanification = DateUtils.toDate(ordreKosc.dateAppelReplanification);
                this.selectedOrdreKosc.dateOuverture = DateUtils.toDate(ordreKosc.dateOuverture);
                this.selectedOrdreKosc.dateEnvoiCri = DateUtils.toDate(ordreKosc.dateEnvoiCri);
                this.selectedOrdreKosc.dateInterventionTechniqueFin = DateUtils.toDate(ordreKosc.dateInterventionTechniqueFin);
                this.selectedOrdreKosc.dateEnvoiPlanification = DateUtils.toDate(ordreKosc.dateEnvoiPlanification);
                this.selectedOrdreKosc.dateEnvoiReplanification = DateUtils.toDate(ordreKosc.dateEnvoiReplanification);
                this.selectedOrdreKosc.dateEnvoiCloture = DateUtils.toDate(ordreKosc.dateEnvoiCloture);
                this.selectedOrdreKosc.dateEnvoiSuivi = DateUtils.toDate(ordreKosc.dateEnvoiSuivi);
                this.showCauseKoView();
                this.viewOrdreKoscDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateOrdreKosc(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedOrdreKosc = new OrdreKoscVo();
            this.createOrdreKoscDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Ordre kosc) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.ordreKoscService.delete(ordreKosc).subscribe(status => {
                        if (status > 0) {
                            const position = this.ordreKoscs.indexOf(ordreKosc);
                            position > -1 ? this.ordreKoscs.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'Ordre kosc Supprimé',
                                life: 3000
                            });
                        }

                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async loadOperator() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.operatorService.findAll().subscribe(operators => this.operators = operators, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadDepartement() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.departementService.findAll().subscribe(departements => this.departements = departements, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTypeForfaitDevis() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.typeForfaitDevisService.findAll().subscribe(typeForfaitDeviss => this.typeForfaitDeviss = typeForfaitDeviss, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTechnicien() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.technicienService.findAll().subscribe(techniciens => this.techniciens = techniciens, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadEtatDemandeKosc() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.etatDemandeKoscService.findAll().subscribe(etatDemandeKoscs => this.etatDemandeKoscs = etatDemandeKoscs, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async duplicateOrdreKosc(ordreKosc: OrdreKoscVo) {
        this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(
            res => {
                this.initDuplicateOrdreKosc(res);
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.id = null;
                this.createOrdreKoscDialog = true;
            });
    }

    initDuplicateOrdreKosc(res: OrdreKoscVo) {
    }

    initExport(): void {
        this.excelPdfButons = [
            {
                label: 'CSV', icon: 'pi pi-file', command: async () => {
                    this.isLoading = true;
                    try {
                        await this.findByCriteriaSuiviCddToExport();
                        this.prepareColumnExport();
                        this.exportService.exporterCSV(this.criteriaData, this.exportData, this.fileName);
                    } finally {
                        this.isLoading = false;  // Hide spinner
                    }
                }
            }, {
                label: 'XLS', icon: 'pi pi-file-excel', command: async () => {
                    this.isLoading = true;
                    try {
                        await this.findByCriteriaSuiviCddToExport();
                        this.prepareColumnExport();
                        this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                    } finally {
                        this.isLoading = false;  // Hide spinner
                    }
                }
            }, /*{
                label: 'PDF', icon: 'pi pi-file-pdf', command: () => {
                    this.findByCriteriaSuiviCddToExport();
                    this.prepareColumnExport();
                    this.exportService.exporterPdf(this.criteriaData, this.exportData, this.fileName);
                }
            }*/
        ];
    }

    initExportCdd(): void {
        this.excelCddButons = [
            {
                label: 'XLS', icon: 'pi pi-file-excel', command: async () => {
                    this.isLoading = true;
                    try {
                        await this.findByCriteriaSuiviCddToExport();
                        this.prepareColumnExportCdd();
                        this.exportService.exporterExcel(this.criteriaData, this.exportData, this.fileName);
                    } finally {
                        this.isLoading = false;  // Hide spinner
                    }
                }
            }
        ];
    }

    public exportDevis() {
        this.validateForm();
        let yearDate = this.selectedOrdreKosc.yearDateRdv;
        let monthDate = this.selectedOrdreKosc.monthDateRdv;
        this.ordreKoscService.findByAnneAndMoins(yearDate, monthDate).subscribe(ordreKoscs => {
            if (ordreKoscs == null || ordreKoscs.length == 0) {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Vous n\'avez pas de demandes dans ce moins'
                });
            } else {
                this.ordreKoscsExport = ordreKoscs;
                this.prepareColumnExportDevis();
                this.exportService.exporterExcel(this.criteriaDataDevis, this.exportDataDevis, this.fileName);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Succès',
                    detail: ordreKoscs.length + ' Ordre kosc exporté(s) avec succès',
                    life: 3000
                });
            }
            this.displayView = false;
            this.showYearDateRdvAndMonthDateRdv = false;
        });
    }

    prepareColumnExportDevis(): void {
        this.exportDataDevis = this.ordreKoscsExport.map(e => {
                return {
                    'Reference': e.reference,
                    'Reference work order': e.referenceWorkOrder,
                    'Opérateur': e.operatorVo?.libelle,
                    'Date de RDV': this.datePipe.transform(e.dateRdv, 'dd/MM/yyyy HH:mm'),
                    'Date cri': this.datePipe.transform(e.dateCri, 'dd/MM/yyyy HH:mm'),
                    'Etat demande kosc': e.etatDemandeKoscVo?.libelle,
                    'Montant devis': e.montantDevis,
                }
            }
        );
        this.criteriaDataDevis = [{
            'Reference': this.searchOrdreKosc.reference ? this.searchOrdreKosc.reference : environment.emptyForExport,
            'Reference work order': this.searchOrdreKosc.referenceWorkOrder ? this.searchOrdreKosc.referenceWorkOrder : environment.emptyForExport,
            'Operator': this.searchOrdreKosc.operatorVo?.libelle ? this.searchOrdreKosc.operatorVo?.libelle : environment.emptyForExport,
            'Etat demande kosc': this.searchOrdreKosc.etatDemandeKoscVo?.libelle ? this.searchOrdreKosc.etatDemandeKoscVo?.libelle : environment.emptyForExport,
            'Date cri Min': this.searchOrdreKosc.dateCriMin ? this.datePipe.transform(this.searchOrdreKosc.dateCriMin, this.dateFormat) : environment.emptyForExport,
            'Date cri Max': this.searchOrdreKosc.dateCriMax ? this.datePipe.transform(this.searchOrdreKosc.dateCriMax, this.dateFormat) : environment.emptyForExport,
            'Montant devis': this.searchOrdreKosc.montantDevis ? this.searchOrdreKosc.montantDevis : environment.emptyForExport,
        }];
    }

    prepareColumnExport(): void {
        this.exportData = this.preparedOrdreKoscs.map(e => {
            return {
                'Reference': e.reference,
                'Reference work order': e.referenceWorkOrder,
                'Opérateur': e.operatorVo?.libelle,
                'Date de RDV': this.datePipe.transform(e.dateRdv, 'dd/MM/yyyy HH:mm'),
                'Date cri': this.datePipe.transform(e.dateCri, 'dd/MM/yyyy HH:mm'),
                'Etat demande kosc': e.etatDemandeKoscVo?.libelle,
            }
        });
        this.criteriaData = [{
            'Reference': this.searchOrdreKosc.reference ? this.searchOrdreKosc.reference : environment.emptyForExport,
            'Reference work order': this.searchOrdreKosc.referenceWorkOrder ? this.searchOrdreKosc.referenceWorkOrder : environment.emptyForExport,
            'Operator': this.searchOrdreKosc.operatorVo?.libelle ? this.searchOrdreKosc.operatorVo?.libelle : environment.emptyForExport,
            'Etat demande kosc': this.searchOrdreKosc.etatDemandeKoscVo?.libelle ? this.searchOrdreKosc.etatDemandeKoscVo?.libelle : environment.emptyForExport,
            'Date cri Min': this.searchOrdreKosc.dateCriMin ? this.datePipe.transform(this.searchOrdreKosc.dateCriMin, this.dateFormat) : environment.emptyForExport,
            'Date cri Max': this.searchOrdreKosc.dateCriMax ? this.datePipe.transform(this.searchOrdreKosc.dateCriMax, this.dateFormat) : environment.emptyForExport,
        }];
    }

    prepareColumnExportCdd(): void {
        this.exportData = this.preparedOrdreKoscs.map(e => {
            return {
                'Numéro de décharge': e.codeDecharge,
                'Order_Réf': e.reference,
                'Work order': e.referenceWorkOrder,
                'Date d\'intervetion': this.datePipe.transform(e.dateRdv, 'dd/MM/yyyy HH:mm'),
                'Département': e.endCustumorZipcode,
                'Type de raccordement': e.supplierServiceCode,
                'Nom du Technicien': e.technicienVo?.identifiant,
                'Statut du raccordement': e.etatDemandeKoscVo?.libelle,
                'Type PBO': e.typePbo,
                'PBO réel': e.pboReelVo?.libelle,
                'Cause KO, OK': e.causeKoOkVo?.libelle,
                'Forfait ou devis': e.typeForfaitDevisVo?.libelle,
                'Installation CPE': e.cpeInstall,
            }
        });
        this.criteriaData = [{
            'Type Forfait/ Devis': this.searchOrdreKosc.typeForfaitDevisVo?.libelle ? this.searchOrdreKosc.typeForfaitDevisVo?.libelle : environment.emptyForExport,
            'Decharge': this.searchOrdreKosc.codeDecharge ? this.searchOrdreKosc.codeDecharge : environment.emptyForExport,
            'Reference': this.searchOrdreKosc.reference ? this.searchOrdreKosc.reference : environment.emptyForExport,
            'Reference work order': this.searchOrdreKosc.referenceWorkOrder ? this.searchOrdreKosc.referenceWorkOrder : environment.emptyForExport,
            'Date d\'intervetion Min': this.searchOrdreKosc.dateRdvMin ? this.datePipe.transform(this.searchOrdreKosc.dateRdvMin, this.dateFormat) : environment.emptyForExport,
            'Date d\'intervetion Max': this.searchOrdreKosc.dateRdvMax ? this.datePipe.transform(this.searchOrdreKosc.dateRdvMax, this.dateFormat) : environment.emptyForExport,
            'departement': this.searchOrdreKosc.departementVo?.libelle ? this.searchOrdreKosc.departementVo?.libelle : environment.emptyForExport,
            'Type de rac': this.searchOrdreKosc.supplierServiceCode ? this.searchOrdreKosc.supplierServiceCode : environment.emptyForExport,
            'Technicien': this.searchOrdreKosc.technicienVo?.identifiant ? this.searchOrdreKosc.technicienVo?.identifiant : environment.emptyForExport,
            'Statut': this.searchOrdreKosc.etatDemandeKoscOldVo?.libelle ? this.searchOrdreKosc.etatDemandeKoscOldVo?.libelle : environment.emptyForExport,
            'ont ref': this.searchOrdreKosc.numeroSerieOnt ? this.searchOrdreKosc.numeroSerieOnt : environment.emptyForExport,
            'Type PBO': this.searchOrdreKosc.typePbo ? this.searchOrdreKosc.typePbo : environment.emptyForExport,
            'PBO reel': this.searchOrdreKosc.pboReelVo?.libelle ? this.searchOrdreKosc.pboReelVo?.libelle : environment.emptyForExport,
            'Decharge orange': this.searchOrdreKosc.numeroDechargeOrange ? this.searchOrdreKosc.numeroDechargeOrange : environment.emptyForExport,
            'PTO': this.searchOrdreKosc.otpRef ? this.searchOrdreKosc.otpRef : environment.emptyForExport,
            'Cause KO': this.searchOrdreKosc.causeKoOkVo?.libelle ? this.searchOrdreKosc.causeKoOkVo?.libelle : environment.emptyForExport,
            'Active / passive': this.searchOrdreKosc.providerProduct ? this.searchOrdreKosc.providerProduct : environment.emptyForExport,
            'Opérateur': this.searchOrdreKosc.operatorVo?.libelle ? this.searchOrdreKosc.operatorVo?.libelle : environment.emptyForExport,
            'Commentaire': this.searchOrdreKosc.koscComment ? this.searchOrdreKosc.koscComment : environment.emptyForExport,
            'Date cri Min': this.searchOrdreKosc.dateCriMin ? this.datePipe.transform(this.searchOrdreKosc.dateCriMin, this.dateFormat) : environment.emptyForExport,
            'Date cri Max': this.searchOrdreKosc.dateCriMax ? this.datePipe.transform(this.searchOrdreKosc.dateCriMax, this.dateFormat) : environment.emptyForExport,
            'Cpe install': this.searchOrdreKosc.cpeInstall ? this.searchOrdreKosc.cpeInstall : environment.emptyForExport,
            'otp displacement': this.searchOrdreKosc.otpDisplacement ? this.searchOrdreKosc.otpDisplacement : environment.emptyForExport,
            'ont type': this.searchOrdreKosc.ontType ? this.searchOrdreKosc.ontType : environment.emptyForExport,
        }];
    }

    public getLibelleCauseKo(ordreKosc: OrdreKoscVo): string {
        if (ordreKosc.causeKoOkVo != null) {
            return ordreKosc.causeKoOkVo?.libelle;
        } else {
            return "-";
        }
    }

    stylefyConfort(ordreKosc: OrdreKoscVo): string {
        return ordreKosc.confort ? 'color:red;' : 'color:black;';
    }

    isErdvAndReferenceEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.reference != null) {
            return true;
        } else {
            return false;
        }
    }

    isErdvAndReferencWorkOrdereEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.referenceWorkOrder != null) {
            return true;
        } else {
            return false;
        }
    }

    isEtatNotEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.etatDemandeKoscVo != null) {
            return true;
        } else {
            return false;
        }
    }

    public validateForm(): void {
        this.errorMessages = new Array<string>()
        this.validateOrdreKoscYearDateRdv();
        this.validateOrdreKoscMonthDateRdv();
    }

    public showCauseKoView() {
        let etat = this.selectedOrdreKosc.etatDemandeKoscVo;
        if (etat != null && etat.code == 'ko') {
            this.showCauseKo = true;
        }
    }

    getEtat(ordreKosc: OrdreKoscVo) {
        return this.ordreKoscService.getEtat(ordreKosc);
    }

    private initCol() {
        this.colsDevis = [
            {field: 'reference', header: 'Reference'},
            {field: 'referenceWorkOrder', header: 'Reference work order'},
            {field: 'operatorVo?.libelle', header: 'Operator'},
            {field: 'etatDemandeKosc?.libelle', header: 'Etat demande kosc'},
            {field: 'causeKoOkVo?.libelle', header: 'Type Ko'},
            {field: 'dateEnvoiCri', header: 'Date envoi cri'},
            {field: 'montantDevis', header: 'Montant devis'},
        ]
        this.cols = [
            {field: 'reference', header: 'Reference'},
            {field: 'referenceWorkOrder', header: 'Reference work order'},
            {field: 'supplierService', header: 'Supplier service'},
            {field: 'dateDebutTraitement', header: 'Date debut traitement'},
            {field: 'endCustumorName', header: 'End custumor name'},
            {field: 'endCustumorSiret', header: 'End custumor siret'},
            {field: 'endCustumorFirstName', header: 'End custumor first name'},
            {field: 'endCustumorLastName', header: 'End custumor last name'},
            {field: 'endCustumorZipcode', header: 'End custumor zipcode'},
            {field: 'endCustumorStreetName', header: 'End custumor street name'},
            {field: 'endCustumorStreetNumber', header: 'End custumor street number'},
            {field: 'endCustumorCity', header: 'End custumor city'},
            {field: 'endCustumorBuilding', header: 'End custumor building'},
            {field: 'endCustumorStairs', header: 'End custumor stairs'},
            {field: 'endCustumorFloor', header: 'End custumor floor'},
            {field: 'endCustumorContactFirstName', header: 'End custumor contact first name'},
            {field: 'endCustumorContactLastName', header: 'End custumor contact last name'},
            {field: 'endCustumorContactPhone', header: 'End custumor contact phone'},
            {field: 'endCustumorContactCellPhone', header: 'End custumor contact cell phone'},
            {field: 'endCustumorContactEmail', header: 'End custumor contact email'},
            {field: 'operator?.libelle', header: 'Operator'},
            {field: 'company', header: 'Company'},
            {field: 'koscContactEmail1', header: 'Referent dossier'},
            {field: 'submissionDate', header: 'Submission date'},
            {field: 'datePremierAppel', header: 'Date premier appel'},
            {field: 'dateDeuxiemeAppel', header: 'Date deuxieme appel'},
            {field: 'dateTroisiemeAppel', header: 'Date troisieme appel'},
            {field: 'datePriseRdv', header: 'Date prise rdv'},
            {field: 'dateRdv', header: 'Date rdv'},
            {field: 'dateAppelReplanification', header: 'Date appel replanification'},
            {field: 'dateInterventionTechnique', header: 'Date intervention technique'},
            {field: 'dateOuverture', header: 'Date ouverture'},
            {field: 'envoiMailClient', header: 'Envoi mail client'},
            {field: 'envoiMailKosc', header: 'Envoi mail kosc'},
            {field: 'coordonnePboY', header: 'Coordonne pbo y'},
            {field: 'hauteurPbo', header: 'Hauteur pbo'},
            {field: 'typeMaterielPbo', header: 'Type materiel pbo'},
            {field: 'typePbo', header: 'Type pbo'},
            {field: 'conditionSyndics', header: 'Condition syndics'},
            {field: 'typeRacordementPboPto', header: 'Type racordement pbo pto'},
            {field: 'codeAccesImmeuble', header: 'Code acces immeuble'},
            {field: 'contacteImmeuble', header: 'Contacte immeuble'},
            {field: 'pmaAccessible', header: 'Pma accessible'},
            {field: 'infoObtentionCle', header: 'Info obtention cle'},
            {field: 'localeIpm', header: 'Locale ipm'},
            {field: 'contactsSyndic', header: 'Contacts syndic'},
            {field: 'racordementLong', header: 'Racordement long'},
            {field: 'oc1', header: 'Oc1'},
            {field: 'nomModulePm1', header: 'Nom module pm1'},
            {field: 'positionModulePm1', header: 'Position module pm1'},
            {field: 'referenceCableModulePm1', header: 'Reference cable module pm1'},
            {field: 'referenceTubeModulePm1', header: 'Reference tube module pm1'},
            {field: 'informationFibreModulePm1', header: 'Information fibre module pm1'},
            {field: 'referenceCablePbo1', header: 'Reference cable pbo1'},
            {field: 'informationTubePbo1', header: 'Information tube pbo1'},
            {field: 'informationFibrePbo1', header: 'Information fibre pbo1'},
            {field: 'connecteurPriseNumero1', header: 'Connecteur prise numero1'},
            {field: 'connecteurPriseCouleur1', header: 'Connecteur prise couleur1'},
            {field: 'oc2', header: 'Oc2'},
            {field: 'nomModulePm2', header: 'Nom module pm2'},
            {field: 'positionModulePm2', header: 'Position module pm2'},
            {field: 'referenceCableModulePm2', header: 'Reference cable module pm2'},
            {field: 'referenceTubeModulePm2', header: 'Reference tube module pm2'},
            {field: 'informationFibreModulePm2', header: 'Information fibre module pm2'},
            {field: 'referenceCablePbo2', header: 'Reference cable pbo2'},
            {field: 'informationTubePbo2', header: 'Information tube pbo2'},
            {field: 'informationFibrePbo2', header: 'Information fibre pbo2'},
            {field: 'connecteurPriseNumero2', header: 'Connecteur prise numero2'},
            {field: 'connecteurPriseCouleur2', header: 'Connecteur prise couleur2'},
            {field: 'oc3', header: 'Oc3'},
            {field: 'nomModulePm3', header: 'Nom module pm3'},
            {field: 'positionModulePm3', header: 'Position module pm3'},
            {field: 'referenceCableModulePm3', header: 'Reference cable module pm3'},
            {field: 'referenceTubeModulePm3', header: 'Reference tube module pm3'},
            {field: 'informationFibreModulePm3', header: 'Information fibre module pm3'},
            {field: 'referenceCablePbo3', header: 'Reference cable pbo3'},
            {field: 'informationTubePbo3', header: 'Information tube pbo3'},
            {field: 'informationFibrePbo3', header: 'Information fibre pbo3'},
            {field: 'connecteurPriseNumero3', header: 'Connecteur prise numero3'},
            {field: 'connecteurPriseCouleur3', header: 'Connecteur prise couleur3'},
            {field: 'oc4', header: 'Oc4'},
            {field: 'nomModulePm4', header: 'Nom module pm4'},
            {field: 'positionModulePm4', header: 'Position module pm4'},
            {field: 'referenceCableModulePm4', header: 'Reference cable module pm4'},
            {field: 'referenceTubeModulePm4', header: 'Reference tube module pm4'},
            {field: 'informationFibreModulePm4', header: 'Information fibre module pm4'},
            {field: 'referenceCablePbo4', header: 'Reference cable pbo4'},
            {field: 'informationTubePbo4', header: 'Information tube pbo4'},
            {field: 'informationFibrePbo4', header: 'Information fibre pbo4'},
            {field: 'connecteurPriseNumero4', header: 'Connecteur prise numero4'},
            {field: 'connecteurPriseCouleur4', header: 'Connecteur prise couleur4'},
            {field: 'departement?.libelle', header: 'Departement'},
            {field: 'technicien?.identifiant', header: 'Technicien'},
            {field: 'dateEnvoiCri', header: 'Date envoi cri'},
            {field: 'pboReel', header: 'Pbo reel'},
            {field: 'numeroSerieOnt', header: 'Numero serie ont'},
            {field: 'workOrderType', header: 'Work order type'},
            {field: 'product', header: 'Product'},
            {field: 'provider', header: 'Provider'},
            {field: 'providerFileType', header: 'Provider file type'},
            {field: 'spliter', header: 'Spliter'},
            {field: 'existingOtp', header: 'Existing otp'},
            {field: 'profile', header: 'Profile'},
            {field: 'providerSlId', header: 'Provider sl id'},
            {field: 'referencePrestationPrise', header: 'Reference prestation prise'},
            {field: 'client?.id', header: 'Client'},
            {field: 'dateInterventionTechniqueDebut', header: 'Date intervention technique debut'},
            {field: 'dateInterventionTechniqueFin', header: 'Date intervention technique fin'},
            {field: 'templateEmailClientInjoinable?.libelle', header: 'Template email client injoinable'},
            {field: 'objetClient', header: 'Objet client'},
            {field: 'envoyeClient', header: 'Envoye client'},
            {field: 'dateEnvoiClient', header: 'Date envoi client'},
            {field: 'templateEmailClientInjoinableKosc?.libelle', header: 'Template email kosc'},
            {field: 'objetKosc', header: 'Objet kosc'},
            {field: 'envoyeKosc', header: 'Envoye kosc'},
            {field: 'dateEnvoiKosc', header: 'Date envoi kosc'},
            {field: 'templateEmailPlanification?.libelle', header: 'Template email planification'},
            {field: 'objetPlanification', header: 'Objet planification'},
            {field: 'envoyePlanification', header: 'Envoye planification'},
            {field: 'dateEnvoiPlanification', header: 'Date envoi planification'},
            {field: 'templateEmailReplanification?.libelle', header: 'Template email replanification'},
            {field: 'objetReplanification', header: 'Objet replanification'},
            {field: 'envoyeReplanification', header: 'Envoye replanification'},
            {field: 'dateEnvoiReplanification', header: 'Date envoi replanification'},
            {field: 'templateEmailReport?.libelle', header: 'Template email report'},
            {field: 'objetReport', header: 'Objet report'},
            {field: 'envoyeReport', header: 'Envoye report'},
            {field: 'dateEnvoiReport', header: 'Date envoi report'},
            {field: 'etatDemandeKosc?.libelle', header: 'Etat demande kosc'},
            {field: 'templateEmailCloture?.libelle', header: 'Template email cloture'},
            {field: 'objetCloture', header: 'Objet cloture'},
            {field: 'envoyeCloture', header: 'Envoye cloture'},
            {field: 'dateEnvoiCloture', header: 'Date envoi cloture'},
            {field: 'emailCloturePieceJoints', header: 'Email cloture piece joints'},
            {field: 'templateSuivi?.libelle', header: 'Template suivi'},
            {field: 'objetSuivi', header: 'Objet suivi'},
            {field: 'envoyeSuivi', header: 'Envoye suivi'},
            {field: 'dateEnvoiSuivi', header: 'Date envoi suivi'},
        ];
    }

    private validateOrdreKoscYearDateRdv() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.yearDateRdv)) {
            this.errorMessages.push('Annee non valide');
            this.validOrdreKoscYearDateRdv = false;
        } else {
            this.validOrdreKoscYearDateRdv = true;
        }
    }

    private validateOrdreKoscMonthDateRdv() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.monthDateRdv)) {
            this.errorMessages.push('Mois non valide');
            this.validOrdreKoscMonthDateRdv = false;
        } else {
            this.validOrdreKoscMonthDateRdv = true;
        }
    }
}
