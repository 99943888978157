import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-email-rappel-48-heures-admin',
    templateUrl: './template-email-rappel-48-heures-admin.component.html',
    styleUrls: ['./template-email-rappel-48-heures-admin.component.css']
})
export class TemplateEmailRappel48HeuresAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
