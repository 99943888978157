import {Component, OnInit} from '@angular/core';
import {TemplateEmailPropositionService} from 'src/app/controller/service/template/TemplateEmailProposition.service';
import {TemplateEmailPropositionVo} from 'src/app/controller/model/template/TemplateEmailProposition.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-proposition-create-admin',
    templateUrl: './template-email-proposition-create-admin.component.html',
    styleUrls: ['./template-email-proposition-create-admin.component.css']
})
export class TemplateEmailPropositionCreateAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private templateEmailPropositionService: TemplateEmailPropositionService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailPropositionLibelle = true;
    get validTemplateEmailPropositionLibelle(): boolean {
        return this._validTemplateEmailPropositionLibelle;
    }

    set validTemplateEmailPropositionLibelle(value: boolean) {
        this._validTemplateEmailPropositionLibelle = value;
    }

    _validTemplateEmailPropositionObjet = true;
    get validTemplateEmailPropositionObjet(): boolean {
        return this._validTemplateEmailPropositionObjet;
    }

    set validTemplateEmailPropositionObjet(value: boolean) {
        this._validTemplateEmailPropositionObjet = value;
    }

    _validTemplateEmailPropositionCorps = true;
    get validTemplateEmailPropositionCorps(): boolean {
        return this._validTemplateEmailPropositionCorps;
    }

    set validTemplateEmailPropositionCorps(value: boolean) {
        this._validTemplateEmailPropositionCorps = value;
    }

    get templateEmailPropositions(): Array<TemplateEmailPropositionVo> {
        return this.templateEmailPropositionService.templateEmailPropositions;
    }

    set templateEmailPropositions(value: Array<TemplateEmailPropositionVo>) {
        this.templateEmailPropositionService.templateEmailPropositions = value;
    }

    get selectedTemplateEmailProposition(): TemplateEmailPropositionVo {
        return this.templateEmailPropositionService.selectedTemplateEmailProposition;
    }

    set selectedTemplateEmailProposition(value: TemplateEmailPropositionVo) {
        this.templateEmailPropositionService.selectedTemplateEmailProposition = value;
    }

    get createTemplateEmailPropositionDialog(): boolean {
        return this.templateEmailPropositionService.createTemplateEmailPropositionDialog;
    }

    set createTemplateEmailPropositionDialog(value: boolean) {
        this.templateEmailPropositionService.createTemplateEmailPropositionDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.templateEmailPropositionService.save().subscribe(templateEmailProposition => {
            if (templateEmailProposition != null) {
                this.templateEmailPropositions.push({...templateEmailProposition});
                this.createTemplateEmailPropositionDialog = false;
                this.submitted = false;
                this.selectedTemplateEmailProposition = new TemplateEmailPropositionVo();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreurs',
                    detail: 'Template email proposition existe déjà'
                });
            }
        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createTemplateEmailPropositionDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailPropositionLibelle = value;
        this.validTemplateEmailPropositionObjet = value;
        this.validTemplateEmailPropositionCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailPropositionLibelle();
        this.validateTemplateEmailPropositionObjet();
        this.validateTemplateEmailPropositionCorps();
    }

    private validateTemplateEmailPropositionLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailProposition.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailPropositionLibelle = false;
        } else {
            this.validTemplateEmailPropositionLibelle = true;
        }
    }

    private validateTemplateEmailPropositionObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailProposition.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailPropositionObjet = false;
        } else {
            this.validTemplateEmailPropositionObjet = true;
        }
    }

    private validateTemplateEmailPropositionCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailProposition.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailPropositionCorps = false;
        } else {
            this.validTemplateEmailPropositionCorps = true;
        }
    }
}
