import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-email-refus-admin',
    templateUrl: './template-email-refus-admin.component.html',
    styleUrls: ['./template-email-refus-admin.component.css']
})
export class TemplateEmailRefusAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
