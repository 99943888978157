import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-entreprise-admin',
    templateUrl: './entreprise-admin.component.html',
    styleUrls: ['./entreprise-admin.component.css']
})
export class EntrepriseAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
