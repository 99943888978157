import {Component, OnInit} from '@angular/core';
import {PboReelService} from 'src/app/controller/service/referentiel/PboReel.service';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';
import {PboReelVo} from "../../../../../../controller/model/referentiel/PboReel";

@Component({
    selector: 'app-pbo-reel-create-admin',
    templateUrl: './pbo-reel-create-admin.component.html',
    styleUrls: ['./pbo-reel-create-admin.component.css']
})
export class PboReelCreateAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private pboReelService: PboReelService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validPboReelCode = true;
    get validPboReelCode(): boolean {
        return this._validPboReelCode;
    }

    set validPboReelCode(value: boolean) {
        this._validPboReelCode = value;
    }

    _validPboReelLibelle = true;
    get validPboReelLibelle(): boolean {
        return this._validPboReelLibelle;
    }

    set validPboReelLibelle(value: boolean) {
        this._validPboReelLibelle = value;
    }

    get pboReels(): Array<PboReelVo> {
        return this.pboReelService.pboReels;
    }

    set pboReels(value: Array<PboReelVo>) {
        this.pboReelService.pboReels = value;
    }

    get selectedPboReel(): PboReelVo {
        return this.pboReelService.selectedPboReel;
    }

    set selectedPboReel(value: PboReelVo) {
        this.pboReelService.selectedPboReel = value;
    }

    get createPboReelDialog(): boolean {
        return this.pboReelService.createPboReelDialog;
    }

    set createPboReelDialog(value: boolean) {
        this.pboReelService.createPboReelDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.pboReelService.save().subscribe(pboReel => {
            if (pboReel != null) {
                this.pboReels.push({...pboReel});
                this.createPboReelDialog = false;
                this.submitted = false;
                this.selectedPboReel = new PboReelVo();
            } else {
                this.messageService.add({severity: 'error', summary: 'Erreurs', detail: 'Pbo Reel existe déjà'});
            }
        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createPboReelDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validPboReelCode = value;
        this.validPboReelLibelle = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validatePboReelCode();
        this.validatePboReelLibelle();
    }

    private validatePboReelCode() {
        if (this.stringUtilService.isEmpty(this.selectedPboReel.code)) {
            this.errorMessages.push('Code non valide');
            this.validPboReelCode = false;
        } else {
            this.validPboReelCode = true;
        }
    }

    private validatePboReelLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedPboReel.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validPboReelLibelle = false;
        } else {
            this.validPboReelLibelle = true;
        }
    }
}
