import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-suivi-admin',
    templateUrl: './template-suivi-admin.component.html',
    styleUrls: ['./template-suivi-admin.component.css']
})
export class TemplateSuiviAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {

    }
}
