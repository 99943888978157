import {Component, OnInit} from '@angular/core';
import {OrdreKoscService} from 'src/app/controller/service/kosc/OrdreKosc.service';
import {OrdreKoscVo} from 'src/app/controller/model/kosc/OrdreKosc.model';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {DatePipe} from '@angular/common';
import {OperatorService} from 'src/app/controller/service/referentiel/Operator.service';
import {DepartementService} from 'src/app/controller/service/technicien/Departement.service';
import {TechnicienService} from 'src/app/controller/service/technicien/Technicien.service';
import {
    TemplateEmailClientInjoinableService
} from 'src/app/controller/service/template/TemplateEmailClientInjoinable.service';
import {
    TemplateEmailClientInjoinableKoscService
} from 'src/app/controller/service/template/TemplateEmailClientInjoinableKosc.service';
import {
    TemplateEmailPlanificationService
} from 'src/app/controller/service/template/TemplateEmailPlanification.service';
import {
    TemplateEmailReplanificationService
} from 'src/app/controller/service/template/TemplateEmailReplanification.service';
import {EtatDemandeKoscService} from 'src/app/controller/service/referentiel/EtatDemandeKosc.service';
import {TemplateEmailClotureService} from 'src/app/controller/service/template/TemplateEmailCloture.service';
import {TemplateSuiviService} from 'src/app/controller/service/template/TemplateSuivi.service';
import {TemplateEmailReplanificationVo} from 'src/app/controller/model/template/TemplateEmailReplanification.model';
import {EtatDemandeKoscVo} from 'src/app/controller/model/referentiel/EtatDemandeKosc.model';
import {TemplateEmailPlanificationVo} from 'src/app/controller/model/template/TemplateEmailPlanification.model';
import {TemplateEmailClotureVo} from 'src/app/controller/model/template/TemplateEmailCloture.model';
import {TemplateSuiviVo} from 'src/app/controller/model/template/TemplateSuivi.model';
import {OperatorVo} from 'src/app/controller/model/referentiel/Operator.model';
import {DepartementVo} from 'src/app/controller/model/technicien/Departement.model';
import {
    TemplateEmailClientInjoinableKoscVo
} from 'src/app/controller/model/template/TemplateEmailClientInjoinableKosc.model';
import {TemplateEmailClientInjoinableVo} from 'src/app/controller/model/template/TemplateEmailClientInjoinable.model';
import {TechnicienVo} from 'src/app/controller/model/technicien/Technicien.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AuthService} from 'src/app/controller/service/security/Auth.service';
import {ExportService} from 'src/app/controller/service/referentiel/Export.service';
import {StringUtilService} from "../../../../controller/service/referentiel/StringUtil.service";
import {
    DefaultTemplateConfigurationService
} from "../../../../controller/service/template/DefaultTemplateConfiguration.service";
import {DateUtils} from "../../../../utils/DateUtils";
import {DefaultTemplateConfigurationVo} from "../../../../controller/model/template/DefaultTemplateConfiguration.model";
import {StatutEmailVo} from "../../../../controller/model/template/StatutEmail.model";
import {StatutEmailService} from "../../../../controller/service/template/StatutEmail.service";

@Component({
    selector: 'app-ordre-kosc-rappel-48-heures-list-cdd',
    templateUrl: './ordre-kosc-rappel-48-heures-list-cdd.component.html',
    styleUrls: ['./ordre-kosc-rappel-48-heures-list-cdd.component.css']
})
export class OrdreKoscRappel48HeuresListCddComponent implements OnInit {
    findByCriteriaShow = false;
    blocked = false;
    cols: any[] = [];
    exportData: any[] = [];
    criteriaData: any[] = [];
    fileName = 'OrdreKosc';
    statutEmails: Array<StatutEmailVo>;
    operators: Array<OperatorVo>;
    departements: Array<DepartementVo>;
    techniciens: Array<TechnicienVo>;
    templateEmailClientInjoinables: Array<TemplateEmailClientInjoinableVo>;
    templateEmailClientInjoinableKoscs: Array<TemplateEmailClientInjoinableKoscVo>;
    templateEmailPlanifications: Array<TemplateEmailPlanificationVo>;
    templateEmailReplanifications: Array<TemplateEmailReplanificationVo>;
    etatDemandeKoscs: Array<EtatDemandeKoscVo>;
    templateEmailClotures: Array<TemplateEmailClotureVo>;
    templateSuivis: Array<TemplateSuiviVo>;
    items: MenuItem[];
    home: MenuItem;
    isLoading: boolean = false;

    constructor(private datePipe: DatePipe, private ordreKoscService: OrdreKoscService, private messageService: MessageService, private confirmationService: ConfirmationService, private roleService: RoleService, private router: Router, private authService: AuthService, private exportService: ExportService
        , private operatorService: OperatorService
        , private statutEmailService: StatutEmailService
        , private departementService: DepartementService
        , private technicienService: TechnicienService
        , private templateEmailClientInjoinableService: TemplateEmailClientInjoinableService
        , private templateEmailClientInjoinableKoscService: TemplateEmailClientInjoinableKoscService
        , private templateEmailPlanificationService: TemplateEmailPlanificationService
        , private templateEmailReplanificationService: TemplateEmailReplanificationService
        , private etatDemandeKoscService: EtatDemandeKoscService
        , private templateEmailClotureService: TemplateEmailClotureService
        , private templateSuiviService: TemplateSuiviService
        , private stringUtilService: StringUtilService
        , private defaultTemplateConfigurationService: DefaultTemplateConfigurationService,
    ) {
    }

    get dateFormat() {
        return environment.dateFormatList;
    }

    get ordreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviRdv;
    }

    set ordreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviRdv = value;
    }

    get preparedOrdreKoscs(): Array<OrdreKoscVo> {
        return this.ordreKoscService.preparedOrdreKoscs;
    }

    set preparedOrdreKoscs(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.preparedOrdreKoscs = value;
    }

    get ordreKoscSelections(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscSelections;
    }

    set ordreKoscSelections(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscSelections = value;
    }

    get selectedOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.selectedOrdreKosc;
    }

    set selectedOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.selectedOrdreKosc = value;
    }

    get createOrdreKoscDialog(): boolean {
        return this.ordreKoscService.createOrdreKoscDialog;
    }

    set createOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.createOrdreKoscDialog = value;
    }

    get editOrdreKoscDialog(): boolean {
        return this.ordreKoscService.editOrdreKoscDialog;
    }

    set editOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.editOrdreKoscDialog = value;
    }

    get viewOrdreKoscDialog(): boolean {
        return this.ordreKoscService.viewOrdreKoscDialog;
    }

    set viewOrdreKoscDialog(value: boolean) {
        this.ordreKoscService.viewOrdreKoscDialog = value;
    }

    get searchOrdreKosc(): OrdreKoscVo {
        return this.ordreKoscService.searchOrdreKoscSuiviRdv;
    }

    set searchOrdreKosc(value: OrdreKoscVo) {
        this.ordreKoscService.searchOrdreKoscSuiviRdv = value;
    }

    get searchOrdreKoscSuiviRdv(): OrdreKoscVo {
        return this.ordreKoscService.searchOrdreKoscSuiviRdv;
    }

    set searchOrdreKoscSuiviRdv(value: OrdreKoscVo) {
        this.ordreKoscService.searchOrdreKoscSuiviRdv = value;
    }

    get showSpinner(): boolean {
        return this.ordreKoscService.showSpinner;
    }

    set showSpinner(value: boolean) {
        this.ordreKoscService.showSpinner = value;
    }

    get totalRecords(): number {
        return this.ordreKoscService.totalRecords;
    }

    set totalRecords(value: number) {
        this.ordreKoscService.totalRecords = value;
    }

    get ordreKoscsSuiviRdv(): Array<OrdreKoscVo> {
        return this.ordreKoscService.ordreKoscsSuiviRdv;
    }

    set ordreKoscsSuiviRdv(value: Array<OrdreKoscVo>) {
        this.ordreKoscService.ordreKoscsSuiviRdv = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    private _validOrdreKoscFromRappel48Heures = true;

    get validOrdreKoscFromRappel48Heures(): boolean {
        return this._validOrdreKoscFromRappel48Heures;
    }

    set validOrdreKoscFromRappel48Heures(value: boolean) {
        this._validOrdreKoscFromRappel48Heures = value;
    }

    private _validOrdreKoscToRappel48Heures = true;

    get validOrdreKoscToRappel48Heures(): boolean {
        return this._validOrdreKoscToRappel48Heures;
    }

    set validOrdreKoscToRappel48Heures(value: boolean) {
        this._validOrdreKoscToRappel48Heures = value;
    }

    private _validOrdreKoscObjetRappel48Heures = true;

    get validOrdreKoscObjetRappel48Heures(): boolean {
        return this._validOrdreKoscObjetRappel48Heures;
    }

    set validOrdreKoscObjetRappel48Heures(value: boolean) {
        this._validOrdreKoscObjetRappel48Heures = value;
    }

    private _validOrdreKoscCorpsRappel48Heures = true;

    get validOrdreKoscCorpsRappel48Heures(): boolean {
        return this._validOrdreKoscCorpsRappel48Heures;
    }

    set validOrdreKoscCorpsRappel48Heures(value: boolean) {
        this._validOrdreKoscCorpsRappel48Heures = value;
    }

    get selectedDefaultTemplateConfiguration(): DefaultTemplateConfigurationVo {
        return this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration;
    }

    set selectedDefaultTemplateConfiguration(value: DefaultTemplateConfigurationVo) {
        this.defaultTemplateConfigurationService.selectedDefaultTemplateConfiguration = value;
    }

    ngOnInit(): void {
        this.items = [{label: 'Rappel J+2', routerLink: '/app/cdd/rappel-48-heures-component'}];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
        this.loadEtatDemandeKoscIncluding(['planifié', 'report-demande-maneo-cl-j-accepte', 'report-demande-client-cl-j', 'report-demande-maneo-cl-inj-replanifié', 'report-demande-maneo-cl-j-refus-replanifié', 'report-demande-client-cl-inj-replanifié']);
        this.initCol();
        this.loadOperator();
        this.loadStatutEmail();
        this.loadDepartement();
        this.loadTechnicien();
        this.loadTemplateEmailClientInjoinable();
        this.loadTemplateEmailClientInjoinableKosc();
        this.loadTemplateEmailPlanification();
        this.loadTemplateEmailReplanification();
        this.loadTemplateEmailCloture();
        this.loadTemplateSuivi();
        this.ordreKoscs = new Array<OrdreKoscVo>();
        this.preparedOrdreKoscs = new Array<OrdreKoscVo>();
        this.defaultTemplateConfigurationService.findDefaultTemplateConfiguration().subscribe((data) => this.selectedDefaultTemplateConfiguration = data,);
    }

    public searchBetweenHour(nbrHeureMin: number, nbrHeureMax: number) {
        this.searchOrdreKosc.nbrHeureDateRdvAndNowMin = nbrHeureMin;
        this.searchOrdreKosc.nbrHeureDateRdvAndNowMax = nbrHeureMax;
    }

    stylefyConfort(ordreKosc: OrdreKoscVo): string {
        return ordreKosc.confort ? 'color:red;' : 'color:black;';
    }

    isErdvAndReferenceEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.reference != null) {
            return true;
        } else {
            return false;
        }
    }

    isErdvAndReferencWorkOrdereEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.referenceWorkOrder != null) {
            return true;
        } else {
            return false;
        }
    }

    isEtatNotEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.etatDemandeKoscVo !== null) {
            return true;
        } else {
            return false;
        }
    }

    isSatutEmailNotEmpty(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.statutEmailVo !== null) {
            return true;
        } else {
            return false;
        }
    }

    public async loadOrdreKoscs() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.ordreKoscService.findSuivi().subscribe(ordreKoscs => this.ordreKoscs = ordreKoscs, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'});
    }

    public async editOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'edit');
        if (isPermistted) {
            this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(res => {
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.dateDebutTraitement = DateUtils.toDate(ordreKosc.dateDebutTraitement);
                this.selectedOrdreKosc.submissionDate = DateUtils.toDate(ordreKosc.submissionDate);
                this.selectedOrdreKosc.datePremierAppel = DateUtils.toDate(ordreKosc.datePremierAppel);
                this.selectedOrdreKosc.dateDeuxiemeAppel = DateUtils.toDate(ordreKosc.dateDeuxiemeAppel);
                this.selectedOrdreKosc.dateTroisiemeAppel = DateUtils.toDate(ordreKosc.dateTroisiemeAppel);
                this.selectedOrdreKosc.datePriseRdv = DateUtils.toDate(ordreKosc.datePriseRdv);
                this.selectedOrdreKosc.dateRdv = DateUtils.toDate(ordreKosc.dateRdv);
                this.selectedOrdreKosc.dateRdvFinale = DateUtils.toDate(ordreKosc.dateRdvFinale);
                this.selectedOrdreKosc.dateAppelReplanification = DateUtils.toDate(ordreKosc.dateAppelReplanification);
                this.selectedOrdreKosc.dateOuverture = DateUtils.toDate(ordreKosc.dateOuverture);
                this.selectedOrdreKosc.dateEnvoiCri = DateUtils.toDate(ordreKosc.dateEnvoiCri);
                this.selectedOrdreKosc.dateInterventionTechniqueFin = DateUtils.toDate(ordreKosc.dateInterventionTechniqueFin);
                this.selectedOrdreKosc.dateEnvoiClientInjoinable = DateUtils.toDate(ordreKosc.dateEnvoiClientInjoinable);
                this.selectedOrdreKosc.dateEnvoiClientInjoinableKosc = DateUtils.toDate(ordreKosc.dateEnvoiClientInjoinableKosc);
                this.selectedOrdreKosc.dateEnvoiPlanification = DateUtils.toDate(ordreKosc.dateEnvoiPlanification);
                this.selectedOrdreKosc.dateEnvoiReplanification = DateUtils.toDate(ordreKosc.dateEnvoiReplanification);
                this.selectedOrdreKosc.dateEnvoiCloture = DateUtils.toDate(ordreKosc.dateEnvoiCloture);
                this.selectedOrdreKosc.dateEnvoiSuivi = DateUtils.toDate(ordreKosc.dateEnvoiSuivi)
                this.editOrdreKoscDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreur', detail: 'Probléme de permission'
            });
        }
    }

    public async viewOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'view');
        if (isPermistted) {
            this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(res => {
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.dateDebutTraitement = DateUtils.toDate(ordreKosc.dateDebutTraitement);
                this.selectedOrdreKosc.submissionDate = DateUtils.toDate(ordreKosc.submissionDate);
                this.selectedOrdreKosc.datePremierAppel = DateUtils.toDate(ordreKosc.datePremierAppel);
                this.selectedOrdreKosc.dateDeuxiemeAppel = DateUtils.toDate(ordreKosc.dateDeuxiemeAppel);
                this.selectedOrdreKosc.dateTroisiemeAppel = DateUtils.toDate(ordreKosc.dateTroisiemeAppel);
                this.selectedOrdreKosc.datePriseRdv = DateUtils.toDate(ordreKosc.datePriseRdv);
                this.selectedOrdreKosc.dateRdv = DateUtils.toDate(ordreKosc.dateRdv);
                this.selectedOrdreKosc.dateAppelReplanification = DateUtils.toDate(ordreKosc.dateAppelReplanification);
                this.selectedOrdreKosc.dateOuverture = DateUtils.toDate(ordreKosc.dateOuverture);
                this.selectedOrdreKosc.dateEnvoiCri = DateUtils.toDate(ordreKosc.dateEnvoiCri);
                this.selectedOrdreKosc.dateInterventionTechniqueFin = DateUtils.toDate(ordreKosc.dateInterventionTechniqueFin);
                this.selectedOrdreKosc.dateEnvoiClientInjoinable = DateUtils.toDate(ordreKosc.dateEnvoiClientInjoinable);
                this.selectedOrdreKosc.dateEnvoiClientInjoinableKosc = DateUtils.toDate(ordreKosc.dateEnvoiClientInjoinableKosc);
                this.selectedOrdreKosc.dateEnvoiPlanification = DateUtils.toDate(ordreKosc.dateEnvoiPlanification);
                this.selectedOrdreKosc.dateEnvoiReplanification = DateUtils.toDate(ordreKosc.dateEnvoiReplanification);
                this.selectedOrdreKosc.dateEnvoiCloture = DateUtils.toDate(ordreKosc.dateEnvoiCloture);
                this.selectedOrdreKosc.dateEnvoiSuivi = DateUtils.toDate(ordreKosc.dateEnvoiSuivi);
                this.viewOrdreKoscDialog = true;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async openCreateOrdreKosc(pojo: string) {
        const isPermistted = await this.roleService.isPermitted(pojo, 'add');
        if (isPermistted) {
            this.selectedOrdreKosc = new OrdreKoscVo();
            this.createOrdreKoscDialog = true;
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'problème d\'autorisation'
            });
        }
    }

    public async deleteOrdreKosc(ordreKosc: OrdreKoscVo) {
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'delete');
        if (isPermistted) {
            this.confirmationService.confirm({
                message: 'Voulez-vous supprimer cet élément (Ordre kosc) ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.ordreKoscService.delete(ordreKosc).subscribe(status => {
                        if (status > 0) {
                            const position = this.ordreKoscs.indexOf(ordreKosc);
                            position > -1 ? this.ordreKoscs.splice(position, 1) : false;
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'Ordre kosc Supprimé',
                                life: 3000
                            });
                        }

                    }, error => console.log(error))
                }
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'erreur', detail: 'Problème de permission'
            });
        }
    }

    public async loadStatutEmail() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.statutEmailService.findAll().subscribe(statutEmails => this.statutEmails = statutEmails, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadOperator() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.operatorService.findAll().subscribe(operators => this.operators = operators, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadDepartement() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.departementService.findAll().subscribe(departements => this.departements = departements, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTechnicien() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.technicienService.findAll().subscribe(techniciens => this.techniciens = techniciens, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateEmailClientInjoinable() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateEmailClientInjoinableService.findAll().subscribe(templateEmailClientInjoinables => this.templateEmailClientInjoinables = templateEmailClientInjoinables, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateEmailClientInjoinableKosc() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateEmailClientInjoinableKoscService.findAll().subscribe(templateEmailKoscs => this.templateEmailClientInjoinableKoscs = templateEmailKoscs, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateEmailPlanification() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateEmailPlanificationService.findAll().subscribe(templateEmailPlanifications => this.templateEmailPlanifications = templateEmailPlanifications, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateEmailReplanification() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateEmailReplanificationService.findAll().subscribe(templateEmailReplanifications => this.templateEmailReplanifications = templateEmailReplanifications, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateEmailCloture() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateEmailClotureService.findAll().subscribe(templateEmailClotures => this.templateEmailClotures = templateEmailClotures, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async loadTemplateSuivi() {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.templateSuiviService.findAll().subscribe(templateSuivis => this.templateSuivis = templateSuivis, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    public async duplicateOrdreKosc(ordreKosc: OrdreKoscVo) {
        this.ordreKoscService.findByIdWithAssociatedList(ordreKosc).subscribe(
            res => {
                this.initDuplicateOrdreKosc(res);
                this.selectedOrdreKosc = res;
                this.selectedOrdreKosc.id = null;
                this.createOrdreKoscDialog = true;
            });
    }

    initDuplicateOrdreKosc(res: OrdreKoscVo) {
    }

    erdvAndConfort(ordreKoscVo: OrdreKoscVo) {
        if (ordreKoscVo.erdv == true && ordreKoscVo.confort)
            return true;
        else
            return false;
    }

    public onPage(event: any) {
        this.searchOrdreKosc.page = event.page;
        this.searchOrdreKosc.maxResults = event.rows;
        this.searchRequestRappel48Heures();
    }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
    }

    public async findByCriteriaSuiviRdvToExport(): Promise<void> {
        try {
            const preparedOrdreKoscs = await this.ordreKoscService.findByCriteriaSuiviRdv(this.searchOrdreKosc).toPromise();
            this.preparedOrdreKoscs = preparedOrdreKoscs;
            this.ordreKoscs = preparedOrdreKoscs;
            this.totalRecords = preparedOrdreKoscs.length;
        } catch (error) {
            console.log(error);
        }
    }

    public searchRequestRappel48Heures() {
        this.showSpinner = true;
        this.searchOrdreKosc.dateRdvMin = this.formatDate(this.searchOrdreKosc.dateRdvMinAsDate)
        this.searchOrdreKosc.dateRdvMax = this.formatDate(this.searchOrdreKosc.dateRdvMaxAsDate)
        this.searchOrdreKosc.dateRdvFinaleMin = this.formatDate(this.searchOrdreKosc.dateRdvFinaleMinAsDate)
        this.searchOrdreKosc.dateRdvFinaleMax = this.formatDate(this.searchOrdreKosc.dateRdvFinaleMaxAsDate)
        this.searchOrdreKosc.submissionDateMin = this.formatDate(this.searchOrdreKosc.submissionDateMinAsDate)
        this.searchOrdreKosc.submissionDateMax = this.formatDate(this.searchOrdreKosc.submissionDateMaxAsDate)
        this.ordreKoscService.findByCriteriaRappel48HeuresPaginated(this.searchOrdreKosc).subscribe(ordreKoscs => {
            this.ordreKoscs = ordreKoscs.list;
            this.totalRecords = ordreKoscs.dataSize;
            // this.searchOrdreKosc = new OrdreKoscVo();
            this.showSpinner = false;
        }, error => {
            console.log(error);
            this.showSpinner = false;
        });
        return this.ordreKoscs;
    }

    public async loadEtatDemandeKoscIncluding(etatNonDesire: Array<String>) {
        await this.roleService.findAll();
        const isPermistted = await this.roleService.isPermitted('OrdreKosc', 'list');
        isPermistted ? this.etatDemandeKoscService.findAll().subscribe(etatDemandeKoscs => {
                this.etatDemandeKoscs = etatDemandeKoscs;
                this.searchOrdreKosc.etatDemandeKoscVos = this.etatDemandeKoscs.filter(e => etatNonDesire.indexOf(e.code) != -1);
            }, error => console.log(error))
            : this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Problème de permission'});
    }

    nbrHeureDateSubmissionAndNowSeverityStyle(ordreKosc: OrdreKoscVo) {
        return (ordreKosc.nbrHeureDateSubmissionAndNow > 48 && ordreKosc.nbrHeureDateSubmissionAndNow <= 72) ? 'danger' : (ordreKosc.nbrHeureDateSubmissionAndNow > 24 && ordreKosc.nbrHeureDateSubmissionAndNow <= 48) ? 'warning' : ordreKosc.nbrHeureDateSubmissionAndNow <= 24 ? 'success' : 'info';
    }

    nbrHeureDateRdvAndNowSeverityStyle(ordreKosc: OrdreKoscVo) {
        return (ordreKosc.nbrHeureDateRdvAndNow > 48 && ordreKosc.nbrHeureDateRdvAndNow <= 72) ? 'danger' : (ordreKosc.nbrHeureDateRdvAndNow > 24 && ordreKosc.nbrHeureDateRdvAndNow <= 48) ? 'warning' : ordreKosc.nbrHeureDateRdvAndNow <= 24 ? 'success' : 'info';
    }

    getEtat(ordreKosc: OrdreKoscVo) {
        return this.ordreKoscService.getEtat(ordreKosc);
    }

    sendMailRappel48Heures(ordreKoscSelections: OrdreKoscVo[]) {
        for (const e of ordreKoscSelections) {
            e.dateDebutTraitement = DateUtils.toDate(e.dateDebutTraitement);
            e.submissionDate = DateUtils.toDate(e.submissionDate);
            e.datePremierAppel = DateUtils.toDate(e.datePremierAppel);
            e.dateDeuxiemeAppel = DateUtils.toDate(e.dateDeuxiemeAppel);
            e.dateTroisiemeAppel = DateUtils.toDate(e.dateTroisiemeAppel);
            e.datePriseRdv = DateUtils.toDate(e.datePriseRdv);
            e.dateRdv = DateUtils.toDate(e.dateRdv);
            e.dateRdvFinale = DateUtils.toDate(e.dateRdvFinale);
            e.dateOuverture = DateUtils.toDate(e.dateOuverture);
            e.dateErdv = DateUtils.toDate(e.dateErdv);
            e.dateCri = DateUtils.toDate(e.dateCri);
            e.dateRdvErdvInit = DateUtils.toDate(e.dateRdvErdvInit);
            e.dateRdvReelle = DateUtils.toDate(e.dateRdvReelle);
            e.dateReceptionCri = DateUtils.toDate(e.dateReceptionCri);
        }
        if (this.errorMessages.length === 0) {
            this.showSpinner = true;
            this.blocked = true;
            this.ordreKoscService.sendMailRappel48Heures(ordreKoscSelections).subscribe(data => {
                let failedOrders: string[] = [];
                for (const order of data) {
                    if (!order.envoyeRappel48Heures) {
                        failedOrders.push(order.referenceWorkOrder);
                    }
                }
                if (failedOrders.length === 0) {
                    this.messageService.add({
                        severity: 'success', summary: 'Success', detail: 'Emails envoyés avec succès',
                    });
                } else {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Warning',
                        detail: `Échec de l'envoi pour les commandes suivantes : ${failedOrders.join(', ')}`,
                    });
                }
                this.showSpinner = false;
                this.blocked = false;
                this.editOrdreKoscDialog = false;
            });
        } else {
            this.messageService.add({
                severity: 'error', summary: 'Erreurs', detail: 'Merci de corriger les erreurs sur le formulaire'
            });
        }
    }

    public formatDdMmYy(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'dd/MM/yyyy') : '';
    }

    public formatHhMm(date: Date): string {
        return date != null ? this.datePipe.transform(date, 'HH:mm') : '';
    }

    private initCol() {
        this.cols = [
            {field: 'reference', header: 'Reference'},
            {field: 'referenceWorkOrder', header: 'Reference work order'},
            {field: 'codeDecharge', header: 'Code decharge'},
            {field: 'supplierService', header: 'Supplier service'},
            {field: 'dateDebutTraitement', header: 'Date debut traitement'},
            {field: 'endCustumorName', header: 'End custumor name'},
            {field: 'endCustumorSiret', header: 'End custumor siret'},
            {field: 'endCustumorFirstName', header: 'End custumor first name'},
            {field: 'endCustumorLastName', header: 'End custumor last name'},
            {field: 'endCustumorZipcode', header: 'End custumor zipcode'},
            {field: 'endCustumorStreetName', header: 'End custumor street name'},
            {field: 'endCustumorStreetNumber', header: 'End custumor street number'},
            {field: 'endCustumorCity', header: 'End custumor city'},
            {field: 'endCustumorBuilding', header: 'End custumor building'},
            {field: 'endCustumorStairs', header: 'End custumor stairs'},
            {field: 'endCustumorFloor', header: 'End custumor floor'},
            {field: 'endCustumorContactFirstName', header: 'End custumor contact first name'},
            {field: 'endCustumorContactLastName', header: 'End custumor contact last name'},
            {field: 'endCustumorContactPhone', header: 'End custumor contact phone'},
            {field: 'endCustumorContactCellPhone', header: 'End custumor contact cell phone'},
            {field: 'endCustumorContactEmail', header: 'End custumor contact email'},
            {field: 'operator?.libelle', header: 'Operator'},
            {field: 'company', header: 'Company'},
            {field: 'koscContactEmail1', header: 'Referent dossier'},
            {field: 'submissionDate', header: 'Submission date'},
            {field: 'datePremierAppel', header: 'Date premier appel'},
            {field: 'dateDeuxiemeAppel', header: 'Date deuxieme appel'},
            {field: 'dateTroisiemeAppel', header: 'Date troisieme appel'},
            {field: 'datePriseRdv', header: 'Date prise rdv'},
            {field: 'dateRdv', header: 'Date rdv'},
            {field: 'dateAppelReplanification', header: 'Date appel replanification'},
            {field: 'dateInterventionTechnique', header: 'Date intervention technique'},
            {field: 'dateOuverture', header: 'Date ouverture'},
            {field: 'envoiMailClient', header: 'Envoi mail client'},
            {field: 'envoiMailKosc', header: 'Envoi mail kosc'},
            {field: 'coordonnePboY', header: 'Coordonne pbo y'},
            {field: 'hauteurPbo', header: 'Hauteur pbo'},
            {field: 'typeMaterielPbo', header: 'Type materiel pbo'},
            {field: 'typePbo', header: 'Type pbo'},
            {field: 'conditionSyndics', header: 'Condition syndics'},
            {field: 'typeRacordementPboPto', header: 'Type racordement pbo pto'},
            {field: 'codeAccesImmeuble', header: 'Code acces immeuble'},
            {field: 'contacteImmeuble', header: 'Contacte immeuble'},
            {field: 'pmaAccessible', header: 'Pma accessible'},
            {field: 'infoObtentionCle', header: 'Info obtention cle'},
            {field: 'localeIpm', header: 'Locale ipm'},
            {field: 'contactsSyndic', header: 'Contacts syndic'},
            {field: 'racordementLong', header: 'Racordement long'},
            {field: 'oc1', header: 'Oc1'},
            {field: 'nomModulePm1', header: 'Nom module pm1'},
            {field: 'positionModulePm1', header: 'Position module pm1'},
            {field: 'referenceCableModulePm1', header: 'Reference cable module pm1'},
            {field: 'referenceTubeModulePm1', header: 'Reference tube module pm1'},
            {field: 'informationFibreModulePm1', header: 'Information fibre module pm1'},
            {field: 'referenceCablePbo1', header: 'Reference cable pbo1'},
            {field: 'informationTubePbo1', header: 'Information tube pbo1'},
            {field: 'informationFibrePbo1', header: 'Information fibre pbo1'},
            {field: 'connecteurPriseNumero1', header: 'Connecteur prise numero1'},
            {field: 'connecteurPriseCouleur1', header: 'Connecteur prise couleur1'},
            {field: 'oc2', header: 'Oc2'},
            {field: 'nomModulePm2', header: 'Nom module pm2'},
            {field: 'positionModulePm2', header: 'Position module pm2'},
            {field: 'referenceCableModulePm2', header: 'Reference cable module pm2'},
            {field: 'referenceTubeModulePm2', header: 'Reference tube module pm2'},
            {field: 'informationFibreModulePm2', header: 'Information fibre module pm2'},
            {field: 'referenceCablePbo2', header: 'Reference cable pbo2'},
            {field: 'informationTubePbo2', header: 'Information tube pbo2'},
            {field: 'informationFibrePbo2', header: 'Information fibre pbo2'},
            {field: 'connecteurPriseNumero2', header: 'Connecteur prise numero2'},
            {field: 'connecteurPriseCouleur2', header: 'Connecteur prise couleur2'},
            {field: 'oc3', header: 'Oc3'},
            {field: 'nomModulePm3', header: 'Nom module pm3'},
            {field: 'positionModulePm3', header: 'Position module pm3'},
            {field: 'referenceCableModulePm3', header: 'Reference cable module pm3'},
            {field: 'referenceTubeModulePm3', header: 'Reference tube module pm3'},
            {field: 'informationFibreModulePm3', header: 'Information fibre module pm3'},
            {field: 'referenceCablePbo3', header: 'Reference cable pbo3'},
            {field: 'informationTubePbo3', header: 'Information tube pbo3'},
            {field: 'informationFibrePbo3', header: 'Information fibre pbo3'},
            {field: 'connecteurPriseNumero3', header: 'Connecteur prise numero3'},
            {field: 'connecteurPriseCouleur3', header: 'Connecteur prise couleur3'},
            {field: 'oc4', header: 'Oc4'},
            {field: 'nomModulePm4', header: 'Nom module pm4'},
            {field: 'positionModulePm4', header: 'Position module pm4'},
            {field: 'referenceCableModulePm4', header: 'Reference cable module pm4'},
            {field: 'referenceTubeModulePm4', header: 'Reference tube module pm4'},
            {field: 'informationFibreModulePm4', header: 'Information fibre module pm4'},
            {field: 'referenceCablePbo4', header: 'Reference cable pbo4'},
            {field: 'informationTubePbo4', header: 'Information tube pbo4'},
            {field: 'informationFibrePbo4', header: 'Information fibre pbo4'},
            {field: 'connecteurPriseNumero4', header: 'Connecteur prise numero4'},
            {field: 'connecteurPriseCouleur4', header: 'Connecteur prise couleur4'},
            {field: 'departement?.libelle', header: 'Departement'},
            {field: 'technicien?.identifiant', header: 'Technicien'},
            {field: 'dateEnvoiCri', header: 'Date envoi cri'},
            {field: 'pboReel', header: 'Pbo reel'},
            {field: 'numeroSerieOnt', header: 'Numero serie ont'},
            {field: 'workOrderType', header: 'Work order type'},
            {field: 'product', header: 'Product'},
            {field: 'provider', header: 'Provider'},
            {field: 'providerFileType', header: 'Provider file type'},
            {field: 'spliter', header: 'Spliter'},
            {field: 'existingOtp', header: 'Existing otp'},
            {field: 'profile', header: 'Profile'},
            {field: 'providerSlId', header: 'Provider sl id'},
            {field: 'referencePrestationPrise', header: 'Reference prestation prise'},
            {field: 'client?.id', header: 'Client'},
            {field: 'dateInterventionTechniqueDebut', header: 'Date intervention technique debut'},
            {field: 'dateInterventionTechniqueFin', header: 'Date intervention technique fin'},
            {field: 'templateEmailClientInjoinable?.libelle', header: 'Template email client injoinable'},
            {field: 'objetClient', header: 'Objet client'},
            {field: 'envoyeClient', header: 'Envoye client'},
            {field: 'dateEnvoiClient', header: 'Date envoi client'},
            {field: 'templateEmailKosc?.libelle', header: 'Template email kosc'},
            {field: 'objetKosc', header: 'Objet kosc'},
            {field: 'envoyeKosc', header: 'Envoye kosc'},
            {field: 'dateEnvoiKosc', header: 'Date envoi kosc'},
            {field: 'templateEmailPlanification?.libelle', header: 'Template email planification'},
            {field: 'objetPlanification', header: 'Objet planification'},
            {field: 'envoyePlanification', header: 'Envoye planification'},
            {field: 'dateEnvoiPlanification', header: 'Date envoi planification'},
            {field: 'templateEmailReplanification?.libelle', header: 'Template email replanification'},
            {field: 'objetReplanification', header: 'Objet replanification'},
            {field: 'envoyeReplanification', header: 'Envoye replanification'},
            {field: 'dateEnvoiReplanification', header: 'Date envoi replanification'},
            {field: 'templateEmailReport?.libelle', header: 'Template email report'},
            {field: 'objetReport', header: 'Objet report'},
            {field: 'envoyeReport', header: 'Envoye report'},
            {field: 'dateEnvoiReport', header: 'Date envoi report'},
            {field: 'etatDemandeKosc?.libelle', header: 'Etat demande kosc'},
            {field: 'templateEmailCloture?.libelle', header: 'Template email cloture'},
            {field: 'objetCloture', header: 'Objet cloture'},
            {field: 'envoyeCloture', header: 'Envoye cloture'},
            {field: 'dateEnvoiCloture', header: 'Date envoi cloture'},
            {field: 'emailCloturePieceJoints', header: 'Email cloture piece joints'},
            {field: 'templateSuivi?.libelle', header: 'Template suivi'},
            {field: 'objetSuivi', header: 'Objet suivi'},
            {field: 'envoyeSuivi', header: 'Envoye suivi'},
            {field: 'dateEnvoiSuivi', header: 'Date envoi suivi'},
        ];
    }

    private deleteFromList(selectedOrdreKosc: OrdreKoscVo) {
        const position = this.ordreKoscs.findIndex(e => e.id == selectedOrdreKosc.id);
        position > -1 ? this.ordreKoscs.splice(position, 1) : false;
    }

    private validateFormRappel48Heures(): void {
        this.errorMessages = new Array<string>();
        this.validateOrdreKoscObjetRappel48Heures();
        this.validateOrdreKoscCorpsRappel48Heures();
        this.validateOrdreKoscFromRappel48Heures();
        this.validateOrdreKoscToRappel48Heures();
    }

    private validateOrdreKoscObjetRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.objetRappel48Heures)) {
            this.errorMessages.push('Objet rappel 48 heures non valide');
            this.validOrdreKoscObjetRappel48Heures = false;
        } else {
            this.validOrdreKoscObjetRappel48Heures = true;
        }
    }

    private validateOrdreKoscCorpsRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.corpsRappel48Heures)) {
            this.errorMessages.push('Corps rappel 48 heures non valide');
            this.validOrdreKoscCorpsRappel48Heures = false;
        } else {
            this.validOrdreKoscCorpsRappel48Heures = true;
        }
    }

    private validateOrdreKoscFromRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.fromRappel48Heures)) {
            this.errorMessages.push('De rappel 48 heures non valide');
            this.validOrdreKoscFromRappel48Heures = false;
        } else {
            this.validOrdreKoscFromRappel48Heures = true;
        }
    }

    private validateOrdreKoscToRappel48Heures() {
        if (this.stringUtilService.isEmpty(this.selectedOrdreKosc.toRappel48Heures)) {
            this.errorMessages.push('A rappel 48 heures non valide');
            this.validOrdreKoscToRappel48Heures = false;
        } else {
            this.validOrdreKoscToRappel48Heures = true;
        }
    }
}
