import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailReportDemandeClientClientInjoignableService
} from 'src/app/controller/service/template/TemplateEmailReportDemandeClientClientInjoignable.service';
import {
    TemplateEmailReportDemandeClientClientInjoignableVo
} from 'src/app/controller/model/template/TemplateEmailReportDemandeClientClientInjoignable.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-report-demande-client-client-injoignable-create-admin',
    templateUrl: './template-email-report-demande-client-client-injoignable-create-admin.component.html',
    styleUrls: ['./template-email-report-demande-client-client-injoignable-create-admin.component.css']
})
export class TemplateEmailReportDemandeClientClientInjoignableCreateAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private templateEmailReportDemandeClientClientInjoignableService: TemplateEmailReportDemandeClientClientInjoignableService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;
    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();
    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailReportDemandeClientClientInjoignableLibelle = true;

    get validTemplateEmailReportDemandeClientClientInjoignableLibelle(): boolean {
        return this._validTemplateEmailReportDemandeClientClientInjoignableLibelle;
    }

    set validTemplateEmailReportDemandeClientClientInjoignableLibelle(value: boolean) {
        this._validTemplateEmailReportDemandeClientClientInjoignableLibelle = value;
    }

    _validTemplateEmailReportDemandeClientClientInjoignableObjet = true;

    get validTemplateEmailReportDemandeClientClientInjoignableObjet(): boolean {
        return this._validTemplateEmailReportDemandeClientClientInjoignableObjet;
    }

    set validTemplateEmailReportDemandeClientClientInjoignableObjet(value: boolean) {
        this._validTemplateEmailReportDemandeClientClientInjoignableObjet = value;
    }

    _validTemplateEmailReportDemandeClientClientInjoignableCorps = true;
    get validTemplateEmailReportDemandeClientClientInjoignableCorps(): boolean {
        return this._validTemplateEmailReportDemandeClientClientInjoignableCorps;
    }

    set validTemplateEmailReportDemandeClientClientInjoignableCorps(value: boolean) {
        this._validTemplateEmailReportDemandeClientClientInjoignableCorps = value;
    }

    get templateEmailReportDemandeClientClientInjoignables(): Array<TemplateEmailReportDemandeClientClientInjoignableVo> {
        return this.templateEmailReportDemandeClientClientInjoignableService.templateEmailReportDemandeClientClientInjoignables;
    }

    set templateEmailReportDemandeClientClientInjoignables(value: Array<TemplateEmailReportDemandeClientClientInjoignableVo>) {
        this.templateEmailReportDemandeClientClientInjoignableService.templateEmailReportDemandeClientClientInjoignables = value;
    }

    get selectedTemplateEmailReportDemandeClientClientInjoignable(): TemplateEmailReportDemandeClientClientInjoignableVo {
        return this.templateEmailReportDemandeClientClientInjoignableService.selectedTemplateEmailReportDemandeClientClientInjoignable;
    }

    set selectedTemplateEmailReportDemandeClientClientInjoignable(value: TemplateEmailReportDemandeClientClientInjoignableVo) {
        this.templateEmailReportDemandeClientClientInjoignableService.selectedTemplateEmailReportDemandeClientClientInjoignable = value;
    }

    get createTemplateEmailReportDemandeClientClientInjoignableDialog(): boolean {
        return this.templateEmailReportDemandeClientClientInjoignableService.createTemplateEmailReportDemandeClientClientInjoignableDialog;
    }

    set createTemplateEmailReportDemandeClientClientInjoignableDialog(value: boolean) {
        this.templateEmailReportDemandeClientClientInjoignableService.createTemplateEmailReportDemandeClientClientInjoignableDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatCreate;
    }

    get dateFormatColumn() {
        return environment.dateFormatCreate;
    }

    ngOnInit(): void {
    }

    public save() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.saveWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public saveWithShowOption(showList: boolean) {
        this.templateEmailReportDemandeClientClientInjoignableService.save().subscribe(templateEmailReportDemandeClientClientInjoignable => {
            if (templateEmailReportDemandeClientClientInjoignable != null) {
                this.templateEmailReportDemandeClientClientInjoignables.push({...templateEmailReportDemandeClientClientInjoignable});
                this.createTemplateEmailReportDemandeClientClientInjoignableDialog = false;
                this.submitted = false;
                this.selectedTemplateEmailReportDemandeClientClientInjoignable = new TemplateEmailReportDemandeClientClientInjoignableVo();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreurs',
                    detail: 'Template email report demande client client injoignable existe déjà'
                });
            }
        }, error => {
            console.log(error);
        });
    }

    hideCreateDialog() {
        this.createTemplateEmailReportDemandeClientClientInjoignableDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailReportDemandeClientClientInjoignableLibelle = value;
        this.validTemplateEmailReportDemandeClientClientInjoignableObjet = value;
        this.validTemplateEmailReportDemandeClientClientInjoignableCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailReportDemandeClientClientInjoignableLibelle();
        this.validateTemplateEmailReportDemandeClientClientInjoignableObjet();
        this.validateTemplateEmailReportDemandeClientClientInjoignableCorps();
    }

    private validateTemplateEmailReportDemandeClientClientInjoignableLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeClientClientInjoignable.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailReportDemandeClientClientInjoignableLibelle = false;
        } else {
            this.validTemplateEmailReportDemandeClientClientInjoignableLibelle = true;
        }
    }

    private validateTemplateEmailReportDemandeClientClientInjoignableObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeClientClientInjoignable.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailReportDemandeClientClientInjoignableObjet = false;
        } else {
            this.validTemplateEmailReportDemandeClientClientInjoignableObjet = true;
        }
    }

    private validateTemplateEmailReportDemandeClientClientInjoignableCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeClientClientInjoignable.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailReportDemandeClientClientInjoignableCorps = false;
        } else {
            this.validTemplateEmailReportDemandeClientClientInjoignableCorps = true;
        }
    }
}
