import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-app',
    templateUrl: './user-app.component.html',
    styleUrls: ['./user-app.component.scss']
})
export class UserAppComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
