import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppMainComponent} from './app.main.component';
import {AuthGuard} from './controller/guards/auth.guard';
import {AccessDeniedComponent} from './auth/access-denied/access-denied.component';
import {LoginAdminComponent} from './module/admin/login-admin/login-admin.component';
import {RegisterAdminComponent} from './module/admin/register-admin/register-admin.component';
import {LoginCddComponent} from "./module/cdd-manager/login-cdd/login-cdd.component";
import {RegisterCddComponent} from "./module/cdd-manager/register-cdd/register-cdd.component";
import {UserAppListComponent} from "./module/admin/view/kosc/user-app/user-app-list/user-app-list.component";

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                // {path: '', component: HomeComponent},
                {path: '', component: LoginAdminComponent},
                {path: 'admin/login', component: LoginAdminComponent},
                {path: 'admin/register', component: RegisterAdminComponent},
                {path: 'admin/user', component: UserAppListComponent},
                {path: 'cdd/login', component: LoginCddComponent},
                {path: 'cdd/register', component: RegisterCddComponent},
                {
                    path: 'app', // '\'' + root + '\'',
                    component: AppMainComponent,
                    children: [
                        {
                            path: 'admin',
                            loadChildren: () => import('./module/admin/admin-routing.module').then(m => m.AdminRoutingModule),
                            canActivate: [AuthGuard],
                        }, {
                            path: 'cdd',
                            loadChildren: () => import('./module/cdd-manager/cdd-manager-routing.module').then(m => m.CddManagerRoutingModule),
                            canActivate: [AuthGuard],
                        },
                        {path: 'denied', component: AccessDeniedComponent},
                    ],
                    canActivate: [AuthGuard]
                },
            ],
            {scrollPositionRestoration: 'enabled'}
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
