import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-email-mauvais-contact-admin',
    templateUrl: './template-email-mauvais-contact-admin.component.html',
    styleUrls: ['./template-email-mauvais-contact-admin.component.css']
})
export class TemplateEmailMauvaisContactAdminComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

    }

}
