import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-default-template-configuration-admin',
    templateUrl: './default-template-configuration-admin.component.html',
    styleUrls: ['./default-template-configuration-admin.component.css']
})
export class DefaultTemplateConfigurationAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
