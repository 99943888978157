import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ordre-kosc-admin',
    templateUrl: './ordre-kosc-admin.component.html',
    styleUrls: ['./ordre-kosc-admin.component.css']
})
export class OrdreKoscAdminComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

    }

}
