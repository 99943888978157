import {Component, OnInit} from '@angular/core';
import {OrdreKoscService} from "../../../../../../controller/service/kosc/OrdreKosc.service";
import {MenuItem, MessageService} from "primeng/api";
import {StatisticEtatStockVo} from "../../../../../../controller/model/statistic/StatisticEtatStock.model";
import {
    StatisticDelaisInterventionsCovageVo
} from "../../../../../../controller/model/statistic/StatisticDelaisInterventionsCovage";
import {
    StatisticDelaiPriseRdvCovageVo
} from "../../../../../../controller/model/statistic/StatisticDelaiPriseRdvCovage";
import {
    StatisticDelaiEnvoiCriCovageVo
} from "../../../../../../controller/model/statistic/StatisticDelaiEnvoiCriCovage";
import {StatisticOnceAndDoneCovageVo} from "../../../../../../controller/model/statistic/StatisticOnceAndDoneCovage";

@Component({
    selector: 'app-kpis-covage-admin',
    templateUrl: './kpis-covage-admin.component.html',
    styleUrls: ['./kpis-covage-admin.component.css']
})
export class KpisCovageAdminComponent implements OnInit {
    blocked = false;
    options: any;
    barChartOptions: any;
    date: Date;
    private basicOptions: any;

    constructor(private ordreKoscService: OrdreKoscService, private messageService: MessageService) {
        this.data = {
            labels: [],
            datasets: []
        };
        this.basicData = {
            labels: [],
            datasets: []
        };
        this.doughnutData = {
            labels: [],
            datasets: []
        };
    }

    _etatStockList: StatisticEtatStockVo[] = [];

    get etatStockList(): Array<StatisticEtatStockVo> {
        if (this._etatStockList == null) {
            this._etatStockList = new Array<StatisticEtatStockVo>();
        }
        return this._etatStockList;
    }

    set etatStockList(value: Array<StatisticEtatStockVo>) {
        this._etatStockList = value;
    }

    _delaiPriseRdvList: StatisticDelaiPriseRdvCovageVo[] = [];

    get delaiPriseRdvList(): Array<StatisticDelaiPriseRdvCovageVo> {
        if (this._delaiPriseRdvList == null) {
            this._delaiPriseRdvList = new Array<StatisticDelaiPriseRdvCovageVo>();
        }
        return this._delaiPriseRdvList;
    }

    set delaiPriseRdvList(value: Array<StatisticDelaiPriseRdvCovageVo>) {
        this._delaiPriseRdvList = value;
    }

    _delaiInterventionsList: StatisticDelaisInterventionsCovageVo[] = [];

    get delaiInterventionsList(): Array<StatisticDelaisInterventionsCovageVo> {
        if (this._delaiInterventionsList == null) {
            this._delaiInterventionsList = new Array<StatisticDelaisInterventionsCovageVo>();
        }
        return this._delaiInterventionsList;
    }

    set delaiInterventionsList(value: Array<StatisticDelaisInterventionsCovageVo>) {
        this._delaiInterventionsList = value;
    }

    _delaiEnvoiCriList: StatisticDelaiEnvoiCriCovageVo[] = [];

    get delaiEnvoiCriList(): Array<StatisticDelaiEnvoiCriCovageVo> {
        if (this._delaiEnvoiCriList == null) {
            this._delaiEnvoiCriList = new Array<StatisticDelaiEnvoiCriCovageVo>();
        }
        return this._delaiEnvoiCriList;
    }

    set delaiEnvoiCriList(value: Array<StatisticDelaiEnvoiCriCovageVo>) {
        this._delaiEnvoiCriList = value;
    }

    _onceAndDoneList: StatisticOnceAndDoneCovageVo[] = [];

    get onceAndDoneList(): Array<StatisticOnceAndDoneCovageVo> {
        if (this._onceAndDoneList == null) {
            this._onceAndDoneList = new Array<StatisticOnceAndDoneCovageVo>();
        }
        return this._onceAndDoneList;
    }

    set onceAndDoneList(value: Array<StatisticOnceAndDoneCovageVo>) {
        this._onceAndDoneList = value;
    }

    private _doughnutChart = false;

    get doughnutChart(): any {
        return this._doughnutChart;
    }

    set doughnutChart(value: any) {
        this._doughnutChart = value;
    }

    private _barChart = false;

    get barChart(): any {
        return this._barChart;
    }

    set barChart(value: any) {
        this._barChart = value;
    }

    private _barChartDelaisInterventions = false;

    get barChartDelaisInterventions(): any {
        return this._barChartDelaisInterventions;
    }

    set barChartDelaisInterventions(value: any) {
        this._barChartDelaisInterventions = value;
    }

    private _barChartDelaiEnvoiCri = false;
    get barChartDelaiEnvoiCri(): any {
        return this._barChartDelaiEnvoiCri;
    }

    set barChartDelaiEnvoiCri(value: any) {
        this._barChartDelaiEnvoiCri = value;
    }

    private _barChartOnceAndDone = false;
    get barChartOnceAndDone(): any {
        return this._barChartOnceAndDone;
    }

    set barChartOnceAndDone(value: any) {
        this._barChartOnceAndDone = value;
    }

    private _basicData: any;
    get basicData(): any {
        return this._basicData;
    }

    set basicData(value: any) {
        this._basicData = value;
    }

    private _delaiInterventionsData: any;
    get delaiInterventionsData(): any {
        return this._delaiInterventionsData;
    }

    set delaiInterventionsData(value: any) {
        this._delaiInterventionsData = value;
    }

    private _delaiEnvoiCriData: any;
    get delaiEnvoiCriData(): any {
        return this._delaiEnvoiCriData;
    }

    set delaiEnvoiCriData(value: any) {
        this._delaiEnvoiCriData = value;
    }

    private _onceAndDoneData: any;
    get onceAndDoneData(): any {
        return this._onceAndDoneData;
    }

    set onceAndDoneData(value: any) {
        this._onceAndDoneData = value;
    }

    private _doughnutData: any;
    get doughnutData(): any {
        return this._doughnutData;
    }

    set doughnutData(value: any) {
        this._doughnutData = value;
    }

    private _barData: any;

    get barData(): any {
        return this._barData;
    }

    set barData(value: any) {
        this._barData = value;
    }

    private _submissionDateMin: Date;

    get submissionDateMin(): Date {
        return this._submissionDateMin;
    }

    set submissionDateMin(value: Date) {
        this._submissionDateMin = value;
    }

    private _submissionDateMax: Date;

    get submissionDateMax(): Date {
        return this._submissionDateMax;
    }

    set submissionDateMax(value: Date) {
        this._submissionDateMax = value;
    }

    private _selectedKPIType: string;

    get selectedKPIType(): string {
        return this._selectedKPIType;
    }

    set selectedKPIType(value: string) {
        this._selectedKPIType = value;
    }

    private _data: any;

    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }

    private _findByCriteriaShow = false;

    get findByCriteriaShow(): boolean {
        return this._findByCriteriaShow;
    }

    set findByCriteriaShow(value: boolean) {
        this._findByCriteriaShow = value;
    }

    private _kpiTypes: MenuItem[];

    get kpiTypes(): MenuItem[] {
        return this._kpiTypes;
    }

    set kpiTypes(value: MenuItem[]) {
        this._kpiTypes = value;
    }

    get showSpinner(): boolean {
        return this.ordreKoscService.showSpinner;
    }

    set showSpinner(value: boolean) {
        this.ordreKoscService.showSpinner = value;
    }

    public handleSearchRequest() {
        if (!this.submissionDateMin || !this.submissionDateMax) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Les dates de soumission min et max doivent être sélectionnées avant de cliquer sur Valider.'
            });
            return;
        }
        if (this.doughnutChart) {
            this.searchRequestDoughnutChart();
        } else if (this.barChart) {
            this.searchRequestBarChart();
        } else if (this.barChartDelaisInterventions) {
            this.searchRequestDelaisInterventions();
        } else if (this.barChartDelaiEnvoiCri) {
            this.searchRequestDelaiEnvoiCri();
        } else if (this.barChartOnceAndDone) {
            this.searchRequestOnceAndDone();
        } else {
            console.error('Invalid chart type');
        }
    }

    getMinSelectableDate(): Date {
        const today = new Date();
        const minSelectableDate = new Date();
        minSelectableDate.setDate(today.getDate() - 3);
        return minSelectableDate;
    }

    ngOnInit(): void {
        this.initKpiTypes();
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        this.options = {
            cutout: '60%',
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            }
        };
        this.barChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };
    }

    public searchRequestDoughnutChart() {
        this.showSpinner = true;
        this.blocked = true;
        this.etatStockList = new Array<StatisticEtatStockVo>();
        const kpiConfig = {
            'Etat du stock': {
                method: 'calculatePercentageEtatStock',
                labels: ['% en stock', '% planifié'],
                percentages: ['percentageEnStock', 'percentagePlanifie'],
                counts: ['countEnStock', 'countPlanifie'],
                backgroundColor: ["#36A2EB", "#FFCE56"]
            },
        };
        const selectedKPIConfig = kpiConfig[this.selectedKPIType];
        if (!selectedKPIConfig) {
            console.error('Invalid KPI Type');
            return;
        }
        this.ordreKoscService[selectedKPIConfig.method](this.submissionDateMin, this.submissionDateMax).subscribe(
            (result) => {
                this.etatStockList.push(result);
                const labels = selectedKPIConfig.labels.map((label, index) => `${result[selectedKPIConfig.percentages[index]]}${label} (${result[selectedKPIConfig.counts[index]]} RDV)`);
                this.doughnutData = {
                    labels: labels,
                    datasets: [
                        {
                            data: selectedKPIConfig.percentages.map(percentage => result[percentage]),
                            backgroundColor: selectedKPIConfig.backgroundColor,
                            hoverBackgroundColor: selectedKPIConfig.backgroundColor
                        }
                    ]
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public searchRequestBarChart() {
        this.showSpinner = true;
        this.blocked = true;
        this.delaiPriseRdvList = new Array<StatisticDelaiPriseRdvCovageVo>();
        this.ordreKoscService.calculatePercentageDelaiPriseRdvCovage(this.submissionDateMin, this.submissionDateMax).subscribe(
            result => {
                this.delaiPriseRdvList.push(result)
                this.basicData = {
                    labels: ['Délai Prise Rdv'],
                    datasets: [
                        {
                            label: 'Inférieur à 2 jours',
                            backgroundColor: '#42A5F5',
                            data: [result.countInferieurA2Jours]
                        }, {
                            label: 'Supérieur à 2 jours',
                            backgroundColor: '#FFA726',
                            data: [result.countSuperieurA2Jours]
                        }
                    ]
                };
                this.barChartOptions = {
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5, // Ajustez cette valeur pour définir la largeur des barres
                            categoryPercentage: 0.5 // Ajustez cette valeur pour définir l'espacement entre les barres
                        }]
                    }
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public searchRequestDelaisInterventions() {
        this.showSpinner = true;
        this.blocked = true;
        this.delaiInterventionsList = new Array<StatisticDelaisInterventionsCovageVo>();
        this.ordreKoscService.calculatePercentageDelaisInterventionsCovage(this.submissionDateMin, this.submissionDateMax).subscribe(
            result => {
                this.delaiInterventionsList.push(result)
                this.delaiInterventionsData = {
                    labels: ['Délai Intervention'],
                    datasets: [
                        {
                            label: '< 7j',
                            backgroundColor: '#42A5F5',
                            data: [result.countInferieurA7Jours]
                        }, {
                            label: '< 14j',
                            backgroundColor: '#FFA726',
                            data: [result.countInferieurA14Jours]
                        }, {
                            label: '+ 14j',
                            backgroundColor: '#169413',
                            data: [result.countSuperieurA14Jours]
                        }
                    ]
                };
                this.barChartOptions = {
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5, // Ajustez cette valeur pour définir la largeur des barres
                            categoryPercentage: 0.5 // Ajustez cette valeur pour définir l'espacement entre les barres
                        }]
                    }
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public searchRequestDelaiEnvoiCri() {
        this.showSpinner = true;
        this.blocked = true;
        this.delaiEnvoiCriList = new Array<StatisticDelaiEnvoiCriCovageVo>();
        this.ordreKoscService.calculatePercentageDelaiEnvoiCriCovage(this.submissionDateMin, this.submissionDateMax).subscribe(
            result => {
                this.delaiEnvoiCriList.push(result)
                this.delaiEnvoiCriData = {
                    labels: ['Délai d\'envoi Cri'],
                    datasets: [
                        {
                            label: 'à J',
                            backgroundColor: '#42A5F5',
                            data: [result.countEgalA0J]
                        }, {
                            label: 'à J+1',
                            backgroundColor: '#FFA726',
                            data: [result.countEgalA1J]
                        }, {
                            label: 'à J+2',
                            backgroundColor: '#169413',
                            data: [result.countEgalA2J]
                        }, {
                            label: 'à J+3',
                            backgroundColor: '#d0173a',
                            data: [result.countEgalA3J]
                        }
                    ]
                };
                this.barChartOptions = {
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5, // Ajustez cette valeur pour définir la largeur des barres
                            categoryPercentage: 0.5 // Ajustez cette valeur pour définir l'espacement entre les barres
                        }]
                    }
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public searchRequestOnceAndDone() {
        this.showSpinner = true;
        this.blocked = true;
        this.onceAndDoneList = new Array<StatisticOnceAndDoneCovageVo>();
        this.ordreKoscService.calculatePercentageOnceAndDoneCovage(this.submissionDateMin, this.submissionDateMax).subscribe(
            result => {
                this.onceAndDoneList.push(result)
                this.onceAndDoneData = {
                    labels: ['ONCE AND DONE'],
                    datasets: [
                        {
                            label: '1 intervention',
                            backgroundColor: '#42A5F5',
                            data: [result.countEgalA1Intervetion]
                        }, {
                            label: '2 interventions',
                            backgroundColor: '#FFA726',
                            data: [result.countEgalA2Intervetions]
                        }, {
                            label: '+ 2 interventions',
                            backgroundColor: '#169413',
                            data: [result.countSuperieurA2Intervetions]
                        },
                    ]
                };
                this.barChartOptions = {
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5, // Ajustez cette valeur pour définir la largeur des barres
                            categoryPercentage: 0.5 // Ajustez cette valeur pour définir l'espacement entre les barres
                        }]
                    }
                };
                this.showSpinner = false;
                this.blocked = false;
            },
            (error) => {
                console.error('Error fetching KPI data:', error);
            }
        );
    }

    public exportKPIData() {
        // Prepare data for export
        const exportData = this.data.datasets[0].data.map((value, index) => {
            return {
                label: this.data.labels[index],
                value: value
            };
        });
        // Convert the data to CSV format
        const csvContent = 'data:text/csv;charset=utf-8,' + exportData.map(e => `${e.label},${e.value}`).join('\n');
        // Encode the CSV content and create a download link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${this.selectedKPIType}.csv`);
        // Append the link to the document, trigger a click to download, and then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private initKpiTypes() {
        this.kpiTypes = [
            {
                label: 'Délais de planifications - I001',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'Délais de planifications - I001';
                    this.findByCriteriaShow = true;
                    this.barChart = true;
                    this.doughnutChart = false;
                    this.barChartDelaisInterventions = false;
                    this.barChartDelaiEnvoiCri = false;
                    this.barChartOnceAndDone = false;
                }
            }, {
                label: 'Délais d\'interventions - I002',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'Délais d\'interventions - I002';
                    this.findByCriteriaShow = true;
                    this.barChartDelaisInterventions = true;
                    this.doughnutChart = false;
                    this.barChart = false;
                    this.barChartDelaiEnvoiCri = false;
                    this.barChartOnceAndDone = false;
                }
            }, {
                label: 'Délai d\'envoi du CRI - I005',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'Délai d\'envoi du CRI - I005';
                    this.findByCriteriaShow = true;
                    this.barChartDelaiEnvoiCri = true;
                    this.doughnutChart = false;
                    this.barChart = false;
                    this.barChartDelaisInterventions = false;
                    this.barChartOnceAndDone = false;
                }
            }, {
                label: 'ONCE AND DONE - I007',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'ONCE AND DONE - I007';
                    this.findByCriteriaShow = true;
                    this.barChartOnceAndDone = true;
                    this.barChartDelaiEnvoiCri = false;
                    this.doughnutChart = false;
                    this.barChart = false;
                    this.barChartDelaisInterventions = false;
                }
            }, {
                label: 'Etat du stock',
                icon: 'pi pi-tag',
                command: () => {
                    this.selectedKPIType = 'Etat du stock';
                    this.findByCriteriaShow = true;
                    this.doughnutChart = true;
                    this.barChart = false;
                    this.barChartDelaisInterventions = false;
                    this.barChartDelaiEnvoiCri = false;
                    this.barChartOnceAndDone = false;
                }
            },
        ];
    }
}
