import {TechnicienVo} from "../technicien/Technicien.model";
import {EtatDemandeKoscVo} from "../referentiel/EtatDemandeKosc.model";
export class OrdreKoscOldVo {
    public technicienVo: TechnicienVo;
    public dateRdvReelle: string | Date;
    public dateRdv: string | Date;
    public dateRdvFinale: string | Date;
    public etatDemandeKoscVo: EtatDemandeKoscVo;
    public trancheJour: number;
}
