import {Injectable} from '@angular/core';
import * as XLSX from "xlsx";
import {OrdreKoscVo} from "../../model/kosc/OrdreKosc.model";
import {EtatDemandeKoscVo} from "../../model/referentiel/EtatDemandeKosc.model";
import {DepartementVo} from "../../model/technicien/Departement.model";
import {DateUtils} from "../../../utils/DateUtils";
import {OrdreKoscService} from 'src/app/controller/service/kosc/OrdreKosc.service';
import {MessageService} from "primeng/api";
import {Observable} from "rxjs";
import {TechnicienVo} from "../../model/technicien/Technicien.model";
import {OperatorVo} from "../../model/referentiel/Operator.model";
import Papa from 'papaparse';
import {CauseKoOkVo} from "../../model/referentiel/CauseKoOk.model";

@Injectable({
    providedIn: 'root'
})
export class OrdreKoscExcelService {
    showSpinner = false;
    fileToUpload: File | null = null;

    constructor(private ordreKoscService: OrdreKoscService, private messageService: MessageService) {
    }

    importAll(event: any) {
        this.showSpinner = true;
        this.readDataObservable(event).subscribe(
            next => {
                this.importAllExc(next);
                this.showSpinner = false;
            },
            error => {
                console.log(error);
                this.showSpinner = false;
            }
        );
    }

    public importerDataBase(event: any): Observable<any> {
        // this.showSpinner = true;
        return new Observable(sub => {
            /* wire up file reader */
            const target: DataTransfer = <DataTransfer>(event.target);
            if (target.files.length !== 1) {
                throw new Error('Cannot use multiple files');
            }
            const reader: FileReader = new FileReader();
            reader.readAsBinaryString(target.files[0]);
            reader.onload = (e: any) => {
                /* create workbook */
                const binarystr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});
                /* selected the first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                /* save data */
                const data = XLSX.utils.sheet_to_json(ws);
                let koscOrdresWork = new Array<OrdreKoscVo>();
                for (let i = 0; i < data.length; i++) {
                    let myOrdreKoscWork = this.constructDataBase(data, i);
                    koscOrdresWork.push(myOrdreKoscWork);
                }
                this.ordreKoscService.ordreKoscs = koscOrdresWork;
                this.ordreKoscService.importerDataBase(this.ordreKoscService.ordreKoscs).subscribe(
                    response => {
                        if (response.length == 0) {
                            sub.next();
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: 'La Base De Données importé avec Succès',
                                life: 3000
                            });
                            // this.searchRequest();
                        } else {
                            sub.error();
                            this.messageService.add({
                                severity: 'error',
                                summary: 'erreur',
                                detail: 'problème d\'importation : reference existe déjà'
                            });
                        }
                    },
                    error => {
                        this.messageService.add({
                            severity: 'error', summary: 'erreur', detail: 'problème d\'importation            '
                        });
                    }
                );
            }
        })
    }

    convertBoleen(value: string): boolean {
        const myBoleenString = ['N']
        return myBoleenString.includes(value) ? true : false;
    }

    convertDate(date) {
        if (date != null) {
            const myDate = new Date(Math.round((date - 25569) * 86400 * 1000));
            return new Date(DateUtils.toString(myDate).split('T')[0]);
        }
        return null;
    }

    private readDataObservable(event: any): Observable<Array<OrdreKoscVo>> {
        return new Observable(subscriber => {
            /* wire up file reader */
            const target: DataTransfer = <DataTransfer>(event.target);
            let koscOrdrers = new Array<OrdreKoscVo>();
            let count = 0;
            let sum = event.target.files.length;
            for (let i = 0; i < event.target.files.length; i++) {
                let fileToUpload = event.target.files.item(i);
                const reader: FileReader = new FileReader();
                reader.readAsBinaryString(target.files[i]);
                const fileName = fileToUpload.name;  // Extract the file name
                reader.onload = (e: any) => {
                    // new reading method using papaparse
                    Papa.parse(e.target.result, {
                        delimiter: ';',  // Specify the delimiter used in the CSV file
                        complete: (results) => {
                            const data = results.data;
                            const headers = data[0];
                            const jsonData = data.slice(1).filter((row) => {
                                return row.some((cell) => cell.trim() !== '');
                            }).map((row) => {
                                const obj = {};
                                headers.forEach((header, i) => {
                                    if (row[i].trim() === '') {
                                        obj[header] = undefined;
                                    } else {
                                        obj[header] = row[i];
                                    }
                                });
                                return obj;
                            });
                            for (let i = 0; i < jsonData.length; i++) {
                                const fileType = this.getFileTypeFromFileName(fileName);
                                if (fileType === 'FTEL') {
                                    let myCosk = this.constructFtl(jsonData, i);
                                    myCosk.type = 'ftl';
                                    koscOrdrers.push(myCosk);
                                } else if (fileType === 'KOSC') {
                                    let myCosk = this.constructWorkOrder(jsonData, i);
                                    myCosk.type = 'kosc';
                                    koscOrdrers.push(myCosk);
                                } else if (fileType === 'ERDV') {
                                    let myCosk = this.constructErdv(jsonData, i);
                                    myCosk.type = 'erdv';
                                    koscOrdrers.push(myCosk);
                                }
                            }
                        },
                        dynamicTyping: false
                    });
                }
                reader.onloadend = function (event) {
                    if (++count === sum) {
                        subscriber.next(koscOrdrers);  // <-- emit notification
                        subscriber.complete();
                    }
                }
            }
        });
    }

    private processFileData(data: any, fileName: string): any[] {
        const headers = data[0];
        return data.slice(1).filter((row) => {
            const isNonEmptyCell = row.some((cell) => cell && cell.trim() !== '');
            return isNonEmptyCell;
        }).map((row) => {
            const obj = {};
            headers.forEach((header, i) => {
                if (row[i] && row[i].trim() === '') {
                    obj[header] = undefined;
                } else {
                    obj[header] = row[i];
                }
            });
            return obj;
        });
    }

    private getFileTypeFromFileName(fileName: string): string {
        if (fileName && typeof fileName === 'string') {
            if (fileName.startsWith('FTEL')) {
                return 'FTEL';
            } else if (fileName.startsWith('WorkOrder')) {
                return 'KOSC';
            } else if (fileName.startsWith('ERDV')) {
                return 'ERDV';
            }
        }
        return 'UNKNOWN';
    }

    /*    private importAllExc(koscOrdrers: OrdreKoscVo[]) {
            this.showSpinner = true;
            this.ordreKoscService.importerAll(koscOrdrers).subscribe(
                response => {
                    if (response.length == 0) {
                        this.messageService.add({
                            severity: 'success', summary: 'Succès', detail: 'Kosc Order importé avec Succès', life: 3000
                        });
                        this.showSpinner = false;
                    } else {
                        this.ordreKoscService.ordreKoscs = response;
                        let message = this.constructMessage(response);
                        this.messageService.add({
                            severity: 'error', summary: 'erreur', detail: '' + message
                        });
                        this.showSpinner = false;
                    }
                },
                error => {
                    this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'importation'});
                }
            );
        }*/

    private importAllExc(koscOrdrers: OrdreKoscVo[]) {
        this.showSpinner = true;
        this.ordreKoscService.importerAll(koscOrdrers).subscribe(
            response => {
                if (response) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Importation réussie',
                        detail: `Ordres Kosc importés avec succès : 
                        total ${response.totalSuccess} :
                        ${response.successKosc}  Kosc,
                         ${response.successFtel}  Ftel, 
                         et ${response.successErdv}  Erdv.`,
                        sticky: true  // Ajoutez cette ligne pour rendre le message persistant
                    });
                    this.showSpinner = false;
                } else {
                    // this.ordreKoscService.ordreKoscs = response;
                    // let message = this.constructMessage(response);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'erreur',
                        detail: '' + response.failedFiles,
                        sticky: true  // Ajoutez cette ligne pour rendre le message persistant
                    });
                    this.showSpinner = false;
                }
            },
            error => {
                this.messageService.add({severity: 'error', summary: 'erreur', detail: 'problème d\'importation'});
            }
        );
    }

    private readExcel(event: any) {
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});
            /* selected the first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            /* save data */
            const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
            let koscOrders = new Array<OrdreKoscVo>();
            for (let i = 1; i < data.length; i++) {
                let myOrdreKosc = new OrdreKoscVo();
                myOrdreKosc.reference = data[i]['kosc_order_ref'];
                myOrdreKosc.referenceWorkOrder = data[i]['work_order_ref'];
                //save technicien
                if (data[i]['tech_reference'] != null) {
                    if (myOrdreKosc.technicienVo == null) {
                        myOrdreKosc.technicienVo = new TechnicienVo();
                    }
                    myOrdreKosc.technicienVo.identifiant = data[i]['tech_reference'];
                }
                //save Operateur
                if (myOrdreKosc.operatorVo == null) {
                    myOrdreKosc.operatorVo = new OperatorVo();
                }
                myOrdreKosc.operatorVo.reference = data[i]['opt_reference'];
                myOrdreKosc.supplierServiceCode = data[i]['supplier_service_code'];
                myOrdreKosc.dateDebutTraitement = null;
                myOrdreKosc.endCustumorName = null;
                myOrdreKosc.endCustumorSiret = data[i]['siret'];
                myOrdreKosc.endCustumorFirstName = data[i]['first_name'];
                myOrdreKosc.endCustumorLastName = data[i]['last_name'];
                myOrdreKosc.endCustumorZipcode = data[i]['zip_code'];///////
                myOrdreKosc.endCustumorStreetName = data[i]['street_name'];///////////
                myOrdreKosc.endCustumorStreetNumber = data[i]['street_number'];/////////
                myOrdreKosc.endCustumorCity = data[i]['city'];/////////
                //myOrdreKosc.endCustumorBuilding = null;
                myOrdreKosc.endCustumorStairs = data[i]['stairs'];
                myOrdreKosc.endCustumorFloor = data[i]['floor'];
                myOrdreKosc.endCustumorContactFirstName = data[i]['end_customer_contact_first_name'];
                myOrdreKosc.endCustumorContactLastName = data[i]['end_customer_contact_last_name'];
                myOrdreKosc.endCustumorContactPhone = data[i]['end_customer_contact_phone'];
                myOrdreKosc.endCustumorContactCellPhone = data[i]['end_customer_contact_cellphone'];
                myOrdreKosc.endCustumorContactEmail = data[i]['end_customer_contact_email'];
                myOrdreKosc.company = data[i]['company_name'];//////////////////////
                //myOrdreKosc.referenceDossier = null;
                myOrdreKosc.dateAppelReplanification = this.convertDate(data[i]['RePlanification']);
                myOrdreKosc.submissionDate = this.convertDate(data[i]['submission_date']);
                myOrdreKosc.datePremierAppel = this.convertDate(data[i]['1 er appel']);
                myOrdreKosc.dateDeuxiemeAppel = this.convertDate(data[i]['2 eme appel']);
                myOrdreKosc.dateTroisiemeAppel = this.convertDate(data[i]['3 eme appel']);
                myOrdreKosc.datePriseRdv = null;
                myOrdreKosc.dateRdv = null;
                myOrdreKosc.referenceCommandePriseInterneOC = data[i]['ReferenceCommandePriseInterneOC'];
                //myOrdreKosc.dateI =new Date(Math.round((data[i]['Date intervention Technicien'] - 25569)*86400*1000));
                myOrdreKosc.dateOuverture = null;
                myOrdreKosc.coordonnePboY = data[i]['CoordonneePBOY'];
                myOrdreKosc.hauteurPbo = data[i]['HauteurPBO'];
                myOrdreKosc.typeMaterielPbo = data[i]['TypeMaterielPBO'];
                myOrdreKosc.typePbo = data[i]['TypePBO'];
                myOrdreKosc.conditionSyndics = data[i]['ConditionsSyndic'];
                myOrdreKosc.typeRacordementPboPto = data[i]['TypeRaccoPBPTO'];
                myOrdreKosc.autreInfosPboPto = data[i]['AutresInfosPBOPTO'];
                myOrdreKosc.codeAccesImmeuble = data[i]['CodeAccesImmeuble'];
                myOrdreKosc.contacteImmeuble = data[i]['ContactsImmeuble'];
                myOrdreKosc.pmaAccessible = data[i]['Pmaccessible'];
                myOrdreKosc.infoObtentionCle = data[i]['InfoObtentionCle'];
                myOrdreKosc.contactsSyndic = data[i]['ContactsSyndic'];
                myOrdreKosc.racordementLong = this.convertBoleen(data[i]['RaccordementLong']);
                myOrdreKosc.oc1 = data[i]['OC 1'];
                myOrdreKosc.nomModulePm1 = data[i]['NomModulePm NÂ°1'];
                myOrdreKosc.positionModulePm1 = data[i]['PositionModulePm NÂ°1'];
                myOrdreKosc.referenceCableModulePm1 = data[i]['ReferenceCableModulePm NÂ°1'];
                myOrdreKosc.referenceTubeModulePm1 = null;
                myOrdreKosc.informationFibreModulePm1 = data[i]['InformationFibreModulePm NÂ°1'];
                myOrdreKosc.referenceCablePbo1 = data[i]['ReferenceCablePBO NÂ°1'];
                myOrdreKosc.informationTubePbo1 = data[i]['InformationTubePBO NÂ°1'];
                myOrdreKosc.informationFibrePbo1 = data[i]['InformationFibrePBO NÂ°1'];
                myOrdreKosc.connecteurPriseNumero1 = data[i]['ConnecteurPriseNumero NÂ°1'];
                myOrdreKosc.connecteurPriseCouleur1 = data[i]['ConnecteurPriseCouleur NÂ°1'];
                myOrdreKosc.reserve1 = data[i]['Reserve1'];
                myOrdreKosc.oc2 = data[i]['OC 2'];
                myOrdreKosc.nomModulePm2 = data[i]['NomModulePm NÂ°2'];
                myOrdreKosc.positionModulePm2 = data[i]['PositionModulePm NÂ°2'];
                myOrdreKosc.referenceCableModulePm2 = data[i]['ReferenceCableModulePm NÂ°2'];
                myOrdreKosc.referenceTubeModulePm2 = null;
                myOrdreKosc.informationFibreModulePm2 = data[i]['InformationFibreModulePm NÂ°2'];
                myOrdreKosc.referenceCablePbo2 = data[i]['ReferenceCablePBO NÂ°2'];
                myOrdreKosc.informationTubePbo2 = data[i]['InformationTubePBO NÂ°2'];
                myOrdreKosc.informationFibrePbo2 = data[i]['InformationFibrePBO NÂ°2'];
                myOrdreKosc.connecteurPriseNumero2 = data[i]['ConnecteurPriseNumero NÂ°2'];
                myOrdreKosc.connecteurPriseCouleur2 = data[i]['ConnecteurPriseCouleur NÂ°2'];
                myOrdreKosc.reserve2 = data[i]['Reserve2'];
                myOrdreKosc.oc3 = data[i]['OC 3'];
                myOrdreKosc.nomModulePm3 = data[i]['NomModulePm NÂ°3'];
                myOrdreKosc.positionModulePm3 = data[i]['PositionModulePm NÂ°3'];
                myOrdreKosc.referenceCableModulePm3 = data[i]['ReferenceCableModulePm NÂ°3'];
                myOrdreKosc.referenceTubeModulePm3 = null;
                myOrdreKosc.informationFibreModulePm3 = data[i]['InformationFibreModulePm NÂ°3'];
                myOrdreKosc.referenceCablePbo3 = data[i]['ReferenceCablePBO NÂ°3'];
                myOrdreKosc.informationTubePbo3 = data[i]['InformationTubePBO NÂ°3'];
                myOrdreKosc.informationFibrePbo3 = data[i]['InformationFibrePBO NÂ°3'];
                myOrdreKosc.connecteurPriseNumero3 = data[i]['ConnecteurPriseNumero NÂ°3'];
                myOrdreKosc.connecteurPriseCouleur3 = data[i]['ConnecteurPriseCouleur NÂ°3'];
                myOrdreKosc.reserve3 = data[i]['Reserve3'];
                myOrdreKosc.oc4 = data[i]['OC 4'];
                myOrdreKosc.nomModulePm4 = data[i]['NomModulePm NÂ°4'];
                myOrdreKosc.positionModulePm4 = data[i]['PositionModulePm NÂ°4'];
                myOrdreKosc.referenceCableModulePm4 = data[i]['ReferenceCableModulePm NÂ°4'];
                myOrdreKosc.referenceTubeModulePm4 = null;
                myOrdreKosc.informationFibreModulePm4 = data[i]['InformationFibreModulePm NÂ°4'];
                myOrdreKosc.referenceCablePbo4 = data[i]['ReferenceCablePBO NÂ°4'];
                myOrdreKosc.informationTubePbo4 = data[i]['InformationTubePBO NÂ°4'];
                myOrdreKosc.informationFibrePbo4 = data[i]['InformationFibrePBO NÂ°4'];
                myOrdreKosc.connecteurPriseNumero4 = data[i]['ConnecteurPriseNumero NÂ°4'];
                myOrdreKosc.connecteurPriseCouleur4 = data[i]['ConnecteurPriseCouleur NÂ°4'];
                myOrdreKosc.reserve4 = data[i]['Reserve4'];
                // myOrdreKosc.dateEnvoiCri = null;
                //myOrdreKosc.pboReel = null;
                //myOrdreKosc.numeroSerieOnt = null;
                myOrdreKosc.workOrderType = data[i]['work_order_type'];
                //myOrdreKosc.product = null; ///////
                myOrdreKosc.productCode = data[i]['product_code'];
                myOrdreKosc.productLabel = data[i]['product_label'];
                myOrdreKosc.provider = data[i]['provider'];
                myOrdreKosc.providerFileType = data[i]['provider_file_type'];
                //myOrdreKosc.spliter = null;
                myOrdreKosc.existingOtp = data[i]['existing_otp'];
                myOrdreKosc.profile = data[i]['profile'];
                //myOrdreKosc.comment = data[i]['operator_comment'];///////
                myOrdreKosc.providerSlId = data[i]['provider_slid'];
                myOrdreKosc.referencePrise = data[i]['ReferencePrise'];
                myOrdreKosc.referencePrestationPrise = data[i]['ReferencePrestationPrise'];
                //myOrdreKosc.dateInterventionTechniqueDebut = null;////////
                //myOrdreKosc.dateInterventionTechniqueFin = null;//////////
                //myOrdreKosc.providerProduct = data[i]['provider_product'];
                myOrdreKosc.referencePm = data[i]['Reference Pm'];
                myOrdreKosc.referencePmTechnique = data[i]['ReferencePmTechnique'];
                myOrdreKosc.localisationPm = data[i]['LocalisationPm'];
                myOrdreKosc.referencePbo = data[i]['ReferencePBO'];
                myOrdreKosc.localisationPbo = data[i]['LocalisationPBO'];
                myOrdreKosc.localeIpm = data[i]['CodeLocalPM'];/////////////////////////  /
                myOrdreKosc.objetPlanification = null;
                myOrdreKosc.corpsPlanification = null;
                myOrdreKosc.envoyePlanification = null;
                myOrdreKosc.dateEnvoiPlanification = null;
                myOrdreKosc.objetReplanification = null;
                myOrdreKosc.corpsReplanification = null;
                myOrdreKosc.envoyeReplanification = null;
                myOrdreKosc.dateEnvoiReplanification = null;
                myOrdreKosc.objetReport = null;
                myOrdreKosc.corpsReport = null;
                myOrdreKosc.envoyeReport = null;
                myOrdreKosc.dateEnvoiReport = null;
                myOrdreKosc.commentaireTechnicien = null;
                myOrdreKosc.commentaireClient = null;
                myOrdreKosc.commantaireCloture = null;
                myOrdreKosc.objetCloture = null;
                myOrdreKosc.corpsCloture = null;
                myOrdreKosc.envoyeCloture = null;
                myOrdreKosc.dateEnvoiCloture = null;
                myOrdreKosc.emailCloturePieceJoints = null;
                myOrdreKosc.objetSuivi = null;
                myOrdreKosc.corpsSuivi = null;
                myOrdreKosc.envoyeSuivi = null;
                myOrdreKosc.dateEnvoiSuivi = null;
                koscOrders.push(myOrdreKosc);
            }
            // let objet = Object.assign(new OrdreKoscVo, data);
            this.ordreKoscService.ordreKoscs = koscOrders;
        };
    }

    private readWorkOder(event: any) {
        this.showSpinner = true;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});
            /* selected the first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            /* save data */
            const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
            let koscOrdresWork = new Array<OrdreKoscVo>();
            for (let i = 0; i < data.length; i++) {
                let myOrdreKoscWork = this.constructWorkOrder(data, i);
                koscOrdresWork.push(myOrdreKoscWork);
            }
            this.ordreKoscService.ordreKoscs = koscOrdresWork;
            this.ordreKoscService.importerWordOrder(this.ordreKoscService.ordreKoscs).subscribe(
                response => {
                    if (response.length == 0) {
                        this.messageService.add({
                            severity: 'success', summary: 'Succès', detail: 'Kosc Order importé avec Succès', life: 3000
                        });
                        this.ordreKoscService.searchOrdreKosc.referenceWorkOrder = koscOrdresWork[0].referenceWorkOrder;
                        // this.searchRequest();
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'erreur',
                            detail: 'problème d\'importation : reference existe déjà'
                        });
                    }
                },
                error => {
                    this.messageService.add({
                        severity: 'error', summary: 'erreur', detail: 'problème d\'importation'
                    });
                }
            );
        }
        this.showSpinner = false;
    }

    private readFtel(event: any) {
        this.showSpinner = true;
        let resultat = null;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});
            /* selected the first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            /* save data */
            const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
            let koscOrdresFtel = new Array<OrdreKoscVo>();
            for (let i = 0; i < data.length; i++) {
                let myOrdreKoscFtel = this.constructFtl(data, i);
                koscOrdresFtel.push(myOrdreKoscFtel);
            }
            this.ordreKoscService.importerFtel(koscOrdresFtel).subscribe(
                response => {
                    if (response.length == 0) {
                        this.messageService.add({
                            severity: 'success', summary: 'Succès', detail: 'FTL importé avec Succès', life: 3000
                        });
                        this.ordreKoscService.searchOrdreKosc.reference = koscOrdresFtel[0].reference;
                        // this.searchRequest();
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'erreur',
                            detail: 'problème d\'importation : reference n\'existe pas'
                        });
                    }
                },
                error => {
                    this.messageService.add({
                        severity: 'error', summary: 'erreur', detail: 'problème d\'importation'
                    });
                }
            );
        };
        this.showSpinner = false;
    }

    private readERdv(event: any) {
        this.showSpinner = true;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});
            /* selected the first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            /* save data */
            const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
            let koscOrdresERdv = new Array<OrdreKoscVo>();
            for (let i = 0; i < data.length; i++) {
                let myOrdreKoscERdv = this.constructErdv(data, i);
                koscOrdresERdv.push(myOrdreKoscERdv);
            }
            this.ordreKoscService.ordreKoscs = koscOrdresERdv;
            this.ordreKoscService.importerERdv(this.ordreKoscService.ordreKoscs).subscribe(
                response => {
                    if (response.length == 0) {
                        this.messageService.add({
                            severity: 'success', summary: 'Succès', detail: 'E-Rdv importé avec Succès', life: 3000
                        });
                        this.ordreKoscService.searchOrdreKosc.reference = koscOrdresERdv[0].reference;
                        // this.searchRequest();
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'erreur',
                            detail: 'problème d\'importation : reference existe déjà'
                        });
                    }
                },
                error => {
                    this.messageService.add({
                        severity: 'error', summary: 'erreur', detail: 'problème d\'importation'
                    });
                }
            );
        };
        this.showSpinner = false;
    }

    private constructErdv(data: any, i: number) {
        let myOrdreKoscERdv = new OrdreKoscVo();
        if (myOrdreKoscERdv.etatDemandeKoscVo == null) {
            myOrdreKoscERdv.etatDemandeKoscVo = new EtatDemandeKoscVo();
        }
        myOrdreKoscERdv.etatDemandeKoscVo.code = 'initialisation-erdv';
        if (myOrdreKoscERdv.operatorVo == null) {
            myOrdreKoscERdv.operatorVo = new OperatorVo();
        }
        myOrdreKoscERdv.operatorVo.reference = data[i]['customer_operator'];
        myOrdreKoscERdv.operatorVo.libelle = data[i]['customer_operator'];
        myOrdreKoscERdv.reference = data[i]['kosc_order_ref'];
        myOrdreKoscERdv.referenceWorkOrder = data[i]['work_order_ref'];
        myOrdreKoscERdv.supplier = data[i]['supplier'];
        myOrdreKoscERdv.workOrderType = data[i]['work_order_type'];
        myOrdreKoscERdv.supplierServiceCode = data[i]['supplier_service_code'];
        myOrdreKoscERdv.submissionDate = DateUtils.toDateForExcel(data[i]['submission_date']);
        myOrdreKoscERdv.productCode = data[i]['product_code'];
        myOrdreKoscERdv.productLabel = data[i]['product_label'];
        myOrdreKoscERdv.provider = data[i]['provider'];
        myOrdreKoscERdv.providerProduct = data[i]['provider_product'];
        myOrdreKoscERdv.gtr = data[i]['gtr'];
        myOrdreKoscERdv.category = data[i]['category'];
        myOrdreKoscERdv.providerFileType = data[i]['provider_file_type'];
        myOrdreKoscERdv.slid = data[i]['slid'];
        myOrdreKoscERdv.koscSplitterPosition = data[i]['kosc_splitter_position'];
        myOrdreKoscERdv.existingOtp = data[i]['existing_otp'];
        myOrdreKoscERdv.otpRef = data[i]['otp_ref'];
        myOrdreKoscERdv.profile = data[i]['profile'];
        myOrdreKoscERdv.company = data[i]['company_name'];
        myOrdreKoscERdv.endCustumorSiret = data[i]['siret'];
        myOrdreKoscERdv.endCustumorFirstName = data[i]['first_name'];
        myOrdreKoscERdv.endCustumorLastName = data[i]['last_name'];
        myOrdreKoscERdv.endCustumorStreetNumber = data[i]['street_number'];
        myOrdreKoscERdv.endCustumorStreetName = data[i]['street_name'];
        myOrdreKoscERdv.endCustumorZipcode = data[i]['zip_code'];
        if (myOrdreKoscERdv.departementVo == null) {
            myOrdreKoscERdv.departementVo = new DepartementVo();
        }
        myOrdreKoscERdv.departementVo.code = data[i]['zip_code'].toString().substring(0, 2);
        myOrdreKoscERdv.departementVo.libelle = data[i]['zip_code'].toString().substring(0, 2);
        myOrdreKoscERdv.endCustumorCity = data[i]['city'];
        myOrdreKoscERdv.endCustumorBuilding = data[i]['building_name'];
        myOrdreKoscERdv.buildingCode = data[i]['building_code'];
        myOrdreKoscERdv.endCustumorStairs = data[i]['stairs'];
        myOrdreKoscERdv.endCustumorFloor = data[i]['floor'];
        myOrdreKoscERdv.operatorComment = data[i]['operator_comment'];
        myOrdreKoscERdv.endCustumorContactFirstName = data[i]['end_customer_contact_first_name'];
        myOrdreKoscERdv.endCustumorContactLastName = data[i]['end_customer_contact_last_name'];
        myOrdreKoscERdv.endCustumorContactPhone = data[i]['end_customer_contact_phone'];
        myOrdreKoscERdv.endCustumorContactCellPhone = data[i]['end_customer_contact_cellphone'];
        myOrdreKoscERdv.endCustumorContactEmail = data[i]['end_customer_contact_email'];
        myOrdreKoscERdv.dateRdv = DateUtils.toDateWithHour(data[i]['intervention_start_datetime']);
        myOrdreKoscERdv.dateRdvErdvInit = DateUtils.toDateWithHour(data[i]['intervention_start_datetime']);
        myOrdreKoscERdv.dateInterventionTechniqueFin = DateUtils.toDateWithHour(data[i]['intervention_end_datetime']);
        myOrdreKoscERdv.koscContactFirstName = data[i]['kosc_contact_first_name'];
        myOrdreKoscERdv.koscContactLastName = data[i]['kosc_contact_last_name'];
        myOrdreKoscERdv.koscContactPhone = data[i]['kosc_contact_phone'];
        myOrdreKoscERdv.koscContactCellphone = data[i]['kosc_contact_cellphone'];
        myOrdreKoscERdv.koscContactEmail1 = data[i]['kosc_contact_email_1'];
        myOrdreKoscERdv.koscContactEmail2 = data[i]['kosc_contact_email_2'];
        myOrdreKoscERdv.koscContactEmail3 = data[i]['kosc_contact_email_3'];
        myOrdreKoscERdv.koscComment = data[i]['kosc_comments'];
        myOrdreKoscERdv.providerSlId = data[i]['provider_slid'];
        myOrdreKoscERdv.cpeInstall = data[i]['cpe_install'];
        myOrdreKoscERdv.otpDisplacement = data[i]['otp_displacement'];
        myOrdreKoscERdv.ontType = data[i]['ont_type'];
        return myOrdreKoscERdv;
    }

    private constructFtl(data: any, i: number) {
        let myOrdreKoscFtel = new OrdreKoscVo();
        //save technicien
        if (data[i]['tech_reference'] != null) {
            if (myOrdreKoscFtel.technicienVo == null) {
                myOrdreKoscFtel.technicienVo = new TechnicienVo();
            }
            myOrdreKoscFtel.technicienVo.identifiant = data[i]['tech_reference'];
        }
        //save Operateur
        if (myOrdreKoscFtel.operatorVo == null) {
            myOrdreKoscFtel.operatorVo = new OperatorVo();
        }
        myOrdreKoscFtel.operatorVo.reference = data[i]['opt_reference'];
        myOrdreKoscFtel.etatCrCommandePrise = data[i]['EtatCrCommandePrise'];
        myOrdreKoscFtel.referenceCommandePriseInterneOC = data[i]['ReferenceCommandePriseInterneOC'];
        myOrdreKoscFtel.referencePrise = data[i]['ReferencePrise'];
        myOrdreKoscFtel.referencePTO = data[i]['ReferencePrise'];
        myOrdreKoscFtel.referencePrestationPrise = data[i]['ReferencePrestationPrise'];
        myOrdreKoscFtel.referencePm = data[i]['ReferencePm'];
        myOrdreKoscFtel.referencePmTechnique = data[i]['ReferencePmTechnique'];
        myOrdreKoscFtel.localisationPm = data[i]['LocalisationPm'];
        myOrdreKoscFtel.referencePbo = data[i]['ReferencePBO'];
        myOrdreKoscFtel.localisationPbo = data[i]['LocalisationPBO'];
        myOrdreKoscFtel.coordonneePBOX = data[i]['CoordonneePBOX'];
        myOrdreKoscFtel.coordonnePboY = data[i]['CoordonneePBOY'];
        myOrdreKoscFtel.hauteurPbo = data[i]['HauteurPBO'];
        myOrdreKoscFtel.typeMaterielPbo = data[i]['TypeMaterielPBO'];
        myOrdreKoscFtel.typePbo = data[i]['TypePBO'];
        myOrdreKoscFtel.conditionSyndics = data[i]['ConditionsSyndic'];
        myOrdreKoscFtel.typeRacordementPboPto = data[i]['TypeRaccoPBPTO'];
        myOrdreKoscFtel.autreInfosPboPto = data[i]['AutresInfosPBOPTO'];
        myOrdreKoscFtel.codeAccesImmeuble = data[i]['CodeAccesImmeuble'];
        myOrdreKoscFtel.contacteImmeuble = data[i]['ContactsImmeuble'];
        myOrdreKoscFtel.pmaAccessible = data[i]['Pmaccessible'];
        myOrdreKoscFtel.infoObtentionCle = data[i]['InfoObtentionCle'];
        myOrdreKoscFtel.localeIpm = data[i]['CodeLocalPM'];//////////////////
        myOrdreKoscFtel.contactsSyndic = data[i]['ContactsSyndic'];
        myOrdreKoscFtel.oc1 = data[i]['OC 1'];
        myOrdreKoscFtel.nomModulePm1 = data[i]['NomModulePm NÂ°1'];
        myOrdreKoscFtel.positionModulePm1 = data[i]['PositionModulePm NÂ°1'];
        myOrdreKoscFtel.referenceCableModulePm1 = data[i]['ReferenceCableModulePm NÂ°1'];
        myOrdreKoscFtel.informationFibreModulePm1 = data[i]['InformationFibreModulePm NÂ°1'];
        myOrdreKoscFtel.referenceCablePbo1 = data[i]['ReferenceCablePBO NÂ°1'];
        myOrdreKoscFtel.informationTubePbo1 = data[i]['InformationTubePBO NÂ°1'];
        myOrdreKoscFtel.informationFibrePbo1 = data[i]['InformationFibrePBO NÂ°1'];
        myOrdreKoscFtel.connecteurPriseNumero1 = data[i]['ConnecteurPriseNumero NÂ°1'];
        myOrdreKoscFtel.connecteurPriseCouleur1 = data[i]['ConnecteurPriseCouleur NÂ°1'];
        myOrdreKoscFtel.oc2 = data[i]['OC 2'];
        myOrdreKoscFtel.nomModulePm2 = data[i]['NomModulePm NÂ°2'];
        myOrdreKoscFtel.positionModulePm2 = data[i]['PositionModulePm NÂ°2'];
        myOrdreKoscFtel.referenceCableModulePm2 = data[i]['ReferenceCableModulePm NÂ°2'];
        myOrdreKoscFtel.informationFibreModulePm2 = data[i]['InformationFibreModulePm NÂ°2'];
        myOrdreKoscFtel.referenceCablePbo2 = data[i]['ReferenceCablePBO NÂ°2'];
        myOrdreKoscFtel.informationTubePbo2 = data[i]['InformationTubePBO NÂ°2'];
        myOrdreKoscFtel.informationFibrePbo2 = data[i]['InformationFibrePBO NÂ°2'];
        myOrdreKoscFtel.connecteurPriseNumero2 = data[i]['ConnecteurPriseNumero NÂ°2'];
        myOrdreKoscFtel.connecteurPriseCouleur2 = data[i]['ConnecteurPriseCouleur NÂ°2'];
        myOrdreKoscFtel.oc3 = data[i]['OC 3'];
        myOrdreKoscFtel.nomModulePm3 = data[i]['NomModulePm NÂ°3'];
        myOrdreKoscFtel.positionModulePm3 = data[i]['PositionModulePm NÂ°3'];
        myOrdreKoscFtel.referenceCableModulePm3 = data[i]['ReferenceCableModulePm NÂ°3'];
        myOrdreKoscFtel.informationFibreModulePm3 = data[i]['InformationFibreModulePm NÂ°3'];
        myOrdreKoscFtel.referenceCablePbo3 = data[i]['ReferenceCablePBO NÂ°3'];
        myOrdreKoscFtel.informationTubePbo3 = data[i]['InformationTubePBO NÂ°3'];
        myOrdreKoscFtel.informationFibrePbo3 = data[i]['InformationFibrePBO NÂ°3'];
        myOrdreKoscFtel.connecteurPriseNumero3 = data[i]['ConnecteurPriseNumero NÂ°3'];
        myOrdreKoscFtel.connecteurPriseCouleur3 = data[i]['ConnecteurPriseCouleur NÂ°3'];
        myOrdreKoscFtel.oc4 = data[i]['OC 4'];
        myOrdreKoscFtel.nomModulePm4 = data[i]['NomModulePm NÂ°4'];
        myOrdreKoscFtel.positionModulePm4 = data[i]['PositionModulePm NÂ°4'];
        myOrdreKoscFtel.referenceCableModulePm4 = data[i]['ReferenceCableModulePm NÂ°4'];
        myOrdreKoscFtel.informationFibreModulePm4 = data[i]['InformationFibreModulePm NÂ°4'];
        myOrdreKoscFtel.referenceCablePbo4 = data[i]['ReferenceCablePBO NÂ°4'];
        myOrdreKoscFtel.informationTubePbo4 = data[i]['InformationTubePBO NÂ°4'];
        myOrdreKoscFtel.informationFibrePbo4 = data[i]['InformationFibrePBO NÂ°4'];
        myOrdreKoscFtel.connecteurPriseNumero4 = data[i]['ConnecteurPriseNumero NÂ°4'];
        myOrdreKoscFtel.connecteurPriseCouleur4 = data[i]['ConnecteurPriseCouleur NÂ°4'];
        myOrdreKoscFtel.reserve1 = data[i]['Reserve1'];
        myOrdreKoscFtel.reserve2 = data[i]['Reserve2'];
        myOrdreKoscFtel.reserve3 = data[i]['Reserve3'];
        myOrdreKoscFtel.reserve4 = data[i]['Reserve4'];
        myOrdreKoscFtel.autreInfos = data[i]['AutresInformations'];
        myOrdreKoscFtel.dateCrCommandePrise = data[i]['DateCrCommandePrise'];
        myOrdreKoscFtel.commentaire = data[i]['Commentaire'];
        myOrdreKoscFtel.referencePrestationPm = data[i]['ReferencePrestationPm'];
        myOrdreKoscFtel.typeCommandeRetenu = data[i]['TypeCommandeRetenu'];
        myOrdreKoscFtel.typeProjectionGeographique = data[i]['TypeProjectionGeographique'];
        myOrdreKoscFtel.motifKoCrCommandePrise = data[i]['MotifKoCrCommandePrise'];
        myOrdreKoscFtel.racordementLong = this.convertBoleen(data[i]['RaccordementLong']);
        return myOrdreKoscFtel;
    }

    private constructWorkOrder(data: any, i: number) {
        let myOrdreKoscWork = new OrdreKoscVo();
        if (myOrdreKoscWork.etatDemandeKoscVo == null) {
            myOrdreKoscWork.etatDemandeKoscVo = new EtatDemandeKoscVo();
        }
        //save technicien
        if (data[i]['tech_reference'] != null) {
            if (myOrdreKoscWork.technicienVo == null) {
                myOrdreKoscWork.technicienVo = new TechnicienVo();
            }
            myOrdreKoscWork.technicienVo.identifiant = data[i]['tech_reference'];
        }
        //save Operateur
        if (myOrdreKoscWork.operatorVo == null) {
            myOrdreKoscWork.operatorVo = new OperatorVo();
        }
        myOrdreKoscWork.operatorVo.reference = data[i]['customer_operator'];
        myOrdreKoscWork.operatorVo.libelle = data[i]['customer_operator'];
        if (myOrdreKoscWork.operatorVo.libelle == null || myOrdreKoscWork.operatorVo.libelle == '') {
            myOrdreKoscWork.operatorVo = null;
        }
        myOrdreKoscWork.etatDemandeKoscVo.code = 'initialisation-wo';
        myOrdreKoscWork.reference = data[i]['kosc_order_ref'];
        myOrdreKoscWork.referenceWorkOrder = data[i]['work_order_ref'];
        myOrdreKoscWork.supplier = data[i]['supplier'];
        myOrdreKoscWork.workOrderType = data[i]['work_order_type'];
        myOrdreKoscWork.supplierServiceCode = data[i]['supplier_service_code'];
        myOrdreKoscWork.submissionDate = DateUtils.toDateForExcel(data[i]['submission_date']);
        myOrdreKoscWork.productCode = data[i]['product_code'];
        myOrdreKoscWork.productLabel = data[i]['product_label'];
        myOrdreKoscWork.provider = data[i]['provider'];
        myOrdreKoscWork.providerProduct = data[i]['provider_product'];
        myOrdreKoscWork.gtr = data[i]['gtr'];
        myOrdreKoscWork.category = data[i]['category'];
        myOrdreKoscWork.providerFileType = data[i]['provider_file_type'];
        myOrdreKoscWork.slid = data[i]['slid'];
        myOrdreKoscWork.koscSplitterPosition = data[i]['kosc_splitter_position'];
        myOrdreKoscWork.existingOtp = data[i]['existing_otp'];
        myOrdreKoscWork.otpRef = data[i]['otp_ref'];
        myOrdreKoscWork.profile = data[i]['profile'];
        myOrdreKoscWork.company = data[i]['company_name'];
        myOrdreKoscWork.endCustumorSiret = data[i]['siret'];
        myOrdreKoscWork.endCustumorFirstName = data[i]['first_name'];
        myOrdreKoscWork.endCustumorLastName = data[i]['last_name'];
        myOrdreKoscWork.endCustumorZipcode = data[i]['zip_code'];
        if (myOrdreKoscWork.departementVo == null) {
            myOrdreKoscWork.departementVo = new DepartementVo();
        }
        myOrdreKoscWork.departementVo.code = data[i]['zip_code'].toString().substring(0, 2);
        myOrdreKoscWork.departementVo.libelle = data[i]['zip_code'].toString().substring(0, 2);
        myOrdreKoscWork.endCustumorStreetName = data[i]['street_name'];
        myOrdreKoscWork.endCustumorStreetNumber = data[i]['street_number'];
        myOrdreKoscWork.endCustumorCity = data[i]['city'];/////////
        myOrdreKoscWork.endCustumorStairs = data[i]['stairs'];
        myOrdreKoscWork.endCustumorFloor = data[i]['floor'];
        myOrdreKoscWork.endCustumorContactFirstName = data[i]['end_customer_contact_first_name'];
        myOrdreKoscWork.endCustumorContactLastName = data[i]['end_customer_contact_last_name'];
        myOrdreKoscWork.endCustumorContactPhone = data[i]['end_customer_contact_phone'];
        myOrdreKoscWork.endCustumorContactCellPhone = data[i]['end_customer_contact_cellphone'];
        myOrdreKoscWork.endCustumorContactEmail = data[i]['end_customer_contact_email'];
        myOrdreKoscWork.providerSlId = data[i]['provider_slid'];
        myOrdreKoscWork.endCustumorBuilding = data[i]['building_name'];
        myOrdreKoscWork.buildingCode = data[i]['building_code'];
        myOrdreKoscWork.dateRdv = DateUtils.toDateWithHour(data[i]['intervention_start_datetime']);
        myOrdreKoscWork.dateInterventionTechniqueFin = DateUtils.toDateWithHour(data[i]['intervention_end_datetime']);
        myOrdreKoscWork.operatorComment = data[i]['operator_comment'];
        myOrdreKoscWork.koscContactFirstName = data[i]['kosc_contact_first_name'];
        myOrdreKoscWork.koscContactLastName = data[i]['kosc_contact_last_name'];
        myOrdreKoscWork.koscContactPhone = data[i]['kosc_contact_phone'];
        myOrdreKoscWork.koscContactCellphone = data[i]['kosc_contact_cellphone'];
        myOrdreKoscWork.koscContactEmail1 = data[i]['kosc_contact_email_1'];
        myOrdreKoscWork.koscContactEmail2 = data[i]['kosc_contact_email_2'];
        myOrdreKoscWork.koscContactEmail3 = data[i]['kosc_contact_email_3'];
        myOrdreKoscWork.koscComment = data[i]['kosc_comments'];
        myOrdreKoscWork.cpeInstall = data[i]['cpe_install'];
        myOrdreKoscWork.otpDisplacement = data[i]['otp_displacement'];
        myOrdreKoscWork.ontType = data[i]['ont_type'];
        return myOrdreKoscWork;
    }

    private isNotNumber(data: any) {
        return (typeof data !== 'number' || isNaN(data));
    }

    private isDateAndHeureNull(date: any, heure: any) {
        return ((date === undefined || date === null) && (heure === null || heure === undefined));
    }

    private isDateNull(date: any) {
        return (date === undefined || date === null);
    }

    private isDateAndHeureNumber(date: any, heure: any) {
        return ((typeof date === 'number' && typeof heure === 'number') || isFinite(date + heure));
    }

    private constructDataBase(data: any, i: number) {
        let myOrdreKoscData = new OrdreKoscVo();
        if (myOrdreKoscData.etatDemandeKoscVo == null) {
            myOrdreKoscData.etatDemandeKoscVo = new EtatDemandeKoscVo();
        }
        //save technicien
        // if( myOrdreKoscData.technicienVo==null){
        //     myOrdreKoscData.technicienVo=new TechnicienVo();
        // }
        // myOrdreKoscData.technicienVo.identifiant=data[i]['tech_reference'];
        //save Operateur
        if (myOrdreKoscData.operatorVo == null) {
            myOrdreKoscData.operatorVo = new OperatorVo();
        }
        myOrdreKoscData.operatorVo.libelle = data[i]['customer_operator'];
        myOrdreKoscData.operatorVo.reference = data[i]['customer_operator'];
        if (myOrdreKoscData.operatorVo.libelle == null || myOrdreKoscData.operatorVo.libelle == '') {
            myOrdreKoscData.operatorVo = null;
        }
        if (myOrdreKoscData.departementVo == null) {
            myOrdreKoscData.departementVo = new DepartementVo();
        }
        myOrdreKoscData.endCustumorZipcode = data[i]['zip_code']; //////zip code
        myOrdreKoscData.departementVo.code = data[i]['zip_code'].toString().substring(0, 2); //////zip code
        myOrdreKoscData.departementVo.libelle = data[i]['zip_code'].toString().substring(0, 2);
        myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        myOrdreKoscData.reference = data[i]['kosc_order_ref'];
        myOrdreKoscData.referenceWorkOrder = data[i]['work_order_ref'];
        myOrdreKoscData.workOrderType = data[i]['work_order_type'];
        myOrdreKoscData.supplierServiceCode = data[i]['supplier_service_code'];
        myOrdreKoscData.submissionDate = this.convertDate(data[i]['submission_date']);
        myOrdreKoscData.productCode = data[i]['product_code'];
        myOrdreKoscData.productLabel = data[i]['product_label'];
        myOrdreKoscData.provider = data[i]['provider'];
        myOrdreKoscData.providerProduct = data[i]['provider_product'];
        myOrdreKoscData.providerFileType = data[i]['provider_file_type'];
        myOrdreKoscData.existingOtp = data[i]['existing_otp'];
        myOrdreKoscData.profile = data[i]['profile'];
        myOrdreKoscData.company = data[i]['company_name'];
        myOrdreKoscData.endCustumorSiret = data[i]['siret'];
        myOrdreKoscData.endCustumorFirstName = data[i]['first_name'];
        myOrdreKoscData.endCustumorLastName = data[i]['last_name'];
        myOrdreKoscData.endCustumorStreetNumber = data[i]['street_number'];
        myOrdreKoscData.endCustumorStreetName = data[i]['street_name'];
        myOrdreKoscData.endCustumorCity = data[i]['city'];
        myOrdreKoscData.endCustumorBuilding = data[i]['building_name'];
        myOrdreKoscData.endCustumorStairs = data[i]['stairs'];
        myOrdreKoscData.endCustumorFloor = data[i]['floor'];
        myOrdreKoscData.endCustumorContactFirstName = data[i]['end_customer_contact_first_name'];
        myOrdreKoscData.endCustumorContactLastName = data[i]['end_customer_contact_last_name'];
        myOrdreKoscData.endCustumorContactPhone = data[i]['end_customer_contact_phone'];
        myOrdreKoscData.endCustumorContactCellPhone = data[i]['end_customer_contact_cellphone'];
        myOrdreKoscData.endCustumorContactEmail = data[i]['end_customer_contact_email'];
        myOrdreKoscData.providerSlId = data[i]['provider_slid'];
        myOrdreKoscData.referenceCommandePriseInterneOC = data[i]['ReferenceCommandePriseInterneOC'];
        myOrdreKoscData.referencePrise = data[i]['ReferencePrise'];
        myOrdreKoscData.referencePTO = data[i]['ReferencePrise'];
        myOrdreKoscData.referencePrestationPrise = data[i]['ReferencePrestationPrise'];
        myOrdreKoscData.commantaireCloture = data[i]['Commentaire']; ////////pas sure!!!!!!!!
        myOrdreKoscData.referencePm = data[i]['Reference Pm'];
        myOrdreKoscData.referencePmTechnique = data[i]['ReferencePmTechnique'];
        myOrdreKoscData.localisationPm = data[i]['LocalisationPm'];
        myOrdreKoscData.referencePbo = data[i]['ReferencePBO'];
        myOrdreKoscData.localisationPbo = data[i]['LocalisationPBO'];
        myOrdreKoscData.coordonnePboY = data[i]['CoordonneePBOY'];
        myOrdreKoscData.hauteurPbo = data[i]['HauteurPBO'];
        myOrdreKoscData.typeMaterielPbo = data[i]['TypeMaterielPBO'];
        myOrdreKoscData.typePbo = data[i]['TypePBO'];
        myOrdreKoscData.conditionSyndics = data[i]['ConditionsSyndic'];
        myOrdreKoscData.typeRacordementPboPto = data[i]['TypeRaccoPBPTO'];
        myOrdreKoscData.autreInfosPboPto = data[i]['AutresInfosPBOPTO'];
        myOrdreKoscData.codeAccesImmeuble = data[i]['CodeAccesImmeuble'];
        myOrdreKoscData.contacteImmeuble = data[i]['ContactsImmeuble'];
        myOrdreKoscData.pmaAccessible = data[i]['Pmaccessible'];
        myOrdreKoscData.infoObtentionCle = data[i]['InfoObtentionCle'];
        myOrdreKoscData.localeIpm = data[i]['CodeLocalPM'];//pas sure!!!!!
        myOrdreKoscData.contactsSyndic = data[i]['ContactsSyndic'];
        myOrdreKoscData.oc1 = data[i]['OC 1'];
        myOrdreKoscData.nomModulePm1 = data[i]['NomModulePm NÂ°1'];
        myOrdreKoscData.positionModulePm1 = data[i]['PositionModulePm NÂ°1'];
        myOrdreKoscData.referenceCableModulePm1 = data[i]['ReferenceCableModulePm NÂ°1'];
        myOrdreKoscData.informationFibreModulePm1 = data[i]['InformationFibreModulePm NÂ°1'];
        myOrdreKoscData.referenceCablePbo1 = data[i]['ReferenceCablePBO NÂ°1'];
        myOrdreKoscData.informationTubePbo1 = data[i]['InformationTubePBO NÂ°1'];
        myOrdreKoscData.informationFibrePbo1 = data[i]['InformationFibrePBO NÂ°1'];
        myOrdreKoscData.connecteurPriseNumero1 = data[i]['ConnecteurPriseNumero NÂ°1'];
        myOrdreKoscData.connecteurPriseCouleur1 = data[i]['ConnecteurPriseCouleur NÂ°1'];
        myOrdreKoscData.oc2 = data[i]['OC 2'];
        myOrdreKoscData.nomModulePm2 = data[i]['NomModulePm NÂ°2'];
        myOrdreKoscData.positionModulePm2 = data[i]['PositionModulePm NÂ°2'];
        myOrdreKoscData.referenceCableModulePm2 = data[i]['ReferenceCableModulePm NÂ°2'];
        myOrdreKoscData.informationFibreModulePm2 = data[i]['InformationFibreModulePm NÂ°2'];
        myOrdreKoscData.referenceCablePbo2 = data[i]['ReferenceCablePBO NÂ°2'];
        myOrdreKoscData.informationTubePbo2 = data[i]['InformationTubePBO NÂ°2'];
        myOrdreKoscData.informationFibrePbo2 = data[i]['InformationFibrePBO NÂ°2'];
        myOrdreKoscData.connecteurPriseNumero2 = data[i]['ConnecteurPriseNumero NÂ°2'];
        myOrdreKoscData.connecteurPriseCouleur2 = data[i]['ConnecteurPriseCouleur NÂ°2'];
        myOrdreKoscData.oc3 = data[i]['OC 3'];
        myOrdreKoscData.nomModulePm3 = data[i]['NomModulePm NÂ°3'];
        myOrdreKoscData.positionModulePm3 = data[i]['PositionModulePm NÂ°3'];
        myOrdreKoscData.referenceCableModulePm3 = data[i]['ReferenceCableModulePm NÂ°3'];
        myOrdreKoscData.informationFibreModulePm3 = data[i]['InformationFibreModulePm NÂ°3'];
        myOrdreKoscData.referenceCablePbo3 = data[i]['ReferenceCablePBO NÂ°3'];
        myOrdreKoscData.informationTubePbo3 = data[i]['InformationTubePBO NÂ°3'];
        myOrdreKoscData.informationFibrePbo3 = data[i]['InformationFibrePBO NÂ°3'];
        myOrdreKoscData.connecteurPriseNumero3 = data[i]['ConnecteurPriseNumero NÂ°3'];
        myOrdreKoscData.connecteurPriseCouleur3 = data[i]['ConnecteurPriseCouleur NÂ°3'];
        myOrdreKoscData.oc4 = data[i]['OC 4'];
        myOrdreKoscData.nomModulePm4 = data[i]['NomModulePm NÂ°4'];
        myOrdreKoscData.positionModulePm4 = data[i]['PositionModulePm NÂ°4'];
        myOrdreKoscData.referenceCableModulePm4 = data[i]['ReferenceCableModulePm NÂ°4'];
        myOrdreKoscData.informationFibreModulePm4 = data[i]['InformationFibreModulePm NÂ°4'];
        myOrdreKoscData.referenceCablePbo4 = data[i]['ReferenceCablePBO NÂ°4'];
        myOrdreKoscData.informationTubePbo4 = data[i]['InformationTubePBO NÂ°4'];
        myOrdreKoscData.informationFibrePbo4 = data[i]['InformationFibrePBO NÂ°4'];
        myOrdreKoscData.connecteurPriseNumero4 = data[i]['ConnecteurPriseNumero NÂ°4'];
        myOrdreKoscData.connecteurPriseCouleur4 = data[i]['ConnecteurPriseCouleur NÂ°4'];
        myOrdreKoscData.reserve1 = data[i]['Reserve1'];
        myOrdreKoscData.reserve2 = data[i]['Reserve2'];
        myOrdreKoscData.reserve3 = data[i]['Reserve3'];
        myOrdreKoscData.reserve4 = data[i]['Reserve4'];
        myOrdreKoscData.raccoLong = data[i]['RaccordementLong'];
        // myOrdreKoscData.racordementLong = this.convertBoleen(data[i]['RaccordementLong']);
        myOrdreKoscData.datePremierAppel = this.convertDate(data[i]['1 er appel ']);
        myOrdreKoscData.dateDeuxiemeAppel = this.convertDate(data[i]['2 eme appel ']);
        myOrdreKoscData.dateTroisiemeAppel = this.convertDate(data[i]['3 eme appel ']);
        myOrdreKoscData.dateAppelReplanification = this.convertDate(data[i]['Re Planification']);
        /*if (typeof data[i]['Date intervention Technicien'] + data[i]['heure de RDV'] !== 'number' || isNaN(data[i]['Date intervention Technicien'] + data[i]['heure de RDV'])) {
            this.messageService.add({ severity: 'warn',
                summary: 'Warning',
                detail: 'Date Rdv contient une valeur non date : O=' + myOrdreKoscData.reference
            });
        }else {
            myOrdreKoscData.dateRdv = DateUtils.ExcelDateToTsDate(data[i]['Date intervention Technicien'] + data[i]['heure de RDV']);
        }*/
        var dateIntervetion = data[i]['Date intervention Technicien'];
        var heureIntervetion = data[i]['heure de RDV'];
        if (typeof heureIntervetion === 'string') {
            // Check if the string matches the format "hh:mm:ss"
            const timeRegex = /^\d{2}:\d{2}:\d{2}$/;
            if (timeRegex.test(heureIntervetion)) {
                // Convert the time string to a number in Excel time units
                const timeParts = heureIntervetion.split(':');
                const hours = parseInt(timeParts[0]);
                const minutes = parseInt(timeParts[1]);
                const seconds = parseInt(timeParts[2]);
                const excelTime = (hours / 24) + (minutes / (24 * 60)) + (seconds / (24 * 60 * 60));
                myOrdreKoscData.dateRdv = DateUtils.ExcelDateToTsDate(dateIntervetion + excelTime);
                myOrdreKoscData.dateRdvErdvInit = DateUtils.ExcelDateToTsDate(dateIntervetion + excelTime);
            } else {
                console.log("Error: heure de RDV is not in the format 'hh:mm:ss'");
            }
        } else {
            myOrdreKoscData.dateRdv = DateUtils.ExcelDateToTsDate(dateIntervetion + heureIntervetion);
            myOrdreKoscData.dateRdvErdvInit = DateUtils.ExcelDateToTsDate(dateIntervetion + heureIntervetion);
        }
        myOrdreKoscData.reconfirmation = data[i]['Reconfirmation'];
        myOrdreKoscData.heureDeRDV = heureIntervetion;
        myOrdreKoscData.informationTubeModulePmNum4 = data[i]['InformationTubeModulePm NÂ°4'];
        myOrdreKoscData.informationTubeModulePmNum3 = data[i]['InformationTubeModulePm NÂ°3'];
        myOrdreKoscData.informationTubeModulePmNum2 = data[i]['InformationTubeModulePm NÂ°2'];
        myOrdreKoscData.informationTubeModulePmNum1 = data[i]['InformationTubeModulePm NÂ°1'];
        myOrdreKoscData.autreInfos = data[i]['AutresInformations'];
        myOrdreKoscData.codeAccesSousSol = data[i]['CodeAccesSousSol'];
        myOrdreKoscData.coordonneePBOX = data[i]['CoordonneePBOX'];
        myOrdreKoscData.typeProjectionGeographique = data[i]['TypeProjectionGeographique'];
        myOrdreKoscData.typeCommandeRetenu = data[i]['TypeCommandeRetenu'];
        myOrdreKoscData.referencePrestationPm = data[i]['ReferencePrestationPm'];
        myOrdreKoscData.commentaire = data[i]['Commentaire'];
        myOrdreKoscData.motifKoCrCommandePrise = data[i]['MotifKoCrCommandePrise'];
        myOrdreKoscData.etatCrCommandePrise = data[i]['EtatCrCommandePrise'];
        myOrdreKoscData.supplier = data[i]['supplier'];
        myOrdreKoscData.slid = data[i]['slid'];
        myOrdreKoscData.koscContactFirstName = data[i]['kosc_contact_first_name'];
        myOrdreKoscData.koscContactLastName = data[i]['kosc_contact_last_name'];
        myOrdreKoscData.koscContactPhone = data[i]['kosc_contact_phone'];
        myOrdreKoscData.koscContactEmail1 = data[i]['kosc_contact_email_1'];
        myOrdreKoscData.koscContactEmail2 = data[i]['kosc_contact_email_2'];
        myOrdreKoscData.koscContactEmail3 = data[i]['kosc_contact_email_3'];
        myOrdreKoscData.koscSplitterPosition = data[i]['kosc_splitter_position'];
        myOrdreKoscData.koscComment = data[i]['kosc_comments'];
        myOrdreKoscData.otpRef = data[i]['otp_ref'];
        myOrdreKoscData.operatorComment = data[i]['operator_comment'];
        myOrdreKoscData.koscContactCellphone = data[i]['kosc_contact_cellphone'];
        myOrdreKoscData.gtr = data[i]['gtr'];
        myOrdreKoscData.category = data[i]['category'];
        myOrdreKoscData.buildingCode = data[i]['building_code'];
        myOrdreKoscData.dateCrCommandePrise = data[i]['DateCrCommandePrise'];//to check the date later !!!!!
        myOrdreKoscData.typeCommandeRetenu = data[i]['TypeCommandeRetenu'];
        myOrdreKoscData.typeProjectionGeographique = data[i]['TypeProjectionGeographique'];
        myOrdreKoscData.codeAccesSousSol = data[i]['CodeAccesSousSol'];
        myOrdreKoscData.autreInfos = data[i]['AutresInformations'];
        myOrdreKoscData.informationTubeModulePm1 = data[i]['InformationTubeModulePm NÂ°1'];
        myOrdreKoscData.informationTubeModulePm2 = data[i]['InformationTubeModulePm NÂ°2'];
        myOrdreKoscData.informationTubeModulePm3 = data[i]['InformationTubeModulePm NÂ°3'];
        myOrdreKoscData.informationTubeModulePm4 = data[i]['InformationTubeModulePm NÂ°4'];
        myOrdreKoscData.reserve5 = data[i]['Reserve5'];
        myOrdreKoscData.commentaireCA = data[i]['Commentaire CA'];
        myOrdreKoscData.cpeInstall = data[i]['cpe_install'];
        myOrdreKoscData.otpDisplacement = data[i]['otp_displacement'];
        myOrdreKoscData.ontType = data[i]['ont_type'];
        myOrdreKoscData.type = 'kosc';
        myOrdreKoscData.erdv = data[i]['ERDV'];
        myOrdreKoscData.codeDecharge = data[i]['decharge'];
        myOrdreKoscData.numeroDechargeOrange = data[i]['decharge orange'];
        myOrdreKoscData.pboReel = data[i]['PBO reel'];
        myOrdreKoscData.typePbo = data[i]['type PBO'];
        myOrdreKoscData.referencePTO = data[i]['PTO'];
        if (typeof data[i]['heure CRI'] === 'string') {
            // Check if the string matches the format "hh:mm:ss"
            const timeRegex = /^\d{2}:\d{2}:\d{2}$/;
            if (timeRegex.test(heureIntervetion)) {
                // Convert the time string to a number in Excel time units
                const timeParts = data[i]['heure CRI'].split(':');
                const hours = parseInt(timeParts[0]);
                const minutes = parseInt(timeParts[1]);
                const seconds = parseInt(timeParts[2]);
                const excelTime = (hours / 24) + (minutes / (24 * 60)) + (seconds / (24 * 60 * 60));
                myOrdreKoscData.dateCri = DateUtils.ExcelDateToTsDate(data[i]['date CRI'] + excelTime);
            } else {
                console.log("Error: heure de RDV is not in the format 'hh:mm:ss'");
            }
        } else {
            myOrdreKoscData.dateCri = DateUtils.ExcelDateToTsDate(data[i]['date CRI'] + data[i]['heure CRI']);
        }
        //save technicien
        if (data[i]['techniciens'] != null) {
            if (myOrdreKoscData.technicienVo == null) {
                myOrdreKoscData.technicienVo = new TechnicienVo();
            }
            myOrdreKoscData.technicienVo.identifiant = data[i]['techniciens'];
        }
        //save CauseKoOk
        if (data[i]['Raisons KO'] != null) {
            if (myOrdreKoscData.causeKoOkVo == null) {
                myOrdreKoscData.causeKoOkVo = new CauseKoOkVo();
            }
            myOrdreKoscData.causeKoOkVo.code = data[i]['Raisons KO'];
        }
        /* myOrdreKoscData.etatDemandeKoscVo.code = 'initialisation-wo';
         myOrdreKoscData.etatDemandeKoscVo.libelle = 'initialisation-wo';
         if (!this.isDateAndHeureNull(dateIntervetion, heureIntervetion)
             && this.isDateAndHeureNumber(dateIntervetion, heureIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'planifié';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Planifié';
             myOrdreKoscData.etatDemandeKoscVo.style = 'info';
         } else if (data[i]['ERDV'] == 'true' && this.isDateAndHeureNull(dateIntervetion, heureIntervetion)
             && !this.isDateAndHeureNumber(dateIntervetion, heureIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'initialisation-erdv';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Initialisation ERDV';
             myOrdreKoscData.etatDemandeKoscVo.style = 'info';
         } else if (dateIntervetion == 'cmd annulee' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'cmd-annulee';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'CMD Annulée';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'ot annule' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'ot-annule';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'OT Annulé';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'en attente' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'en-attente';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'En Attente';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'injoignable' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'client-injoinable';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Client Injoinable';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'mauvaise adresse' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'autre';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Autre';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'autre' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'autre';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Autre';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'refus client' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'refus-client';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Refus Client';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'mauvais contact' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'mauvais-contact';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'Mauvais Contact';
             myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
         } else if (dateIntervetion == 'ok' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'ok';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'ok';
             myOrdreKoscData.etatDemandeKoscVo.style = 'success';
         } else if (dateIntervetion == 'ko' && this.isNotNumber(dateIntervetion)) {
             myOrdreKoscData.etatDemandeKoscVo.code = 'ko';
             myOrdreKoscData.etatDemandeKoscVo.libelle = 'ko';
             myOrdreKoscData.etatDemandeKoscVo.style = 'danger';
         } else {
             var statut = data[i]['statut'];
             if (statut !== undefined && statut == 'KO') {
                 myOrdreKoscData.etatDemandeKoscVo.code = 'ko';
                 myOrdreKoscData.etatDemandeKoscVo.libelle = 'ko';
                 myOrdreKoscData.etatDemandeKoscVo.style = 'danger';
             } else if (statut !== undefined && statut == 'OK') {
                 myOrdreKoscData.etatDemandeKoscVo.code = 'ok';
                 myOrdreKoscData.etatDemandeKoscVo.libelle = 'ok';
                 myOrdreKoscData.etatDemandeKoscVo.style = 'success';
             }
         }*/
        myOrdreKoscData.etatDemandeKoscVo.code = 'initialisation-wo';
        myOrdreKoscData.etatDemandeKoscVo.libelle = 'initialisation-wo';
        if (!this.isDateAndHeureNull(dateIntervetion, heureIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'planifié';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Planifié';
            myOrdreKoscData.etatDemandeKoscVo.style = 'info';
        }
        if (data[i]['ERDV'] == 'true' && this.isDateNull(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'initialisation-erdv';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Initialisation ERDV';
            myOrdreKoscData.etatDemandeKoscVo.style = 'info';
        }
        if (dateIntervetion == 'cmd annulee' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'cmd-annulee';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'CMD Annulée';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'ot annule' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'ot-annule';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'OT Annulé';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'en attente' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'en-attente';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'En Attente';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'injoignable' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'client-injoinable';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Client Injoinable';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'mauvaise adresse' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'autre';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Autre';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'autre' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'autre';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Autre';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'refus client' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'refus-client';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Refus Client';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'mauvais contact' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'mauvais-contact';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'Mauvais Contact';
            myOrdreKoscData.etatDemandeKoscVo.style = 'warning';
        }
        if (dateIntervetion == 'ok' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'ok';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'ok';
            myOrdreKoscData.etatDemandeKoscVo.style = 'success';
        }
        if (dateIntervetion == 'ko' && this.isNotNumber(dateIntervetion)) {
            myOrdreKoscData.etatDemandeKoscVo.code = 'ko';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'ko';
            myOrdreKoscData.etatDemandeKoscVo.style = 'danger';
        }
        if (data[i]['statut'] === 'KO') {
            myOrdreKoscData.etatDemandeKoscVo.code = 'ko';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'ko';
            myOrdreKoscData.etatDemandeKoscVo.style = 'danger';
        }
        if (data[i]['statut'] === 'OK') {
            myOrdreKoscData.etatDemandeKoscVo.code = 'ok';
            myOrdreKoscData.etatDemandeKoscVo.libelle = 'ok';
            myOrdreKoscData.etatDemandeKoscVo.style = 'success';
        }
        return myOrdreKoscData;
    }

    private isFtl(data: any) {
        if (data["ReferenceCommandePriseInterneOC"] != undefined) return true;
        else return false;
    }

    private isErdv(data: any) {
        if (data["intervention_start_datetime"] != undefined && data["intervention_start_datetime"] != '') return true;
        else return false;
    }

    private isKosc(data: any) {
        if (data["work_order_ref"] != undefined && data["work_order_ref"] != '') return true;
        else return false;
    }

    private constructMessage(koscOrdres: Array<OrdreKoscVo>): string {
        let message = '';
        koscOrdres.forEach(e => message += ('O=' + e.reference + ', WO= ' + e.referenceWorkOrder + ', Type= ' + e.type));
        return message;
    }
}
