import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pbo-reel-admin',
    templateUrl: './pbo-reel-admin.component.html',
    styleUrls: ['./pbo-reel-admin.component.css']
})
export class PboReelAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
