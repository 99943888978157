import {TemplateEmailReplanificationVo} from './TemplateEmailReplanification.model';
import {TemplateEmailFtlVo} from './TemplateEmailFtl.model';
import {TemplateEmailClotureVo} from './TemplateEmailCloture.model';
import {TemplateEmailPlanificationVo} from './TemplateEmailPlanification.model';
import {TemplateSuiviVo} from './TemplateSuivi.model';
import {TemplateEmailRefusVo} from './TemplateEmailRefus.model';
import {TemplateEmailConfirmationClientVo} from './TemplateEmailConfirmationClient.model';
import {TemplateEmailClientInjoinableKoscVo} from './TemplateEmailClientInjoinableKosc.model';
import {TemplateEmailMauvaisContactVo} from './TemplateEmailMauvaisContact.model';
import {TemplateEmailClientInjoinableVo} from './TemplateEmailClientInjoinable.model';
import {TemplateEmailCriVo} from './TemplateEmailCri.model';
import {
    TemplateEmailReportDemandeManeoClientInjoignableVo
} from "./TemplateEmailReportDemandeManeoClientInjoignable.model";
import {
    TemplateEmailReportDemandeManeoClientJoignableRefusVo
} from "./TemplateEmailReportDemandeManeoClientJoignableRefus.model";
import {
    TemplateEmailReportDemandeManeoClientJoignableAccepteVo
} from "./TemplateEmailReportDemandeManeoClientJoignableAccepte.model";
import {
    TemplateEmailReportDemandeClientClientJoignableVo
} from "./TemplateEmailReportDemandeClientClientJoignable.model";
import {
    TemplateEmailReportDemandeClientClientInjoignableVo
} from "./TemplateEmailReportDemandeClientClientInjoignable.model";
import {TemplateEmailGardeEnMainVo} from "./TemplateEmailGardeEnMain.model";
import {TemplateEmailPropositionVo} from "./TemplateEmailProposition.model";
import {TemplateEmailRappel48HeuresVo} from "./TemplateEmailRappel48Heures.model";

export class DefaultTemplateConfigurationVo {
    public id: number;
    public emailKosc: string;
    public emailManeo: string;
    public fromCri: string;
    public toCri: string;
    public toPlanification: string;
    public toProposition: string;
    public toClientInjoinable: string;
    public toConfirmationClient: string;
    public ccProposition: string;
    public ccFtl: string;
    public ccCloture: string;
    public ccSuivi: string;
    public ccClientInjoinable: string;
    public ccPlanification: string;
    public ccReplanification: string;
    public ccRefus: string;
    public ccClientInjoinableKosc: string;
    public ccConfirmationClient: string;
    public ccMauvaisContact: string;
    public ccCri: string;
    public ccGardeEnMain: string;
    public ccAutre: string;
    public ccReportDemandeManeoClientInjoignable: string;
    public ccReportDemandeManeoClientJoignableAccepte: string;
    public ccReportDemandeManeoClientJoignableRefus: string;
    public ccReportDemandeClientClientInjoignable: string;
    public ccReportDemandeClientClientJoignable: string;
    public ccRappel48Heures: string;
    public toRappel48Heures: string;
    public enabled: null | boolean;
    public templateEmailFtlVo: TemplateEmailFtlVo;
    public templateEmailClotureVo: TemplateEmailClotureVo;
    public templateSuiviVo: TemplateSuiviVo;
    public templateEmailClientInjoinableVo: TemplateEmailClientInjoinableVo;
    public templateEmailPlanificationVo: TemplateEmailPlanificationVo;
    public templateEmailReplanificationVo: TemplateEmailReplanificationVo;
    public templateEmailRefusVo: TemplateEmailRefusVo;
    public templateEmailPropositionVo: TemplateEmailPropositionVo;
    public templateEmailClientInjoinableKoscVo: TemplateEmailClientInjoinableKoscVo;
    public templateEmailConfirmationClientVo: TemplateEmailConfirmationClientVo;
    public templateEmailMauvaisContactVo: TemplateEmailMauvaisContactVo;
    public templateEmailCriVo: TemplateEmailCriVo;
    public templateEmailReportDemandeManeoClientInjoignableVo: TemplateEmailReportDemandeManeoClientInjoignableVo;
    public templateEmailReportDemandeManeoClientJoignableAccepteVo: TemplateEmailReportDemandeManeoClientJoignableAccepteVo;
    public templateEmailReportDemandeManeoClientJoignableRefusVo: TemplateEmailReportDemandeManeoClientJoignableRefusVo;
    public templateEmailReportDemandeClientClientJoignableVo: TemplateEmailReportDemandeClientClientJoignableVo;
    public templateEmailReportDemandeClientClientInjoignableVo: TemplateEmailReportDemandeClientClientInjoignableVo;
    public templateEmailGardeEnMainVo: TemplateEmailGardeEnMainVo;
    public templateEmailRappel48HeuresVo: TemplateEmailRappel48HeuresVo;
}
