import {TemplateEmailReplanificationVo} from '../template/TemplateEmailReplanification.model';
import {TemplateEmailPlanificationVo} from '../template/TemplateEmailPlanification.model';
import {TemplateSuiviVo} from '../template/TemplateSuivi.model';
import {OperatorVo} from '../referentiel/Operator.model';
import {SourceReplanificationVo} from '../referentiel/SourceReplanification.model';
import {TemplateEmailClientInjoinableKoscVo} from '../template/TemplateEmailClientInjoinableKosc.model';
import {CauseKoOkVo} from '../referentiel/CauseKoOk.model';
import {TemplateEmailClientInjoinableVo} from '../template/TemplateEmailClientInjoinable.model';
import {EtatDemandeKoscVo} from '../referentiel/EtatDemandeKosc.model';
import {TemplateEmailFtlVo} from '../template/TemplateEmailFtl.model';
import {TemplateEmailClotureVo} from '../template/TemplateEmailCloture.model';
import {TemplateEmailRefusVo} from '../template/TemplateEmailRefus.model';
import {TemplateEmailConfirmationClientVo} from '../template/TemplateEmailConfirmationClient.model';
import {DepartementVo} from '../technicien/Departement.model';
import {TemplateEmailMauvaisContactVo} from '../template/TemplateEmailMauvaisContact.model';
import {TechnicienVo} from '../technicien/Technicien.model';
import {TemplateEmailCriVo} from '../template/TemplateEmailCri.model';
import {User} from "../security/User.model";
import {TypeForfaitDevisVo} from "../referentiel/TypeForfaitDevis";
import {PboReelVo} from "../referentiel/PboReel";
import {TemplateEmailPropositionVo} from "../template/TemplateEmailProposition.model";
import {TemplateEmailRappel48HeuresVo} from "../template/TemplateEmailRappel48Heures.model";
import {StatutEmailVo} from "../template/StatutEmail.model";

export class OrdreKoscVo {
    public id: number;
    public oldData: string;
    public nbrReplanification: number;
    public attachment: Uint8Array;
    public fileName: string;
    public attachments: Array<Uint8Array>;
    public filesNames: string[];
    public maxResults: number;
    public page: number;
    public erdv: null | boolean;
    public assignedErdv: null | boolean;
    public file: File;
    public confort: null | boolean;
    public delaiPriseRdvParHeure: string;
    public trancheJour: number;
    public reference: string;
    public referenceWorkOrder: string;
    public codeDecharge: string;
    public supplierServiceCode: string;
    public dateDebutTraitement: Date;
    public endCustumorName: string;
    public endCustumorSiret: string;
    public endCustumorFirstName: string;
    public endCustumorLastName: string;
    public endCustumorZipcode: string;
    public endCustumorStreetName: string;
    public endCustumorStreetNumber: string;
    public endCustumorCity: string;
    public endCustumorBuilding: string;
    public endCustumorStairs: string;
    public endCustumorFloor: string;
    public endCustumorContactFirstName: string;
    public endCustumorContactLastName: string;
    public endCustumorContactPhone: string;
    public endCustumorContactCellPhone: string;
    public endCustumorContactEmail: string;
    public company: string;
    public referenceDossier: string;
    public submissionDate: Date;
    public coordonnePboY: string;
    public hauteurPbo: string;
    public typeMaterielPbo: string;
    public typePbo: string;
    public conditionSyndics: string;
    public typeRacordementPboPto: string;
    public autreInfosPboPto: string;
    public codeAccesImmeuble: string;
    public contacteImmeuble: string;
    public pmaAccessible: string;
    public infoObtentionCle: string;
    public localeIpm: string;
    public contactsSyndic: string;
    public raccoLong: string;
    public racordementLong: null | boolean;
    public oc1: string;
    public nomModulePm1: string;
    public positionModulePm1: string;
    public referenceCableModulePm1: string;
    public referenceTubeModulePm1: string;
    public informationFibreModulePm1: string;
    public referenceCablePbo1: string;
    public informationTubePbo1: string;
    public informationFibrePbo1: string;
    public connecteurPriseNumero1: string;
    public connecteurPriseCouleur1: string;
    public reserve1: string;
    public oc2: string;
    public nomModulePm2: string;
    public positionModulePm2: string;
    public referenceCableModulePm2: string;
    public referenceTubeModulePm2: string;
    public informationFibreModulePm2: string;
    public referenceCablePbo2: string;
    public informationTubePbo2: string;
    public informationFibrePbo2: string;
    public connecteurPriseNumero2: string;
    public connecteurPriseCouleur2: string;
    public reserve2: string;
    public oc3: string;
    public nomModulePm3: string;
    public positionModulePm3: string;
    public referenceCableModulePm3: string;
    public referenceTubeModulePm3: string;
    public informationFibreModulePm3: string;
    public referenceCablePbo3: string;
    public informationTubePbo3: string;
    public informationFibrePbo3: string;
    public connecteurPriseNumero3: string;
    public connecteurPriseCouleur3: string;
    public reserve3: string;
    public oc4: string;
    public nomModulePm4: string;
    public positionModulePm4: string;
    public referenceCableModulePm4: string;
    public referenceTubeModulePm4: string;
    public informationFibreModulePm4: string;
    public referenceCablePbo4: string;
    public informationTubePbo4: string;
    public informationFibrePbo4: string;
    public connecteurPriseNumero4: string;
    public connecteurPriseCouleur4: string;
    public reserve4: string;
    public pboReel: string;
    public numeroSerieOnt: string;
    public hotline: string;
    public mutationPbo: string;
    public workOrderType: string;
    public product: string;
    public productCode: string;
    public productLabel: string;
    public provider: string;
    public providerFileType: string;
    public spliter: string;
    public existingOtp: null | boolean;
    public profile: string;
    public providerSlId: string;
    public referencePrestationPrise: string;
    public referencePm: string;
    public referencePmTechnique: string;
    public localisationPm: string;
    public providerProduct: string;
    public referencePbo: string;
    public localisationPbo: string;
    public referencePrise: string;
    public datePremierAppel: Date;
    public dateDeuxiemeAppel: Date;
    public dateTroisiemeAppel: Date;
    public datePriseRdv: Date;
    public dateRdv: string | Date;
    public dateRdvFinale: string | Date;
    public dateOuverture: Date;
    public commentaireClient: string;
    public commentaireKosc: string;
    public objetPlanification: string;
    public corpsPlanification: string;
    public envoyePlanification: null | boolean;
    public dateEnvoiPlanification: Date;
    public fromPlanification: string;
    public toPlanification: string;
    public ccPlanification: string;
    public dateAppelReplanification: Date;
    public objetReport: string;
    public corpsReport: string;
    public fromReport: string;
    public toReport: string;
    public ccReport: string;
    public envoyeReport: null | boolean;
    public dateEnvoiReport: Date;
    public objetReplanification: string;
    public corpsReplanification: string;
    public fromReplanification: string;
    public toReplanification: string;
    public ccReplanification: string;
    public envoyeReplanification: null | boolean;
    public dateEnvoiReplanification: Date;
    public objetReinitialisation: string;
    public corpsReinitialisation: string;
    public fromReinitialisation: string;
    public toReinitialisation: string;
    public ccReinitialisation: string;
    public envoyeReinitialisation: null | boolean;
    public dateEnvoiReinitialisation: Date;
    public objetSuiviCdd: string;
    public corpsSuiviCdd: string;
    public fromSuiviCdd: string;
    public toSuiviCdd: string;
    public ccSuiviCdd: string;
    public envoyeSuiviCdd: null | boolean;
    public dateEnvoiSuiviCdd: Date;
    public objetRefus: string;
    public corpsRefus: string;
    public fromRefus: string;
    public toRefus: string;
    public ccRefus: string;
    public envoyeRefus: null | boolean;
    public dateEnvoiRefus: Date;
    public objetProposition: string;
    public corpsProposition: string;
    public fromProposition: string;
    public toProposition: string;
    public ccProposition: string;
    public envoyeProposition: null | boolean;
    public dateEnvoiProposition: Date;
    public objetMauvaisContact: string;
    public corpsMauvaisContact: string;
    public fromMauvaisContact: string;
    public toMauvaisContact: string;
    public ccMauvaisContact: string;
    public envoyeMauvaisContact: null | boolean;
    public dateEnvoiMauvaisContact: Date;
    public objetConfirmationClient: string;
    public corpsConfirmationClient: string;
    public fromConfirmationClient: string;
    public toConfirmationClient: string;
    public ccConfirmationClient: string;
    public envoyeConfirmationClient: null | boolean;
    public dateEnvoiConfirmationClient: Date;
    public objetCri: string;
    public corpsCri: string;
    public fromCri: string;
    public toCri: string;
    public ccCri: string;
    public envoyeCri: null | boolean;
    public dateEnvoiCri: Date;
    public objetRappel48Heures: string;
    public corpsRappel48Heures: string;
    public fromRappel48Heures: string;
    public toRappel48Heures: string;
    public ccRappel48Heures: string;
    public envoyeRappel48Heures: null | boolean;
    public dateEnvoiRappel48Heures: Date;
    public objetGardeEnMain: string;
    public corpsGardeEnMain: string;
    public fromGardeEnMain: string;
    public toGardeEnMain: string;
    public ccGardeEnMain: string;
    public envoyeGardeEnMain: null | boolean;
    public dateEnvoiGardeEnMain: Date;
    public objetFtl: string;
    public corpsFtl: string;
    public fromFtl: string;
    public toFtl: string;
    public ccFtl: string;
    public envoyeFtl: null | boolean;
    public dateEnvoiFtl: Date;
    public dateInterventionTechniqueFin: string | Date;
    public objetClientInjoinable: string;
    public corpsClientInjoinable: string;
    public fromClientInjoinable: string;
    public toClientInjoinable: string;
    public ccClientInjoinable: string;
    public envoyeClientInjoinable: null | boolean;
    public dateEnvoiClientInjoinable: Date;
    public objetAutre: string;
    public corpsAutre: string;
    public fromAutre: string;
    public toAutre: string;
    public ccAutre: string;
    public envoyeAutre: null | boolean;
    public dateEnvoiAutre: Date;
    public objetClientInjoinableKosc: string;
    public corpsClientInjoinableKosc: string;
    public fromClientInjoinableKosc: string;
    public toClientInjoinableKosc: string;
    public ccClientInjoinableKosc: string;
    public envoyeClientInjoinableKosc: null | boolean;
    public dateEnvoiClientInjoinableKosc: Date;
    public commentaireTechnicien: string;
    public commantaireCloture: string;
    public objetCloture: string;
    public corpsCloture: string;
    public envoyeCloture: null | boolean;
    public dateEnvoiCloture: Date;
    public emailCloturePieceJoints: string;
    public objetSuivi: string;
    public corpsSuivi: string;
    public envoyeSuivi: null | boolean;
    public dateEnvoiSuivi: Date;
    public dateErdv: Date;
    public referenceCommandePriseInterneOC: string;
    public type: string;
    public dateDebutTraitementMax: string;
    public dateDebutTraitementMin: string;
    public submissionDateMax: string;
    public submissionDateMin: string;
    public submissionDateMaxAsDate: Date;
    public submissionDateMinAsDate: Date;
    public datePremierAppelMax: string;
    public datePremierAppelMin: string;
    public dateDeuxiemeAppelMax: string;
    public dateDeuxiemeAppelMin: string;
    public dateTroisiemeAppelMax: string;
    public dateTroisiemeAppelMin: string;
    public datePriseRdvMax: string;
    public datePriseRdvMin: string;
    public dateRdvMax: string;
    public dateRdvMin: string;
    public dateRdvFinaleMax: string;
    public dateRdvFinaleMin: string;
    public dateRdvAsDate: Date;
    public dateRdvMaxAsDate: Date;
    public dateRdvMinAsDate: Date;
    public dateRdvFinaleMaxAsDate: Date;
    public dateRdvFinaleMinAsDate: Date;
    public dateOuvertureMax: string;
    public dateOuvertureMin: string;
    public dateEnvoiPlanificationMax: string;
    public dateEnvoiPlanificationMin: string;
    public dateAppelReplanificationMax: string;
    public dateAppelReplanificationMin: string;
    public dateEnvoiReportMax: string;
    public dateEnvoiReportMin: string;
    public dateEnvoiReplanificationMax: string;
    public dateEnvoiReplanificationMin: string;
    public dateEnvoiRefusMax: string;
    public dateEnvoiRefusMin: string;
    public dateEnvoiMauvaisContactMax: string;
    public dateEnvoiMauvaisContactMin: string;
    public dateEnvoiConfirmationClientMax: string;
    public dateEnvoiConfirmationClientMin: string;
    public dateEnvoiCriMax: string;
    public dateEnvoiCriMin: string;
    public dateEnvoiFtlMax: string;
    public dateEnvoiFtlMin: string;
    public dateInterventionTechniqueFinMax: string;
    public dateInterventionTechniqueFinMin: string;
    public dateEnvoiClientInjoinableMax: string;
    public dateEnvoiClientInjoinableMin: string;
    public dateEnvoiAutreMax: string;
    public dateEnvoiAutreMin: string;
    public dateEnvoiClientInjoinableKoscMax: string;
    public dateEnvoiClientInjoinableKoscMin: string;
    public dateEnvoiClotureMax: string;
    public dateEnvoiClotureMin: string;
    public dateEnvoiSuiviMax: string;
    public dateEnvoiSuiviMin: string;
    public operatorVo: OperatorVo;
    public departementVo: DepartementVo;
    public technicienVo: TechnicienVo;
    public technicienVos: Array<TechnicienVo>;
    public templateEmailPlanificationVo: TemplateEmailPlanificationVo;
    public templateEmailReplanificationVo: TemplateEmailReplanificationVo;
    public templateEmailRefusVo: TemplateEmailRefusVo;
    public templateEmailPropositionVo: TemplateEmailPropositionVo;
    public templateEmailMauvaisContactVo: TemplateEmailMauvaisContactVo;
    public templateEmailConfirmationClientVo: TemplateEmailConfirmationClientVo;
    public templateEmailCriVo: TemplateEmailCriVo;
    public templateEmailRappel48HeuresVo: TemplateEmailRappel48HeuresVo;
    public templateEmailFtlVo: TemplateEmailFtlVo;
    public templateEmailClientInjoinableVo: TemplateEmailClientInjoinableVo;
    public templateEmailClientInjoinableKoscVo: TemplateEmailClientInjoinableKoscVo;
    public etatDemandeKoscVo: EtatDemandeKoscVo;
    public etatDemandeKoscOldVo: EtatDemandeKoscVo;
    public etatDemandeKoscVos: Array<EtatDemandeKoscVo>;
    public statutEmailVo: StatutEmailVo;
    public statutEmailVos: Array<StatutEmailVo>;
    public templateEmailClotureVo: TemplateEmailClotureVo;
    public templateSuiviVo: TemplateSuiviVo;
    public causeKoOkVo: CauseKoOkVo;
    public typeForfaitDevisVo: TypeForfaitDevisVo;
    public pboReelVo: PboReelVo;
    public sourceReplanificationVo: SourceReplanificationVo;
    public dateDernierAppel: Date;
    public numeroDernierAppel: number;
    public nbrHeureDateSubmissionAndNow: number;
    public nbrHeureDateSubmissionAndNowMin: number;
    public nbrHeureDateSubmissionAndNowMax: number;
    public delaiPriseRdv: number;
    public delaiEnvoiCri: number;
    public delaiPriseRdvMin: number;
    public delaiPriseRdvMax: number;
    public delaiPlanification: number;
    public delaiPlanificationMin: number;
    public delaiPlanificationMax: number;
    public nbrHeureDateRdvAndNow: number;
    public nbrHeureDateRdvAndNowMin: number;
    public nbrHeureDateRdvAndNowMax: number;
    public objetReportDemandeManeoClientInjoignable: string;
    public corpsReportDemandeManeoClientInjoignable: string;
    public fromReportDemandeManeoClientInjoignable: string;
    public toReportDemandeManeoClientInjoignable: string;
    public ccReportDemandeManeoClientInjoignable: string;
    public envoyeReportDemandeManeoClientInjoignable: null | boolean;
    public dateEnvoiReportDemandeManeoClientInjoignable: Date;
    public objetReportDemandeManeoClientJoignableAccepte: string;
    public corpsReportDemandeManeoClientJoignableAccepte: string;
    public fromReportDemandeManeoClientJoignableAccepte: string;
    public toReportDemandeManeoClientJoignableAccepte: string;
    public ccReportDemandeManeoClientJoignableAccepte: string;
    public envoyeReportDemandeManeoClientJoignableAccepte: null | boolean;
    public dateEnvoiReportDemandeManeoClientJoignableAccepte: Date;
    public objetReportDemandeManeoClientJoignableRefus: string;
    public corpsReportDemandeManeoClientJoignableRefus: string;
    public fromReportDemandeManeoClientJoignableRefus: string;
    public toReportDemandeManeoClientJoignableRefus: string;
    public ccReportDemandeManeoClientJoignableRefus: string;
    public envoyeReportDemandeManeoClientJoignableRefus: null | boolean;
    public dateEnvoiReportDemandeManeoClientJoignableRefus: Date;
    public objetReportDemandeClientClientInjoignable: string;
    public corpsReportDemandeClientClientInjoignable: string;
    public fromReportDemandeClientClientInjoignable: string;
    public toReportDemandeClientClientInjoignable: string;
    public ccReportDemandeClientClientInjoignable: string;
    public envoyeReportDemandeClientClientInjoignable: null | boolean;
    public dateEnvoiReportDemandeClientClientInjoignable: Date;
    public objetReportDemandeClientClientJoignable: string;
    public corpsReportDemandeClientClientJoignable: string;
    public fromReportDemandeClientClientJoignable: string;
    public toReportDemandeClientClientJoignable: string;
    public ccReportDemandeClientClientJoignable: string;
    public envoyeReportDemandeClientClientJoignable: null | boolean;
    public dateEnvoiReportDemandeClientClientJoignable: Date;
    public dateCri: Date;
    public dateGardeEnMain: Date;
    public dateCriMin: string;
    public dateCriMax: string;
    public dateCriMinAsDate: Date;
    public dateCriMaxAsDate: Date;
    public montantDevis: string;
    public supplier: string;
    public slid;
    public koscContactFirstName: string;
    public koscContactLastName: string;
    public koscContactPhone: string;
    public koscContactEmail1: string;
    public koscContactEmail2: string;
    public koscContactEmail3: string;
    public koscSplitterPosition: string;
    public koscComment: string;
    public otpRef: string;
    public operatorComment: string;
    public koscContactCellphone: string;
    public gtr: string;
    public category: string;
    public buildingCode: string;
    public motifKoCrCommandePrise: string;
    public dateCrCommandePrise: string;
    public typeCommandeRetenu: string;
    public typeProjectionGeographique: string;
    public coordonneePBOX: string;
    public codeAccesSousSol: string;
    public autreInfos: string;
    public informationTubeModulePm1: string;
    public informationTubeModulePm2: string;
    public informationTubeModulePm3: string;
    public informationTubeModulePm4: string;
    public reserve5: string;
    public userPlanification: User;
    public userReplanification: User;
    public userReinitialisation: User;
    public userSuiviCdd: User;
    public userRefus;
    public userProposition;
    public userMauvaisContact: User;
    public userConfirmationClient: User;
    public userCri: User;
    public userRappel48Heures: User;
    public userGardeEnMain: User;
    public userFtl: User;
    public userClientInjoinable: User;
    public userAutre: User;
    public userClientInjoinableKosc: User;
    public userReportDemandeManeoClientInjoignable: User;
    public userReportDemandeManeoClientJoignableAccepte: User;
    public userReportDemandeManeoClientJoignableRefus: User;
    public userReportDemandeClientClientInjoignable: User;
    public userReportDemandeClientClientJoignable: User;
    public userImportation: User;
    public yearDateRdv: number;
    public monthDateRdv: number;
    public object: string;
    public from: string;
    public to: string;
    public cc: string;
    public corps: string;
    public envoi: null | boolean;
    public dateEnvoi: Date;
    public dateEnvoiMin: string;
    public dateEnvoiMax: string;
    public userEnvoi: User;
    public objectSearch: string;
    public fromSearch: string;
    public toSearch: string;
    public ccSearch: string;
    public envoiSearch: null | boolean;
    public dateEnvoiMinSearch: string;
    public dateEnvoiMaxSearch: string;
    public userEnvoiSearch: User;
    public emailTo: string;
    public emailCc: string;
    public emailDe: string;
    public emailObjet: string;
    public emailCorps: string;
    public emailEnvoye: boolean;
    public result: number;
    public etatCrCommandePrise: string;
    public reconfirmation: string;
    public heureDeRDV: string;
    public informationTubeModulePmNum4: string;
    public informationTubeModulePmNum3: string;
    public informationTubeModulePmNum2: string;
    public informationTubeModulePmNum1: string;
    public referencePrestationPm: string;
    public commentaire: string;
    public commentaireCA: string;
    public numeroDechargeOrange: string;
    public referencePTO: string;
    public fileUploadPaths: string;
    public dateRdvErdvInit: string | Date;
    public dateRdvErdvInitMin: string;
    public dateRdvErdvInitMax: string;
    public dateRdvReelle: string | Date;
    public dateRdvReelleMin: string;
    public dateRdvReelleMax: string;
    public dateReceptionCri: string | Date;
    public dateReceptionCriMin: string;
    public dateReceptionCriMax: string;
    public rdvNonHonore: null | boolean;
    public rdvGem: null | boolean;
    public cpeInstall: string;
    public otpDisplacement: string;
    public ontType: string;
    public emailRappelValide: null | boolean;
}
