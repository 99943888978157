import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-region-admin',
    templateUrl: './region-admin.component.html',
    styleUrls: ['./region-admin.component.css']
})
export class RegionAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
