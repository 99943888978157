import {Component, OnInit} from '@angular/core';
import {
    TemplateEmailReportDemandeManeoClientJoignableAccepteService
} from 'src/app/controller/service/template/TemplateEmailReportDemandeManeoClientJoignableAccepte.service';
import {
    TemplateEmailReportDemandeManeoClientJoignableAccepteVo
} from 'src/app/controller/model/template/TemplateEmailReportDemandeManeoClientJoignableAccepte.model';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {StringUtilService} from 'src/app/controller/service/referentiel/StringUtil.service';

@Component({
    selector: 'app-template-email-report-demande-maneo-client-joignable-accepte-edit-admin',
    templateUrl: './template-email-report-demande-maneo-client-joignable-accepte-edit-admin.component.html',
    styleUrls: ['./template-email-report-demande-maneo-client-joignable-accepte-edit-admin.component.css']
})
export class TemplateEmailReportDemandeManeoClientJoignableAccepteEditAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private templateEmailReportDemandeManeoClientJoignableAccepteService: TemplateEmailReportDemandeManeoClientJoignableAccepteService
        , private stringUtilService: StringUtilService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    _submitted = false;

    get submitted(): boolean {
        return this._submitted;
    }

    set submitted(value: boolean) {
        this._submitted = value;
    }

    private _errorMessages = new Array<string>();

    get errorMessages(): string[] {
        return this._errorMessages;
    }

    set errorMessages(value: string[]) {
        this._errorMessages = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle = true;

    get validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet = true;
    get validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet = value;
    }

    _validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps = true;

    get validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps(): boolean {
        return this._validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps;
    }

    set validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps(value: boolean) {
        this._validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps = value;
    }

    get templateEmailReportDemandeManeoClientJoignableAcceptes(): Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo> {
        return this.templateEmailReportDemandeManeoClientJoignableAccepteService.templateEmailReportDemandeManeoClientJoignableAcceptes;
    }

    set templateEmailReportDemandeManeoClientJoignableAcceptes(value: Array<TemplateEmailReportDemandeManeoClientJoignableAccepteVo>) {
        this.templateEmailReportDemandeManeoClientJoignableAccepteService.templateEmailReportDemandeManeoClientJoignableAcceptes = value;
    }

    get selectedTemplateEmailReportDemandeManeoClientJoignableAccepte(): TemplateEmailReportDemandeManeoClientJoignableAccepteVo {
        return this.templateEmailReportDemandeManeoClientJoignableAccepteService.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte;
    }

    set selectedTemplateEmailReportDemandeManeoClientJoignableAccepte(value: TemplateEmailReportDemandeManeoClientJoignableAccepteVo) {
        this.templateEmailReportDemandeManeoClientJoignableAccepteService.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte = value;
    }

    get editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(): boolean {
        return this.templateEmailReportDemandeManeoClientJoignableAccepteService.editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog;
    }

    set editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog(value: boolean) {
        this.templateEmailReportDemandeManeoClientJoignableAccepteService.editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatEdit;
    }

    get dateFormatColumn() {
        return environment.dateFormatEdit;
    }

    ngOnInit(): void {
    }

    public edit() {
        this.submitted = true;
        this.validateForm();
        if (this.errorMessages.length === 0) {
            this.editWithShowOption(false);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreurs',
                detail: 'Merci de corrigé les erreurs sur le formulaire'
            });
        }
    }

    public editWithShowOption(showList: boolean) {
        this.templateEmailReportDemandeManeoClientJoignableAccepteService.edit().subscribe(templateEmailReportDemandeManeoClientJoignableAccepte => {
            const myIndex = this.templateEmailReportDemandeManeoClientJoignableAcceptes.findIndex(e => e.id === this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte.id);
            this.templateEmailReportDemandeManeoClientJoignableAcceptes[myIndex] = templateEmailReportDemandeManeoClientJoignableAccepte;
            this.editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = false;
            this.submitted = false;
            this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte = new TemplateEmailReportDemandeManeoClientJoignableAccepteVo();
        }, error => {
            console.log(error);
        });
    }

    hideEditDialog() {
        this.editTemplateEmailReportDemandeManeoClientJoignableAccepteDialog = false;
        this.setValidation(true);
    }

    private setValidation(value: boolean) {
        this.validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle = value;
        this.validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet = value;
        this.validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps = value;
    }

    private validateForm(): void {
        this.errorMessages = new Array<string>();
        this.validateTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle();
        this.validateTemplateEmailReportDemandeManeoClientJoignableAccepteObjet();
        this.validateTemplateEmailReportDemandeManeoClientJoignableAccepteCorps();
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte.libelle)) {
            this.errorMessages.push('Libelle non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteLibelle = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableAccepteObjet() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte.objet)) {
            this.errorMessages.push('Objet non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteObjet = true;
        }
    }

    private validateTemplateEmailReportDemandeManeoClientJoignableAccepteCorps() {
        if (this.stringUtilService.isEmpty(this.selectedTemplateEmailReportDemandeManeoClientJoignableAccepte.corps)) {
            this.errorMessages.push('Corps non valide');
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps = false;
        } else {
            this.validTemplateEmailReportDemandeManeoClientJoignableAccepteCorps = true;
        }
    }
}
