import {Component, OnInit} from '@angular/core';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {PboReelVo} from "../../../../../../controller/model/referentiel/PboReel";
import {PboReelService} from "../../../../../../controller/service/referentiel/PboReel.service";

@Component({
    selector: 'app-pbo-reel-view-admin',
    templateUrl: './pbo-reel-view-admin.component.html',
    styleUrls: ['./pbo-reel-view-admin.component.css']
})
export class PboReelViewAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private pboReelService: PboReelService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    get pboReels(): Array<PboReelVo> {
        return this.pboReelService.pboReels;
    }

    set pboReels(value: Array<PboReelVo>) {
        this.pboReelService.pboReels = value;
    }

    get selectedPboReel(): PboReelVo {
        return this.pboReelService.selectedPboReel;
    }

    set selectedPboReel(value: PboReelVo) {
        this.pboReelService.selectedPboReel = value;
    }

    get viewPboReelDialog(): boolean {
        return this.pboReelService.viewPboReelDialog;
    }

    set viewPboReelDialog(value: boolean) {
        this.pboReelService.viewPboReelDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatView;
    }

    get dateFormatColumn() {
        return environment.dateFormatList;
    }

    ngOnInit(): void {
    }

    hideViewDialog() {
        this.viewPboReelDialog = false;
    }
}
