import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-statistic-kosc-admin',
    templateUrl: './statistic-kosc-admin.component.html',
    styleUrls: ['./statistic-kosc-admin.component.scss']
})
export class StatisticKoscAdminComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
