import {Component, OnInit} from '@angular/core';
import {TypeForfaitDevisService} from 'src/app/controller/service/referentiel/TypeForfaitDevis.service';
import {RoleService} from 'src/app/controller/service/security/role.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import {TypeForfaitDevisVo} from "../../../../../../controller/model/referentiel/TypeForfaitDevis";

@Component({
    selector: 'app-type-forfait-devis-view-admin',
    templateUrl: './type-forfait-devis-view-admin.component.html',
    styleUrls: ['./type-forfait-devis-view-admin.component.css']
})
export class TypeForfaitDevisViewAdminComponent implements OnInit {
    constructor(private datePipe: DatePipe, private typeForfaitDevisService: TypeForfaitDevisService
        , private roleService: RoleService
        , private messageService: MessageService
        , private router: Router
    ) {
    }

    get typeForfaitDeviss(): Array<TypeForfaitDevisVo> {
        return this.typeForfaitDevisService.typeForfaitDeviss;
    }

    set typeForfaitDeviss(value: Array<TypeForfaitDevisVo>) {
        this.typeForfaitDevisService.typeForfaitDeviss = value;
    }

// getters and setters

    get selectedTypeForfaitDevis(): TypeForfaitDevisVo {
        return this.typeForfaitDevisService.selectedTypeForfaitDevis;
    }

    set selectedTypeForfaitDevis(value: TypeForfaitDevisVo) {
        this.typeForfaitDevisService.selectedTypeForfaitDevis = value;
    }

    get viewTypeForfaitDevisDialog(): boolean {
        return this.typeForfaitDevisService.viewTypeForfaitDevisDialog;
    }

    set viewTypeForfaitDevisDialog(value: boolean) {
        this.typeForfaitDevisService.viewTypeForfaitDevisDialog = value;
    }

    get dateFormat() {
        return environment.dateFormatView;
    }

    get dateFormatColumn() {
        return environment.dateFormatList;
    }

// methods
    ngOnInit(): void {
    }

    hideViewDialog() {
        this.viewTypeForfaitDevisDialog = false;
    }
}
